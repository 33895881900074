import React, { Component } from "react";
import {
  IProviderBid,
  IProviderItemBid,
  IRestaurantArea,
  IRestaurantItem,
  IProvider,
  IProfile,
  ProfileRoles,
  IRestaurantCG,
} from "../../models/IFirestoreModels";
import { PrintTime, PrintDate, createExcelLink } from "../../util/functions";
import MultiSelect from "react-multi-select-component";
import {
  Icon,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import IApi from "../../api/IApi";

interface IBidSelectedProps {
  Api: IApi;
  Restaurant: IRestaurantCG;
  Profile: IProfile;
  ProviderCollection: Array<IProvider>;
  AreaCollection: Array<IRestaurantArea>;
  ProductCollection: Array<IRestaurantItem>;
  ProductCollectionOptions: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;
  CurrentBidSelected: IProviderBid;
  ProductsFilter: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;
  innerHeight: number;
  innerWidth: number;
}
interface IBidSelectedState {
  ProductsFilter: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;
  ProductBidList: Array<IProviderItemBid>;
  isExporting: boolean;
}
export default class BidSelected extends Component<
  IBidSelectedProps,
  IBidSelectedState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      ProductsFilter: [],
      ProductBidList: this.props.CurrentBidSelected.ProductBidList,
      isExporting: false,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    this.filterByProduct(this.props.ProductsFilter);
  }
  renderProductBidList(
    ProductBidList: Array<IProviderItemBid>
  ): Array<JSX.Element> {
    return ProductBidList.map((ProductBid: IProviderItemBid, index: number) => {
      const Product: IRestaurantItem = this.props.ProductCollection.find(
        (product) => product.ref?.path === ProductBid.ProductGUID?.path
      ) as IRestaurantItem;
      const AreaGUID: IRestaurantArea = Product
        ? (this.props.AreaCollection.find(
            (area) => area.ref?.path === Product.AreaGUID?.path
          ) as IRestaurantArea)
        : (undefined as any);
      return (
        <tr
          id={`${ProductBid.ProductGUID?.path}`}
          key={`Bid${index}${ProductBid.ProductGUID?.path}`}
          style={{
            backgroundColor: index % 2 ? "#DCDCDC" : "white",
            color: "black",
          }}
        >
          <td style={{ textAlign: "center" }}>{`${
            AreaGUID ? AreaGUID.AreaName : "N/A"
          }`}</td>
          <td>{`${Product ? Product.ProductName : "N/A"}`}</td>
          <td
            style={{ textAlign: "center", fontWeight: "bold" }}
          >{`$${ProductBid.ProductPackPrice}`}</td>
          <td
            style={{ textAlign: "center" }}
          >{`${ProductBid.ProductUnitPerPack}/${ProductBid.ProductUnitAmount} ${ProductBid.ProductUnitMesure}`}</td>
          <td>{`${ProductBid.ProductDescription}`}</td>
        </tr>
      );
    });
  }
  componentDidUpdate(
    prevProps: IBidSelectedProps,
    prevState: IBidSelectedState,
    snapshot: any
  ) {
    if (
      this.props.CurrentBidSelected.ref?.path !==
      prevProps.CurrentBidSelected.ref?.path
    ) {
      //ProductsFilter Config from Manager
      this.filterByProduct(this.props.ProductsFilter);
    }
  }
  filterByProduct(
    ProductsFilter: Array<{
      label: string;
      value: string;
      disabled?: boolean;
    }>
  ) {
    if (ProductsFilter.length > 0) {
      const filterIds: Array<string> = ProductsFilter.map((p) => p.value);
      let ProductBidList: Array<IProviderItemBid> = [];
      filterIds.forEach((id) => {
        let Product: IProviderItemBid = this.props.CurrentBidSelected.ProductBidList.find(
          (p) => p.ProductGUID && p.ProductGUID.path === id
        ) as IProviderItemBid;
        if (Product) {
          ProductBidList.push(Product);
        }
      });
      this.setState({ ProductBidList, ProductsFilter });
    } else {
      this.setState({
        ProductBidList: this.props.CurrentBidSelected.ProductBidList,
        ProductsFilter,
      });
    }
  }
  filterByProviderProducts(ProductFilter: string): Array<IProviderItemBid> {
    return this.props.CurrentBidSelected.ProductBidList.filter(
      (p) => p.ProductGUID?.path === ProductFilter
    );
  }
  async createExcel() {
    this.setState({ isExporting: true });
    const Path = `${
      this.props.Restaurant.RestaurantSubCollectionPaths
        .RestaurantItemBidCollectionPath
    }/Bid On ${PrintDate(this.props.CurrentBidSelected.ProductBidDay, true)}`;
    let Sheet: Array<Array<string>> = [
      ["KEY", "PRICE", "UNITS PER PACK", "UNIT AMOUNT", "UNIT", "DESCRIPTION"],
    ];
    this.props.CurrentBidSelected.ProductBidList.forEach((p) => {
      const Product = this.props.ProductCollection.find(
        (product) => product.ref?.id === p.ProductGUID?.id
      );
      Sheet.push([
        Product ? Product.ProductName : "N/A",
        `${p.ProductPackPrice}`,
        `${p.ProductUnitPerPack}`,
        `${p.ProductUnitAmount}`,
        `${p.ProductUnitMesure}`,
        `${p.ProductDescription}`,
      ]);
    });
    window.location = (await createExcelLink(this.Api, Path, [
      { SheetName: "Bid Products", SheetData: Sheet },
    ])) as any;
    this.setState({ isExporting: false });
  }
  render(): JSX.Element {
    const OverFlowStyle: React.CSSProperties = {
      width: "100%",
      height: `${this.props.innerHeight - 185}px`,
      overflowY: "scroll",
    };
    const BidTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const BidTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    const Provider: IProvider = this.props.ProviderCollection.find(
      (provider) =>
        provider.ref?.path === this.props.CurrentBidSelected.ProviderGUID?.path
    ) as IProvider;
    const ProviderName: string = `${Provider ? Provider.ProviderName : "N/A"}`;
    return (
      <div>
        <span style={{ position: "fixed", bottom: 15, right: 10, zIndex: 5 }}>
          <PrimaryButton
            disabled={
              this.state.isExporting ||
              (this.props.Profile.ProfileRole !== ProfileRoles.Dev &&
                !this.props.Profile.ProfileRole.includes(ProfileRoles.Admin))
            }
            onClick={() => this.createExcel()}
          >
            {this.state.isExporting ? (
              <Spinner size={SpinnerSize.small} />
            ) : (
              <span>
                <Icon iconName="Export" /> Export to Excel
              </span>
            )}
          </PrimaryButton>
        </span>
        <table style={BidTableStyle}>
          <thead style={BidTableHeadStyle}>
            <tr>
              <th style={{ width: "10%", textAlign: "center" }}>Date</th>
              <th style={{ width: "10%", textAlign: "center" }}>Time</th>
              <th style={{ width: "20%" }}>Vendor</th>
              <th style={{ width: "40%" }}>Search</th>
              <th style={{ width: "20%" }}>Items</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "center" }}>
                {PrintDate(this.props.CurrentBidSelected.ProductBidDay)}
              </td>
              <td style={{ textAlign: "center" }}>
                {PrintTime(this.props.CurrentBidSelected.ProductBidDay)}
              </td>
              <td>{ProviderName}</td>
              <td style={{ textAlign: "center", maxWidth: "500px" }}>
                <MultiSelect
                  options={this.props.ProductCollectionOptions}
                  value={this.state.ProductsFilter}
                  onChange={(value: any) => {
                    this.filterByProduct(value ? value : []);
                  }}
                  labelledBy={"Search Item Price..."}
                />
              </td>
              <td style={{ textAlign: "center" }}>
                {this.state.ProductBidList.length}
              </td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <div style={OverFlowStyle}>
          <table style={BidTableStyle}>
            <thead style={BidTableHeadStyle}>
              <tr>
                <th style={{ width: "15%" }}>Area</th>
                <th style={{ width: "20%" }}>Item</th>
                <th style={{ width: "10%" }}>Case Price</th>
                <th style={{ width: "15%" }}>Units per Case</th>
                <th style={{ width: "40%" }}>Description</th>
              </tr>
            </thead>
            <tbody>
              {this.renderProductBidList(this.state.ProductBidList)}
              <tr>
                <td>
                  <div style={{ height: "100px" }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
