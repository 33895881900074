import React, { Component } from "react";
import { IOrderItemModel } from "../../models/IOrderItemModel";
import { PrintDate } from "../../util/functions";
import firebase from "firebase/app";

interface IOrderItemTableRowProps {
  index: number;
  OrderItem: IOrderItemModel;
  amountChanged: (
    ProductGUIDRef: string,
    ProviderIndex: number,
    ProductOrderAmount: number
  ) => void;
}
interface IOrderItemTableRowState {
  ProductOrderAmount: string;
  ProvidersTotals: number;
  ProviderIndex: number;
  ProviderName: string;
  ProductDescription: string;
  ProductPackPrice: number;
  ProductUnitPerPack: number;
  ProductUnitAmount: number;
  ProductUnitMesure: string;
  ProductBidDay: string;
}
export default class OrderItemTableRow extends Component<
  IOrderItemTableRowProps,
  IOrderItemTableRowState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      ProductOrderAmount: "",
      ProvidersTotals: 0,
      ProviderIndex: 0,
      ProviderName: "",
      ProductDescription: "",
      ProductPackPrice: 0,
      ProductUnitPerPack: 0,
      ProductUnitAmount: 0,
      ProductUnitMesure: "",
      ProductBidDay: "",
    };
  }
  componentDidMount() {
    if (this.props.OrderItem.ProviderOrders) {
      this.setState({
        ProvidersTotals: this.props.OrderItem.ProviderOrders.length,
      });
      this.setProvider(this.state.ProviderIndex);
    }
  }
  componentDidUpdate(
    prevProps: IOrderItemTableRowProps,
    prevState: IOrderItemTableRowState
  ) {
    if (
      prevProps !== this.props ||
      prevState.ProviderIndex !== this.state.ProviderIndex
    ) {
      if (this.props.OrderItem.ProviderOrders) {
        this.setProvider(this.state.ProviderIndex);
      }
    }
  }
  setProvider = (ProviderIndex: number) => {
    if (this.props.OrderItem.ProviderOrders[ProviderIndex]) {
      let ProductOrderAmount: string = this.props.OrderItem.ProviderOrders[
        ProviderIndex
      ].Order.ProductOrderAmount.toString();
      if (ProductOrderAmount === "0" || ProductOrderAmount === "NaN") {
        ProductOrderAmount = "";
      }
      let ProviderName: string = this.props.OrderItem.ProviderOrders[
        ProviderIndex
      ].ProviderName;
      let ProductDescription: string = this.props.OrderItem.ProviderOrders[
        ProviderIndex
      ].Order.ProductDescription;
      let ProductPackPrice: number = this.props.OrderItem.ProviderOrders[
        ProviderIndex
      ].Order.ProductPackPrice;
      let ProductUnitPerPack: number = this.props.OrderItem.ProviderOrders[
        ProviderIndex
      ].Order.ProductUnitPerPack;
      let ProductUnitAmount: number = this.props.OrderItem.ProviderOrders[
        ProviderIndex
      ].Order.ProductUnitAmount;
      let ProductUnitMesure: string = this.props.OrderItem.ProviderOrders[
        ProviderIndex
      ].Order.ProductUnitMesure;
      let ProductBidDay: string = "";
      if (
        this.props.OrderItem.ProviderOrders[ProviderIndex].Order.ProductBidDay
      ) {
        let ProductBidDayDate: firebase.firestore.Timestamp = this.props
          .OrderItem.ProviderOrders[ProviderIndex].Order.ProductBidDay;
        ProductBidDay = PrintDate(ProductBidDayDate);
      }
      this.setState({
        ProductOrderAmount,
        ProviderName,
        ProductDescription,
        ProductPackPrice,
        ProductUnitPerPack,
        ProductUnitAmount,
        ProductUnitMesure,
        ProductBidDay,
      });
    }
  };
  switchProvider = () => {
    if (this.state.ProvidersTotals > 1) {
      let ProviderIndex: number =
        this.state.ProviderIndex + 1 < this.state.ProvidersTotals
          ? this.state.ProviderIndex + 1
          : 0;
      this.setState({ ProviderIndex });
    }
  };
  amountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ProductOrderAmount: event.target.value });
    if (this.props.OrderItem.Product.ref?.id) {
      this.props.amountChanged(
        this.props.OrderItem.Product.ref?.id,
        this.state.ProviderIndex,
        Number(event.target.value)
      );
    }
  };
  render(): JSX.Element {
    const OrderTableRowStyle: React.CSSProperties = {
      backgroundColor: this.props.index % 2 ? "#DCDCDC" : "white",
      color: "black",
    };
    const { Area, Product } = this.props.OrderItem;
    const {
      ProvidersTotals,
      ProviderIndex,
      ProviderName,
      ProductOrderAmount,
      ProductPackPrice,
      ProductUnitPerPack,
      ProductUnitAmount,
      ProductUnitMesure,
      ProductDescription,
      ProductBidDay,
    } = this.state;
    return (
      <tr style={OrderTableRowStyle}>
        <td style={{ width: "16%", textAlign: "center" }}>{Area.AreaName}</td>
        <td style={{ width: "20%" }}>{Product.ProductName}</td>
        <td style={{ width: "6%", textAlign: "center" }}>
          <input
            style={{ width: "80%" }}
            onChange={this.amountChanged}
            disabled={ProvidersTotals === 0}
            value={ProductOrderAmount}
            type="number"
            id={`OderAmount${Product.ref?.id}`}
          />
        </td>
        <td
          onClick={this.switchProvider}
          style={{
            width: "12%",
            textAlign: "center",
            cursor: ProvidersTotals <= 1 ? "" : "pointer",
          }}
        >
          {this.props.OrderItem.ProviderPreference
            ? `${this.props.OrderItem.ProviderPreference.ProviderName} Only`
            : ProvidersTotals === 0
            ? "N/A"
            : ProviderIndex
            ? `${ProviderName}`
            : `*${ProviderName}*`}
        </td>
        <td style={{ width: "8%", textAlign: "center", fontWeight: "bold" }}>
          {ProvidersTotals !== 0 && ProductPackPrice && `$${ProductPackPrice}`}
        </td>
        <td style={{ width: "10%", textAlign: "center" }}>
          {ProvidersTotals !== 0 &&
            `${ProductUnitPerPack}/${ProductUnitAmount} ${ProductUnitMesure}`}
        </td>
        <td style={{ width: "20%" }}>
          {ProvidersTotals !== 0 && ProductDescription}
        </td>
        <td style={{ width: "8%", textAlign: "center" }}>
          {ProvidersTotals !== 0 && ProductBidDay}
        </td>
      </tr>
    );
  }
}
