import React, { Component } from "react";
import IApi from "../../api/IApi";
import {
  IRestaurantItem,
  IRestaurantCG,
  IProvider,
  IRestaurantArea,
  ProviderStock,
  IProfile,
  ProfileRoles,
} from "../../models/IFirestoreModels";
import {
  Spinner,
  SpinnerSize,
  Dropdown,
  Label,
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
} from "office-ui-fabric-react";
import firebase from "firebase/app";
import CRUD, { CRUDPropertyType } from "../DevelopmentView/CRUD";
import EmailCreationManager from "../../util/EmailCreationManager";
import TextSMSCreationManager from "../../util/TextSMSCreationManager";

interface IModelEditorProductsProps {
  currentProfile: IProfile | null;
  Api: IApi;
  currentRestaurant: IRestaurantCG;
  innerHeight: number;
  innerWidth: number;
}
interface IModelEditorProductsState {
  isLoading: boolean;
  isLoadingOffCollections: boolean;
  SelectedArea: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProductCollection: Array<IRestaurantItem>;
  ProductSelectedRef: string;
  AreaCollection: Array<{ key: string; text: string; value: any }>;
  ProviderCollection: Array<{ key: string; text: string; value: any }>;
}
export default class ModelEditorProducts extends Component<
  IModelEditorProductsProps,
  IModelEditorProductsState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingOffCollections: true,
      SelectedArea: { path: "" } as any,
      ProductCollection: [],
      ProductSelectedRef: "",
      AreaCollection: [],
      ProviderCollection: [],
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (this.props.currentRestaurant) {
      this.setState({
        AreaCollection: (
          await this.Api.getCollect(
            this.props.currentRestaurant.RestaurantSubCollectionPaths
              .RestaurantAreaCollectionPath,
            undefined,
            undefined,
            "AreaList",
            "asc"
          )
        ).map((area: IRestaurantArea) => {
          return {
            key: area.ref?.path,
            text: area.AreaName,
            value: area.ref,
          } as any;
        }),
        ProviderCollection: (
          await this.Api.getCollect(
            this.props.currentRestaurant.RestaurantSubCollectionPaths
              .ProviderCollectionPath,
            undefined,
            undefined,
            "ProviderName",
            "asc"
          )
        ).map((provider: IProvider) => {
          return {
            key: provider.ref?.path,
            text: provider.ProviderName,
            value: provider.ref,
            ContactInfo: {
              ProfileContactName: provider.ProviderContactName,
              ProfileContactEmail: provider.ProviderContactEmail,
              ProfileContactPhone: provider.ProviderContactPhone,
            },
          } as any;
        }),
        isLoadingOffCollections: false,
      });
    }
  }
  componentDidUpdate(
    prevProps: IModelEditorProductsProps,
    prevState: IModelEditorProductsState,
    snapshot: any
  ) {
    if (
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
      prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  setSelectedArea = async (ref: string) => {
    const SelectedArea =
      ref === "*"
        ? { path: "*" }
        : this.state.AreaCollection.find((a) => a.key.toString() === ref)
            ?.value;
    this.setState({ SelectedArea, isLoading: true });
    this.Api.getCollect(
      this.props.currentRestaurant.RestaurantSubCollectionPaths
        .RestaurantItemCollectionPath,
      ref === "*"
        ? []
        : [{ fieldPath: "AreaGUID", opStr: "==", value: SelectedArea }],
      (ProductCollection) =>
        this.setState({
          ProductCollection,
          isLoading: false,
        }),
      "ProductList",
      "asc"
    );
  };
  printProductStockLeft(
    ProductStockLeft?: Array<ProviderStock>
  ): Array<JSX.Element> {
    let value: Array<JSX.Element> = [];
    if (ProductStockLeft && ProductStockLeft.length) {
      ProductStockLeft.sort((A, B) =>
        Number(A.StockLeft) > Number(B.StockLeft)
          ? -1
          : Number(B.StockLeft) > Number(A.StockLeft)
          ? 1
          : 0
      ).forEach((s, index) => {
        const provider = this.state.ProviderCollection.find(
          (p) => p.key === s.ProviderGUID?.path
        );
        if (index < 3) {
          value.push(
            index === 0 ? (
              <span
                key={s.ProviderGUID?.path}
              >{`${provider?.text}: ${s.StockLeft}`}</span>
            ) : (
              <span key={s.ProviderGUID?.path}>
                <br />
                {`${provider?.text}: ${s.StockLeft}`}
              </span>
            )
          );
        } else if (index === 3) {
          value.push(<span key={s.ProviderGUID?.path}>, ...</span>);
        }
      });
    }
    return value;
  }
  async getProductIndex(item: IRestaurantItem): Promise<Number> {
    let ProductCollection =
      this.state.SelectedArea.path === "*"
        ? this.state.ProductCollection.filter(
            (p) => p.AreaGUID?.path === item.AreaGUID?.path
          )
        : item.AreaGUID?.path === this.state.SelectedArea.path
        ? this.state.ProductCollection
        : await this.Api.getCollect(
            this.props.currentRestaurant.RestaurantSubCollectionPaths
              .RestaurantItemCollectionPath,
            [
              {
                fieldPath: "AreaGUID",
                opStr: "==",
                value: item.AreaGUID,
              },
            ],
            undefined,
            "ProductList",
            "desc",
            1
          );
    ProductCollection.sort((A, B) =>
      Number(A.ProductList) > Number(B.ProductList)
        ? 1
        : Number(A.ProductList) < Number(B.ProductList)
        ? -1
        : 0
    );
    return ProductCollection.length
      ? Number(ProductCollection[ProductCollection.length - 1].ProductList) + 1
      : 0;
  }
  render(): JSX.Element {
    const ProductSelected = this.state.ProductCollection.find(
      (p) => p.ref?.path === this.state.ProductSelectedRef
    );
    return (
      <div>
        {this.state.isLoadingOffCollections ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : (
          <Dropdown
            selectedKey={this.state.SelectedArea.path}
            options={[
              { key: "*", text: "All Areas", value: { path: "*" } },
            ].concat(this.state.AreaCollection)}
            onChange={(e, o) => {
              o && this.setSelectedArea(o.key.toString());
            }}
            style={{ marginTop: 5 }}
          />
        )}
        <hr />
        {this.state.isLoading ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : this.state.SelectedArea.path === "" ? (
          <Label>Please select an area first...</Label>
        ) : (
          <CRUD
            List={this.state.ProductCollection.map((i) => {
              return {
                ...i,
                ref: i.ref?.path,
                ProductStockLeft: this.printProductStockLeft(
                  i.ProductStockLeft
                ),
              };
            })}
            ItemName={"Item"}
            DisplayField={"ProductName"}
            Structure={[
              {
                Type: CRUDPropertyType.ID,
                Name: "ref",
              },
              {
                Type: CRUDPropertyType.Enum,
                Name: "AreaGUID",
                Enforced: true,
                DisplayName: "Area",
                IconName: "Move",
                Width: 10,
                Settings: {
                  refAsKey: "path",
                  Choices: this.state.AreaCollection,
                },
                BuiltInFilter: this.state.SelectedArea.path === "*",
              },
              {
                Type: CRUDPropertyType.Number,
                Name: "ProductList",
                DisplayName: "Area List",
                Hidden: true,
                BuiltInSort: this.state.SelectedArea.path !== "*",
              },
              {
                Type: CRUDPropertyType.String,
                Name: "ProductName",
                Enforced: true,
                DisplayName: "Item Name",
                IconName: "AllApps",
                Width: "15%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Enum,
                Name: "ProductUnitMesure",
                Enforced: true,
                DisplayName: "Measure",
                IconName: "Compare",
                Width: 10,
                Settings: {
                  Choices:
                    this.props.currentRestaurant.RestaurantUnitMesures.cases.map(
                      (c) => {
                        return {
                          key: c.replaceWith,
                          text: c.replaceWith,
                          value: c.replaceWith,
                        };
                      }
                    ),
                },
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Enum,
                Name: "ProductTags",
                DisplayName: "Tags",
                IconName: "Tag",
                Width: 15,
                Settings: {
                  Choices:
                    this.props.currentRestaurant.RestaurantProductTags.map(
                      (c) => {
                        return { key: c, text: c, value: c };
                      }
                    ),
                  Multiple: 10,
                },
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Enum,
                Name: "ProviderPreference",
                DisplayName: "Vendor Preference",
                IconName: "FavoriteStar",
                Width: 10,
                Settings: {
                  refAsKey: "path",
                  Choices: this.state.ProviderCollection,
                },
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "ProductStockLeft",
                DisplayName: "Vendor Stock",
                IconName: "ProductList",
                Width: "10%",
                Settings: { Cursor: true },
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Boolean,
                Name: "ProductOffSeason",
                DisplayName: "Show",
                IconName: "View",
                Width: "5%",
                Settings: {},
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Boolean,
                Name: "ProductPriceChangedNotify",
                DisplayName: "Notify",
                IconName: "Money",
                Width: "5%",
                Settings: {},
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Control,
                Name: "Control",
                Width: "20%",
              },
            ]}
            onDisable={(item, col) =>
              (this.props.currentProfile?.ProfileRole !== ProfileRoles.Dev &&
                col.Name === "@Delete") ||
              (col.Name === "ProductStockLeft" && item.ref === "NewItem")
            }
            onClick={(item, col) => {
              if (col.Name === "ProductStockLeft" && item.ref !== "NewItem") {
                this.setState({
                  ProductSelectedRef: item.ref as string,
                });
              }
            }}
            AddItem={true}
            AddItemExtra={"Add & Notify"}
            AddConfirm={true}
            onAdd={async (item, AddItemExtra) => {
              // console.log("ADD", item, AddItemExtra);
              try {
                let DuplicateProductName = await this.Api.getCollect(
                  this.props.currentRestaurant.RestaurantSubCollectionPaths
                    .RestaurantItemCollectionPath,
                  [
                    {
                      fieldPath: "ProductName",
                      opStr: "==",
                      value: item.ProductName,
                    },
                  ],
                  undefined,
                  undefined,
                  undefined,
                  1
                );
                if (DuplicateProductName.length !== 0) {
                  return "Duplicate Product Name";
                }
                await this.Api.addDoc(
                  this.props.currentRestaurant.RestaurantSubCollectionPaths
                    .RestaurantItemCollectionPath,
                  {
                    ...item,
                    ProductList: await this.getProductIndex(item as any),
                  }
                );
                if (AddItemExtra) {
                  //[Vendor] [Email]
                  let promiseArraySendEmails: Array<Promise<any>> = [];
                  this.state.ProviderCollection.forEach((Provider: any) => {
                    promiseArraySendEmails.push(
                      this.Api.sendEmail(
                        EmailCreationManager.newProductNotify(
                          Provider.ContactInfo,
                          item.ProductName,
                          this.props.currentRestaurant.RestaurantName
                        )
                      )
                    );
                    //[Vendor] [Text]
                    promiseArraySendEmails.push(
                      this.Api.sendTextSMS(
                        TextSMSCreationManager.newProductNotify(
                          Provider.ContactInfo,
                          item.ProductName,
                          this.props.currentRestaurant.RestaurantName
                        )
                      )
                    );
                  });
                  Promise.all(promiseArraySendEmails);
                }
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            UpdateConfirm={true}
            onUpdate={async (updates) => {
              // console.log("UPD", updates);
              try {
                let promiseUpdates: Array<Promise<void>> = [];
                updates.forEach(async (u) => {
                  if (u.changes.ProductName) {
                    let DuplicateProductName = await this.Api.getCollect(
                      this.props.currentRestaurant.RestaurantSubCollectionPaths
                        .RestaurantItemCollectionPath,
                      [
                        {
                          fieldPath: "ProductName",
                          opStr: "==",
                          value: u.changes.ProductName,
                        },
                      ],
                      undefined,
                      undefined,
                      undefined,
                      1
                    );
                    if (DuplicateProductName.length !== 0) {
                      return "Duplicate Product Name";
                    }
                  }
                  if (u.changes.ProviderPreference === null) {
                    u.changes.ProviderPreference =
                      firebase.firestore.FieldValue.delete();
                  }
                  if (u.changes.AreaGUID) {
                    u.changes.ProductList = await this.getProductIndex(
                      u.changes as any
                    );
                  }
                  promiseUpdates.push(this.Api.updDoc(u.id, u.changes));
                });
                await Promise.all(promiseUpdates);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error as string;
              }
            }}
            DeleteConfirm={true}
            onDelete={async (id) => {
              // console.log("DEL", id);
              try {
                await this.Api.delDoc(id);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            BuiltInFilter={true}
            SortField={
              this.state.SelectedArea.path === "*"
                ? "ProductName@Up"
                : "ProductList@Up"
            }
            BuiltInSort={true}
            BuiltInReSort={this.state.SelectedArea.path !== "*"}
            maxHeight={this.props.innerHeight}
            maxWidth={this.props.innerWidth}
          ></CRUD>
        )}
        <Dialog
          hidden={this.state.ProductSelectedRef === ""}
          onDismiss={() => {
            this.setState({ ProductSelectedRef: "" });
          }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: `${ProductSelected ? ProductSelected?.ProductName : ""}`,
            closeButtonAriaLabel: "Close",
          }}
          modalProps={{
            containerClassName: "ms-dialogMainOverride textDialogHalf",
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          <CRUD
            List={
              ProductSelected && ProductSelected.ProductStockLeft
                ? ProductSelected.ProductStockLeft.map((i) => {
                    const Provider = this.state.ProviderCollection.find(
                      (p) => p.key === i.ProviderGUID?.path
                    );
                    return {
                      ...i,
                      ref: `${i.ProviderGUID?.path}`,
                      ProviderName: Provider?.text,
                    };
                  })
                : []
            }
            ItemName={"Vendor Stock"}
            DisplayField={"ProviderName"}
            Structure={[
              {
                Type: CRUDPropertyType.ID,
                Name: "ref",
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "ProviderName",
                Hidden: true,
              },
              {
                Type: CRUDPropertyType.Enum,
                Name: "ProviderGUID",
                Enforced: true,
                DisplayName: "Vendor",
                IconName: "DeliveryTruck",
                Width: "40%",
                Settings: {
                  refAsKey: "path",
                  Choices: this.state.ProviderCollection,
                },
              },
              {
                Type: CRUDPropertyType.Number,
                Name: "StockLeft",
                Enforced: true,
                DisplayName: "Stock Left",
                IconName: "Quantity",
                Width: "20%",
              },
              {
                Type: CRUDPropertyType.Control,
                Name: "Control",
                Width: "40%",
              },
            ]}
            AddItem={true}
            AddConfirm={true}
            onAdd={async (item) => {
              // console.log(this.state.ProductSelectedRef, "ADD", item);
              try {
                if (ProductSelected) {
                  let DuplicateProviderGUID =
                    ProductSelected.ProductStockLeft &&
                    ProductSelected.ProductStockLeft.findIndex(
                      (s) => s.ProviderGUID?.path === item.ProviderGUID?.path
                    ) !== -1;
                  if (DuplicateProviderGUID) {
                    return "Duplicate Vendor";
                  }
                  await this.Api.updDoc(
                    this.state.ProductSelectedRef as string,
                    {
                      ProductStockLeft: ProductSelected.ProductStockLeft
                        ? ProductSelected.ProductStockLeft.concat(item)
                        : [item],
                    }
                  );
                }
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            UpdateConfirm={true}
            onUpdate={async (updates) => {
              // console.log(this.state.ProductSelectedRef, "UPD", updates);
              try {
                if (ProductSelected && ProductSelected.ProductStockLeft) {
                  let Stock = ProductSelected.ProductStockLeft;
                  updates.forEach((u) => {
                    if (u.changes.ProviderGUID) {
                      let DuplicateProviderGUID =
                        Stock.findIndex(
                          (s) =>
                            s.ProviderGUID?.path ===
                            u.changes.ProviderGUID?.path
                        ) !== -1;
                      if (DuplicateProviderGUID) {
                        throw new Error("Duplicate Vendor");
                      }
                    }
                  });
                  Stock = Stock.map((item) => {
                    const update = updates.find(
                      (u) => u.id === `${item.ProviderGUID?.path}`
                    );
                    if (update) {
                      return { ...item, ...update.changes };
                    } else {
                      return item;
                    }
                  });
                  await this.Api.updDoc(
                    this.state.ProductSelectedRef as string,
                    {
                      ProductStockLeft: Stock,
                    }
                  );
                }
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            DeleteConfirm={true}
            onDelete={async (id) => {
              // console.log(this.state.ProductSelectedRef, "DEL", id);
              try {
                if (ProductSelected && ProductSelected.ProductStockLeft) {
                  await this.Api.updDoc(
                    this.state.ProductSelectedRef as string,
                    {
                      ProductStockLeft: ProductSelected.ProductStockLeft.filter(
                        (s) => s.ProviderGUID?.path !== id
                      ),
                    }
                  );
                }
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            SortField={"StockLeft@Down"}
          ></CRUD>
          <DialogFooter>
            <DefaultButton
              onClick={() => {
                this.setState({ ProductSelectedRef: "" });
              }}
              text="Close"
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
