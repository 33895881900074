import { IDropdownOption } from "office-ui-fabric-react";
import { Environment } from "../api/IApi";
import { IProfile, IRestaurantCG } from "../models/IFirestoreModels";
import firebase from "firebase/app";
import React from "react";

interface IAppState {
  //Gobal States
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  RestaurantCollection: Array<IRestaurantCG>;
  restaurantOptions: Array<IDropdownOption>;
  innerHeight: number;
  innerWidth: number;

  //Roles Views
  showEditModels: boolean;
  showOrdersManager: boolean;
  showBidsManager: boolean;
  showUpdateBid: boolean;
  showDevelopment: boolean;
}
const Context = React.createContext({
  currentEnviroment: Environment.Development,
  currentRestaurant: null,
  currentUser: null,
  currentProfile: null,
  RestaurantCollection: [],
  restaurantOptions: [],
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,

  showEditModels: false,
  showOrdersManager: false,
  showBidsManager: false,
  showUpdateBid: false,
  showDevelopment: false,
} as IAppState);
export default Context;
