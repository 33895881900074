import React, { Component } from "react";
import Dropzone, { DropEvent } from "react-dropzone";
import {
  CompoundButton,
  Dropdown,
  IDropdownOption,
  Label,
  PrimaryButton,
} from "office-ui-fabric-react";
import XLSX from "xlsx";
import IApi, { Environment } from "../../api/IApi";
import {
  IProviderItemBid,
  IRestaurantItem,
  IProvider,
  IProfile,
  IRestaurantCG,
  ProfileRoles,
} from "../../models/IFirestoreModels";
import ProductBidTable from "./ProductBidTable";
import { IProductsBidsTableErrors } from "../../models/IProductsBidsTableErrors";
import {
  createExcelLink,
  getReplaceWith,
  removeSpaces,
} from "../../util/functions";
import firebase from "firebase/app";

interface IBidUploadProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
}
interface IBidUploadState {
  Workbook?: XLSX.WorkBook;

  SheetNamesOptions: Array<IDropdownOption>;
  SelectedSheetName: string;
  SelectedSheet: Array<Array<string>>;

  ColumnsNamesOptions: Array<IDropdownOption>;
  SelectedProductNameColumn: string;
  SelectedProductDescriptionColumn: string;
  SelectedProductPackPriceColumn: string;
  SelectedProductUnitPerPackColumn: string;
  SelectedProductUnitAmountColumn: string;
  SelectedProductUnitMesureColumn: string;

  ProviderIDsOptions: Array<IDropdownOption>;
  SelectedProviderID: string;
  SelectedProvider: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProductBidDay: Date;

  ProductsBidsTable: Array<IProviderItemBid>;
  ProductsBidsTableErrors: Array<IProductsBidsTableErrors>;

  Restaurant: IRestaurantCG;
  ProductCollection: Array<IRestaurantItem>;
  ProviderCollection: Array<IProvider>;

  isLoading: boolean;
}
export default class BidUpload extends Component<
  IBidUploadProps,
  IBidUploadState
> {
  Api: IApi;
  constructor(props: IBidUploadProps) {
    super(props);
    this.state = {
      Workbook: undefined,

      SheetNamesOptions: [{ key: "", text: "" }],
      SelectedSheetName: "",
      SelectedSheet: [],

      ColumnsNamesOptions: [{ key: "", text: "" }],
      SelectedProductNameColumn: "",
      SelectedProductDescriptionColumn: "",
      SelectedProductPackPriceColumn: "",
      SelectedProductUnitPerPackColumn: "",
      SelectedProductUnitAmountColumn: "",
      SelectedProductUnitMesureColumn: "",

      ProviderIDsOptions: [],
      SelectedProviderID: "",
      SelectedProvider: {} as any,
      ProductBidDay: new Date(),

      ProductsBidsTable: [],
      ProductsBidsTableErrors: [],

      ProductCollection: [],
      ProviderCollection: [],
      Restaurant: {} as any,

      isLoading: false,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (
      this.props.currentUser &&
      this.props.currentProfile &&
      this.props.currentRestaurant
    ) {
      Promise.all([
        this.Api.getCollect(
          this.props.currentRestaurant?.RestaurantSubCollectionPaths
            .RestaurantItemCollectionPath
        ),
        this.Api.getCollect(
          this.props.currentRestaurant?.RestaurantSubCollectionPaths
            .ProviderCollectionPath
        ),
      ]).then((data: Array<any>) => {
        this.setState({ ProductCollection: data[0] });
        this.setState({
          ProviderCollection: data[1].sort((A: any, B: any) =>
            A.ProviderName < B.ProviderName
              ? -1
              : A.ProviderName > B.ProviderName
              ? 1
              : 0
          ),
        });
        this.setState({
          Restaurant: this.props.currentRestaurant as IRestaurantCG,
        });
        let ProviderIDsOptions: Array<IDropdownOption> = [];
        data[1].forEach((ProviderDoc: IProvider) =>
          ProviderIDsOptions.push({
            key: ProviderDoc.ref?.id ? ProviderDoc.ref?.id : "",
            text: ProviderDoc.ProviderName,
          })
        );
        let SelectedProviderID = this.props.currentProfile?.ProviderGUID
          ? this.props.currentProfile?.ProviderGUID?.id
          : "";
        this.setState({ ProviderIDsOptions, SelectedProviderID });
      });
    }
  }
  componentDidUpdate(
    prevProps: IBidUploadProps,
    prevState: IBidUploadState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  handleOnDrop = (
    acceptedFiles: Array<File>,
    rejectedFiles: Array<any>,
    event: DropEvent
  ) => {
    let reader = new FileReader();
    reader.readAsBinaryString(acceptedFiles[0]);
    reader.onload = (ev: ProgressEvent<FileReader>) => {
      if (ev.target && ev.target.result) {
        let file: string | ArrayBuffer = ev.target.result;
        let opts: XLSX.ParsingOptions = { type: "binary" };
        let Workbook: XLSX.WorkBook = XLSX.read(file, opts);
        let SheetNamesOptions: Array<IDropdownOption> = Workbook.SheetNames.map(
          (SheetName: string) => {
            return { key: SheetName, text: SheetName };
          }
        );
        let SelectedSheet: Array<Array<string>> = XLSX.utils.sheet_to_json(
          Workbook.Sheets[Workbook.SheetNames[0]]
        );
        this.setState({
          Workbook,
          SheetNamesOptions,
          SelectedSheetName: Workbook.SheetNames[0],
          SelectedSheet,
        });
        if (SelectedSheet.length) {
          this.setColumnsNamesOptions(SelectedSheet);
        }
      }
    };
  };
  setColumnsNamesOptions(SelectedSheet: Array<Array<string>>) {
    let ColumnsNames: Array<string> = [];
    SelectedSheet.forEach(
      (Row: any) => (ColumnsNames = ColumnsNames.concat(Object.keys(Row)))
    );
    let ColumnsNamesSet: Set<string> = new Set(ColumnsNames);
    ColumnsNames = [];
    ColumnsNamesSet.forEach((ColumnsName: string) =>
      ColumnsNames.push(ColumnsName)
    );
    let ColumnsNamesOptions: Array<IDropdownOption> = ColumnsNames.map(
      (ColumnsName: string) => {
        return { key: ColumnsName, text: ColumnsName };
      }
    );
    this.setState({
      ColumnsNamesOptions,
      SelectedProductNameColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "KEY"
      ),
      SelectedProductDescriptionColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "DESCRIPTION"
      ),
      SelectedProductPackPriceColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "PRICE"
      ),
      SelectedProductUnitPerPackColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "UNITS PER PACK"
      ),
      SelectedProductUnitAmountColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "UNIT AMOUNT"
      ),
      SelectedProductUnitMesureColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "UNIT"
      ),
    });
  }
  filterByDefaultColumnName(
    ColumnsNamesOptions: Array<IDropdownOption>,
    DefaultColumnName: string
  ): string {
    const filtered = ColumnsNamesOptions.find(
      (ColumnName: IDropdownOption) =>
        removeSpaces(ColumnName.key.toString().toUpperCase()) ===
        DefaultColumnName
    );
    return filtered
      ? filtered.key.toString()
      : ColumnsNamesOptions[0].key.toString();
  }
  handleSheetNameSelected = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ) => {
    if (this.state.Workbook && this.state.Workbook.Sheets && option) {
      let SelectedSheet: Array<Array<string>> = XLSX.utils.sheet_to_json(
        this.state.Workbook.Sheets[option?.key]
      );
      this.setState({
        SelectedSheetName: option?.key.toString(),
        SelectedSheet,
      });
      this.setColumnsNamesOptions(SelectedSheet);
    }
  };
  handleColumnNameSelected = (
    event: any,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ) => {
    if (option) {
      switch (event.target.id) {
        case "SelectedProviderID":
          this.setState({ SelectedProviderID: option?.key.toString() });
          break;
        case "SelectedProductNameColumn":
          this.setState({ SelectedProductNameColumn: option?.key.toString() });
          break;
        case "SelectedProductDescriptionColumn":
          this.setState({
            SelectedProductDescriptionColumn: option?.key.toString(),
          });
          break;
        case "SelectedProductPackPriceColumn":
          this.setState({
            SelectedProductPackPriceColumn: option?.key.toString(),
          });
          break;
        case "SelectedProductUnitPerPackColumn":
          this.setState({
            SelectedProductUnitPerPackColumn: option?.key.toString(),
          });
          break;
        case "SelectedProductUnitAmountColumn":
          this.setState({
            SelectedProductUnitAmountColumn: option?.key.toString(),
          });
          break;
        case "SelectedProductUnitMesureColumn":
          this.setState({
            SelectedProductUnitMesureColumn: option?.key.toString(),
          });
          break;
      }
    }
  };
  handleStartMaping = () => {
    let ProductsBidsTable: Array<IProviderItemBid> = [];
    let ProductsBidsTableErrors: Array<IProductsBidsTableErrors> = [];
    const ProviderGUID: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = this.state.ProviderCollection.find(
      (provider: IProvider) =>
        provider.ref?.id === this.state.SelectedProviderID
    )?.ref as any;
    let uniqueRefAdded: boolean = false;
    this.state.ProductCollection.forEach((ProductDoc: IRestaurantItem) => {
      uniqueRefAdded = false;
      this.state.SelectedSheet.forEach((Row: any) => {
        if (
          Row[this.state.SelectedProductNameColumn] &&
          removeSpaces(
            Row[this.state.SelectedProductNameColumn] as string
          ).toUpperCase() === ProductDoc.ProductName
        ) {
          if (!uniqueRefAdded) {
            uniqueRefAdded = true;
            try {
              let ProductGUID: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = ProductDoc.ref as any;
              let ProductPackPrice: number = Row[
                this.state.SelectedProductPackPriceColumn
              ]
                ? Number(Row[this.state.SelectedProductPackPriceColumn])
                : 0;
              let ProductUnitPerPack: number = Row[
                this.state.SelectedProductUnitPerPackColumn
              ]
                ? Number(Row[this.state.SelectedProductUnitPerPackColumn])
                : 1;
              let ProductUnitAmount: number = Row[
                this.state.SelectedProductUnitAmountColumn
              ]
                ? Number(Row[this.state.SelectedProductUnitAmountColumn])
                : 1;
              let ProductUnitMesure: any = getReplaceWith(
                this.state.Restaurant.RestaurantUnitMesures,
                Row[this.state.SelectedProductUnitMesureColumn]
              );

              if (!ProductPackPrice) throw new Error("PackPrice_NaN");
              if (!ProductUnitPerPack) throw new Error("UnitPerPack_NaN");
              if (!ProductUnitAmount) throw new Error("UnitAmount_NaN");
              if (ProductUnitMesure === "NoFoundCase")
                throw new Error("UnitMesure_NotFound");
              if (
                ProductDoc.ProductUnitMesure !==
                  this.state.Restaurant.RestaurantUnitMesures.defaultCase &&
                ProductUnitMesure !== ProductDoc.ProductUnitMesure
              )
                throw new Error("UnitMesure_NotMatch");

              let ProductBidRow: IProviderItemBid = {
                ProductGUID,
                ProductDescription: Row[
                  this.state.SelectedProductDescriptionColumn
                ]
                  ? Row[this.state.SelectedProductDescriptionColumn]
                  : "",
                ProductPackPrice,
                ProductUnitPerPack,
                ProductUnitAmount,
                ProductUnitMesure: ProductUnitMesure,
              };
              ProductsBidsTable.push(ProductBidRow);
            } catch (ex) {
              let ProductBidRowError: IProductsBidsTableErrors = {
                ErrorsFound: ex,
                ProductName: Row[this.state.SelectedProductNameColumn],
                ProductDescription:
                  Row[this.state.SelectedProductDescriptionColumn],
                ProductPackPrice:
                  Row[this.state.SelectedProductPackPriceColumn],
                ProductUnitPerPack:
                  Row[this.state.SelectedProductUnitPerPackColumn],
                ProductUnitAmount:
                  Row[this.state.SelectedProductUnitAmountColumn],
                ProductUnitMesure:
                  Row[this.state.SelectedProductUnitMesureColumn],
              };
              ProductsBidsTableErrors.push(ProductBidRowError);
            }
          } else {
            let ProductBidRowError: IProductsBidsTableErrors = {
              ErrorsFound: new Error("ProductGUID_Duplicate"),
              ProductName: Row[this.state.SelectedProductNameColumn],
              ProductDescription:
                Row[this.state.SelectedProductDescriptionColumn],
              ProductPackPrice: Row[this.state.SelectedProductPackPriceColumn],
              ProductUnitPerPack:
                Row[this.state.SelectedProductUnitPerPackColumn],
              ProductUnitAmount:
                Row[this.state.SelectedProductUnitAmountColumn],
              ProductUnitMesure:
                Row[this.state.SelectedProductUnitMesureColumn],
            };
            ProductsBidsTableErrors.push(ProductBidRowError);
          }
        }
      });
    });
    this.state.SelectedSheet.forEach((Row: any) => {
      if (
        Row[this.state.SelectedProductNameColumn] &&
        !this.state.ProductCollection.find(
          (ProductDoc: IRestaurantItem) =>
            ProductDoc.ProductName ===
            (Row[this.state.SelectedProductNameColumn] as string).toUpperCase()
        )
      ) {
        let ProductBidRowError: IProductsBidsTableErrors = {
          ErrorsFound: new Error("ProductGUID_NotFound"),
          ProductName: Row[this.state.SelectedProductNameColumn],
          ProductDescription: Row[this.state.SelectedProductDescriptionColumn],
          ProductPackPrice: Row[this.state.SelectedProductPackPriceColumn],
          ProductUnitPerPack: Row[this.state.SelectedProductUnitPerPackColumn],
          ProductUnitAmount: Row[this.state.SelectedProductUnitAmountColumn],
          ProductUnitMesure: Row[this.state.SelectedProductUnitMesureColumn],
        };
        ProductsBidsTableErrors.push(ProductBidRowError);
      }
    });
    this.setState({
      ProductsBidsTable,
      ProductsBidsTableErrors,
      SelectedProvider: ProviderGUID,
    });
  };
  async createExcel() {
    this.setState({ isLoading: true });
    let Sheet: Array<Array<string>> = [
      [
        "KEY",
        "ITEMS",
        "PRICE",
        "UNITS PER PACK",
        "UNIT AMOUNT",
        "UNIT",
        "DESCRIPTION",
      ],
    ];
    this.state.ProductCollection.filter((p) => !p.ProductOffSeason).forEach(
      (p) => {
        Sheet.push([p.ProductName, p.ProductName, "", "", "", "", ""]);
      }
    );
    window.location = (await createExcelLink(
      this.Api,
      `${this.props.currentRestaurant?.ref?.id}/Master Price Bid`,
      [{ SheetName: "Master Price Bid", SheetData: Sheet }]
    )) as any;
    this.setState({ isLoading: false });
  }
  renderStep1(): JSX.Element {
    return (
      <span>
        <span style={{ position: "fixed", bottom: 15, right: 10 }}>
          <PrimaryButton
            text="Download Master Price Bid"
            iconProps={{ iconName: "Download" }}
            disabled={this.state.isLoading}
            onClick={() => this.createExcel()}
            style={{ width: 280 }}
          />
        </span>
        <Dropdown
          id="SelectedProviderID"
          label="Vendor Name"
          defaultSelectedKey={this.state.SelectedProviderID}
          options={this.state.ProviderIDsOptions}
          onChange={this.handleColumnNameSelected}
          required={true}
          disabled={
            this.state.isLoading ||
            this.props.currentProfile?.ProfileRole.includes(
              ProfileRoles.Provider
            )
          }
          style={{ maxWidth: 280 }}
        />
        <br />
        <Dropzone onDrop={this.handleOnDrop}>
          {({ getRootProps, getInputProps }) => (
            <span {...getRootProps()}>
              <input {...getInputProps()} />
              <CompoundButton
                primary={true}
                text="Upload Excel File"
                secondaryText="Drag 'n' drop some file here, or click to select file"
                iconProps={{ iconName: "Table" }}
                disabled={this.state.isLoading}
              />
            </span>
          )}
        </Dropzone>
      </span>
    );
  }
  renderStep2(): JSX.Element {
    return (
      <span>
        <span style={{ position: "fixed", bottom: 15, right: 10 }}>
          <PrimaryButton
            text="Start Map Now"
            iconProps={{ iconName: "Search" }}
            onClick={this.handleStartMaping}
          />
        </span>
        <Dropdown
          id="SelectedSheetName"
          label="Select Sheet Name"
          defaultSelectedKey={this.state.SelectedSheetName}
          options={this.state.SheetNamesOptions}
          onChange={this.handleSheetNameSelected}
          style={{ maxWidth: 300 }}
        />
        <Label style={{ color: "red" }}>
          First Row Must Be the Table's Header
        </Label>
        <br />
        <Dropdown
          id="SelectedProductNameColumn"
          label="Select Item Name Column"
          defaultSelectedKey={this.state.SelectedProductNameColumn}
          options={this.state.ColumnsNamesOptions}
          onChange={this.handleColumnNameSelected}
          style={{ maxWidth: 300 }}
        />
        <Dropdown
          id="SelectedProductDescriptionColumn"
          label="Select Item Description Column"
          defaultSelectedKey={this.state.SelectedProductDescriptionColumn}
          options={this.state.ColumnsNamesOptions}
          onChange={this.handleColumnNameSelected}
          style={{ maxWidth: 300 }}
        />
        <Dropdown
          id="SelectedProductPackPriceColumn"
          label="Select Item Case Price Column"
          defaultSelectedKey={this.state.SelectedProductPackPriceColumn}
          options={this.state.ColumnsNamesOptions}
          onChange={this.handleColumnNameSelected}
          style={{ maxWidth: 300 }}
        />
        <Dropdown
          id="SelectedProductUnitPerPackColumn"
          label="Select Item Units Per Pack Column"
          defaultSelectedKey={this.state.SelectedProductUnitPerPackColumn}
          options={this.state.ColumnsNamesOptions}
          onChange={this.handleColumnNameSelected}
          style={{ maxWidth: 300 }}
        />
        <Dropdown
          id="SelectedProductUnitAmountColumn"
          label="Select Item Unit Amount Column"
          defaultSelectedKey={this.state.SelectedProductUnitAmountColumn}
          options={this.state.ColumnsNamesOptions}
          onChange={this.handleColumnNameSelected}
          style={{ maxWidth: 300 }}
        />
        <Dropdown
          id="SelectedProductUnitMesureColumn"
          label="Select Item Unit Measure Column"
          defaultSelectedKey={this.state.SelectedProductUnitMesureColumn}
          options={this.state.ColumnsNamesOptions}
          onChange={this.handleColumnNameSelected}
          style={{ maxWidth: 300 }}
        />
      </span>
    );
  }
  resetUpload = () => {
    this.setState({
      Workbook: undefined,

      SheetNamesOptions: [],
      SelectedSheetName: "",
      SelectedSheet: [],

      ColumnsNamesOptions: [],
      SelectedProductNameColumn: "",
      SelectedProductDescriptionColumn: "",
      SelectedProductPackPriceColumn: "",
      SelectedProductUnitPerPackColumn: "",
      SelectedProductUnitAmountColumn: "",
      SelectedProductUnitMesureColumn: "",

      SelectedProviderID: "",
      ProductBidDay: new Date(),

      ProductsBidsTable: [],
      ProductsBidsTableErrors: [],
    });
  };
  render() {
    return (
      <div>
        {this.props.currentRestaurant &&
        this.props.currentProfile &&
        (this.state.ProductsBidsTable.length ||
          this.state.ProductsBidsTableErrors.length) ? (
          <ProductBidTable
            Profile={this.props.currentProfile}
            Table={this.state.ProductsBidsTable}
            TableErrors={this.state.ProductsBidsTableErrors}
            ProductCollection={this.state.ProductCollection}
            ProviderCollection={this.state.ProviderCollection}
            SelectedProviderID={this.state.SelectedProviderID}
            SelectedProvider={this.state.SelectedProvider}
            Api={this.Api}
            Restaurant={this.props.currentRestaurant}
            ProductBidDay={this.state.ProductBidDay}
            resetUpload={this.resetUpload}
          />
        ) : this.state.SelectedSheet.length && this.state.SelectedProviderID ? (
          this.renderStep2()
        ) : (
          this.renderStep1()
        )}
      </div>
    );
  }
}
