import {
  IProvider,
  IRestaurantItemOrder,
  IRestaurantItem,
  IRestaurantArea,
  IProviderItemBid,
} from "../models/IFirestoreModels";
import { IEmail } from "../models/ICommunication";
import { Greeting, PrintDate, setHash } from "./functions";

export default class EmailCreationManager {
  static newProductNotify(
    ProfileContact: { ProfileContactName: string; ProfileContactEmail: string },
    newProductName: string,
    RestaurantName: string
  ): IEmail {
    let email: IEmail = {
      EmailTo: ProfileContact.ProfileContactEmail,
      EmailSubject: `Add Item, ${newProductName}`,
      EmailBody: `
          <p>${Greeting()}, ${ProfileContact.ProfileContactName}</p>
          <p><strong>${newProductName}</strong> Item's Price need to be added for next order at ${RestaurantName}</p>
          `,
    };
    return email;
  }
  static newOrderNotify(
    ProfileContact: { ProfileContactName: string; ProfileContactEmail: string },
    ProductsOrderToSubmit: Array<IRestaurantItemOrder>,
    ProductsOrderToReturn: Array<IRestaurantItemOrder>,
    ProductCollection: Array<IRestaurantItem>,
    ProviderCollection: Array<IProvider>,
    RestaurantName: string,
    ProductOrderDelivery: Date | undefined,
    OrderCreatedRef: string,
    providerResponse?: boolean,
    notifyDaysLater?: boolean
  ): IEmail {
    let tables: string = "";
    if (providerResponse) {
      let tags: string = "";
      ProductsOrderToSubmit.map(
        (OrderProduct: IRestaurantItemOrder, index: number) => {
          const Product: IRestaurantItem = ProductCollection.find(
            (product) => product.ref?.id === OrderProduct.ProductGUID?.id
          ) as IRestaurantItem;
          return `
          <tr>
            <td>${Product ? Product.ProductName : "N/A"}</td>
            <td>$${OrderProduct.ProductPackPrice}</td>
            <td>${OrderProduct.ProductOrderAmount}</td>
            <td>$${(
              OrderProduct.ProductPackPrice * OrderProduct.ProductOrderAmount
            ).toFixed(2)}</td>
          </tr>
        `;
        }
      ).forEach((tag: string) => (tags += tag));
      let total: number = 0;
      ProductsOrderToSubmit.forEach((OrderProduct: IRestaurantItemOrder) => {
        total +=
          OrderProduct.ProductPackPrice * OrderProduct.ProductOrderAmount;
      });

      let returnedtags: string = "";
      ProductsOrderToReturn.map(
        (OrderProduct: IRestaurantItemOrder, index: number) => {
          const Product: IRestaurantItem = ProductCollection.find(
            (product) => product.ref?.id === OrderProduct.ProductGUID?.id
          ) as IRestaurantItem;
          return `
          <tr>
            <td>${Product ? Product.ProductName : "N/A"}</td>
            <td>$${OrderProduct.ProductPackPrice}</td>
            <td>${OrderProduct.ProductOrderAmount}</td>
            <td>$${(
              OrderProduct.ProductPackPrice * OrderProduct.ProductOrderAmount
            ).toFixed(2)}</td>
          </tr>
        `;
        }
      ).forEach((tag: string) => (returnedtags += tag));
      let returnedtotal: number = 0;
      ProductsOrderToReturn.forEach((OrderProduct: IRestaurantItemOrder) => {
        returnedtotal +=
          OrderProduct.ProductPackPrice * OrderProduct.ProductOrderAmount;
      });

      tables +=
        tags !== ""
          ? `
      <table style=" width: 100%; text-align: left; border-collapse: collapse; border: 1px solid black ">
        <thead style=" border: 1px solid black ">
        <tr>
          <th>Item</th>
          <th>Case Price</th>
          <th>* Amount Ordered</th>
          <th>Total</th>
        </tr>
        </thead>
        <tbody style=" border: 1px solid black ">
          ${tags}
        </tbody>
        <tfoot style=" border: 1px solid black ">
        <tr>
          <td>Ordered Total:</td>
          <td></td><td></td>
          <td>$${total.toFixed(2)}</td>
        </tr>
        </tfoot>
        </table>`
          : "";
      tables += tags !== "" && returnedtags !== "" ? "<br/>" : "";
      tables +=
        returnedtags !== ""
          ? `
        <p><b>* The following products are marked for pick up</b></p>
        <table style=" width: 100%; text-align: left; border-collapse: collapse; border: 1px solid black ">
        <thead style=" border: 1px solid black ">
        <tr>
          <th>Item</th>
          <th>Case Price</th>
          <th>* Amount Returned</th>
          <th>Total</th>
        </tr>
        </thead>
        <tbody style=" border: 1px solid black ">
          ${returnedtags}
        </tbody>
        <tfoot style=" border: 1px solid black ">
        <tr>
          <td>Returned Total:</td>
          <td></td><td></td>
          <td>$${returnedtotal.toFixed(2)}</td>
        </tr>
        </tfoot>
        </table>`
          : "";
      ///////////////////////////////////////////////////////////////////////////////////////////
    } else {
      ProviderCollection.forEach((Provider) => {
        let tags: string = "";
        ProductsOrderToSubmit.filter(
          (OrderProduct) => OrderProduct.ProviderGUID?.id === Provider.ref?.id
        )
          .map((OrderProduct: IRestaurantItemOrder, index: number) => {
            const Product: IRestaurantItem = ProductCollection.find(
              (product) => product.ref?.id === OrderProduct.ProductGUID?.id
            ) as IRestaurantItem;
            return `
              <tr>
                <td>${Product ? Product.ProductName : "N/A"}</td>
                <td>$${OrderProduct.ProductPackPrice}</td>
                <td>${OrderProduct.ProductOrderAmount}</td>
                <td>$${(
                  OrderProduct.ProductPackPrice *
                  OrderProduct.ProductOrderAmount
                ).toFixed(2)}</td>
              </tr>
          `;
          })
          .forEach((tag: string) => (tags += tag));
        let total: number = 0;
        ProductsOrderToSubmit.filter(
          (OrderProduct) => OrderProduct.ProviderGUID?.id === Provider.ref?.id
        ).forEach((OrderProduct: IRestaurantItemOrder) => {
          total +=
            OrderProduct.ProductPackPrice * OrderProduct.ProductOrderAmount;
        });

        let returnedtags: string = "";
        ProductsOrderToReturn.filter(
          (OrderProduct) => OrderProduct.ProviderGUID?.id === Provider.ref?.id
        )
          .map((OrderProduct: IRestaurantItemOrder, index: number) => {
            const Product: IRestaurantItem = ProductCollection.find(
              (product) => product.ref?.id === OrderProduct.ProductGUID?.id
            ) as IRestaurantItem;
            return `
              <tr>
                <td>${Product ? Product.ProductName : "N/A"}</td>
                <td>$${OrderProduct.ProductPackPrice}</td>
                <td>${OrderProduct.ProductOrderAmount}</td>
                <td>$${(
                  OrderProduct.ProductPackPrice *
                  OrderProduct.ProductOrderAmount
                ).toFixed(2)}</td>
              </tr>
          `;
          })
          .forEach((tag: any) => (returnedtags += tag));
        let returnedtotal: number = 0;
        ProductsOrderToReturn.filter(
          (OrderProduct) => OrderProduct.ProviderGUID?.id === Provider.ref?.id
        ).forEach((OrderProduct: IRestaurantItemOrder) => {
          returnedtotal +=
            OrderProduct.ProductPackPrice * OrderProduct.ProductOrderAmount;
        });

        tables +=
          tags !== ""
            ? `<p>${Provider.ProviderName}'s Ordered Items:</p>
              <table style=" width: 100%; text-align: left; border-collapse: collapse; border: 1px solid black ">
              <thead style=" border: 1px solid black ">
              <tr>
                <th>Item</th>
                <th>Case Price</th>
                <th>* Amount Ordered</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody style=" border: 1px solid black ">
                ${tags}
              </tbody>
              <tfoot style=" border: 1px solid black ">
              <tr>
                <td>Ordered Total:</td>
                <td></td><td></td>
                <td>$${total.toFixed(2)}</td>
              </tr>
              </tfoot>
              </table>`
            : "";
        tables +=
          returnedtags !== ""
            ? `<p>${Provider.ProviderName}'s Returned Items:</p>
              <table style=" width: 100%; text-align: left; border-collapse: collapse; border: 1px solid black ">
              <thead style=" border: 1px solid black ">
              <tr>
                <th>Item</th>
                <th>Case Price</th>
                <th>* Amount Returned</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody style=" border: 1px solid black ">
                ${returnedtags}
              </tbody>
              <tfoot style=" border: 1px solid black ">
              <tr>
                <td>Returned Total:</td>
                <td></td><td></td>
                <td>$${returnedtotal.toFixed(2)}</td>
              </tr>
              </tfoot>
              </table>`
            : "";
      });
    }
    ///////////////////////////////////////////////////////////////////////////////////////////
    let hash = setHash("", "Order", OrderCreatedRef);
    let email: IEmail = {
      EmailTo: ProfileContact.ProfileContactEmail,
      EmailSubject: `${
        ProductsOrderToSubmit.length === 0 ? "Pick Up" : "Order Delivery"
      } ${
        notifyDaysLater
          ? "Today"
          : `on ${ProductOrderDelivery && PrintDate(ProductOrderDelivery)}`
      }`,
      EmailBody: `
      <p>${Greeting()}, ${ProfileContact.ProfileContactName}</p>
      ${
        !providerResponse
          ? `<a href="${window.location.origin}/orders_manager${hash}">Link to Check Order</a>`
          : ""
      }
      ${tables}
      <p>Order at ${RestaurantName}</p>
      `,
    };
    if (notifyDaysLater) {
      let t = new Date((ProductOrderDelivery as Date).getTime());
      t.setHours(t.getHours() + 8);
      email.EmailTriggerDate = t;
    }
    return email;
  }
  static orderCheckedNotify(
    ProfileContact: { ProfileContactName: string; ProfileContactEmail: string },
    OrderProducts: Array<IRestaurantItemOrder>,
    AreaCollection: Array<IRestaurantArea>,
    ProductCollection: Array<IRestaurantItem>,
    ProviderCollection: Array<IProvider>,
    RestaurantName: string,
    OrderCheckedRef: string,
    notifyDaysLater?: number,
    selfResponse?: boolean,
    providerResponse?: boolean
  ): IEmail {
    let tags: string = "";
    OrderProducts.map((OrderProduct: IRestaurantItemOrder, index: number) => {
      const Area: IRestaurantArea = AreaCollection.find(
        (area) => area.ref?.id === OrderProduct.AreaGUID?.id
      ) as IRestaurantArea;
      const Product: IRestaurantItem = ProductCollection.find(
        (product) => product.ref?.id === OrderProduct.ProductGUID?.id
      ) as IRestaurantItem;
      const Provider: IProvider = ProviderCollection.find(
        (provider) => provider.ref?.id === OrderProduct.ProviderGUID?.id
      ) as IProvider;
      return `
        <tr>
        <td${Area ? Area.AreaName : "N/A"}</td>
        <td>${Product ? Product.ProductName : "N/A"}</td>
        ${
          !providerResponse
            ? `<td>${Provider ? Provider.ProviderName : "N/A"}</td>`
            : ""
        }   
        <td>${OrderProduct.ProductOrderState}</td>
        <td>${OrderProduct.ProductOrderAmount}</td>
        <td>${OrderProduct.ProductOrderIncomingAmount}</td>
        <td>${
          OrderProduct.ProductOrderReturnAmount
            ? OrderProduct.ProductOrderReturnAmount
            : ""
        }</td>
        <td>${
          OrderProduct.ProductOrderReturnReason
            ? OrderProduct.ProductOrderReturnReason
            : ""
        }</td>
        <td>${
          OrderProduct.ProductOrderInvoiceState
            ? OrderProduct.ProductOrderInvoiceState
            : ""
        }</td>
        <td>${
          OrderProduct.ProductOrderInvoiceAmount
            ? OrderProduct.ProductOrderInvoiceAmount
            : ""
        }</td>
        <td>${
          OrderProduct.ProductOrderInvoicePrice
            ? `$${OrderProduct.ProductOrderInvoicePrice}`
            : ""
        }</td>
        <td>$${OrderProduct.ProductPackPrice}</td>
        <td>${OrderProduct.ProductUnitPerPack}/${
        OrderProduct.ProductUnitAmount
      } ${OrderProduct.ProductUnitMesure}</td>
        <td>${OrderProduct.ProductDescription}</td>
        <td>${PrintDate(OrderProduct.ProductBidDay)}</td>
        </tr>
        `;
    }).forEach((tag: any) => (tags += tag));
    let hash = setHash("", "Order", OrderCheckedRef);
    let email: IEmail = {
      EmailTo: ProfileContact.ProfileContactEmail,
      EmailSubject: selfResponse
        ? "Order has been Notify Successfully"
        : providerResponse
        ? "Order Invoice has been Notify as Ready for Vendor Check"
        : notifyDaysLater
        ? `Reminder: Order has been Notify as Ready for Invoice Check`
        : `Order has been Notify as Ready for Invoice Check`,
      EmailBody: `
            <p>${Greeting()}, ${ProfileContact.ProfileContactName}</p>
            ${
              notifyDaysLater
                ? `<p>If the invoice of this Order has been checked, please disregard...</p>`
                : ""
            }
            ${
              !providerResponse
                ? `<a href="${window.location.origin}/orders_manager${hash}">Link to Check Order Invoice</a>`
                : ""
            }            
            <table style=" width: 100%; text-align: left; border-collapse: collapse; border: 1px solid black ">
                <thead style=" border: 1px solid black ">
                    <tr>
                    <th>Area</th>
                    <th>Item</th>
                    ${!providerResponse ? "<th>Vendor</th>" : ""}
                    <th>Truck Status</th>
                    <th>Amount Ordered</th>
                    <th>Amount Checked</th>
                    <th>Return Amount</th>
                    <th>Return Reason</th>
                    <th>Invoice Status</th>
                    <th>Invoice Amount</th>
                    <th>Invoice Price</th>
                    <th>Case Price</th>
                    <th>Units per Case</th>
                    <th>Description</th>
                    <th>Bid Day</th>
                    </tr>
                </thead>
                <tbody style=" border: 1px solid black ">
                  ${tags}
                </tbody>
                <p>Order at ${RestaurantName}</p>
            </table>
            `,
    };
    if (notifyDaysLater) {
      let t = new Date();
      t.setDate(t.getDate() + notifyDaysLater);
      email.EmailTriggerDate = t;
    }
    return email;
  }
  static updateBidsNotify(
    ProfileContact: { ProfileContactName: string; ProfileContactEmail: string },
    ProductsToAdd: Array<IProviderItemBid>,
    ProductCollection: Array<IRestaurantItem>,
    RestaurantName: string,
    BidCreatedRef: string,
    ProviderName?: string
  ): IEmail {
    let tags: string = "";
    ProductsToAdd.map((BidProduct: IProviderItemBid, index: number) => {
      const Product: IRestaurantItem = ProductCollection.find(
        (product) => product.ref?.id === BidProduct.ProductGUID?.id
      ) as IRestaurantItem;
      return `
        <tr>
        <td>${Product.ProductName}</td>
        <td>${BidProduct.ProductDescription}</td>
        <td>$${BidProduct.ProductPackPrice}</td>
        <td>${BidProduct.ProductUnitPerPack}</td>
        <td>${BidProduct.ProductUnitAmount}</td>
        <td>${BidProduct.ProductUnitMesure}</td>
        </tr>
        `;
    }).forEach((tag: any) => (tags += tag));
    let hash = setHash("", "Bid", BidCreatedRef);
    let email: IEmail = {
      EmailTo: ProfileContact.ProfileContactEmail,
      EmailSubject: ProviderName
        ? `${ProviderName} has Updated Price Bids`
        : "Price Bids Updated Successfully",
      EmailBody: `
            <p>${Greeting()}, ${ProfileContact.ProfileContactName}</p>
            ${
              ProviderName
                ? `<a href="${window.location.origin}/bids_manager${hash}">Link to View Bid Updated</a>`
                : ""
            }
            <table style=" width: 100%; text-align: left; border-collapse: collapse; border: 1px solid black ">
                <thead style=" border: 1px solid black ">
                    <tr>
                    <th>Item</th>
                    <th>Description</th>
                    <th>Case Price</th>
                    <th>Unit Per Pack</th>
                    <th>Unit Amount</th>
                    <th>Unit Measure</th>
                    </tr>
                </thead>
                <tbody style=" border: 1px solid black ">
                  ${tags}
                </tbody>
            </table>
            <p>Bid at ${RestaurantName}</p>
            `,
    };
    return email;
  }
  static productPriceChangedNotify(
    ProfileContact: { ProfileContactName: string; ProfileContactEmail: string },
    ProductsPriceChanged: Array<{
      previous: IProviderItemBid;
      current: IProviderItemBid;
    }>,
    ProductCollection: Array<IRestaurantItem>,
    RestaurantName: string,
    ProviderName: string,
    providerResponse?: boolean
  ): IEmail {
    let tags: string = "";
    ProductsPriceChanged.map(
      (
        BidProductChanged: {
          previous: IProviderItemBid;
          current: IProviderItemBid;
        },
        index: number
      ) => {
        const Product: IRestaurantItem = ProductCollection.find(
          (product) =>
            product.ref?.id === BidProductChanged.previous.ProductGUID?.id
        ) as IRestaurantItem;
        return `
        <tr>
        <td>${Product.ProductName}</td>
        <td>${BidProductChanged.previous.ProductDescription}</td>
        <td>${BidProductChanged.current.ProductDescription}</td>
        <td>$${BidProductChanged.previous.ProductPackPrice}</td>
        <td>$${BidProductChanged.current.ProductPackPrice}</td>
        <td>${BidProductChanged.previous.ProductUnitPerPack}/${BidProductChanged.previous.ProductUnitAmount} ${BidProductChanged.previous.ProductUnitMesure}</td>
        <td>${BidProductChanged.current.ProductUnitPerPack}/${BidProductChanged.current.ProductUnitAmount} ${BidProductChanged.current.ProductUnitMesure}</td>
        </tr>
        `;
      }
    ).forEach((tag: any) => (tags += tag));
    let email: IEmail = {
      EmailTo: ProfileContact.ProfileContactEmail,
      EmailSubject: providerResponse
        ? `Prices Changed Marked as Notifiable`
        : `${ProviderName} has Change Prices Marked as Notifiable`,
      EmailBody: `
          <p>${Greeting()}, ${ProfileContact.ProfileContactName}</p>
          ${
            providerResponse &&
            "<p>The following prices are higher than the past bid, please review then and update the bid again</p>"
          }
          <table style=" width: 100%; text-align: left; border-collapse: collapse; border: 1px solid black ">
              <thead style=" border: 1px solid black ">
                  <tr>
                  <th>Item</th>
                  <th>Previous Description</th>
                  <th>Current Description</th>
                  <th>Previous Case Price</th>
                  <th>Current Case Price</th>
                  <th>Previous Units per Case</th>
                  <th>Current Units per Case</th>
                  </tr>
              </thead>
              <tbody style=" border: 1px solid black ">
                ${tags}
              </tbody>
          </table>
          <p>Bid at ${RestaurantName}</p>
          `,
    };
    return email;
  }
  static productStockLeftNotify(
    ProfileContact: { ProfileContactName: string; ProfileContactEmail: string },
    ProviderCollection: Array<IProvider>,
    ProductCollection: Array<IRestaurantItem>,
    RestaurantName: string
  ): IEmail {
    let tags: string = "";
    ProductCollection.forEach((Product, index: number) => {
      Product.ProductStockLeft?.forEach((s) => {
        const Provider: IProvider = ProviderCollection.find(
          (provider) => provider.ref?.id === s.ProviderGUID?.id
        ) as IProvider;
        tags += `
        <tr>
          <td>${Product.ProductName}</td>
          <td>${Provider ? Provider.ProviderName : "N/A"}</td>
          <td>${s.StockLeft}</td>
          </tr>
          `;
      });
    });
    let email: IEmail = {
      EmailTo: ProfileContact.ProfileContactEmail,
      EmailSubject: `Item Stock Left has Changed`,
      EmailBody: `
          <p>${Greeting()}, ${ProfileContact.ProfileContactName}</p>
          <table style=" width: 100%; text-align: left; border-collapse: collapse; border: 1px solid black ">
              <thead style=" border: 1px solid black ">
                  <tr>
                  <th>Item</th>
                  <th>Vendor</th>
                  <th>Stock Left</th>
                  </tr>
              </thead>
              <tbody style=" border: 1px solid black ">
                ${tags}
              </tbody>
          </table>
          <p>Order at ${RestaurantName}</p>
          `,
    };
    return email;
  }
}
