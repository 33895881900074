// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const key = {
  apiKey: "AIzaSyDCJyhpQeAjiA6ql1TwcwcXoT3hOFE24XU",
  authDomain: "datatest-5e002.firebaseapp.com",
  databaseURL: "https://datatest-5e002.firebaseio.com",
  projectId: "datatest-5e002",
  storageBucket: "datatest-5e002.appspot.com",
  messagingSenderId: "334662483642",
  appId: "1:334662483642:web:db8ded70a0f1962b3d4c83",
  measurementId: "G-PRQC2NFQFY",
};
export default key;
