import React, { Component } from "react";
import IApi, { Environment } from "../../api/IApi";
import {
  IRestaurantCG,
  IProfile,
  IRestaurantArea,
  IRestaurantItem,
  ProfileRoles,
  IRestaurantRecipe,
} from "../../models/IFirestoreModels";
import {
  Spinner,
  SpinnerSize,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Icon,
  ISelectableOption,
  DefaultButton,
  Dialog,
  DialogType,
  DialogFooter,
  SearchBox,
} from "office-ui-fabric-react";
import { ProfileCollectionPath } from "../../const/CollectionsPaths";
import { deleteHash, getHash, setHash } from "../../util/functions";
import RecipeSelected from "./RecipeSelected";
import firebase from "firebase/app";

interface IRecipeManagerProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
}
interface IRecipeManagerState {
  isLoading: boolean;
  ProfileCollection: Array<IProfile>;
  RestaurantRecipeCollectionNotFilter: Array<IRestaurantRecipe>;
  RestaurantRecipeCollection: Array<IRestaurantRecipe>;
  AreaCollection: Array<IRestaurantArea>;
  ProductCollection: Array<IRestaurantItem>;

  RecipeSearch: string;
  SortSelected: string;

  CurrentRecipeSelected: IRestaurantRecipe | null;
  isDeleteDialogHidden: boolean;
  currentDeleteRef: string;
}
export default class RecipeManager extends Component<
  IRecipeManagerProps,
  IRecipeManagerState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      ProfileCollection: [],
      RestaurantRecipeCollectionNotFilter: [],
      RestaurantRecipeCollection: [],
      AreaCollection: [],
      ProductCollection: [],

      RecipeSearch: "",
      SortSelected: "RecipeNameAscendent",

      CurrentRecipeSelected: null,
      isDeleteDialogHidden: true,
      currentDeleteRef: "",
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (
      this.props.currentUser &&
      this.props.currentProfile &&
      this.props.currentRestaurant
    ) {
      this.setState({ isLoading: true });
      const ProfileCollection: Array<IProfile> = await this.Api.getCollect(
        ProfileCollectionPath
      );
      const AreaCollection: Array<IRestaurantArea> = await this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantAreaCollectionPath
      );
      const ProductCollection: Array<IRestaurantItem> = await this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantItemCollectionPath
      );
      const RestaurantRecipeCollection: Array<IRestaurantRecipe> = await this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantRecipeCollectionPath
      );
      let hash = new URL(window.location.href).hash;
      const CurrentRecipeSelected = RestaurantRecipeCollection.find(
        (r) => r.ref?.id === getHash(hash, "Recipe")
      );
      this.setState({
        ProfileCollection,
        AreaCollection,
        ProductCollection,
        RestaurantRecipeCollectionNotFilter: RestaurantRecipeCollection.map(
          (r) => r
        ),
        RestaurantRecipeCollection: this.sortRestaurantOrderCollection(
          RestaurantRecipeCollection,
          this.state.SortSelected
        ),
        CurrentRecipeSelected: CurrentRecipeSelected
          ? CurrentRecipeSelected
          : null,
        isLoading: false,
      });
    }
  }
  sortRestaurantOrderCollection(
    RestaurantRecipeCollection: Array<IRestaurantRecipe>,
    SortSelected: string
  ): Array<IRestaurantRecipe> {
    let Output: Array<IRestaurantRecipe> = [];
    switch (SortSelected) {
      case "RecipeNameAscendent":
        Output = RestaurantRecipeCollection.sort((A, B) =>
          A.RecipeName < B.RecipeName ? -1 : A.RecipeName > B.RecipeName ? 1 : 0
        );
        break;
      case "RecipeNameDescendent":
        Output = RestaurantRecipeCollection.sort((A, B) =>
          A.RecipeName > B.RecipeName ? -1 : A.RecipeName < B.RecipeName ? 1 : 0
        );
        break;
      case "RecipeRatingAscendent":
        Output = RestaurantRecipeCollection.sort((A, B) =>
          A.RecipeRating < B.RecipeRating
            ? -1
            : A.RecipeRating > B.RecipeRating
            ? 1
            : 0
        );
        break;
      case "RecipeRatingDescendent":
        Output = RestaurantRecipeCollection.sort((A, B) =>
          A.RecipeRating > B.RecipeRating
            ? -1
            : A.RecipeRating < B.RecipeRating
            ? 1
            : 0
        );
        break;
    }
    return Output;
  }
  componentDidUpdate(
    prevProps: IRecipeManagerProps,
    prevState: IRecipeManagerState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  onChanged = async (field: string, option?: any) => {
    switch (field) {
      case "Search":
        this.setState({
          RestaurantRecipeCollection: this.sortRestaurantOrderCollection(
            this.state.RestaurantRecipeCollectionNotFilter.filter((r) =>
              option
                ? r.RecipeName.includes((option as string).toUpperCase())
                : true
            ),
            this.state.SortSelected
          ),
          RecipeSearch: option ? (option as string).toUpperCase() : "",
        });
        break;
      case "Sort":
        this.setState({
          RestaurantRecipeCollection: this.sortRestaurantOrderCollection(
            this.state.RestaurantRecipeCollection,
            option?.key.toString()
          ),
          SortSelected: option?.key.toString(),
        });
        break;
      case "Recipe":
        const CurrentRecipeSelected: IRestaurantRecipe = this.state.RestaurantRecipeCollection.find(
          (order) => order.ref?.id === option
        ) as IRestaurantRecipe;
        let hash = new URL(window.location.href).hash;
        hash = setHash(hash, "Recipe", CurrentRecipeSelected.ref?.id as string);
        window.location.hash = hash;
        this.setState({ CurrentRecipeSelected });
        break;
    }
  };
  renderRecipeList(): Array<JSX.Element> {
    return this.state.RestaurantRecipeCollection.map(
      (Recipe: IRestaurantRecipe, index: number) => {
        return (
          <tr
            id={`${Recipe.ref?.id}`}
            key={`Recipe${index}${Recipe.ref?.id}`}
            style={{
              backgroundColor:
                Recipe.ref?.id === this.state.CurrentRecipeSelected?.ref?.id
                  ? "#0078ff"
                  : index % 2
                  ? "#DCDCDC"
                  : "white",
              color:
                Recipe.ref?.id === this.state.CurrentRecipeSelected?.ref?.id
                  ? "white"
                  : "black",
              cursor: "pointer",
            }}
            onClick={(event) => this.onChanged("Recipe", Recipe.ref?.id)}
          >
            <td style={{ width: "30%" }}>{Recipe.RecipeName}</td>
            <td
              style={{
                width:
                  this.props.currentProfile?.ProfileRole === ProfileRoles.Dev ||
                  this.props.currentProfile?.ProfileRole === ProfileRoles.Admin
                    ? "60%"
                    : "70%",
                textAlign: "center",
              }}
            >
              {`${Recipe.RecipeDescription.substr(0, 80)}${
                Recipe.RecipeDescription.length > 80 ? "..." : ""
              }`}
            </td>
            {(this.props.currentProfile?.ProfileRole === ProfileRoles.Dev ||
              this.props.currentProfile?.ProfileRole ===
                ProfileRoles.Admin) && (
              <td style={{ width: "10%", textAlign: "center" }}>
                {
                  <DefaultButton
                    text="Delete"
                    iconProps={{ iconName: "Delete" }}
                    id={`Delete`}
                    onClick={async () =>
                      this.handleShowDeleteDialog(Recipe.ref?.id as string)
                    }
                  />
                }
              </td>
            )}
          </tr>
        );
      }
    );
  }
  handleShowDeleteDialog = (ref: string) => {
    this.setState({
      currentDeleteRef: ref,
      isDeleteDialogHidden: false,
    });
  };
  handleHideDeleteDialog = () => {
    if (!this.state.isLoading) {
      this.setState({ isDeleteDialogHidden: true });
    }
  };
  handleDelete = async (ref: string) => {
    this.setState({ isLoading: true });
    await this.Api.delDoc(
      ref as string,
      this.props.currentRestaurant?.RestaurantSubCollectionPaths
        .RestaurantRecipeCollectionPath
    );
    let RestaurantRecipeCollection: Array<IRestaurantRecipe> = this.state.RestaurantRecipeCollection.filter(
      (r) => r.ref?.id !== ref
    );
    this.setState({
      RestaurantRecipeCollection,
      CurrentRecipeSelected: null,
      isLoading: false,
      isDeleteDialogHidden: true,
    });
  };
  setCurrentRecipeSelected = async (ref?: string) => {
    let RestaurantRecipeCollection: Array<IRestaurantRecipe> = this.state
      .RestaurantRecipeCollection;
    let RestaurantRecipeCollectionNotFilter: Array<IRestaurantRecipe> = this
      .state.RestaurantRecipeCollectionNotFilter;
    if (ref) {
      const CurrentRecipeSelected = RestaurantRecipeCollectionNotFilter.find(
        (r) => r.ref?.id === ref
      );
      if (CurrentRecipeSelected) {
        let hash = new URL(window.location.href).hash;
        hash = setHash(hash, "Recipe", CurrentRecipeSelected.ref?.id as string);
        window.location.hash = hash;
        this.setState({
          CurrentRecipeSelected: CurrentRecipeSelected,
        });
      } else {
        let Recipe: IRestaurantRecipe = await this.Api.getDoc(
          ref,
          this.props.currentRestaurant?.RestaurantSubCollectionPaths
            .RestaurantRecipeCollectionPath
        );
        RestaurantRecipeCollection.push(Recipe);
        RestaurantRecipeCollectionNotFilter.push(Recipe);
        let hash = new URL(window.location.href).hash;
        hash = setHash(hash, "Recipe", ref);
        window.location.hash = hash;
        this.setState({
          RestaurantRecipeCollection: this.sortRestaurantOrderCollection(
            RestaurantRecipeCollection,
            this.state.SortSelected
          ),
          RestaurantRecipeCollectionNotFilter: this.sortRestaurantOrderCollection(
            RestaurantRecipeCollectionNotFilter,
            this.state.SortSelected
          ),
          CurrentRecipeSelected: Recipe,
        });
      }
    } else {
      let index: number = RestaurantRecipeCollection.findIndex(
        (p) => p.ref?.id === this.state.CurrentRecipeSelected?.ref?.id
      );
      let indexNotFilter: number = RestaurantRecipeCollectionNotFilter.findIndex(
        (p) => p.ref?.id === this.state.CurrentRecipeSelected?.ref?.id
      );
      if (indexNotFilter !== -1) {
        let Recipe: IRestaurantRecipe = await this.Api.getDoc(
          this.state.CurrentRecipeSelected?.ref?.id as string,
          this.props.currentRestaurant?.RestaurantSubCollectionPaths
            .RestaurantRecipeCollectionPath
        );
        if (index !== -1) {
          RestaurantRecipeCollection[index] = Recipe;
        }
        RestaurantRecipeCollectionNotFilter[indexNotFilter] = Recipe;
        this.setState({
          RestaurantRecipeCollection,
          RestaurantRecipeCollectionNotFilter,
          CurrentRecipeSelected: Recipe,
        });
      }
    }
  };
  render(): JSX.Element {
    const CurrentRecipeSelected: IRestaurantRecipe | null = this.state
      .CurrentRecipeSelected;
    const OverFlowStyle: React.CSSProperties = {
      width: "100%",
      height: `${this.props.innerHeight - 165}px`,
      overflowY: "scroll",
    };
    const OrderTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const OrderTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    const iconStyles = { marginRight: "8px" };
    const onRenderOption = (option: ISelectableOption): JSX.Element => {
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
      const option = options[0];
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    return (
      <div>
        {this.props.innerWidth < 1400 && this.state.CurrentRecipeSelected && (
          <span style={{ position: "fixed", bottom: 15, left: 10, zIndex: 5 }}>
            <DefaultButton
              text="Back"
              iconProps={{ iconName: "Back" }}
              id={`Back`}
              onClick={() => {
                let hash = new URL(window.location.href).hash;
                hash = deleteHash(hash, "Recipe");
                window.location.hash = hash;
                this.setState({ CurrentRecipeSelected: null });
              }}
            />
          </span>
        )}
        {!this.state.isLoading &&
          ((((this.props.currentProfile &&
            this.props.currentProfile.ProfileRole === ProfileRoles.Dev) ||
            (this.props.currentProfile &&
              this.props.currentProfile.ProfileRole === ProfileRoles.Admin)) &&
            this.props.innerWidth >= 1400) ||
            !this.state.CurrentRecipeSelected) && (
            <span
              style={{ position: "fixed", bottom: 15, left: 10, zIndex: 5 }}
            >
              <PrimaryButton
                id={`-1 AddRecipe`}
                onClick={() => {
                  let hash = new URL(window.location.href).hash;
                  hash = setHash(hash, "Recipe", "New");
                  window.location.hash = hash;
                  this.setState({
                    CurrentRecipeSelected: {
                      RecipeName: "",
                      RecipeShow: true,
                      RecipeRating: 0,
                      RecipeTypes: [],
                      RecipeDescription: "",
                      RecipeWorkInHours: 0,
                      RecipeCookTimeInHours: 0,
                      RecipeSteps: [],
                      RecipePortions: 0,
                      RecipeContent: {
                        RecipeProducts: [],
                        RecipeRecipes: [],
                      },
                      RecipeLastUpdateProfile: (this.props
                        .currentProfile as any).ref,
                      RecipeLastUpdateDate: this.Api.TimestampNow(),
                    },
                  });
                }}
                iconProps={{ iconName: "Add" }}
              >
                Add Recipe
              </PrimaryButton>
            </span>
          )}
        <table style={OrderTableStyle}>
          <tbody>
            <tr>
              {(this.props.innerWidth >= 1400 ||
                !this.state.CurrentRecipeSelected) && (
                <td
                  style={{
                    width: this.props.innerWidth >= 1400 ? "40%" : "98%",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      width: this.props.innerWidth > 600 ? "100%" : "98%",
                    }}
                  >
                    <table style={OrderTableStyle}>
                      <tbody>
                        <tr key={"FilterAndSortMenu"}>
                          <td>
                            <SearchBox
                              id={`-1 Recipe`}
                              placeholder="Search Recipe..."
                              value={this.state.RecipeSearch}
                              onChange={(e, v) => this.onChanged("Search", v)}
                              style={{
                                maxWidth:
                                  this.props.innerWidth >= 1400
                                    ? this.props.innerWidth * 0.25
                                    : this.props.innerWidth * 0.7,
                              }}
                            />
                          </td>
                          <td>
                            <Dropdown
                              id={`-1 Sort`}
                              placeholder="Select Sort..."
                              selectedKey={this.state.SortSelected}
                              onChange={(e, o) => this.onChanged("Sort", o)}
                              options={[
                                {
                                  key: "RecipeNameDescendent",
                                  text: "Name",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "RecipeNameAscendent",
                                  text: "Name",
                                  data: { icon: "Up" },
                                },
                                {
                                  key: "RecipeRatingDescendent",
                                  text: "Rating",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "RecipeRatingAscendent",
                                  text: "Rating",
                                  data: { icon: "Up" },
                                },
                              ]}
                              onRenderTitle={(t) =>
                                onRenderTitle(t as Array<IDropdownOption>)
                              }
                              onRenderOption={(o) =>
                                onRenderOption(o as ISelectableOption)
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr></hr>
                    <table style={OrderTableStyle}>
                      <thead style={OrderTableHeadStyle}>
                        <tr>
                          <th style={{ width: "30%" }}>Name</th>
                          <th style={{ width: "70%" }}>Description</th>
                        </tr>
                      </thead>
                    </table>
                    <div style={OverFlowStyle}>
                      <table style={OrderTableStyle}>
                        <tbody>
                          {this.state.isLoading ? (
                            <tr>
                              <td>
                                <Spinner
                                  style={{ marginTop: 10 }}
                                  size={SpinnerSize.large}
                                />
                              </td>
                            </tr>
                          ) : this.state.RestaurantRecipeCollection.length ? (
                            this.renderRecipeList()
                          ) : (
                            <tr>
                              <td>No Results</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <div style={{ height: "100px" }} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              )}
              {this.props.currentProfile &&
                this.props.currentRestaurant &&
                CurrentRecipeSelected && (
                  <td
                    style={{
                      width: this.props.innerWidth >= 1400 ? "59%" : "100%",
                    }}
                  >
                    <RecipeSelected
                      Api={this.Api}
                      Profile={this.props.currentProfile}
                      Restaurant={this.props.currentRestaurant}
                      ProfileCollection={this.state.ProfileCollection}
                      ProductCollection={this.state.ProductCollection}
                      RestaurantRecipeCollection={
                        this.state.RestaurantRecipeCollectionNotFilter
                      }
                      CurrentRecipeSelected={CurrentRecipeSelected as any}
                      innerHeight={this.props.innerHeight}
                      innerWidth={this.props.innerWidth}
                      setCurrentRecipeSelected={this.setCurrentRecipeSelected}
                      AddRecipe={CurrentRecipeSelected.ref === undefined}
                    />
                  </td>
                )}
            </tr>
          </tbody>
        </table>
        <Dialog
          hidden={this.state.isDeleteDialogHidden}
          onDismiss={this.handleHideDeleteDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Recipe?",
            closeButtonAriaLabel: "Close",
            subText: `The Recipe ${
              this.state.RestaurantRecipeCollection.find(
                (p) => p.ref?.id === this.state.currentDeleteRef
              )?.RecipeName
            } will be Deleted`,
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => this.handleDelete(this.state.currentDeleteRef)}
              text="Delete Now"
            />
            <DefaultButton
              onClick={this.handleHideDeleteDialog}
              text="Don't Delete"
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
