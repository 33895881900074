import {
  IRestaurantArea,
  IRestaurantItem,
  IRestaurantCG,
  IProvider,
  ProductUnitMesure,
  IRestaurantRecipe,
  IProfile,
} from "../models/IFirestoreModels";
import IApi from "../api/IApi";
import { RestaurantCollectionPath } from "../const/CollectionsPaths";
import { getReplaceWith } from "./functions";
import firebase from "firebase/app";

export default class UploadManager {
  static async setRestaurant(
    Api: IApi,
    RestaurantDocumentPath: string,
    Restaurant: IRestaurantCG
  ) {
    console.log("upd", Restaurant);
    Api.updDoc(RestaurantDocumentPath, Restaurant, RestaurantCollectionPath);
  }
  static async setProviders(
    Api: IApi,
    ProviderCollectionPath: string,
    Providers: Array<IProvider>
  ) {
    Api.merCollect(
      Providers,
      ProviderCollectionPath,
      "ProviderName",
      () => true,
      console.log
    );
  }
  static async setAreas(
    Api: IApi,
    RestaurantAreaCollectionPath: string,
    Areas: Array<IRestaurantArea>
  ) {
    Api.merCollect(
      Areas,
      RestaurantAreaCollectionPath,
      "AreaName",
      () => true,
      console.log
    );
  }
  static async setProducts(
    Api: IApi,
    Restaurant: IRestaurantCG,
    RestaurantItemCollectionPath: string,
    Products: Array<any>
  ) {
    const AreaCollection: Array<IRestaurantArea> = await Api.getCollect(
      Restaurant.RestaurantSubCollectionPaths.RestaurantAreaCollectionPath
    );
    const ProviderCollection: Array<IProvider> = await Api.getCollect(
      Restaurant.RestaurantSubCollectionPaths.ProviderCollectionPath
    );
    Api.merCollect(
      Products.map((product: IRestaurantItem) => {
        let ProductDoc: IRestaurantItem;
        ProductDoc = {
          AreaGUID: product.AreaGUID
            ? AreaCollection.find(
                (a) => a.AreaName === (product.AreaGUID as any)
              )
              ? (AreaCollection.find(
                  (a) => a.AreaName === (product.AreaGUID as any)
                ) as IRestaurantArea).ref
              : AreaCollection.find((a) => a.ref?.id === product.AreaGUID?.id)
              ? (AreaCollection.find(
                  (a) => a.ref?.id === product.AreaGUID?.id
                ) as IRestaurantArea).ref
              : (undefined as any)
            : (undefined as any),
          ProductName: product.ProductName
            ? product.ProductName
            : (undefined as any),
          ProductList:
            product.ProductList === 0
              ? 0
              : Number(product.ProductList)
              ? Number(product.ProductList)
              : (undefined as any),
          ProductUnitMesure:
            product.ProductUnitMesure &&
            getReplaceWith(
              Restaurant.RestaurantUnitMesures,
              product.ProductUnitMesure
            ) !== ("NoFoundCase" as any)
              ? (getReplaceWith(
                  Restaurant.RestaurantUnitMesures,
                  product.ProductUnitMesure
                ) as ProductUnitMesure)
              : (Restaurant.RestaurantUnitMesures
                  .defaultCase as ProductUnitMesure),
          ProductTags: product.ProductTags ? product.ProductTags : undefined,
          ProviderPreference: product.ProviderPreference
            ? ProviderCollection.find(
                (p) => p.ProviderName === (product.ProviderPreference as any)
              )
              ? (ProviderCollection.find(
                  (p) => p.ProviderName === (product.ProviderPreference as any)
                ) as IProvider).ref
              : ProviderCollection.find(
                  (p) => p.ref?.id === product.ProviderPreference?.id
                )
              ? (ProviderCollection.find(
                  (p) => p.ref?.id === product.ProviderPreference?.id
                ) as IProvider).ref
              : (undefined as any)
            : (undefined as any),
          //ProductStockLeft undefined
          ProductOffSeason: product.ProductOffSeason
            ? Boolean(product.ProductOffSeason)
            : (undefined as any),
          ProductPriceChangedNotify: product.ProductPriceChangedNotify
            ? Boolean(product.ProductPriceChangedNotify)
            : (undefined as any),
        };
        if (ProductDoc.AreaGUID === undefined) {
          delete ProductDoc.AreaGUID;
        }
        if ((ProductDoc as any).ProductList === undefined) {
          delete (ProductDoc as any).ProductList;
        }
        if ((ProductDoc as any).ProductUnitMesure === undefined) {
          delete (ProductDoc as any).ProductUnitMesure;
        }
        if (ProductDoc.ProductTags === undefined) {
          delete ProductDoc.ProductTags;
        }
        if (ProductDoc.ProviderPreference === undefined) {
          delete ProductDoc.ProviderPreference;
        }
        if (ProductDoc.ProductStockLeft === undefined) {
          delete ProductDoc.ProductStockLeft;
        }
        if (ProductDoc.ProductOffSeason !== true) {
          delete ProductDoc.ProductOffSeason;
        }
        if (ProductDoc.ProductPriceChangedNotify === undefined) {
          delete ProductDoc.ProductPriceChangedNotify;
        }
        return ProductDoc;
      }).filter((doc) => doc.ProductName),
      RestaurantItemCollectionPath,
      "ProductName",
      (doc: IRestaurantItem) => doc.AreaGUID !== undefined,
      console.log
    );
  }
  static async test(
    Api: IApi,
    Restaurant: IRestaurantCG,
    Profile: IProfile,
    A?: any,
    B?: any,
    C?: any
  ) {
    const ProductCollection: Array<IRestaurantItem> = await Api.getCollect(
      Restaurant.RestaurantSubCollectionPaths.RestaurantItemCollectionPath
    );
    const RecipeCollection: Array<IRestaurantRecipe> = await Api.getCollect(
      Restaurant.RestaurantSubCollectionPaths.RestaurantRecipeCollectionPath
    );
    Object.keys(A).forEach((sheetName) => {
      let sheet: Array<{ AMOUNT: number; UNITS: string; NAME: string }> =
        A[sheetName];
      let RecipeProducts: Array<{
        ProductGUID?: firebase.firestore.DocumentReference<
          firebase.firestore.DocumentData
        >;
        ProductUnitAmount: number;
        ProductUnitMesure: ProductUnitMesure;
      }> = [];
      let RecipeRecipes: Array<{
        RecipeGUID?: firebase.firestore.DocumentReference<
          firebase.firestore.DocumentData
        >;
        RecipeUnitAmount: number;
        RecipeUnitMesure: ProductUnitMesure;
      }> = [];
      sheet.forEach((row) => {
        if (row.NAME.includes("+")) {
          const RecipeGUID = RecipeCollection.find(
            (p) => p.RecipeName === row.NAME.replace("+", "")
          )?.ref;
          const RecipeUnitAmount = Number(row.AMOUNT);
          const RecipeUnitMesure = getReplaceWith(
            Restaurant.RestaurantUnitMesures,
            row.UNITS
          ) as ProductUnitMesure;
          RecipeRecipes.push({
            RecipeGUID: RecipeGUID ? RecipeGUID : (row.NAME as any),
            RecipeUnitAmount: RecipeUnitAmount,
            RecipeUnitMesure: RecipeUnitMesure,
          });
        } else {
          const ProductGUID = ProductCollection.find(
            (p) => p.ProductName === row.NAME
          )?.ref;
          const ProductUnitAmount = Number(row.AMOUNT);
          const ProductUnitMesure = getReplaceWith(
            Restaurant.RestaurantUnitMesures,
            row.UNITS
          ) as ProductUnitMesure;
          RecipeProducts.push({
            ProductGUID: ProductGUID ? ProductGUID : (row.NAME as any),
            ProductUnitAmount: ProductUnitAmount,
            ProductUnitMesure: ProductUnitMesure,
          });
        }
      });
      const recipe: IRestaurantRecipe = {
        RecipeName: sheetName,
        RecipeShow: true,
        RecipeRating: 5,
        RecipeTypes: [],
        RecipeDescription: sheetName,
        RecipeWorkInHours: 1,
        RecipeCookTimeInHours: 1,
        RecipeSteps: [],
        RecipePortions: 1,
        RecipeContent: {
          RecipeProducts,
          RecipeRecipes,
        },
        RecipeLastUpdateProfile: Profile.ref,
        RecipeLastUpdateDate: Api.TimestampNow() as any,
      };
      console.log(recipe.RecipeName);
      console.table(recipe.RecipeContent.RecipeProducts);
      console.table(recipe.RecipeContent.RecipeRecipes);
      // Api.addDoc(
      //   Restaurant.RestaurantSubCollectionPaths.RestaurantRecipeCollectionPath,
      //   recipe
      // );
    });
  }
}
