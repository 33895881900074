import React, { Component } from "react";
import IApi from "../../api/IApi";
import {
  IProfile,
  IRestaurantCG,
  IProvider,
  ProfileRoles,
} from "../../models/IFirestoreModels";
import {
  ProfileCollectionPath,
  RestaurantCollectionPath,
} from "../../const/CollectionsPaths";
import { Spinner, SpinnerSize, Label } from "office-ui-fabric-react";
import firebase from "firebase/app";
import CRUD, { CRUDPropertyType } from "../DevelopmentView/CRUD";

interface IModelEditorProfilesProps {
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  Restaurant: IRestaurantCG;
  innerHeight: number;
  innerWidth: number;
}
interface IModelEditorProfilesState {
  isLoading: boolean;
  isLoadingOffCollections: boolean;
  ProfileCollection: Array<IProfile>;
  RestaurantCollection: Array<IRestaurantCG>;
  RolesCollection: Array<{ key: string; text: string; value: any }>;
  ProviderCollection: Array<{ key: string; text: string; value: any }>;
}
export default class ModelEditorProfiles extends Component<
  IModelEditorProfilesProps,
  IModelEditorProfilesState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingOffCollections: true,
      ProfileCollection: [],
      RestaurantCollection: [],
      RolesCollection: [],
      ProviderCollection: [],
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (
      this.props.currentUser &&
      this.props.Restaurant &&
      this.props.currentProfile
    ) {
      const RestaurantCollection: Array<IRestaurantCG> = await this.Api.getCollect(
        RestaurantCollectionPath
      );
      let RolesCollection: Array<String> = [];
      if (this.props.currentProfile.ProfileRole === ProfileRoles.Dev) {
        RolesCollection.push(ProfileRoles.Dev);
      }
      RolesCollection.push(ProfileRoles.Admin);
      RestaurantCollection.map(
        (restaurant: IRestaurantCG) => restaurant.RestaurantRoles
      ).forEach(
        (roles: Array<String>) =>
          (RolesCollection = RolesCollection.concat(roles))
      );
      RolesCollection.push(ProfileRoles.New);
      this.setState({
        RestaurantCollection,
        RolesCollection: RolesCollection.map((rol) => {
          return { key: rol, text: rol, value: rol } as any;
        }),
        ProviderCollection: (
          await this.Api.getCollect(
            this.props.Restaurant.RestaurantSubCollectionPaths
              .ProviderCollectionPath,
            undefined,
            undefined,
            "ProviderName",
            "asc"
          )
        ).map((provider: IProvider) => {
          return {
            key: provider.ref?.path,
            text: provider.ProviderName,
            value: provider.ref,
          } as any;
        }),
        isLoadingOffCollections: false,
      });
      this.Api.getCollect(
        ProfileCollectionPath,
        this.props.currentProfile.ProfileRole !== ProfileRoles.Dev
          ? [{ fieldPath: "ProfileRole", opStr: "!=", value: ProfileRoles.Dev }]
          : undefined,
        (ProfileCollection) =>
          this.setState({ ProfileCollection, isLoading: false })
      );
    }
  }
  componentDidUpdate(
    prevProps: IModelEditorProfilesProps,
    prevState: IModelEditorProfilesState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.Restaurant?.RestaurantSubCollectionPaths !==
        prevProps.Restaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  render(): JSX.Element {
    return (
      <div>
        {this.state.isLoading || this.state.isLoadingOffCollections ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : (
          <div>
            <CRUD
              List={this.state.ProfileCollection.map((i) => {
                return { ...i, ref: i.ref?.path };
              })}
              ItemName={"Profile"}
              DisplayField={"ProfileContactName"}
              Structure={[
                {
                  Type: CRUDPropertyType.ID,
                  Name: "ref",
                },
                {
                  Type: CRUDPropertyType.Enum,
                  Name: "ProfileRole",
                  Enforced: true,
                  DisplayName: "Role",
                  IconName: "Assign",
                  Width: 15,
                  Settings: {
                    Choices: this.state.RolesCollection,
                  },
                  BuiltInFilter: true,
                },
                {
                  Type: CRUDPropertyType.Enum,
                  Name: "ProviderGUID",
                  DisplayName: "Vendor",
                  IconName: "DeliveryTruck",
                  Width: 10,
                  Settings: {
                    refAsKey: "path",
                    Choices: this.state.ProviderCollection,
                  },
                },
                {
                  Type: CRUDPropertyType.String,
                  Name: "ProfileContactName",
                  Enforced: true,
                  DisplayName: "Contact Name",
                  IconName: "People",
                  Width: "15%",
                  BuiltInFilter: true,
                },
                {
                  Type: CRUDPropertyType.Email,
                  Name: "ProfileContactEmail",
                  Enforced: true,
                  DisplayName: "Contact Email",
                  IconName: "Mail",
                  Width: "15%",
                },
                {
                  Type: CRUDPropertyType.Control,
                  Name: "ResetPassword",
                  DisplayName: "Reset Password",
                  IconName: "Mail",
                  Width: "10%",
                },
                {
                  Type: CRUDPropertyType.Number,
                  Name: "ProfileContactPhone",
                  Enforced: true,
                  DisplayName: "Contact Phone",
                  IconName: "Phone",
                  Width: "15%",
                },
                {
                  Type: CRUDPropertyType.Control,
                  Name: "Control",
                  Width: "20%",
                },
              ]}
              onDisable={(item, col) => {
                switch (col.Name) {
                  case "ProfileContactEmail":
                    return true;
                  case "ProviderGUID":
                    return (
                      item.ProfileRole !==
                      this.props.Restaurant.RestaurantRoles.find((rol) =>
                        rol.includes(ProfileRoles.Provider)
                      )
                    );
                }
              }}
              UpdateConfirm={true}
              onUpdate={async (updates) => {
                // console.log("UPD", updates);
                try {
                  let promiseUpdates: Array<Promise<void>> = [];
                  updates.forEach((u) => {
                    if (
                      u.changes.ProviderGUID === null ||
                      (u.changes.ProfileRole &&
                        u.changes.ProfileRole !==
                          this.props.Restaurant.RestaurantRoles.find((rol) =>
                            rol.includes(ProfileRoles.Provider)
                          ))
                    ) {
                      u.changes.ProviderGUID = firebase.firestore.FieldValue.delete();
                    }
                    if (u.changes.ProfileRole) {
                      const r = this.state.RestaurantCollection.find((r) =>
                        r.RestaurantRoles.find(
                          (rol) => rol === u.changes.ProfileRole
                        )
                      );
                      if (r) {
                        u.changes.RestaurantGUID = r.ref;
                      } else {
                        u.changes.RestaurantGUID = this.props.Restaurant.ref;
                      }
                    }
                    promiseUpdates.push(this.Api.updDoc(u.id, u.changes));
                  });
                  await Promise.all(promiseUpdates);
                  return "true";
                  //check for onSnapshot posible issues
                } catch (error) {
                  return error;
                }
              }}
              DeleteConfirm={true}
              onDelete={async (id) => {
                // console.log("DEL", id);
                try {
                  await this.Api.updDoc(id, {
                    ProfileRole: ProfileRoles.New,
                    ProviderGUID: firebase.firestore.FieldValue.delete(),
                  });
                  return "true";
                  //check for onSnapshot posible issues
                } catch (error) {
                  return error;
                }
              }}
              ActionConfirm={true}
              onAction={async (id, item) => {
                // console.log("Reset", id, item);
                try {
                  await this.Api.resetPassword(item.ProfileContactEmail);
                  return "true";
                  //check for onSnapshot posible issues
                } catch (error) {
                  return error;
                }
              }}
              BuiltInFilter={true}
              onSort={(list) => {
                let outList: Array<any> = [];
                this.state.RolesCollection.forEach((r) => {
                  outList = outList.concat(
                    list.filter((i) => i.ProfileRole === (r as any).value)
                  );
                });
                return outList;
              }}
              maxHeight={this.props.innerHeight}
              maxWidth={this.props.innerWidth}
            ></CRUD>
            <hr />
            <Label>Create a New Profile</Label>
            <CRUD
              List={[]}
              ItemName={"Profile"}
              DisplayField={"ProfileContactEmail"}
              Structure={[
                {
                  Type: CRUDPropertyType.ID,
                  Name: "ref",
                },

                {
                  Type: CRUDPropertyType.Email,
                  Name: "ProfileContactEmail",
                  Enforced: true,
                  DisplayName: "Contact Email",
                  IconName: "Mail",
                  Width: "40%",
                },
                {
                  Type: CRUDPropertyType.String,
                  Name: "ProfileContactPassword",
                  Enforced: true,
                  DisplayName: "Contact Password",
                  Width: "40%",
                },
                {
                  Type: CRUDPropertyType.Control,
                  Name: "Control",
                  Width: "20%",
                },
              ]}
              AddItem={true}
              AddConfirm={true}
              onAdd={async (item) => {
                // console.log("ADD", item);
                try {
                  const cred: firebase.auth.UserCredential = await this.Api.createUser(
                    (item as any).ProfileContactEmail,
                    (item as any).ProfileContactPassword
                  );
                  let newProfile: IProfile = {
                    ProfileContactEmail: cred.user?.email,
                    ProfileRole: ProfileRoles.New,
                  } as any;
                  await this.Api.setDoc(
                    cred.user ? cred.user?.uid : "",
                    newProfile,
                    ProfileCollectionPath
                  );
                  await this.Api.signOut();
                  return "true";
                  //check for onSnapshot posible issues
                } catch (error) {
                  return error;
                }
              }}
            ></CRUD>
          </div>
        )}
      </div>
    );
  }
}
