import React, { Component } from "react";
import IApi, { Environment } from "../../api/IApi";
import {
  Pivot,
  PivotItem,
  PivotLinkFormat,
  PivotLinkSize,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import { IRestaurantCG, IProfile } from "../../models/IFirestoreModels";
import ModelEditorRestaurantStaff from "./ModelEditorRestaurantStaff";
import firebase from "firebase/app";

interface IModelEditorEmailsProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
}
interface IModelEditorEmailsState {}
export default class ModelEditorEmails extends Component<
  IModelEditorEmailsProps,
  IModelEditorEmailsState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {};
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (
      this.props.currentUser &&
      this.props.currentProfile &&
      this.props.currentRestaurant
    ) {
    }
  }
  componentDidUpdate(
    prevProps: IModelEditorEmailsProps,
    prevState: IModelEditorEmailsState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  NavTo = (item?: PivotItem) => {
    if (item && item.props && item.props.itemKey) {
      let params = new URL(window.location.href).searchParams;
      params.set("notify", item.props.itemKey);
      params.delete("sort");
      window.location.search = params.toString();
    }
  };
  render(): JSX.Element {
    const OverFlowStyle: React.CSSProperties = {
      marginTop: "5px",
      width: "100%",
      height: `${this.props.innerHeight - 150}px`,
      overflowY: "scroll",
    };
    let params = new URL(window.location.href).searchParams;
    return (
      <div>
        {!this.props.currentRestaurant ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : (
          <Pivot
            onLinkClick={this.NavTo}
            selectedKey={
              params.get("notify")
                ? params.get("notify")
                : "RestaurantStaffNewOrderNotify"
            }
            linkFormat={PivotLinkFormat.tabs}
            linkSize={PivotLinkSize.large}
          >
            <PivotItem
              itemKey="RestaurantStaffNewOrderNotify"
              headerText={"Order Created"}
              itemIcon="Mail"
            >
              <div style={OverFlowStyle}>
                <ModelEditorRestaurantStaff
                  currentUser={this.props.currentUser}
                  Api={this.Api}
                  Restaurant={this.props.currentRestaurant}
                  EmailGroup={"RestaurantStaffNewOrderNotify"}
                />
              </div>
            </PivotItem>
            <PivotItem
              itemKey="RestaurantStaffReturnOrderNotify"
              headerText={"Order Returned"}
              itemIcon="Mail"
            >
              <div style={OverFlowStyle}>
                <ModelEditorRestaurantStaff
                  currentUser={this.props.currentUser}
                  Api={this.Api}
                  Restaurant={this.props.currentRestaurant}
                  EmailGroup={"RestaurantStaffReturnOrderNotify"}
                />
              </div>
            </PivotItem>
            <PivotItem
              itemKey="RestaurantStaffOrderCheckedNotify"
              headerText={"Order Checked"}
              itemIcon="Mail"
            >
              <div style={OverFlowStyle}>
                <ModelEditorRestaurantStaff
                  currentUser={this.props.currentUser}
                  Api={this.Api}
                  Restaurant={this.props.currentRestaurant}
                  EmailGroup={"RestaurantStaffOrderCheckedNotify"}
                />
              </div>
            </PivotItem>
            <PivotItem
              itemKey="RestaurantStaffUpdateBidsNotify"
              headerText={"Bid Updated"}
              itemIcon="Mail"
            >
              <div style={OverFlowStyle}>
                <ModelEditorRestaurantStaff
                  currentUser={this.props.currentUser}
                  Api={this.Api}
                  Restaurant={this.props.currentRestaurant}
                  EmailGroup={"RestaurantStaffUpdateBidsNotify"}
                />
              </div>
            </PivotItem>
            <PivotItem
              itemKey="RestaurantStaffProductPriceChangedNotify"
              headerText={"Price Changed"}
              itemIcon="Mail"
            >
              <div style={OverFlowStyle}>
                <ModelEditorRestaurantStaff
                  currentUser={this.props.currentUser}
                  Api={this.Api}
                  Restaurant={this.props.currentRestaurant}
                  EmailGroup={"RestaurantStaffProductPriceChangedNotify"}
                />
              </div>
            </PivotItem>
            <PivotItem
              itemKey="RestaurantStaffProductStockLeftNotify"
              headerText={"Stock Changed"}
              itemIcon="Mail"
            >
              <div style={OverFlowStyle}>
                <ModelEditorRestaurantStaff
                  currentUser={this.props.currentUser}
                  Api={this.Api}
                  Restaurant={this.props.currentRestaurant}
                  EmailGroup={"RestaurantStaffProductStockLeftNotify"}
                />
              </div>
            </PivotItem>
          </Pivot>
        )}
      </div>
    );
  }
}
