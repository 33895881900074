import React, { Component } from "react";
import {
  IProviderBid,
  IRestaurantItem,
  IProvider,
} from "../../models/IFirestoreModels";
import { Line } from "react-chartjs-2";
import { getDaysBetween, PrintDateWithMonthName } from "../../util/functions";
import { Dropdown, IDropdownOption, Toggle } from "office-ui-fabric-react";

interface IBidGraphProps {
  ProviderCollection: Array<IProvider>;
  ProductCollection: Array<IRestaurantItem>;
  ProviderBidCollection: Array<IProviderBid>;
  StartDate: Date;
  EndDate: Date;
  Average: number;
  innerHeight: number;
  innerWidth: number;
  setCurrentBidSelected: (CurrentBidSelected: IProviderBid | null) => void;
}
interface IBidGraphState {
  useUnitPrice: boolean;
  labels: Array<string>;
  datasets: Array<{
    label: string;
    borderColor: string;
    data: Array<number>;
  }>;
  datasetsBids: Array<{
    label: IProvider;
    data: Array<IProviderBid>;
  }>;

  timeframe: number;
  TimeFrameOptions: Array<IDropdownOption>;
  StartDate: Date;
  EndDate: Date;
}
export default class BidGraph extends Component<
  IBidGraphProps,
  IBidGraphState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      useUnitPrice: false,
      labels: [],
      datasets: [],
      datasetsBids: [],

      timeframe: 1,
      TimeFrameOptions: [],
      StartDate: this.props.StartDate,
      EndDate: this.props.EndDate,
    };
  }
  componentDidMount() {
    const StartDate: Date =
      this.props.ProviderBidCollection.length > 0
        ? new Date(
            this.props.ProviderBidCollection.reduce(
              (previousValue: IProviderBid, currentValue: IProviderBid) =>
                previousValue.ProductBidDay < currentValue.ProductBidDay
                  ? previousValue
                  : currentValue
            )
              .ProductBidDay.toDate()
              .getTime()
          )
        : this.props.StartDate;
    const EndDate: Date =
      this.props.ProviderBidCollection.length > 0
        ? new Date(
            this.props.ProviderBidCollection.reduce(
              (previousValue: IProviderBid, currentValue: IProviderBid) =>
                previousValue.ProductBidDay > currentValue.ProductBidDay
                  ? previousValue
                  : currentValue
            )
              .ProductBidDay.toDate()
              .getTime()
          )
        : this.props.EndDate;
    const BorderColors: Array<string> = [
      "DodgerBlue",
      "Orange",
      "MediumSeaGreen",
      "Tomato",
      "Violet",
      "SlateBlue",
      "DarkGreen",
      "Indigo",
      "FireBrick",
      "MediumBlue",
      "DeepPink",
      "DarkSalmon",
      "LightBlue",
      "Lime",
    ];
    let colorIndex = 0;
    let labels: Array<string> = [];
    let t: Date = new Date(StartDate.getTime());
    while (t <= EndDate) {
      labels.push(PrintDateWithMonthName(t, true));
      t.setDate(t.getDate() + this.state.timeframe);
    }
    let datasets: Array<{
      label: string;
      borderColor: string;
      data: Array<number>;
    }> = [];
    let datasetsBids: Array<{
      label: IProvider;
      data: Array<IProviderBid>;
    }> = [];
    this.props.ProviderCollection.forEach((provider) => {
      const bids = this.props.ProviderBidCollection.filter(
        (p) => p.ProviderGUID?.path === provider.ref?.path
      ).sort((A, B) =>
        A.ProductBidDay > B.ProductBidDay
          ? 1
          : A.ProductBidDay < B.ProductBidDay
          ? -1
          : 0
      );
      if (bids.length > 0) {
        let data: Array<number> = [];
        let dataBids: Array<IProviderBid> = [];
        let t: Date = new Date(StartDate.getTime());
        let lastBid = bids[0];
        let lastBidAverage: number = this.state.useUnitPrice
          ? (lastBid as any).TopResultProduct.ProductPackPrice /
            (lastBid as any).TopResultProduct.ProductUnitPerPack /
            (lastBid as any).TopResultProduct.ProductUnitAmount
          : (lastBid as any).TopResultProduct.ProductPackPrice;
        while (t <= EndDate) {
          let t_plusTimeFrame: Date = new Date(t.getTime());
          t_plusTimeFrame.setDate(
            t_plusTimeFrame.getDate() + this.state.timeframe
          );
          const currentBids = bids.filter(
            (bid) =>
              bid.ProductBidDay.toDate() >= t &&
              bid.ProductBidDay.toDate() <= t_plusTimeFrame
          );
          lastBid = currentBids.length > 0 ? currentBids[0] : lastBid;
          if (currentBids.length > 0) {
            let Summation = 0;
            currentBids.forEach((bid) => {
              Summation += this.state.useUnitPrice
                ? (bid as any).TopResultProduct.ProductPackPrice /
                  (bid as any).TopResultProduct.ProductUnitPerPack /
                  (bid as any).TopResultProduct.ProductUnitAmount
                : (bid as any).TopResultProduct.ProductPackPrice;
            });
            lastBidAverage = Summation / currentBids.length;
          }
          data.push(Number(lastBidAverage.toFixed(2)));
          dataBids.push(lastBid);
          t.setDate(t.getDate() + this.state.timeframe);
        }
        datasets.push({
          label: provider.ProviderName,
          borderColor:
            colorIndex < BorderColors.length
              ? BorderColors[colorIndex++]
              : "Gray",
          data,
        });
        datasetsBids.push({
          label: provider,
          data: dataBids,
        });
      }
    });
    let TimeFrameOptions = [{ key: "1", text: "Day" }];
    if (getDaysBetween(StartDate, EndDate) > 7) {
      TimeFrameOptions.push({ key: "7", text: "Week" });
    }
    if (getDaysBetween(StartDate, EndDate) > 30) {
      TimeFrameOptions.push({ key: "30", text: "Month" });
    }
    if (getDaysBetween(StartDate, EndDate) > 90) {
      TimeFrameOptions.push({ key: "90", text: "3 Month" });
    }
    if (getDaysBetween(StartDate, EndDate) > 180) {
      TimeFrameOptions.push({ key: "180", text: "6 Month" });
    }
    if (getDaysBetween(StartDate, EndDate) > 365) {
      TimeFrameOptions.push({ key: "365", text: "Year" });
    }
    this.setState({
      labels,
      datasets,
      datasetsBids,

      StartDate,
      EndDate,
      timeframe:
        getDaysBetween(StartDate, EndDate) < this.state.timeframe
          ? 1
          : this.state.timeframe,
      TimeFrameOptions,
    });
  }
  componentDidUpdate(
    prevProps: IBidGraphProps,
    prevState: IBidGraphState,
    snapshot: any
  ) {
    if (
      this.props.ProviderBidCollection !== prevProps.ProviderBidCollection ||
      this.state.useUnitPrice !== prevState.useUnitPrice ||
      this.state.timeframe !== prevState.timeframe
    ) {
      this.componentDidMount();
    }
  }
  render(): JSX.Element {
    const GraphTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const GraphTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    return (
      <div style={{ width: "98%", marginLeft: "5px" }}>
        <table style={GraphTableStyle}>
          <thead style={GraphTableHeadStyle}>
            <tr>
              <th style={{ width: "20%", height: "30px" }}>Average:</th>
              <th style={{ width: "40%", height: "30px" }}>
                ${this.props.Average.toFixed(2)}
              </th>
              <th style={{ width: "15%", height: "30px" }}>Use Unit Price</th>
              <th style={{ width: "5%", height: "30px" }}>
                <Toggle
                  style={{ marginTop: "5px" }}
                  defaultChecked={this.state.useUnitPrice}
                  onChange={(event: any, checked?: boolean | undefined) =>
                    this.setState({
                      useUnitPrice: checked ? true : false,
                    })
                  }
                  role="checkbox"
                />
              </th>
              <th style={{ width: "20%", height: "30px" }}>
                <Dropdown
                  selectedKey={this.state.timeframe.toString()}
                  options={this.state.TimeFrameOptions}
                  onChange={(
                    event: any,
                    option?: IDropdownOption | undefined
                  ) =>
                    option && this.setState({ timeframe: Number(option.key) })
                  }
                  style={{ width: "100%" }}
                />
              </th>
            </tr>
          </thead>
        </table>
        <hr></hr>
        <Line
          data={{
            labels: this.state.labels,
            datasets: this.state.datasets,
          }}
          getElementAtEvent={(e: any) => {
            if (e[0]) {
              const Bid = this.state.datasetsBids[e[0]._datasetIndex].data[
                e[0]._index
              ];
              this.props.setCurrentBidSelected(Bid ? Bid : null);
            }
          }}
        />
      </div>
    );
  }
}
