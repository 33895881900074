import React, { Component } from "react";
import IApi, { Environment, whereQuery } from "../../api/IApi";
import {
  IProviderBid,
  IRestaurantCG,
  IProvider,
  IRestaurantArea,
  IRestaurantItem,
  IProfile,
  IProviderItemBid,
} from "../../models/IFirestoreModels";
import {
  Spinner,
  SpinnerSize,
  Dropdown,
  IDropdownOption,
  DatePicker,
  Icon,
  ISelectableOption,
  DefaultButton,
  PrimaryButton,
} from "office-ui-fabric-react";
import BidSelected from "./BidSelected";
import { PrintDate, getHash, setHash, deleteHash } from "../../util/functions";
import { RestaurantCollectionPath } from "../../const/CollectionsPaths";
import MultiSelect from "react-multi-select-component";
import BidGraph from "./BidGraph";
import firebase from "firebase/app";

interface IBidManagerProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
}
interface IBidManagerState {
  isLoading: boolean;
  RestaurantCollection: Array<IRestaurantCG>;
  ProviderBidCollectionNotFilter: Array<IProviderBid>;
  ProviderBidCollection: Array<IProviderBid>;
  ProviderCollection: Array<IProvider>;
  ProviderCollectionOptions: Array<IDropdownOption>;
  AreaCollection: Array<IRestaurantArea>;
  ProductCollection: Array<IRestaurantItem>;
  ProductCollectionOptions: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;

  ProductsSearch: Array<{ label: string; value: string; disabled?: boolean }>;
  StartDate?: Date;
  EndDate?: Date;
  Providers?: Array<string>;
  SortSelected: string;
  Average: number;

  CurrentBidSelected: IProviderBid | null;
  ShowBidsGraph: boolean;
}
export default class BidManager extends Component<
  IBidManagerProps,
  IBidManagerState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    let StartDate: Date = new Date();
    StartDate.setDate(StartDate.getDate() - 180);
    this.state = {
      isLoading: true,
      RestaurantCollection: [],
      ProviderBidCollectionNotFilter: [],
      ProviderBidCollection: [],
      ProviderCollection: [],
      ProviderCollectionOptions: [],
      AreaCollection: [],
      ProductCollection: [],
      ProductCollectionOptions: [],

      ProductsSearch: [],
      StartDate,
      EndDate: new Date(),
      Providers: [],
      SortSelected: "ProductBidDayDescendent",
      Average: 0,

      CurrentBidSelected: null,
      ShowBidsGraph: false,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (this.props.currentUser && this.props.currentProfile) {
      this.setState({ isLoading: true });
      const RestaurantCollection: Array<IRestaurantCG> = await this.Api.getCollect(
        RestaurantCollectionPath
      );
      let ProviderCollectionPromises: Array<Promise<Array<IProvider>>> = [];
      RestaurantCollection.forEach((r) => {
        ProviderCollectionPromises.push(
          this.Api.getCollect(
            r.RestaurantSubCollectionPaths.ProviderCollectionPath
          )
        );
      });
      const ProviderCollection = (await Promise.all(ProviderCollectionPromises))
        .flatMap((p) => p)
        .map((p) => {
          const ProviderCollectionPath = p.ref?.path.replace(
            "/" + p.ref?.id,
            ""
          );
          const RestaurantCity = RestaurantCollection.find(
            (r) =>
              r.RestaurantSubCollectionPaths.ProviderCollectionPath ===
              ProviderCollectionPath
          )?.RestaurantCity;
          return { ...p, ProviderName: `${p.ProviderName}, ${RestaurantCity}` };
        })
        .sort((A, B) =>
          A.ProviderName > B.ProviderName
            ? 1
            : A.ProviderName < B.ProviderName
            ? -1
            : 0
        );
      const ProviderCollectionOptions: Array<IDropdownOption> = ProviderCollection.map(
        (p) => {
          return {
            key: p.ref?.path,
            text: p.ProviderName,
          } as any;
        }
      );
      let AreaCollectionPromises: Array<Promise<Array<IRestaurantArea>>> = [];
      RestaurantCollection.forEach((r) => {
        AreaCollectionPromises.push(
          this.Api.getCollect(
            r.RestaurantSubCollectionPaths.RestaurantAreaCollectionPath
          )
        );
      });
      const AreaCollection = (
        await Promise.all(AreaCollectionPromises)
      ).flatMap((p) => p);
      let ProductCollectionPromises: Array<
        Promise<Array<IRestaurantItem>>
      > = [];
      RestaurantCollection.forEach((r) => {
        ProductCollectionPromises.push(
          this.Api.getCollect(
            r.RestaurantSubCollectionPaths.RestaurantItemCollectionPath
          )
        );
      });
      const ProductCollection = (
        await Promise.all(ProductCollectionPromises)
      ).flatMap((p) => p);
      let ProviderBidCollectionPromises: Array<
        Promise<Array<IProviderBid>>
      > = [];
      const ProductCollectionOptions = ProductCollection.map((p) => {
        const ProductCollectionPath = p.ref?.path.replace("/" + p.ref?.id, "");
        const RestaurantCity = RestaurantCollection.find(
          (r) =>
            r.RestaurantSubCollectionPaths.RestaurantItemCollectionPath ===
            ProductCollectionPath
        )?.RestaurantCity;
        return {
          label: `${p.ProductName}, ${RestaurantCity}`,
          value: p.ref?.path as string,
        };
      }).sort((A, B) => (A.label > B.label ? 1 : A.label < B.label ? -1 : 0));
      RestaurantCollection.forEach((r) => {
        ProviderBidCollectionPromises.push(
          this.getProviderBidCollection(
            r.RestaurantSubCollectionPaths.RestaurantItemBidCollectionPath,
            this.state.StartDate,
            this.state.EndDate,
            this.state.Providers
          )
        );
      });
      const ProviderBidCollection = (
        await Promise.all(ProviderBidCollectionPromises)
      ).flatMap((p) => p);
      let hash = new URL(window.location.href).hash;
      const CurrentBidSelected = ProviderBidCollection.find(
        (o) => o.ref?.id === getHash(hash, "Bid")
      );
      this.setState({
        RestaurantCollection,
        ProviderCollection,
        ProviderCollectionOptions,
        AreaCollection,
        ProductCollection,
        ProductCollectionOptions,
        isLoading: false,
        ProviderBidCollectionNotFilter: this.sortProviderBidCollection(
          ProviderBidCollection,
          this.state.SortSelected
        ),
        ProviderBidCollection: this.sortProviderBidCollection(
          ProviderBidCollection,
          this.state.SortSelected
        ),
        CurrentBidSelected: CurrentBidSelected ? CurrentBidSelected : null,
      });
    }
  }
  async getProviderBidCollection(
    RestaurantItemBidCollectionPath: string,
    StartDate?: Date,
    EndDate?: Date,
    Providers?: Array<string>
  ): Promise<Array<IProviderBid>> {
    let queryArray: Array<whereQuery> = [];
    if (StartDate) {
      queryArray.push({
        fieldPath: "ProductBidDay",
        opStr: ">=",
        value: StartDate,
      });
    }
    if (EndDate) {
      queryArray.push({
        fieldPath: "ProductBidDay",
        opStr: "<=",
        value: EndDate,
      });
    }
    let ProvidersRefs: Array<any> = [];
    Providers?.forEach((provider) => {
      ProvidersRefs.push(
        provider === "-1"
          ? { id: "-1", path: "-1" }
          : this.state.ProviderCollection.find((p) => p.ref?.path === provider)
              ?.ref
      );
    });
    if (Providers?.length) {
      queryArray.push({
        fieldPath: "ProviderGUID",
        opStr: "in",
        value: ProvidersRefs,
      });
    }
    return this.Api.getCollect(RestaurantItemBidCollectionPath, queryArray);
  }
  sortProviderBidCollection(
    ProviderBidCollection: Array<IProviderBid>,
    SortSelected: string
  ): Array<IProviderBid> {
    let Output: Array<IProviderBid> = [];
    switch (SortSelected) {
      case "ProviderGUIDAscendent":
        Output = ProviderBidCollection.sort((A, B) =>
          this.getProviderName(A) < this.getProviderName(B)
            ? -1
            : this.getProviderName(A) > this.getProviderName(B)
            ? 1
            : 0
        );
        break;
      case "ProviderGUIDDescendent":
        Output = ProviderBidCollection.sort((A, B) =>
          this.getProviderName(A) > this.getProviderName(B)
            ? -1
            : this.getProviderName(A) < this.getProviderName(B)
            ? 1
            : 0
        );
        break;
      case "ProductBidDayAscendent":
        Output = ProviderBidCollection.sort((A, B) =>
          A.ProductBidDay < B.ProductBidDay
            ? -1
            : A.ProductBidDay > B.ProductBidDay
            ? 1
            : 0
        );
        break;
      case "ProductBidDayDescendent":
        Output = ProviderBidCollection.sort((A, B) =>
          A.ProductBidDay > B.ProductBidDay
            ? -1
            : A.ProductBidDay < B.ProductBidDay
            ? 1
            : 0
        );
        break;
    }
    return Output;
  }
  filterByProduct(
    ProviderBidCollection: Array<IProviderBid>,
    ProductsFilter: Array<{
      label: string;
      value: string;
      disabled?: boolean;
    }>
  ): Array<IProviderBid> {
    let Average: number = 0;
    let TotalProductsFound: number = 0;
    let ProviderBidCollectionFiltered: Array<IProviderBid> = [];
    if (ProductsFilter.length > 0) {
      const filterIds: Array<string> = ProductsFilter.map((p) => p.value);
      ProviderBidCollection.forEach((bid) => {
        let Product: IProviderItemBid = bid.ProductBidList.find((p) =>
          filterIds.includes(p.ProductGUID?.path as string)
        ) as IProviderItemBid;
        if (Product) {
          Average += Product.ProductPackPrice;
          TotalProductsFound++;
          ProviderBidCollectionFiltered.push({
            ...bid,
            TopResultProduct: Product,
          } as IProviderBid);
        }
      });
      if (ProviderBidCollectionFiltered.length) {
        this.setState({ Average: Average / TotalProductsFound });
      }
    } else {
      ProviderBidCollectionFiltered = ProviderBidCollection.map((bid) => {
        return {
          ref: bid.ref,
          ProviderGUID: bid.ProviderGUID,
          ProductBidDay: bid.ProductBidDay,
          ProductBidList: bid.ProductBidList,
          isLastProviderBid: bid.isLastProviderBid,
        };
      });
      this.setState({ Average: 0 });
    }
    return ProviderBidCollectionFiltered;
  }
  getProviderName(Bid: any): string {
    return (this.state.ProviderCollection.find(
      (p) => p.ref?.path === Bid.ProviderGUID.path
    ) as IProvider).ProviderName;
  }
  componentDidUpdate(
    prevProps: IBidManagerProps,
    prevState: IBidManagerState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole
    ) {
      this.componentDidMount();
    }
  }
  onChanged = async (event: any, option?: any) => {
    let index: number = Number(event.target.id.split(" ")[0]);
    let field: string = event.target.id.split(" ")[1];
    if (index === -1) {
      if (this.props.currentRestaurant) {
        switch (field) {
          case "StartDate":
            this.setState({ isLoading: true });
            const StartDateRestaurantCollection = this.state
              .RestaurantCollection;
            let StartDateProviderBidCollectionPromises: Array<
              Promise<Array<IProviderBid>>
            > = [];
            StartDateRestaurantCollection.forEach((r) => {
              StartDateProviderBidCollectionPromises.push(
                this.getProviderBidCollection(
                  r.RestaurantSubCollectionPaths
                    .RestaurantItemBidCollectionPath,
                  option,
                  this.state.EndDate,
                  this.state.Providers
                )
              );
            });
            let StartDateBidCollection: Array<IProviderBid> = (
              await Promise.all(StartDateProviderBidCollectionPromises)
            ).flatMap((p) => p);
            StartDateBidCollection = this.filterByProduct(
              StartDateBidCollection,
              this.state.ProductsSearch
            );
            StartDateBidCollection = this.sortProviderBidCollection(
              StartDateBidCollection,
              this.state.SortSelected
            );
            this.setState({
              isLoading: false,
              ProviderBidCollectionNotFilter: StartDateBidCollection,
              ProviderBidCollection: StartDateBidCollection,
              StartDate: option,
            });
            break;
          case "EndDate":
            this.setState({ isLoading: true });
            const EndDateRestaurantCollection = this.state.RestaurantCollection;
            let EndDateProviderBidCollectionPromises: Array<
              Promise<Array<IProviderBid>>
            > = [];
            EndDateRestaurantCollection.forEach((r) => {
              EndDateProviderBidCollectionPromises.push(
                this.getProviderBidCollection(
                  r.RestaurantSubCollectionPaths
                    .RestaurantItemBidCollectionPath,
                  this.state.StartDate,
                  option,
                  this.state.Providers
                )
              );
            });
            let EndDateBidCollection: Array<IProviderBid> = (
              await Promise.all(EndDateProviderBidCollectionPromises)
            ).flatMap((p) => p);
            EndDateBidCollection = this.filterByProduct(
              EndDateBidCollection,
              this.state.ProductsSearch
            );
            EndDateBidCollection = this.sortProviderBidCollection(
              EndDateBidCollection,
              this.state.SortSelected
            );
            this.setState({
              isLoading: false,
              ProviderBidCollectionNotFilter: EndDateBidCollection,
              ProviderBidCollection: EndDateBidCollection,
              EndDate: option,
            });
            break;
          case "Provider":
            this.setState({ isLoading: true });
            let Providers: Array<string> | undefined = this.state.Providers;
            Providers =
              option?.selected === true
                ? this.state.Providers?.concat([option.key as string])
                : this.state.Providers?.filter((key) => key !== option.key);

            const ProviderRestaurantCollection = this.state
              .RestaurantCollection;
            let ProviderProviderBidCollectionPromises: Array<
              Promise<Array<IProviderBid>>
            > = [];
            ProviderRestaurantCollection.forEach((r) => {
              let ProvidersFiltered = Providers?.filter(
                (p) =>
                  p.indexOf(
                    r.RestaurantSubCollectionPaths.ProviderCollectionPath
                  ) !== -1
              );
              ProviderProviderBidCollectionPromises.push(
                this.getProviderBidCollection(
                  r.RestaurantSubCollectionPaths
                    .RestaurantItemBidCollectionPath,
                  this.state.StartDate,
                  this.state.EndDate,
                  Providers &&
                    Providers?.length > 0 &&
                    ProvidersFiltered?.length === 0
                    ? ["-1"]
                    : ProvidersFiltered
                )
              );
            });
            let ProviderBidCollection: Array<IProviderBid> = (
              await Promise.all(ProviderProviderBidCollectionPromises)
            ).flatMap((p) => p);
            ProviderBidCollection = this.filterByProduct(
              ProviderBidCollection,
              this.state.ProductsSearch
            );
            ProviderBidCollection = this.sortProviderBidCollection(
              ProviderBidCollection,
              this.state.SortSelected
            );
            this.setState({
              isLoading: false,
              ProviderBidCollectionNotFilter: ProviderBidCollection,
              ProviderBidCollection,
              Providers,
            });
            break;
          case "Sort":
            this.setState({
              ProviderBidCollection: this.sortProviderBidCollection(
                this.state.ProviderBidCollection,
                option?.key.toString()
              ),
              SortSelected: option?.key.toString(),
            });
            break;
          case "Clear":
            this.setState({ isLoading: true });
            let StartDate: Date = new Date();
            StartDate.setDate(StartDate.getDate() - 180);
            const ClearRestaurantCollection = this.state.RestaurantCollection;
            let ClearProviderBidCollectionPromises: Array<
              Promise<Array<IProviderBid>>
            > = [];
            ClearRestaurantCollection.forEach((r) => {
              ClearProviderBidCollectionPromises.push(
                this.getProviderBidCollection(
                  r.RestaurantSubCollectionPaths
                    .RestaurantItemBidCollectionPath,
                  StartDate,
                  new Date()
                )
              );
            });
            let ClearProviderBidCollection: Array<IProviderBid> = (
              await Promise.all(ClearProviderBidCollectionPromises)
            ).flatMap((p) => p);
            ClearProviderBidCollection = this.sortProviderBidCollection(
              ClearProviderBidCollection,
              "ProductBidDayDescendent"
            );
            this.setState({
              ProviderBidCollectionNotFilter: ClearProviderBidCollection,
              ProviderBidCollection: ClearProviderBidCollection,
              ProductsSearch: [],
              StartDate,
              EndDate: new Date(),
              Providers: [],
              SortSelected: "ProductBidDayDescendent",
              Average: 0,
              ShowBidsGraph: false,
              isLoading: false,
            });
            break;
          case "Average":
            let hash = new URL(window.location.href).hash;
            hash = deleteHash(hash, "Bid");
            window.location.hash = hash;
            this.setState({ ShowBidsGraph: true, CurrentBidSelected: null });
            break;
        }
      }
    } else {
      const CurrentBidSelected: IProviderBid = this.state.ProviderBidCollection.find(
        (bid) => bid.ref?.path === option
      ) as IProviderBid;
      let hash = new URL(window.location.href).hash;
      hash = setHash(hash, "Bid", CurrentBidSelected.ref?.id as string);
      window.location.hash = hash;
      this.setState({ CurrentBidSelected, ShowBidsGraph: false });
    }
  };
  renderBidList(): Array<JSX.Element> {
    return this.state.ProviderBidCollection.map(
      (Bid: IProviderBid, index: number) => {
        const Provider: IProvider = this.state.ProviderCollection.find(
          (provider) => provider.ref?.path === Bid.ProviderGUID?.path
        ) as IProvider;
        let TopResult: JSX.Element | string = "";
        const TopResultProductBid: IProviderItemBid = (Bid as any)
          .TopResultProduct;
        const TopResultProduct: IRestaurantItem =
          TopResultProductBid &&
          (this.state.ProductCollection.find(
            (p) => p.ref?.path === TopResultProductBid.ProductGUID?.path
          ) as IRestaurantItem);
        TopResult = (
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "40%", fontWeight: "bold" }}>
                  {TopResultProduct && TopResultProduct.ProductName}
                </td>
                <td style={{ width: "30%" }}>
                  {TopResultProduct && "Case Price"}
                </td>
                <td style={{ width: "30%" }}>
                  {TopResultProduct && "Units per Case"}
                </td>
              </tr>
              <tr>
                <td>
                  {TopResultProduct && "from "}
                  {Provider ? Provider.ProviderName : "N/A"}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {TopResultProductBid &&
                    `$${TopResultProductBid.ProductPackPrice}`}
                </td>
                <td style={{ textAlign: "center" }}>
                  {TopResultProductBid &&
                    `${TopResultProductBid.ProductUnitPerPack}/${TopResultProductBid.ProductUnitAmount} ${TopResultProductBid.ProductUnitMesure}`}
                </td>
              </tr>
            </tbody>
          </table>
        );
        return (
          <tr
            id={`${Bid.ref?.path}`}
            key={`Bid${index}${Bid.ref?.path}`}
            style={{
              backgroundColor:
                Bid.ref?.path === this.state.CurrentBidSelected?.ref?.path
                  ? "#0078ff"
                  : index % 2
                  ? "#DCDCDC"
                  : "white",
              color:
                Bid.ref?.path === this.state.CurrentBidSelected?.ref?.path
                  ? "white"
                  : "black",
              cursor: "pointer",
            }}
            onClick={(event) => this.onChanged(event, Bid.ref?.path)}
          >
            <td style={{ textAlign: "center", width: "30%" }}>
              {PrintDate(Bid.ProductBidDay)}
            </td>
            <td style={{ width: "70%" }}>{TopResult}</td>
          </tr>
        );
      }
    );
  }
  render(): JSX.Element {
    const CurrentBidSelected: IProviderBid | null = this.state
      .CurrentBidSelected;
    const CurrentBidSelectedRestaurant = this.state.RestaurantCollection.find(
      (r) =>
        CurrentBidSelected?.ref?.path.indexOf(
          r.RestaurantSubCollectionPaths.RestaurantItemBidCollectionPath
        ) !== -1
    );
    const ProductCollectionOptions = this.state.ProductCollection.filter(
      (p) =>
        CurrentBidSelectedRestaurant &&
        p.ref?.path.indexOf(
          CurrentBidSelectedRestaurant?.RestaurantSubCollectionPaths
            .RestaurantItemCollectionPath
        ) !== -1
    )
      .map((p) => {
        return {
          label: p.ProductName,
          value: p.ref?.path as string,
        };
      })
      .sort((A, B) => (A.label > B.label ? 1 : A.label < B.label ? -1 : 0));
    const OverFlowStyle: React.CSSProperties = {
      width: "100%",
      height: `${this.props.innerHeight - 265}px`,
      overflowY: "scroll",
    };
    const BidTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const BidTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    const iconStyles = { marginRight: "8px" };
    const onRenderOption = (option: ISelectableOption): JSX.Element => {
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
      const option = options[0];
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    return (
      <div>
        {this.props.innerWidth < 1400 &&
          (this.state.CurrentBidSelected || this.state.ShowBidsGraph) && (
            <span style={{ position: "fixed", bottom: 15, left: 10 }}>
              <DefaultButton
                text="Back"
                iconProps={{ iconName: "Back" }}
                id={`Back`}
                onClick={() => {
                  this.setState({
                    CurrentBidSelected: null,
                    ShowBidsGraph: false,
                  });
                  let hash = new URL(window.location.href).hash;
                  hash = deleteHash(hash, "Bid");
                  window.location.hash = hash;
                }}
              />
            </span>
          )}
        <table style={BidTableStyle}>
          <tbody>
            <tr>
              {(this.props.innerWidth >= 1400 ||
                (!this.state.CurrentBidSelected &&
                  !this.state.ShowBidsGraph)) && (
                <td
                  style={{
                    width: this.props.innerWidth >= 1400 ? "40%" : "98%",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      width: this.props.innerWidth > 600 ? "100%" : "96%",
                    }}
                  >
                    <span>
                      <MultiSelect
                        options={this.state.ProductCollectionOptions}
                        value={this.state.ProductsSearch}
                        onChange={(
                          value: Array<{
                            label: string;
                            value: string;
                            disabled?: boolean;
                          }>
                        ) => {
                          this.setState({
                            ProviderBidCollection: this.filterByProduct(
                              this.state.ProviderBidCollectionNotFilter,
                              value ? value : []
                            ),
                            ProductsSearch: value,
                            ShowBidsGraph:
                              value.length > 0 && this.props.innerWidth >= 1400
                                ? true
                                : false,
                            CurrentBidSelected:
                              value.length > 0 && this.props.innerWidth >= 1400
                                ? null
                                : this.state.CurrentBidSelected,
                          });
                        }}
                        labelledBy={"Search Item Price..."}
                      />
                    </span>
                    <hr></hr>
                    <table style={BidTableStyle}>
                      <tbody>
                        <tr key={"DateFilter"}>
                          <td style={{ width: "45%" }}>
                            <DatePicker
                              id={`-1 StartDate`}
                              placeholder="From Date..."
                              value={this.state.StartDate}
                              onSelectDate={(date) =>
                                this.onChanged(
                                  { target: { id: "-1 StartDate" } },
                                  date
                                )
                              }
                            />
                          </td>
                          <td style={{ width: "45" }}>
                            <DatePicker
                              id={`-1 EndDate`}
                              placeholder="To Date..."
                              value={this.state.EndDate}
                              onSelectDate={(date) =>
                                this.onChanged(
                                  { target: { id: "-1 EndDate" } },
                                  date
                                )
                              }
                            />
                          </td>
                          <td style={{ width: "10%" }}>
                            <DefaultButton
                              id={`-1 Clear`}
                              onClick={() =>
                                this.onChanged({ target: { id: "-1 Clear" } })
                              }
                              text="Clear"
                              iconProps={{ iconName: "Clear" }}
                              style={{ width: "100%" }}
                            />
                          </td>
                        </tr>
                        <tr key={"ProviderAndSort"}>
                          <td>
                            <Dropdown
                              id={`-1 Provider`}
                              placeholder="Filter By Vendor..."
                              selectedKeys={this.state.Providers}
                              multiSelect
                              onChange={this.onChanged}
                              options={this.state.ProviderCollectionOptions}
                              style={{
                                maxWidth:
                                  this.props.innerWidth >= 1400
                                    ? this.props.innerWidth * 0.18
                                    : this.props.innerWidth * 0.45,
                              }}
                            />
                          </td>
                          <td>
                            <Dropdown
                              id={`-1 Sort`}
                              placeholder="Select Sort..."
                              selectedKey={this.state.SortSelected}
                              onChange={this.onChanged}
                              options={[
                                {
                                  key: "ProviderGUIDDescendent",
                                  text: "Vendor",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "ProviderGUIDAscendent",
                                  text: "Vendor",
                                  data: { icon: "Up" },
                                },
                                {
                                  key: "ProductBidDayDescendent",
                                  text: "Date",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "ProductBidDayAscendent",
                                  text: "Date",
                                  data: { icon: "Up" },
                                },
                              ]}
                              onRenderTitle={(t) =>
                                onRenderTitle(t as Array<IDropdownOption>)
                              }
                              onRenderOption={(o) =>
                                onRenderOption(o as ISelectableOption)
                              }
                            />
                          </td>
                          <td>
                            <PrimaryButton
                              id={`-1 Average`}
                              disabled={
                                this.state.ProviderBidCollection.length === 0 ||
                                this.state.ProductsSearch.length === 0
                              }
                              onClick={() =>
                                this.onChanged({ target: { id: "-1 Average" } })
                              }
                              text={`$${this.state.Average.toFixed(2)}`}
                              iconProps={{ iconName: "Financial" }}
                              style={{ width: "100%" }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr></hr>
                    <table style={BidTableStyle}>
                      <thead style={BidTableHeadStyle}>
                        <tr>
                          <th style={{ width: "30%" }}>Date</th>
                          <th style={{ width: "70%" }}>Results</th>
                        </tr>
                      </thead>
                    </table>
                    <div style={OverFlowStyle}>
                      <table style={BidTableStyle}>
                        <tbody>
                          {this.state.isLoading ? (
                            <tr>
                              <td>
                                <Spinner
                                  style={{ marginTop: 10 }}
                                  size={SpinnerSize.large}
                                />
                              </td>
                            </tr>
                          ) : this.state.ProviderBidCollection.length ? (
                            this.renderBidList()
                          ) : (
                            <tr>
                              <td>No Results</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <div style={{ height: "100px" }} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              )}
              {this.props.currentRestaurant &&
                this.props.currentProfile &&
                CurrentBidSelected && (
                  <td
                    style={{
                      width: this.props.innerWidth >= 1400 ? "59%" : "100%",
                    }}
                  >
                    <BidSelected
                      Api={this.props.Api}
                      Restaurant={this.props.currentRestaurant}
                      Profile={this.props.currentProfile}
                      ProviderCollection={this.state.ProviderCollection}
                      AreaCollection={this.state.AreaCollection}
                      ProductCollection={this.state.ProductCollection}
                      ProductCollectionOptions={ProductCollectionOptions}
                      CurrentBidSelected={CurrentBidSelected}
                      ProductsFilter={this.state.ProductsSearch}
                      innerHeight={this.props.innerHeight}
                      innerWidth={this.props.innerWidth}
                    />
                  </td>
                )}
              {this.state.ShowBidsGraph &&
                this.state.StartDate &&
                this.state.EndDate && (
                  <td
                    style={{
                      width: this.props.innerWidth >= 1400 ? "59%" : "100%",
                    }}
                  >
                    <BidGraph
                      ProviderCollection={this.state.ProviderCollection}
                      ProductCollection={this.state.ProductCollection}
                      ProviderBidCollection={this.state.ProviderBidCollection}
                      StartDate={this.state.StartDate}
                      EndDate={this.state.EndDate}
                      Average={this.state.Average}
                      innerHeight={this.props.innerHeight}
                      innerWidth={this.props.innerWidth}
                      setCurrentBidSelected={(
                        CurrentBidSelected: IProviderBid | null
                      ) =>
                        this.setState({
                          CurrentBidSelected,
                          ShowBidsGraph: CurrentBidSelected ? false : true,
                        })
                      }
                    />
                  </td>
                )}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
