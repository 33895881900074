import React, { Component } from "react";
import {
  Dialog,
  PrimaryButton,
  DialogFooter,
  SpinnerSize,
  DialogType,
  Spinner,
  TextField,
  Icon,
} from "office-ui-fabric-react";
import IApi, { Environment } from "../api/IApi";
import {
  ProfileCollectionPath,
  RestaurantCollectionPath,
} from "../const/CollectionsPaths";
import { IProfile, IRestaurantCG } from "../models/IFirestoreModels";
import firebase from "firebase/app";

interface IAuthProps {
  currentEnviroment: Environment;
  Api: IApi;
  setCurrentRestaurant: (currentUser: IRestaurantCG | null) => void;
  setCurrentUser: (currentUser: firebase.User | null) => void;
  setCurrentProfile: (currentUser: IProfile | null) => void;
}
interface IAuthState {
  currentState: LoggingState;
  email: string;
  password: string;
}
enum LoggingState {
  Initializing = 0,
  Ready = 1,
  Busy = 2,
  Error = 3,
  Successfull = 4,
}
export default class Auth extends Component<IAuthProps, IAuthState> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      currentState: LoggingState.Initializing,
      email: "",
      password: "",
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (this.props.currentEnviroment === Environment.Local) {
      this.props.setCurrentUser({ uid: "Local" } as any);
      this.props.setCurrentProfile({ ProfileRole: "" } as any);
      this.props.setCurrentRestaurant(
        await this.Api.getDoc("MyrtleBeach", RestaurantCollectionPath)
      );
      this.setState({ currentState: LoggingState.Initializing });
    } else {
      this.Api.onAuthStateChanged(
        async (user: firebase.User | null) => {
          this.setState({
            currentState: user ? LoggingState.Successfull : LoggingState.Ready,
          });
          this.props.setCurrentUser(user);
          if (user) {
            const profile: IProfile = await this.Api.getDoc(
              user?.uid as string,
              ProfileCollectionPath
            );
            this.props.setCurrentProfile(profile);
            this.props.setCurrentRestaurant(
              await this.Api.getDoc(
                profile.RestaurantGUID?.id as string,
                RestaurantCollectionPath
              )
            );
          }
        },
        (error: firebase.auth.Error) => {
          this.handleError();
        }
      );
    }
  }
  handleAuth = async () => {
    if (!this.disabledSignin()) {
      this.setState({ currentState: LoggingState.Busy });
      try {
        await this.Api.signIn(this.state.email, this.state.password);
      } catch (e) {
        this.handleError();
      }
    }
  };
  handleError = () => {
    this.setState({ currentState: LoggingState.Error });
    setTimeout(() => this.setState({ currentState: LoggingState.Ready }), 3000);
  };
  handleChange = (event: any, value?: string | undefined) => {
    const Target = event.target.id;
    const Value = value ? value : "";
    switch (Target) {
      case "Email":
        this.setState({ email: Value });
        break;
      case "Password":
        this.setState({ password: Value });
        break;
    }
  };
  disabledSignin = (): boolean => {
    const { currentState, email, password } = this.state;
    return (
      currentState !== LoggingState.Ready || email === "" || password === ""
    );
  };
  render(): JSX.Element {
    const currentState = this.state.currentState;
    return (
      <Dialog
        hidden={
          currentState === LoggingState.Initializing ||
          currentState === LoggingState.Successfull
        }
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: "Authentication Required",
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 400 } },
        }}
      >
        <TextField
          id="Email"
          label="Email"
          type="email"
          required={true}
          onChange={this.handleChange}
          onKeyPress={(e) => e.key === "Enter" && this.handleAuth()}
        />
        <TextField
          id="Password"
          label="Password"
          type="password"
          required={true}
          onChange={this.handleChange}
          onKeyPress={(e) => e.key === "Enter" && this.handleAuth()}
        />
        <br />
        <DialogFooter>
          <PrimaryButton
            onClick={this.handleAuth}
            disabled={this.disabledSignin()}
          >
            {currentState === LoggingState.Busy ? (
              <Spinner size={SpinnerSize.small} />
            ) : currentState === LoggingState.Error ? (
              "Error: try again..."
            ) : (
              <span>
                <Icon iconName="Signin"></Icon> Sign In
              </span>
            )}
          </PrimaryButton>
        </DialogFooter>
      </Dialog>
    );
  }
}
