import {
  IConversionMap,
  PhysicalProperty,
  ProductUnitMesure,
  SystemOfMeasurement,
} from "../models/IFirestoreModels";
export function getUnitMeasuresPossibles(
  UnitMeasure: ProductUnitMesure,
  ConversionsMap: IConversionMap
): Array<ProductUnitMesure> {
  //Default Output if not possible conversion
  let output: Array<ProductUnitMesure> = [];
  const ConversionSystem = ConversionsMap.ConversionSystems.find((s) =>
    s.SystemValues.find((m) => m.UnitMeasure === UnitMeasure)
  );
  if (ConversionSystem) {
    //Same Conversion System
    output = ConversionSystem.SystemValues.map((m) => m.UnitMeasure);
    //Different Conversion System
    const ConversionSystemsUnions = ConversionsMap.ConversionSystemsUnions.filter(
      (u) =>
        (u.SystemOfMeasurementFrom === ConversionSystem.SystemOfMeasurement &&
          u.PhysicalPropertyFrom === ConversionSystem.PhysicalProperty) ||
        (u.SystemOfMeasurementTo === ConversionSystem.SystemOfMeasurement &&
          u.PhysicalPropertyTo === ConversionSystem.PhysicalProperty)
    );
    ConversionSystemsUnions.forEach((u) => {
      //Forward Conversion
      const ConversionSystemsUnionForward = ConversionsMap.ConversionSystems.find(
        (s) =>
          u.SystemOfMeasurementFrom === s.SystemOfMeasurement &&
          u.PhysicalPropertyFrom === s.PhysicalProperty
      );
      ConversionSystemsUnionForward &&
        ConversionSystemsUnionForward.SystemValues.forEach((m) => {
          if (!output.includes(m.UnitMeasure)) {
            output.push(m.UnitMeasure);
          }
        });
      //Backwards Conversion
      const ConversionSystemsUnionBackwards = ConversionsMap.ConversionSystems.find(
        (s) =>
          u.SystemOfMeasurementTo === s.SystemOfMeasurement &&
          u.PhysicalPropertyTo === s.PhysicalProperty
      );
      ConversionSystemsUnionBackwards &&
        ConversionSystemsUnionBackwards.SystemValues.forEach((m) => {
          if (!output.includes(m.UnitMeasure)) {
            output.push(m.UnitMeasure);
          }
        });
    });
  }
  output.length === 0 && output.push(UnitMeasure);
  return output;
}
export function convertUnitMeasure(
  input: { Amount: number; UnitMeasure: ProductUnitMesure },
  convertTo: ProductUnitMesure,
  ConversionsMap: IConversionMap
): { Amount: number; UnitMeasure: ProductUnitMesure } {
  //Default Output if not possible conversion
  let output: { Amount: number; UnitMeasure: ProductUnitMesure } = {
    Amount: input.Amount,
    UnitMeasure: input.UnitMeasure,
  };
  if (input.UnitMeasure !== convertTo) {
    //Conversion Cases
    const ConversionSystemFrom = ConversionsMap.ConversionSystems.find((s) =>
      s.SystemValues.find((m) => m.UnitMeasure === input.UnitMeasure)
    );
    const ConversionSystemTo = ConversionsMap.ConversionSystems.find((s) =>
      s.SystemValues.find((m) => m.UnitMeasure === convertTo)
    );
    if (ConversionSystemFrom && ConversionSystemTo) {
      //Same Conversion System
      if (
        ConversionSystemFrom.SystemOfMeasurement ===
          ConversionSystemTo.SystemOfMeasurement &&
        ConversionSystemFrom.PhysicalProperty ===
          ConversionSystemTo.PhysicalProperty
      ) {
        const indexFrom = ConversionSystemFrom.SystemValues.findIndex(
          (m) => m.UnitMeasure === input.UnitMeasure
        );
        const indexTo = ConversionSystemFrom.SystemValues.findIndex(
          (m) => m.UnitMeasure === convertTo
        );
        //Forward Conversion (Multiplication)
        if (indexFrom < indexTo) {
          for (let index = indexFrom + 1; index <= indexTo; index++) {
            output.Amount *= ConversionSystemFrom.SystemValues[index].Factor;
            output.UnitMeasure =
              ConversionSystemFrom.SystemValues[index].UnitMeasure;
          }
          //Backwards Conversion (Division)
        } else {
          for (let index = indexFrom; index > indexTo; index--) {
            output.Amount /= ConversionSystemFrom.SystemValues[index].Factor;
            output.UnitMeasure =
              ConversionSystemFrom.SystemValues[index - 1].UnitMeasure;
          }
        }
        //Different Conversion System
      } else {
        const ConversionSystemsUnionForward = ConversionsMap.ConversionSystemsUnions.find(
          (u) =>
            u.SystemOfMeasurementFrom ===
              ConversionSystemFrom.SystemOfMeasurement &&
            u.PhysicalPropertyFrom === ConversionSystemFrom.PhysicalProperty &&
            u.SystemOfMeasurementTo ===
              ConversionSystemTo.SystemOfMeasurement &&
            u.PhysicalPropertyTo === ConversionSystemTo.PhysicalProperty
        );
        const ConversionSystemsUnionBackwards = ConversionsMap.ConversionSystemsUnions.find(
          (u) =>
            u.SystemOfMeasurementTo ===
              ConversionSystemFrom.SystemOfMeasurement &&
            u.PhysicalPropertyTo === ConversionSystemFrom.PhysicalProperty &&
            u.SystemOfMeasurementFrom ===
              ConversionSystemTo.SystemOfMeasurement &&
            u.PhysicalPropertyFrom === ConversionSystemTo.PhysicalProperty
        );
        if (ConversionSystemsUnionForward || ConversionSystemsUnionBackwards) {
          output = convertUnitMeasure(
            input,
            ConversionSystemFrom.SystemValues[0].UnitMeasure,
            ConversionsMap
          );
        }
        //Forward Conversion (Multiplication)
        if (ConversionSystemsUnionForward) {
          output.Amount *= ConversionSystemsUnionForward.Factor;
          output.UnitMeasure = ConversionSystemTo.SystemValues[0].UnitMeasure;
          output = convertUnitMeasure(output, convertTo, ConversionsMap);
        }
        //Backwards Conversion (Division)
        if (ConversionSystemsUnionBackwards) {
          output.Amount /= ConversionSystemsUnionBackwards.Factor;
          output.UnitMeasure = ConversionSystemTo.SystemValues[0].UnitMeasure;
          output = convertUnitMeasure(output, convertTo, ConversionsMap);
        }
      }
    }
  }
  return output;
}
// TEST
export const ConversionMap: IConversionMap = {
  ConversionSystems: [
    {
      SystemOfMeasurement: SystemOfMeasurement.InternationalSystem,
      PhysicalProperty: PhysicalProperty.Volumen,
      SystemValues: [
        {
          UnitMeasure: ProductUnitMesure.liter,
          Factor: 1,
        },
        {
          UnitMeasure: ProductUnitMesure.ml,
          Factor: 1000,
        },
      ],
    },
    {
      SystemOfMeasurement: SystemOfMeasurement.InternationalSystem,
      PhysicalProperty: PhysicalProperty.Mass,
      SystemValues: [
        {
          UnitMeasure: ProductUnitMesure.kg,
          Factor: 1,
        },
        {
          UnitMeasure: ProductUnitMesure.gram,
          Factor: 1000,
        },
      ],
    },
    {
      SystemOfMeasurement: SystemOfMeasurement.UnitedStatesSystem,
      PhysicalProperty: PhysicalProperty.Volumen,
      SystemValues: [
        {
          UnitMeasure: ProductUnitMesure.gallon,
          Factor: 1,
        },
        {
          UnitMeasure: ProductUnitMesure.quarter,
          Factor: 4,
        },
        {
          UnitMeasure: ProductUnitMesure.cup,
          Factor: 4,
        },
        {
          UnitMeasure: ProductUnitMesure.tablespoon,
          Factor: 16,
        },
        {
          UnitMeasure: ProductUnitMesure.teaspoon,
          Factor: 3,
        },
      ],
    },
    {
      SystemOfMeasurement: SystemOfMeasurement.UnitedStatesSystem,
      PhysicalProperty: PhysicalProperty.Mass,
      SystemValues: [
        {
          UnitMeasure: ProductUnitMesure.pound,
          Factor: 1,
        },
        {
          UnitMeasure: ProductUnitMesure.ounce,
          Factor: 16,
        },
        {
          UnitMeasure: ProductUnitMesure.drop,
          Factor: 590,
        },
      ],
    },
  ],
  ConversionSystemsUnions: [
    {
      SystemOfMeasurementFrom: SystemOfMeasurement.InternationalSystem,
      PhysicalPropertyFrom: PhysicalProperty.Volumen,
      SystemOfMeasurementTo: SystemOfMeasurement.InternationalSystem,
      PhysicalPropertyTo: PhysicalProperty.Mass,
      Factor: 1,
    },
    {
      SystemOfMeasurementFrom: SystemOfMeasurement.UnitedStatesSystem,
      PhysicalPropertyFrom: PhysicalProperty.Volumen,
      SystemOfMeasurementTo: SystemOfMeasurement.UnitedStatesSystem,
      PhysicalPropertyTo: PhysicalProperty.Mass,
      Factor: 8,
    },
    {
      SystemOfMeasurementFrom: SystemOfMeasurement.UnitedStatesSystem,
      PhysicalPropertyFrom: PhysicalProperty.Volumen,
      SystemOfMeasurementTo: SystemOfMeasurement.InternationalSystem,
      PhysicalPropertyTo: PhysicalProperty.Volumen,
      Factor: 3.785,
    },
    {
      SystemOfMeasurementFrom: SystemOfMeasurement.InternationalSystem,
      PhysicalPropertyFrom: PhysicalProperty.Mass,
      SystemOfMeasurementTo: SystemOfMeasurement.UnitedStatesSystem,
      PhysicalPropertyTo: PhysicalProperty.Mass,
      Factor: 2.205,
    },
    {
      SystemOfMeasurementFrom: SystemOfMeasurement.UnitedStatesSystem,
      PhysicalPropertyFrom: PhysicalProperty.Volumen,
      SystemOfMeasurementTo: SystemOfMeasurement.InternationalSystem,
      PhysicalPropertyTo: PhysicalProperty.Mass,
      Factor: 3.785,
    },
    {
      SystemOfMeasurementFrom: SystemOfMeasurement.InternationalSystem,
      PhysicalPropertyFrom: PhysicalProperty.Volumen,
      SystemOfMeasurementTo: SystemOfMeasurement.UnitedStatesSystem,
      PhysicalPropertyTo: PhysicalProperty.Mass,
      Factor: 2.2,
    },
  ],
};
