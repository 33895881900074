import IApi, { Environment, whereQuery } from "./IApi";
import { IEmail, IQueueStatus, ITextSMS } from "../models/ICommunication";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import "firebase/performance";
import DevelopmentKey from "../config/DevelopmentKey";
import ReleaseKey from "../config/ReleaseKey";

export default class Api implements IApi {
  private firebaseApp: firebase.app.App;
  private analytics: firebase.analytics.Analytics;
  private performance: firebase.performance.Performance;
  public constructor(currentEnviroment: Environment) {
    this.firebaseApp =
      currentEnviroment === Environment.Development
        ? firebase.initializeApp(DevelopmentKey)
        : firebase.initializeApp(ReleaseKey);
    this.analytics = this.firebaseApp.analytics();
    this.performance = this.firebaseApp.performance();
    this.performance.dataCollectionEnabled = true;
    this.performance.instrumentationEnabled = true;
  }
  public getEnvironment(): number {
    return Environment.Development;
  }
  public TimestampNow(): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.now();
  }
  public TimestampFromDate(Date: Date): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(Date);
  }
  ///////////////////////////////////////////////////////////////////////////////////////
  public async getCollect(
    collectionPath: string,
    queryArray?: Array<whereQuery>,
    onSnapshot?: (data: Array<any>) => void,
    orderBy?: string,
    directionStr?: "desc" | "asc",
    limit?: number
  ): Promise<Array<any>> {
    const collection = this.firebaseApp.firestore().collection(collectionPath);
    let query =
      collection as firebase.firestore.Query<firebase.firestore.DocumentData>;
    if (orderBy && directionStr) {
      query = collection.orderBy(orderBy, directionStr);
    }
    if (limit) {
      query = query.limit(limit);
    }
    if (queryArray?.length) {
      for (let index = 0; index < queryArray.length; index++) {
        query = query.where(
          queryArray[index].fieldPath,
          queryArray[index].opStr,
          queryArray[index].value
        );
      }
    }
    if (onSnapshot) {
      query.onSnapshot((collection) =>
        onSnapshot(
          collection.docs.map((doc) => {
            return { ref: doc.ref, ...doc.data() };
          })
        )
      );
      return ["onSnapshot"];
    } else {
      return (await query.get()).docs.map((doc) => {
        return { ref: doc.ref, ...doc.data() };
      });
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public async getDoc(
    documentPath: string,
    collectionPath?: string,
    onSnapshot?: (data: any) => void
  ): Promise<any> {
    const doc = collectionPath
      ? this.firebaseApp
          .firestore()
          .collection(collectionPath)
          .doc(documentPath)
      : this.firebaseApp.firestore().doc(documentPath);
    let query =
      doc as firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    if (onSnapshot) {
      query.onSnapshot((doc) => onSnapshot({ ref: doc.ref, ...doc.data() }));
      return "onSnapshot";
    } else {
      const d = await query.get();
      return { ref: d.ref, ...d.data() };
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public addDoc(
    collectionPath: string,
    documentData: firebase.firestore.DocumentData
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > {
    if (documentData.ref !== undefined) {
      delete documentData.ref;
    }
    if (documentData.id !== undefined) {
      delete documentData.id;
    }
    return this.firebaseApp
      .firestore()
      .collection(collectionPath)
      .add(documentData);
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public delDoc(documentPath: string, collectionPath?: string): Promise<void> {
    return collectionPath
      ? this.firebaseApp
          .firestore()
          .collection(collectionPath)
          .doc(documentPath)
          .delete()
      : this.firebaseApp.firestore().doc(documentPath).delete();
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public updDoc(
    documentPath: string,
    documentData: firebase.firestore.DocumentData,
    collectionPath?: string
  ): Promise<void> {
    if (documentData.ref !== undefined) {
      delete documentData.ref;
    }
    if (documentData.id !== undefined) {
      delete documentData.id;
    }
    return collectionPath
      ? this.firebaseApp
          .firestore()
          .collection(collectionPath)
          .doc(documentPath)
          .update(documentData)
      : this.firebaseApp.firestore().doc(documentPath).update(documentData);
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public setDoc(
    documentPath: string,
    documentData: firebase.firestore.DocumentData,
    collectionPath?: string
  ): Promise<void> {
    if (documentData.ref !== undefined) {
      delete documentData.ref;
    }
    if (documentData.id !== undefined) {
      delete documentData.id;
    }
    return collectionPath
      ? this.firebaseApp
          .firestore()
          .collection(collectionPath)
          .doc(documentPath)
          .set(documentData)
      : this.firebaseApp.firestore().doc(documentPath).set(documentData);
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public sendEmail(
    email: IEmail
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > {
    return new Promise<any>((resolve, reject) => {
      if (email.EmailTriggerDate) {
        this.addDoc("mailPending", {
          ...email,
          EmailStatus: IQueueStatus.PENDING,
          EmailSendOn: this.TimestampNow(),
          EmailTriggerDate: this.TimestampFromDate(email.EmailTriggerDate),
        }).then(resolve);
      } else {
        this.addDoc("mail", {
          ...email,
          EmailStatus: IQueueStatus.PROCESSING,
          EmailSendOn: this.TimestampNow(),
        }).then(resolve);
      }
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public sendTextSMS(
    textSMS: ITextSMS
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > {
    return new Promise<any>((resolve, reject) => {
      if (textSMS.TextSMSTriggerDate) {
        this.addDoc("textSMSPending", {
          ...textSMS,
          TextSMSStatus: IQueueStatus.PENDING,
          TextSMSSendOn: this.TimestampNow(),
          TextSMSTriggerDate: this.TimestampFromDate(
            textSMS.TextSMSTriggerDate
          ),
        }).then(resolve);
      } else {
        this.addDoc("textSMS", {
          ...textSMS,
          TextSMSStatus: IQueueStatus.PROCESSING,
          TextSMSSendOn: this.TimestampNow(),
        }).then(resolve);
      }
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public async merCollect(
    collection: Array<any>,
    collectionPath: string,
    lookUp: string,
    addIf: (doc: any) => boolean,
    currentOp?: (op: string, doc: any) => void
  ): Promise<Array<any>> {
    const lookUpCollection: Array<any> = await this.getCollect(collectionPath);
    let outputCollection: Array<any> = [];
    collection.forEach(async (doc) => {
      const docFound: any = lookUpCollection.find(
        (lookUpDoc: any) => lookUpDoc[lookUp] === doc[lookUp]
      );
      if (docFound) {
        const ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =
          docFound.ref;
        await this.updDoc(ref?.id, doc, collectionPath);
        outputCollection.push({ ref, ...doc });
        currentOp && currentOp("upd", doc);
      } else {
        if (addIf(doc)) {
          const ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =
            await this.addDoc(collectionPath, doc);
          outputCollection.push({ ref, ...doc });
          currentOp && currentOp("add", doc);
        }
      }
    });
    return outputCollection;
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public createUser(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    this.analytics.logEvent("sign_up");
    return this.firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password);
  }
  public signIn(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    this.analytics.logEvent("login");
    return this.firebaseApp.auth().signInWithEmailAndPassword(email, password);
  }
  public signOut(): Promise<void> {
    return this.firebaseApp.auth().signOut();
  }
  public resetPassword(email: string): Promise<void> {
    return this.firebaseApp.auth().sendPasswordResetEmail(email);
  }
  public onAuthStateChanged(
    nextOrObserver:
      | firebase.Observer<any, Error>
      | ((a: firebase.User | null) => any),
    error?: ((a: firebase.auth.Error) => any) | undefined,
    completed?: firebase.Unsubscribe | undefined
  ): void {
    this.firebaseApp
      .auth()
      .onAuthStateChanged(nextOrObserver, error, completed);
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public putFile(
    path: string,
    data: Blob | Uint8Array | ArrayBuffer,
    metadata?: firebase.storage.UploadMetadata | undefined
  ): any {
    return this.firebaseApp.storage().ref(path).put(data, metadata);
  }
  public getFile(path: string): Promise<any> {
    return this.firebaseApp.storage().ref(path).getDownloadURL();
  }
  public deleteFile(path: string): Promise<any> {
    return this.firebaseApp.storage().ref(path).delete();
  }
}
/*
Set Cross Origin
gsutil cors set D:\Repos\ReactFirestoreTemplate\solution\cors.json gs://datatest-5e002.appspot.com/
*/
