import React, { Component, CSSProperties } from "react";
import {
  PrimaryButton,
  Label,
  TextField,
  Toggle,
  CompoundButton,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import UploadManager from "../../util/UploadManager";
import IApi, { Environment } from "../../api/IApi";
import {
  IProvider,
  IRestaurantArea,
  IRestaurantItem,
  IRestaurantCG,
  IProfile,
  IRestaurantRecipe,
} from "../../models/IFirestoreModels";
import {
  RestaurantCollectionPath,
  ProfileCollectionPath,
} from "../../const/CollectionsPaths";
import { prettyStringify } from "../../util/functions";
import firebase from "firebase/app";

interface IDevelopmentProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
}
interface IDevelopmentState {
  resolveRef: boolean;
  currentCollection: string;
  JSONData: string;
}
export default class Development extends Component<
  IDevelopmentProps,
  IDevelopmentState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      resolveRef: false,
      currentCollection: "",
      JSONData: "",
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (
      this.props.currentUser &&
      this.props.currentProfile &&
      this.props.currentRestaurant
    ) {
    }
  }
  componentDidUpdate(
    prevProps: IDevelopmentProps,
    prevState: IDevelopmentState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  render(): JSX.Element {
    const ButtonStyle: CSSProperties = {
      display: "inline",
      width: 150,
      marginBottom: 5,
      marginRight: 5,
      textAlign: "left",
    };
    return (
      <div>
        <span>
          <TextField
            label="Current Enviroment Data as JSON"
            value={this.state.JSONData}
            onChange={(e, str) => this.setState({ JSONData: str ? str : "" })}
            multiline
            rows={13}
            style={{ height: this.props.innerHeight - 400 }}
          />
          <Toggle
            label="Resolve References in JSON"
            onText="Yes"
            offText="No"
            onChange={() =>
              this.setState({ resolveRef: !this.state.resolveRef })
            }
            role="checkbox"
          />
          <PrimaryButton
            id="1"
            text="Get Restaurant"
            onClick={this.getRestaurantDoc}
            style={ButtonStyle}
          />
          <PrimaryButton
            id="1"
            text="Get Vendors"
            onClick={this.getProviderCollection}
            style={ButtonStyle}
          />
          <PrimaryButton
            id="2"
            text="Get Areas"
            onClick={this.getAreaCollection}
            style={ButtonStyle}
          />
          <PrimaryButton
            id="3"
            text="Get Items"
            onClick={this.getProductCollection}
            style={ButtonStyle}
          />
          <PrimaryButton
            id="4"
            text="Get Recipes"
            onClick={this.getRecipeCollection}
            style={ButtonStyle}
          />
          <hr />
          <Label style={{ color: "red" }}>* Console Required (F12)</Label>
          {!this.props.currentRestaurant ? (
            <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
          ) : (
            <span>
              <CompoundButton
                id="0"
                primary={true}
                text="Set Restaurant"
                secondaryText="**********"
                disabled={this.state.currentCollection !== "Restaurant"}
                onClick={() =>
                  UploadManager.setRestaurant(
                    this.Api,
                    this.props.currentRestaurant?.ref?.id as string,
                    JSON.parse(this.state.JSONData)
                  )
                }
                style={ButtonStyle}
              />
              <CompoundButton
                id="1"
                primary={true}
                text="Set Vendors"
                secondaryText="**********"
                disabled={this.state.currentCollection !== "Provider"}
                onClick={() =>
                  UploadManager.setProviders(
                    this.Api,
                    this.props.currentRestaurant?.RestaurantSubCollectionPaths
                      .ProviderCollectionPath as string,
                    JSON.parse(this.state.JSONData)
                  )
                }
                style={ButtonStyle}
              />
              <CompoundButton
                id="2"
                primary={true}
                text="Set Areas"
                secondaryText="**********"
                disabled={this.state.currentCollection !== "Area"}
                onClick={() =>
                  UploadManager.setAreas(
                    this.Api,
                    this.props.currentRestaurant?.RestaurantSubCollectionPaths
                      .RestaurantAreaCollectionPath as string,
                    JSON.parse(this.state.JSONData)
                  )
                }
                style={ButtonStyle}
              />
              <CompoundButton
                id="3"
                primary={true}
                text="Set Items"
                secondaryText="**********"
                disabled={this.state.currentCollection !== "Product"}
                onClick={() =>
                  UploadManager.setProducts(
                    this.Api,
                    this.props.currentRestaurant as IRestaurantCG,
                    this.props.currentRestaurant?.RestaurantSubCollectionPaths
                      .RestaurantItemCollectionPath as string,
                    JSON.parse(this.state.JSONData)
                  )
                }
                style={ButtonStyle}
              />
              <CompoundButton
                id="4"
                primary={true}
                text="Test f(x)"
                secondaryText="**********"
                disabled={false}
                onClick={() =>
                  UploadManager.test(
                    this.Api,
                    this.props.currentRestaurant as IRestaurantCG,
                    this.props.currentProfile as IProfile
                    // JSON.parse(this.state.JSONData)
                    // (json: any) =>
                    //   this.setState({ JSONData: prettyStringify(json) })
                  )
                }
                style={ButtonStyle}
              />
            </span>
          )}
        </span>
      </div>
    );
  }
  getRestaurantDoc = () => {
    if (this.props.currentRestaurant) {
      this.Api.getDoc(
        this.props.currentRestaurant.ref?.id as string,
        RestaurantCollectionPath
      ).then((data: IRestaurantCG) => {
        this.setState({
          currentCollection: "Restaurant",
          JSONData: prettyStringify({
            ref: { id: data.ref?.id, path: data.ref?.path } as any,
            RestaurantName: data.RestaurantName,
            RestaurantCity: data.RestaurantCity,
            RestaurantProductTags: data.RestaurantProductTags,
            RestaurantRecipeTypes: data.RestaurantRecipeTypes,
            RestaurantRoles: data.RestaurantRoles,
            RestaurantStaffNewOrderNotify: data.RestaurantStaffNewOrderNotify,
            RestaurantStaffReturnOrderNotify:
              data.RestaurantStaffReturnOrderNotify,
            RestaurantStaffOrderCheckedNotify:
              data.RestaurantStaffOrderCheckedNotify,
            RestaurantStaffUpdateBidsNotify:
              data.RestaurantStaffUpdateBidsNotify,
            RestaurantStaffProductPriceChangedNotify:
              data.RestaurantStaffProductPriceChangedNotify,
            RestaurantStaffProductStockLeftNotify:
              data.RestaurantStaffProductStockLeftNotify,
            RestaurantSubCollectionPaths: data.RestaurantSubCollectionPaths,
            RestaurantUnitMesures: data.RestaurantUnitMesures,
            RestaurantUnitConversions: data.RestaurantUnitConversions,
          } as IRestaurantCG),
        });
      });
    }
  };
  getProviderCollection = () => {
    if (this.props.currentRestaurant) {
      this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .ProviderCollectionPath
      ).then((data: Array<IProvider>) => {
        this.setState({
          currentCollection: "Provider",
          JSONData: prettyStringify(
            data.map((item) => {
              return {
                ref: { id: item.ref?.id, path: item.ref?.path } as any,
                ProviderName: item.ProviderName,
                ProviderContactName: item.ProviderContactName,
                ProviderContactPhone: item.ProviderContactPhone,
                ProviderContactEmail: item.ProviderContactEmail,
                ProviderDiscountPercentage: item.ProviderDiscountPercentage,
              } as IProvider;
            })
          ),
        });
      });
    }
  };
  getAreaCollection = () => {
    if (this.props.currentRestaurant) {
      this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantAreaCollectionPath
      ).then((data: Array<IRestaurantArea>) => {
        this.setState({
          currentCollection: "Area",
          JSONData: prettyStringify(
            data.map((item) => {
              return {
                ref: { id: item.ref?.id, path: item.ref?.path } as any,
                AreaName: item.AreaName,
                AreaList: item.AreaList,
              } as IRestaurantArea;
            })
          ),
        });
      });
    }
  };
  getProductCollection = async () => {
    if (this.props.currentRestaurant) {
      const AreaCollection: Array<IRestaurantArea> = await this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantAreaCollectionPath
      );
      const ProviderCollection: Array<IProvider> = await this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .ProviderCollectionPath
      );
      this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantItemCollectionPath
      ).then((data: Array<IRestaurantItem>) => {
        this.setState({
          currentCollection: "Product",
          JSONData: prettyStringify(
            data.map((item) => {
              return {
                ref: { id: item.ref?.id, path: item.ref?.path } as any,
                AreaGUID: this.state.resolveRef
                  ? AreaCollection.find((a) => a.ref?.id === item.AreaGUID?.id)
                      ?.AreaName
                  : ({
                      id: item.AreaGUID?.id,
                      path: item.AreaGUID?.path,
                    } as any),
                ProductName: item.ProductName,
                ProductList: item.ProductList,
                ProductUnitMesure: item.ProductUnitMesure,
                ProductTags: item.ProductTags,
                ProviderPreference: item.ProviderPreference
                  ? this.state.resolveRef
                    ? ProviderCollection.find(
                        (a) => a.ref?.id === item.ProviderPreference?.id
                      )?.ProviderName
                    : ({
                        id: item.ProviderPreference?.id,
                        path: item.ProviderPreference?.path,
                      } as any)
                  : undefined,
                ProductStockLeft: item.ProductStockLeft
                  ? item.ProductStockLeft.map((Stock) => {
                      return {
                        ProviderGUID:
                          this.state.resolveRef && Stock.ProviderGUID
                            ? ProviderCollection.find(
                                (a) => a.ref?.id === Stock.ProviderGUID?.id
                              )?.ProviderName
                            : ({
                                id: Stock.ProviderGUID?.id,
                                path: Stock.ProviderGUID?.path,
                              } as any),
                        StockLeft: Stock.StockLeft,
                      };
                    })
                  : undefined,
                ProductOffSeason: item.ProductOffSeason,
                ProductPriceChangedNotify: item.ProductPriceChangedNotify,
              } as IRestaurantItem;
            })
          ),
        });
      });
    }
  };
  getRecipeCollection = async () => {
    if (this.props.currentRestaurant) {
      const ProfileCollection: Array<IProfile> = await this.Api.getCollect(
        ProfileCollectionPath
      );
      const ProductCollection: Array<IRestaurantItem> = await this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantItemCollectionPath
      );
      this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantRecipeCollectionPath
      ).then((data: Array<IRestaurantRecipe>) => {
        this.setState({
          currentCollection: "Recipe",
          JSONData: prettyStringify(
            data.map((item) => {
              return {
                ref: { id: item.ref?.id, path: item.ref?.path } as any,
                RecipeName: item.RecipeName,
                RecipeShow: item.RecipeShow,
                RecipeRating: item.RecipeRating,
                RecipeRatingIndex: item.RecipeRatingIndex,
                RecipeTypes: item.RecipeTypes,
                RecipeDescription: item.RecipeDescription,
                RecipeWorkInHours: item.RecipeWorkInHours,
                RecipeCookTimeInHours: item.RecipeCookTimeInHours,
                RecipeSteps: item.RecipeSteps,
                RecipePortions: item.RecipePortions,
                RecipeContent: {
                  RecipeProducts: item.RecipeContent.RecipeProducts.map(
                    (RecipeProduct) => {
                      return {
                        ProductGUID:
                          this.state.resolveRef && RecipeProduct.ProductGUID
                            ? ProductCollection.find(
                                (p) =>
                                  p.ref?.id === RecipeProduct.ProductGUID?.id
                              )?.ProductName
                            : ({
                                id: RecipeProduct.ProductGUID?.id,
                                path: RecipeProduct.ProductGUID?.path,
                              } as any),
                        ProductUnitAmount: RecipeProduct.ProductUnitAmount,
                        ProductUnitMesure: RecipeProduct.ProductUnitMesure,
                      };
                    }
                  ),
                  RecipeRecipes: item.RecipeContent.RecipeRecipes.map(
                    (RecipeRecipe) => {
                      return {
                        RecipeGUID:
                          this.state.resolveRef && RecipeRecipe.RecipeGUID
                            ? data.find(
                                (r) => r.ref?.id === RecipeRecipe.RecipeGUID?.id
                              )?.RecipeName
                            : ({
                                id: RecipeRecipe.RecipeGUID?.id,
                                path: RecipeRecipe.RecipeGUID?.path,
                              } as any),
                        RecipeUnitAmount: RecipeRecipe.RecipeUnitAmount,
                        RecipeUnitMesure: RecipeRecipe.RecipeUnitMesure,
                      };
                    }
                  ),
                },
                RecipeLastUpdateProfile:
                  this.state.resolveRef && item.RecipeLastUpdateProfile
                    ? ProfileCollection.find(
                        (r) => r.ref?.id === item.RecipeLastUpdateProfile?.id
                      )?.ProfileContactName
                    : ({
                        id: item.RecipeLastUpdateProfile?.id,
                        path: item.RecipeLastUpdateProfile?.path,
                      } as any),
                RecipeLastUpdateDate: item.RecipeLastUpdateDate,
              } as IRestaurantRecipe;
            })
          ),
        });
      });
    }
  };
}
