import { IEmail, ITextSMS } from "../models/ICommunication";
import firebase from "firebase/app";

export default interface IApi {
  getEnvironment(): number;
  ///////////////////////////////////////////////////////////////////////////////////////
  TimestampNow(): firebase.firestore.Timestamp;
  TimestampFromDate(Date: Date): firebase.firestore.Timestamp;
  ///////////////////////////////////////////////////////////////////////////////////////
  getCollect(
    collectionPath: string,
    queryArray?: Array<whereQuery>,
    onSnapshot?: (data: Array<any>) => void,
    orderBy?: string,
    directionStr?: "desc" | "asc",
    limit?: number
  ): Promise<Array<any>>;
  /////////////////////////////////////////////////////////////////////////////////////////
  getDoc(
    documentPath: string,
    collectionPath?: string,
    onSnapshot?: (data: Array<any>) => void
  ): Promise<any>;
  /////////////////////////////////////////////////////////////////////////////////////////
  addDoc(
    collectionPath: string,
    documentData: firebase.firestore.DocumentData
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  >;
  /////////////////////////////////////////////////////////////////////////////////////////
  delDoc(documentPath: string, collectionPath?: string): Promise<void>;
  /////////////////////////////////////////////////////////////////////////////////////////
  updDoc(
    documentPath: string,
    documentData: firebase.firestore.DocumentData,
    collectionPath?: string
  ): Promise<void>;
  /////////////////////////////////////////////////////////////////////////////////////////
  setDoc(
    documentPath: string,
    documentData: firebase.firestore.DocumentData,
    collectionPath?: string
  ): Promise<void>;
  /////////////////////////////////////////////////////////////////////////////////////////
  sendEmail(
    email: IEmail
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  >;
  /////////////////////////////////////////////////////////////////////////////////////////
  sendTextSMS(
    textSMS: ITextSMS
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  >;
  /////////////////////////////////////////////////////////////////////////////////////////
  merCollect(
    collection: Array<any>,
    collectionPath: string,
    lookUp: string,
    addIf: (doc: any) => boolean,
    currentOp?: (op: string, doc: any) => void
  ): Promise<Array<any>>;
  /////////////////////////////////////////////////////////////////////////////////////////
  createUser(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential>;
  signIn(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential>;
  signOut(): Promise<void>;
  resetPassword(email: string): Promise<void>;
  onAuthStateChanged(
    nextOrObserver:
      | firebase.Observer<any, Error>
      | ((a: firebase.User | null) => any),
    error?: ((a: firebase.auth.Error) => any) | undefined,
    completed?: firebase.Unsubscribe | undefined
  ): void;
  /////////////////////////////////////////////////////////////////////////////////////////
  putFile(
    path: string,
    data: Blob | Uint8Array | ArrayBuffer,
    metadata?: firebase.storage.UploadMetadata | undefined
  ): firebase.storage.UploadTask;
  getFile(path: string): Promise<any>;
  deleteFile(path: string): Promise<any>;
}
export enum Environment {
  Local = 0,
  Development = 1,
  Release = 2,
}
export interface whereQuery {
  fieldPath: string | firebase.firestore.FieldPath;
  opStr: firebase.firestore.WhereFilterOp;
  value: any;
}
