import React, { Component } from "react";
import IApi, { Environment } from "../../api/IApi";
import {
  Pivot,
  PivotItem,
  PivotLinkFormat,
  PivotLinkSize,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import ModelEditorProducts from "./ModelEditorProducts";
import ModelEditorProviders from "./ModelEditorProviders";
import ModelEditorAreas from "./ModelEditorAreas";
import ModelEditorProfiles from "./ModelEditorProfiles";
import { IRestaurantCG, IProfile } from "../../models/IFirestoreModels";
import firebase from "firebase/app";
import ModelEditorMail from "./ModelEditorMail";
import ModelEditorText from "./ModelEditorText";
import Context from "../../const/Context";
import ModelEditorAudit from "./ModelEditorAudit";

interface IModelEditorProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
  showDevelopment: boolean;
}
interface IModelEditorState {}
export default class ModelEditor extends Component<
  IModelEditorProps,
  IModelEditorState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {};
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (
      this.props.currentUser &&
      this.props.currentProfile &&
      this.props.currentRestaurant
    ) {
    }
  }
  componentDidUpdate(
    prevProps: IModelEditorProps,
    prevState: IModelEditorState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  NavTo = (item?: PivotItem) => {
    if (item && item.props && item.props.itemKey) {
      let params = new URL(window.location.href).searchParams;
      params.set("model", item.props.itemKey);
      params.delete("sort");
      window.location.search = params.toString();
    }
  };
  render(): JSX.Element {
    const OverFlowStyle: React.CSSProperties = {
      marginTop: "5px",
      width: "100%",
      height: `${this.props.innerHeight - 150}px`,
      overflowY: "scroll",
    };
    let params = new URL(window.location.href).searchParams;
    return (
      <div>
        {!this.props.currentRestaurant ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : (
          <Pivot
            onLinkClick={this.NavTo}
            selectedKey={params.get("model") ? params.get("model") : "profiles"}
            linkFormat={PivotLinkFormat.tabs}
            linkSize={PivotLinkSize.large}
          >
            <PivotItem
              itemKey="profiles"
              headerText={"Profiles"}
              itemIcon="AccountManagement"
            >
              <div style={OverFlowStyle}>
                <ModelEditorProfiles
                  currentUser={this.props.currentUser}
                  currentProfile={this.props.currentProfile}
                  Api={this.Api}
                  Restaurant={this.props.currentRestaurant}
                  innerHeight={this.props.innerHeight}
                  innerWidth={this.props.innerWidth}
                />
              </div>
            </PivotItem>
            <PivotItem
              itemKey="providers"
              headerText={"Vendors"}
              itemIcon="DeliveryTruck"
            >
              <div style={OverFlowStyle}>
                <ModelEditorProviders
                  currentUser={this.props.currentUser}
                  currentProfile={this.props.currentProfile}
                  Api={this.Api}
                  Restaurant={this.props.currentRestaurant}
                />
              </div>
            </PivotItem>
            <PivotItem itemKey="areas" headerText={"Areas"} itemIcon="Move">
              <div style={OverFlowStyle}>
                <ModelEditorAreas
                  currentUser={this.props.currentUser}
                  currentProfile={this.props.currentProfile}
                  Api={this.Api}
                  Restaurant={this.props.currentRestaurant}
                />
              </div>
            </PivotItem>
            <PivotItem
              itemKey="products"
              headerText={"Items"}
              itemIcon="AllApps"
            >
              <div style={OverFlowStyle}>
                <Context.Consumer>
                  {(state) =>
                    state.currentRestaurant && (
                      <ModelEditorProducts
                        Api={this.Api}
                        currentProfile={state.currentProfile}
                        currentRestaurant={state.currentRestaurant}
                        innerHeight={state.innerHeight}
                        innerWidth={state.innerWidth}
                      />
                    )
                  }
                </Context.Consumer>
              </div>
            </PivotItem>
            {this.props.showDevelopment && (
              <PivotItem itemKey="mail" headerText={"Mail"} itemIcon="Mail">
                <div style={OverFlowStyle}>
                  <ModelEditorMail
                    currentUser={this.props.currentUser}
                    Api={this.Api}
                    Restaurant={this.props.currentRestaurant}
                  />
                </div>
              </PivotItem>
            )}
            {this.props.showDevelopment && (
              <PivotItem
                itemKey="SMStext"
                headerText={"SMS Text"}
                itemIcon="Message"
              >
                <div style={OverFlowStyle}>
                  <ModelEditorText
                    currentUser={this.props.currentUser}
                    Api={this.Api}
                    Restaurant={this.props.currentRestaurant}
                  />
                </div>
              </PivotItem>
            )}
            {this.props.showDevelopment && (
              <PivotItem
                itemKey="audit"
                headerText={"Audit"}
                itemIcon="SearchData"
              >
                <div style={OverFlowStyle}>
                  <ModelEditorAudit
                    Api={this.Api}
                    innerHeight={this.props.innerHeight}
                    innerWidth={this.props.innerWidth}
                  />
                </div>
              </PivotItem>
            )}
          </Pivot>
        )}
      </div>
    );
  }
}
