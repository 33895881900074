import React, { Component } from "react";
import IApi, { Environment, whereQuery } from "../../api/IApi";
import {
  IRestaurantOrder,
  IRestaurantCG,
  IProfile,
  IRestaurantArea,
  IRestaurantItem,
  IProvider,
  ProfileRoles,
  IProviderBid,
} from "../../models/IFirestoreModels";
import {
  Spinner,
  SpinnerSize,
  Dropdown,
  IDropdownOption,
  DatePicker,
  PrimaryButton,
  Icon,
  ISelectableOption,
  DefaultButton,
  FontIcon,
} from "office-ui-fabric-react";
import { ProfileCollectionPath } from "../../const/CollectionsPaths";
import OrderSelected from "./OrderSelected";
import {
  PrintDate,
  PrintTime,
  getHash,
  setHash,
  deleteHash,
} from "../../util/functions";
import MultiSelect from "react-multi-select-component";
import OrderGraph from "./OrderGraph";
import firebase from "firebase/app";

interface IOrderManagerProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
}
interface IOrderManagerState {
  isLoading: boolean;
  ProviderBidCollection: Array<IProviderBid>;
  RestaurantOrderCollection: Array<IRestaurantOrder>;
  RestaurantOrderCollectionNotFilter: Array<IRestaurantOrder>;
  ProfileCollection: Array<IProfile>;
  ProfileCollectionOptions: Array<IDropdownOption>;
  AreaCollection: Array<IRestaurantArea>;
  ProductCollection: Array<IRestaurantItem>;
  ProviderCollection: Array<IProvider>;

  SortSelected: string;
  ProductsSearch: Array<{ label: string; value: string; disabled?: boolean }>;
  ProductCollectionOptions: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;
  StartDate?: Date;
  EndDate?: Date;
  Profiles?: Array<string>;

  CurrentOrderSelected: IRestaurantOrder | null;
  ShowOrdersGraph: boolean;
  isLoadingGraphs: boolean;
}
export default class OrderManager extends Component<
  IOrderManagerProps,
  IOrderManagerState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    let StartDate: Date = new Date();
    StartDate.setDate(StartDate.getDate() - 30);
    this.state = {
      isLoading: true,
      ProviderBidCollection: [],
      RestaurantOrderCollection: [],
      RestaurantOrderCollectionNotFilter: [],
      ProfileCollection: [],
      ProfileCollectionOptions: [],
      AreaCollection: [],
      ProductCollection: [],
      ProviderCollection: [],

      SortSelected: "OrderDateDescendent",
      ProductsSearch: [],
      ProductCollectionOptions: [],
      StartDate,
      EndDate: new Date(),
      Profiles: [],

      CurrentOrderSelected: null,
      ShowOrdersGraph: false,
      isLoadingGraphs: false,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (
      this.props.currentUser &&
      this.props.currentProfile &&
      this.props.currentRestaurant
    ) {
      this.setState({ isLoading: true });
      const ProfileCollection: Array<IProfile> = await this.Api.getCollect(
        ProfileCollectionPath
      );
      const ProfileCollectionOptions: Array<IDropdownOption> = ProfileCollection.filter(
        (p) => p.ProfileRole !== ProfileRoles.Dev
      ).map((p) => {
        return { key: p.ref?.id, text: p.ProfileContactName } as any;
      });
      const AreaCollection: Array<IRestaurantArea> = await this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantAreaCollectionPath
      );
      const ProductCollection: Array<IRestaurantItem> = await this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantItemCollectionPath
      );
      const ProviderCollection: Array<IProvider> = (
        await this.Api.getCollect(
          this.props.currentRestaurant.RestaurantSubCollectionPaths
            .ProviderCollectionPath
        )
      ).sort((A, B) =>
        A.ProviderName > B.ProviderName
          ? 1
          : A.ProviderName < B.ProviderName
          ? -1
          : 0
      );
      const ProductCollectionOptions = ProductCollection.map((p) => {
        return {
          label: p.ProductName,
          value: p.ref?.id as string,
        };
      }).sort((A, B) => (A.label > B.label ? 1 : A.label < B.label ? -1 : 0));
      const Data = await this.getRestaurantOrderCollection(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantItemOrderCollectionPath,
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantItemBidCollectionPath,
        this.state.StartDate,
        this.state.EndDate,
        this.state.Profiles
      );
      const RestaurantOrderCollection: Array<IRestaurantOrder> = Data.Orders;
      const ProviderBidCollection: Array<IProviderBid> = Data.Bids;
      let hash = new URL(window.location.href).hash;
      const CurrentOrderSelected = RestaurantOrderCollection.find(
        (o) => o.ref?.id === getHash(hash, "Order")
      );
      this.setState({
        ProfileCollection,
        ProfileCollectionOptions,
        AreaCollection,
        ProductCollection,
        ProviderCollection,
        RestaurantOrderCollectionNotFilter: this.sortRestaurantOrderCollection(
          RestaurantOrderCollection,
          this.state.SortSelected
        ),
        RestaurantOrderCollection: this.sortRestaurantOrderCollection(
          RestaurantOrderCollection,
          this.state.SortSelected
        ),
        ProviderBidCollection,
        ProductCollectionOptions,

        CurrentOrderSelected: CurrentOrderSelected
          ? CurrentOrderSelected
          : null,
        ShowOrdersGraph:
          this.props.innerWidth >= 1400 && !CurrentOrderSelected ? true : false,
        isLoading: false,
      });
    }
  }
  async getRestaurantOrderCollection(
    RestaurantItemOrderCollectionPath: string,
    RestaurantItemBidCollectionPath: string,
    StartDate?: Date,
    EndDate?: Date,
    Profiles?: Array<string>
  ): Promise<{ Orders: Array<IRestaurantOrder>; Bids: Array<IProviderBid> }> {
    let OrdersQueryArray: Array<whereQuery> = [];
    if (StartDate) {
      OrdersQueryArray.push({
        fieldPath: "OrderDate",
        opStr: ">=",
        value: StartDate,
      });
    }
    if (EndDate) {
      OrdersQueryArray.push({
        fieldPath: "OrderDate",
        opStr: "<=",
        value: EndDate,
      });
    }
    let ProfilesRefs: Array<any> = [];
    Profiles?.forEach((profile) => {
      ProfilesRefs.push(
        profile === "-1"
          ? { id: "-1", path: "-1" }
          : this.state.ProfileCollection.find((p) => p.ref?.id === profile)?.ref
      );
    });
    if (Profiles?.length) {
      OrdersQueryArray.push({
        fieldPath: "OrderProfile",
        opStr: "in",
        value: ProfilesRefs,
      });
    }
    let BidsQueryArray: Array<whereQuery> = [];
    if (StartDate) {
      let t_minus30Days = new Date(StartDate.getTime());
      t_minus30Days.setDate(t_minus30Days.getDate() - 30);
      BidsQueryArray.push({
        fieldPath: "ProductBidDay",
        opStr: ">=",
        value: t_minus30Days,
      });
    }
    if (EndDate) {
      BidsQueryArray.push({
        fieldPath: "ProductBidDay",
        opStr: "<=",
        value: EndDate,
      });
    }
    return {
      Orders: await this.Api.getCollect(
        RestaurantItemOrderCollectionPath,
        OrdersQueryArray
      ),
      Bids: (
        await this.Api.getCollect(
          RestaurantItemBidCollectionPath,
          BidsQueryArray
        )
      ).sort((A, B) =>
        A.ProductBidDay > B.ProductBidDay
          ? -1
          : A.ProductBidDay < B.ProductBidDay
          ? 1
          : 0
      ),
    };
  }
  sortRestaurantOrderCollection(
    RestaurantOrderCollection: Array<IRestaurantOrder>,
    SortSelected: string
  ): Array<IRestaurantOrder> {
    let Output: Array<IRestaurantOrder> = [];
    switch (SortSelected) {
      case "OrderProfileAscendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          this.getProfileContactName(A) < this.getProfileContactName(B)
            ? -1
            : this.getProfileContactName(A) > this.getProfileContactName(B)
            ? 1
            : 0
        );
        break;
      case "OrderProfileDescendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          this.getProfileContactName(A) > this.getProfileContactName(B)
            ? -1
            : this.getProfileContactName(A) < this.getProfileContactName(B)
            ? 1
            : 0
        );
        break;
      case "OrderDateAscendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          A.OrderDate < B.OrderDate ? -1 : A.OrderDate > B.OrderDate ? 1 : 0
        );
        break;
      case "OrderDateDescendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          A.OrderDate > B.OrderDate ? -1 : A.OrderDate < B.OrderDate ? 1 : 0
        );
        break;
      case "OrderDeliveryAscendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          A.OrderDelivery < B.OrderDelivery
            ? -1
            : A.OrderDelivery > B.OrderDelivery
            ? 1
            : 0
        );
        break;
      case "OrderDeliveryDescendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          A.OrderDelivery > B.OrderDelivery
            ? -1
            : A.OrderDelivery < B.OrderDelivery
            ? 1
            : 0
        );
        break;
      case "OrderTotalAscendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          A.OrderTotal < B.OrderTotal ? -1 : A.OrderTotal > B.OrderTotal ? 1 : 0
        );
        break;
      case "OrderTotalDescendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          A.OrderTotal > B.OrderTotal ? -1 : A.OrderTotal < B.OrderTotal ? 1 : 0
        );
        break;
      case "OrderTotalCheckedAscendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          A.OrderTotalChecked && B.OrderTotalChecked
            ? A.OrderTotalChecked < B.OrderTotalChecked
              ? -1
              : A.OrderTotalChecked > B.OrderTotalChecked
              ? 1
              : 0
            : 0
        );
        break;
      case "OrderTotalCheckedDescendent":
        RestaurantOrderCollection.sort((A, B) =>
          A.OrderTotalChecked && B.OrderTotalChecked
            ? A.OrderTotalChecked > B.OrderTotalChecked
              ? -1
              : A.OrderTotalChecked < B.OrderTotalChecked
              ? 1
              : 0
            : 0
        );
        break;
      case "OrderPacksAscendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          A.OrderPacks < B.OrderPacks ? -1 : A.OrderPacks > B.OrderPacks ? 1 : 0
        );
        break;
      case "OrderPacksDescendent":
        Output = RestaurantOrderCollection.sort((A, B) =>
          A.OrderPacks > B.OrderPacks ? -1 : A.OrderPacks < B.OrderPacks ? 1 : 0
        );
        break;
    }
    return Output;
  }
  filterByProduct(
    RestaurantOrderCollection: Array<IRestaurantOrder>,
    ProductsSearch: Array<{
      label: string;
      value: string;
      disabled?: boolean;
    }>
  ): Array<IRestaurantOrder> {
    let RestaurantOrderCollectionFiltered: Array<IRestaurantOrder> = [];
    if (ProductsSearch.length > 0) {
      const filterIds: Array<string> = ProductsSearch.map((p) => p.value);
      RestaurantOrderCollectionFiltered = RestaurantOrderCollection.filter(
        (o) =>
          o.OrderProducts.findIndex((p) =>
            filterIds.includes(p.ProductGUID?.id as string)
          ) !== -1 ||
          (o.ReturnedProducts &&
            o.ReturnedProducts.findIndex((p) =>
              filterIds.includes(p.ProductGUID?.id as string)
            ) !== -1)
      );
    } else {
      RestaurantOrderCollectionFiltered = RestaurantOrderCollection.map(
        (order) => {
          return {
            ...order,
          };
        }
      );
    }
    return RestaurantOrderCollectionFiltered;
  }
  getProfileContactName(Order: any): string {
    return (this.state.ProfileCollection.find(
      (p) => p.ref?.id === Order.OrderProfile.id
    ) as IProfile).ProfileContactName;
  }
  componentDidUpdate(
    prevProps: IOrderManagerProps,
    prevState: IOrderManagerState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  onChanged = async (event: any, option?: any) => {
    let index: number = Number(event.target.id.split(" ")[0]);
    let field: string = event.target.id.split(" ")[1];
    if (index === -1) {
      let Data: {
        Orders: Array<IRestaurantOrder>;
        Bids: Array<IProviderBid>;
      } = { Orders: [], Bids: [] };
      let RestaurantOrderCollection: Array<IRestaurantOrder> = [];
      switch (field) {
        case "StartDate":
          this.setState({ isLoading: true });
          Data = await this.getRestaurantOrderCollection(
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemOrderCollectionPath as string,
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemBidCollectionPath as string,
            option,
            this.state.EndDate,
            this.state.Profiles
          );
          RestaurantOrderCollection = this.filterByProduct(
            Data.Orders,
            this.state.ProductsSearch
          );
          RestaurantOrderCollection = this.sortRestaurantOrderCollection(
            RestaurantOrderCollection,
            this.state.SortSelected
          );
          this.setState({
            isLoading: false,
            RestaurantOrderCollectionNotFilter: RestaurantOrderCollection,
            RestaurantOrderCollection: RestaurantOrderCollection,
            ProviderBidCollection: Data.Bids,
            StartDate: option,
          });
          break;
        case "EndDate":
          this.setState({ isLoading: true });
          Data = await this.getRestaurantOrderCollection(
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemOrderCollectionPath as string,
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemBidCollectionPath as string,
            this.state.StartDate,
            option,
            this.state.Profiles
          );
          RestaurantOrderCollection = this.filterByProduct(
            Data.Orders,
            this.state.ProductsSearch
          );
          RestaurantOrderCollection = this.sortRestaurantOrderCollection(
            RestaurantOrderCollection,
            this.state.SortSelected
          );
          this.setState({
            isLoading: false,
            RestaurantOrderCollectionNotFilter: RestaurantOrderCollection,
            RestaurantOrderCollection: RestaurantOrderCollection,
            ProviderBidCollection: Data.Bids,
            EndDate: option,
          });
          break;
        case "OrderProfile":
          this.setState({ isLoading: true });
          const Profiles =
            option?.selected === true
              ? this.state.Profiles?.concat([option.key as string])
              : this.state.Profiles?.filter((key) => key !== option.key);

          Data = await this.getRestaurantOrderCollection(
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemOrderCollectionPath as string,
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemBidCollectionPath as string,
            this.state.StartDate,
            this.state.EndDate,
            Profiles
          );
          RestaurantOrderCollection = this.filterByProduct(
            Data.Orders,
            this.state.ProductsSearch
          );
          RestaurantOrderCollection = this.sortRestaurantOrderCollection(
            RestaurantOrderCollection,
            this.state.SortSelected
          );
          this.setState({
            isLoading: false,
            RestaurantOrderCollectionNotFilter: RestaurantOrderCollection,
            RestaurantOrderCollection: RestaurantOrderCollection,
            ProviderBidCollection: Data.Bids,
            Profiles,
          });
          break;
        case "Sort":
          this.setState({
            RestaurantOrderCollection: this.sortRestaurantOrderCollection(
              this.state.RestaurantOrderCollection,
              option?.key.toString()
            ),
            SortSelected: option?.key.toString(),
          });
          break;
        case "Clear":
          this.setState({ isLoading: true });
          let StartDate: Date = new Date();
          StartDate.setDate(StartDate.getDate() - 30);
          Data = await this.getRestaurantOrderCollection(
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemOrderCollectionPath as string,
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemBidCollectionPath as string,
            StartDate,
            new Date()
          );
          RestaurantOrderCollection = this.sortRestaurantOrderCollection(
            Data.Orders,
            "OrderDateDescendent"
          );
          this.setState({
            RestaurantOrderCollectionNotFilter: RestaurantOrderCollection,
            RestaurantOrderCollection: RestaurantOrderCollection,
            ProviderBidCollection: Data.Bids,
            ProductsSearch: [],
            StartDate,
            EndDate: new Date(),
            Profiles: [],
            SortSelected: "OrderDateDescendent",
            isLoading: false,
          });
          break;
        case "Graphs":
          let hash = new URL(window.location.href).hash;
          hash = deleteHash(hash, "Order");
          window.location.hash = hash;
          this.setState({
            isLoadingGraphs: this.state.ShowOrdersGraph ? false : true,
            ShowOrdersGraph: true,
            CurrentOrderSelected: null,
          });
          break;
      }
    } else {
      const CurrentOrderSelected: IRestaurantOrder = this.state.RestaurantOrderCollection.find(
        (order) => order.ref?.id === option
      ) as IRestaurantOrder;
      let hash = new URL(window.location.href).hash;
      hash = setHash(hash, "Order", CurrentOrderSelected.ref?.id as string);
      window.location.hash = hash;
      this.setState({ CurrentOrderSelected, ShowOrdersGraph: false });
    }
  };
  renderOrdersList(): Array<JSX.Element> {
    return this.state.RestaurantOrderCollection.map(
      (Order: IRestaurantOrder, index: number) => {
        const Profile: IProfile = this.state.ProfileCollection.find(
          (profile) => profile.ref?.id === Order.OrderProfile?.id
        ) as IProfile;
        return (
          <tr
            id={`${Order.ref?.id}`}
            key={`Order${index}${Order.ref?.id}`}
            style={{
              backgroundColor:
                Order.ref?.id === this.state.CurrentOrderSelected?.ref?.id
                  ? "#0078ff"
                  : index % 2
                  ? "#DCDCDC"
                  : "white",
              color:
                Order.ref?.id === this.state.CurrentOrderSelected?.ref?.id
                  ? "white"
                  : "black",
              cursor: "pointer",
            }}
            onClick={(event) => this.onChanged(event, Order.ref?.id)}
          >
            <td style={{ width: "15%", textAlign: "center" }}>
              {PrintDate(Order.OrderDate)}
            </td>
            <td style={{ width: "15%", textAlign: "center" }}>
              {PrintTime(Order.OrderDate)}
            </td>
            <td style={{ width: "60%" }}>{`${
              Profile ? Profile.ProfileContactName : "N/A"
            }`}</td>
            <td style={{ width: "5%", textAlign: "center" }}>
              {Order.OrderRequireCheck && <FontIcon iconName="DeliveryTruck" />}
            </td>
            <td style={{ width: "5%", textAlign: "center" }}>
              {Order.OrderRequireInvoiceCheck && <FontIcon iconName="Money" />}
            </td>
          </tr>
        );
      }
    );
  }
  setCurrentOrderSelected = async () => {
    let RestaurantOrderCollection: Array<IRestaurantOrder> = this.state
      .RestaurantOrderCollection;
    const index: number = RestaurantOrderCollection.findIndex(
      (p) => p.ref?.id === this.state.CurrentOrderSelected?.ref?.id
    );
    if (index !== -1) {
      let Order: IRestaurantOrder = await this.Api.getDoc(
        this.state.CurrentOrderSelected?.ref?.id as string,
        this.props.currentRestaurant?.RestaurantSubCollectionPaths
          .RestaurantItemOrderCollectionPath
      );
      RestaurantOrderCollection[index] = Order;
      this.setState({ RestaurantOrderCollection, CurrentOrderSelected: Order });
    }
  };
  render(): JSX.Element {
    const CurrentOrderSelected: IRestaurantOrder | null = this.state
      .CurrentOrderSelected;
    const OverFlowStyle: React.CSSProperties = {
      width: "100%",
      height: `${this.props.innerHeight - 265}px`,
      overflowY: "scroll",
    };
    const OrderTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const OrderTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    const iconStyles = { marginRight: "8px" };
    const onRenderOption = (option: ISelectableOption): JSX.Element => {
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
      const option = options[0];
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    return (
      <div>
        {this.props.innerWidth < 1400 &&
          (this.state.CurrentOrderSelected || this.state.ShowOrdersGraph) && (
            <span style={{ position: "fixed", bottom: 15, left: 10 }}>
              <DefaultButton
                text="Back"
                iconProps={{ iconName: "Back" }}
                id={`Back`}
                onClick={() => {
                  let hash = new URL(window.location.href).hash;
                  hash = deleteHash(hash, "Order");
                  window.location.hash = hash;
                  this.setState({
                    CurrentOrderSelected: null,
                    ShowOrdersGraph: false,
                  });
                }}
              />
            </span>
          )}
        <table style={OrderTableStyle}>
          <tbody>
            <tr>
              {(this.props.innerWidth >= 1400 ||
                (!this.state.CurrentOrderSelected &&
                  !this.state.ShowOrdersGraph)) && (
                <td
                  style={{
                    width: this.props.innerWidth >= 1400 ? "40%" : "98%",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      width: this.props.innerWidth > 600 ? "100%" : "96%",
                    }}
                  >
                    <span>
                      <MultiSelect
                        options={this.state.ProductCollectionOptions}
                        value={this.state.ProductsSearch}
                        onChange={(
                          value: Array<{
                            label: string;
                            value: string;
                            disabled?: boolean;
                          }>
                        ) => {
                          this.setState({
                            RestaurantOrderCollection: this.filterByProduct(
                              this.state.RestaurantOrderCollectionNotFilter,
                              value ? value : []
                            ),
                            ProductsSearch: value,
                            CurrentOrderSelected:
                              value.length > 0 && this.props.innerWidth >= 1400
                                ? null
                                : this.state.CurrentOrderSelected,
                          });
                        }}
                        labelledBy={"Search Item Price..."}
                      />
                    </span>
                    <hr></hr>
                    <table style={OrderTableStyle}>
                      <tbody>
                        <tr key={"DateFilter"}>
                          <td style={{ width: "45%" }}>
                            <DatePicker
                              id={`-1 StartDate`}
                              placeholder="From Order Date..."
                              value={this.state.StartDate}
                              onSelectDate={(date) =>
                                this.onChanged(
                                  { target: { id: "-1 StartDate" } },
                                  date
                                )
                              }
                            />
                          </td>
                          <td style={{ width: "45" }}>
                            <DatePicker
                              id={`-1 EndDate`}
                              placeholder="To Order Date..."
                              value={this.state.EndDate}
                              onSelectDate={(date) =>
                                this.onChanged(
                                  { target: { id: "-1 EndDate" } },
                                  date
                                )
                              }
                            />
                          </td>
                          <td style={{ width: "10%" }}>
                            <DefaultButton
                              id={`-1 Clear`}
                              onClick={() =>
                                this.onChanged({
                                  target: { id: "-1 Clear" },
                                })
                              }
                              text="Clear"
                              iconProps={{ iconName: "Clear" }}
                              style={{ width: "100%" }}
                            />
                          </td>
                        </tr>
                        <tr key={"ProviderAndSort"}>
                          <td>
                            <Dropdown
                              id={`-1 OrderProfile`}
                              placeholder="Filter By Profile..."
                              selectedKeys={this.state.Profiles}
                              multiSelect
                              onChange={this.onChanged}
                              options={this.state.ProfileCollectionOptions}
                              style={{
                                maxWidth:
                                  this.props.innerWidth >= 1400
                                    ? this.props.innerWidth * 0.18
                                    : this.props.innerWidth * 0.45,
                              }}
                            />
                          </td>
                          <td>
                            <Dropdown
                              id={`-1 Sort`}
                              placeholder="Select Sort..."
                              selectedKey={this.state.SortSelected}
                              onChange={this.onChanged}
                              options={[
                                {
                                  key: "OrderProfileDescendent",
                                  text: "Profile",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "OrderProfileAscendent",
                                  text: "Profile",
                                  data: { icon: "Up" },
                                },
                                {
                                  key: "OrderDateDescendent",
                                  text: "Date",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "OrderDateAscendent",
                                  text: "Date",
                                  data: { icon: "Up" },
                                },
                                {
                                  key: "OrderDeliveryDescendent",
                                  text: "Delivery",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "OrderDeliveryAscendent",
                                  text: "Delivery",
                                  data: { icon: "Up" },
                                },
                                {
                                  key: "OrderTotalAscendent",
                                  text: "Total",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "OrderTotalDescendent",
                                  text: "Total",
                                  data: { icon: "Up" },
                                },
                                {
                                  key: "OrderTotalCheckedAscendent",
                                  text: "Total Checked",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "OrderTotalCheckedDescendent",
                                  text: "Total Checked",
                                  data: { icon: "Up" },
                                },
                                {
                                  key: "OrderPacksDescendent",
                                  text: "Cases",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "OrderPacksAscendent",
                                  text: "Cases",
                                  data: { icon: "Up" },
                                },
                              ]}
                              onRenderTitle={(t) =>
                                onRenderTitle(t as Array<IDropdownOption>)
                              }
                              onRenderOption={(o) =>
                                onRenderOption(o as ISelectableOption)
                              }
                            />
                          </td>
                          <td>
                            <PrimaryButton
                              id={`-1 Graphs`}
                              onClick={() =>
                                this.onChanged({ target: { id: "-1 Graphs" } })
                              }
                              style={{ width: "100%" }}
                              disabled={
                                this.state.isLoading ||
                                this.state.isLoadingGraphs
                              }
                            >
                              {this.state.isLoadingGraphs ? (
                                <Spinner size={SpinnerSize.small} />
                              ) : (
                                <span>
                                  <Icon iconName="Financial" /> Graphs
                                </span>
                              )}
                            </PrimaryButton>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr></hr>
                    <table style={OrderTableStyle}>
                      <thead style={OrderTableHeadStyle}>
                        <tr>
                          <th style={{ width: "15%", textAlign: "center" }}>
                            Date
                          </th>
                          <th style={{ width: "15%", textAlign: "center" }}>
                            Time
                          </th>
                          <th style={{ width: "70%" }}>Order Profile</th>
                        </tr>
                      </thead>
                    </table>
                    <div style={OverFlowStyle}>
                      <table style={OrderTableStyle}>
                        <tbody>
                          {this.state.isLoading ? (
                            <tr>
                              <td>
                                <Spinner
                                  style={{ marginTop: 10 }}
                                  size={SpinnerSize.large}
                                />
                              </td>
                            </tr>
                          ) : this.state.RestaurantOrderCollection.length ? (
                            this.renderOrdersList()
                          ) : (
                            <tr>
                              <td>No Results</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <div style={{ height: "100px" }} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              )}
              {this.props.currentProfile &&
                this.props.currentRestaurant &&
                CurrentOrderSelected && (
                  <td
                    style={{
                      width: this.props.innerWidth >= 1400 ? "59%" : "100%",
                    }}
                  >
                    <OrderSelected
                      Api={this.Api}
                      Profile={this.props.currentProfile}
                      Restaurant={this.props.currentRestaurant}
                      ProfileCollection={this.state.ProfileCollection}
                      AreaCollection={this.state.AreaCollection}
                      ProductCollection={this.state.ProductCollection}
                      ProviderCollection={this.state.ProviderCollection}
                      CurrentOrderSelected={CurrentOrderSelected}
                      innerHeight={this.props.innerHeight}
                      innerWidth={this.props.innerWidth}
                      setCurrentOrderSelected={this.setCurrentOrderSelected}
                    />
                  </td>
                )}
              {this.state.ShowOrdersGraph &&
                this.props.currentProfile &&
                this.props.currentRestaurant &&
                this.state.StartDate &&
                this.state.EndDate && (
                  <td
                    style={{
                      width: this.props.innerWidth >= 1400 ? "59%" : "100%",
                    }}
                  >
                    <OrderGraph
                      Api={this.Api}
                      Profile={this.props.currentProfile}
                      Restaurant={this.props.currentRestaurant}
                      AreaCollection={this.state.AreaCollection}
                      ProviderCollection={this.state.ProviderCollection}
                      ProductCollection={this.state.ProductCollection}
                      RestaurantOrderCollection={
                        this.state.RestaurantOrderCollection
                      }
                      ProviderBidCollection={this.state.ProviderBidCollection}
                      ProductsSearch={this.state.ProductsSearch}
                      StartDate={this.state.StartDate}
                      EndDate={this.state.EndDate}
                      innerHeight={this.props.innerHeight}
                      innerWidth={this.props.innerWidth}
                      LoadedGraphs={() =>
                        this.setState({
                          isLoadingGraphs: false,
                        })
                      }
                      isLoadingGraphs={this.state.isLoadingGraphs}
                    />
                  </td>
                )}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
