import IApi, { Environment, whereQuery } from "./IApi";
import ProductCollection from "../local/ProductCollection.json";
import AreaCollection from "../local/AreaCollection.json";
import ProductsOrderCollection from "../local/ProductsOrderCollection.json";
import ProviderCollection from "../local/ProviderCollection.json";
import ProductsBidCollection from "../local/ProductsBidCollection.json";
import RestaurantCollection from "../local/RestaurantCollection.json";
import ProfileCollection from "../local/ProfileCollection.json";
import { IEmail, ITextSMS } from "../models/ICommunication";
import firebase from "firebase/app";
import { formatXML } from "../util/functions";
import {
  ProfileCollectionPath,
  RestaurantCollectionPath,
} from "../const/CollectionsPaths";

export default class LocalApi implements IApi {
  private delay: number = 100;
  public getEnvironment(): number {
    return Environment.Local;
  }
  public TimestampNow(): firebase.firestore.Timestamp {
    return new Date() as any;
  }
  public TimestampFromDate(Date: Date): firebase.firestore.Timestamp {
    return Date as any;
  }
  ///////////////////////////////////////////////////////////////////////////////////////
  public getCollect(
    collectionPath: string,
    queryArray?: Array<whereQuery>,
    onSnapshot?: (data: Array<any>) => void,
    orderBy?: string,
    directionStr?: "desc" | "asc",
    limit?: number
  ): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      setTimeout(() => {
        switch (collectionPath) {
          case "productCGMyrtleBeach":
            if (queryArray && queryArray[0].fieldPath) {
              resolve(
                ProductCollection.filter(
                  (item) =>
                    queryArray && item.AreaGUID.id === queryArray[0].value.id
                )
              );
            } else {
              resolve(ProductCollection);
            }
            break;
          case "productAreaCGMyrtleBeach":
            resolve(AreaCollection);
            break;
          case "productOrderCGMyrtleBeach":
            resolve(ProductsOrderCollection);
            break;
          case "providerCGMyrtleBeach":
            resolve(ProviderCollection);
            break;
          case "productBidCGMyrtleBeach":
            resolve(ProductsBidCollection as any);
            break;
          case ProfileCollectionPath:
            if (queryArray && queryArray[0].fieldPath) {
              resolve(
                ProfileCollection.filter(
                  (item) =>
                    queryArray && item.ProfileRole === queryArray[0].value
                )
              );
            } else {
              resolve(ProfileCollection);
            }
            break;
          default:
            resolve([{}]);
            break;
        }
      }, this.delay);
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public getDoc(
    documentPath: string,
    collectionPath?: string,
    onSnapshot?: (data: Array<any>) => void
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      setTimeout(() => {
        if (documentPath && documentPath) {
          switch (collectionPath) {
            case "productCGMyrtleBeach":
              resolve(ProductCollection.find((i) => (i.ref.id = documentPath)));
              break;
            case "productAreaCGMyrtleBeach":
              resolve(AreaCollection.find((i) => (i.ref.id = documentPath)));
              break;
            case "productOrderCGMyrtleBeach":
              resolve(
                ProductsOrderCollection.find((i) => (i.ref.id = documentPath))
              );
              break;
            case "providerCGMyrtleBeach":
              resolve(
                ProviderCollection.find((i) => (i.ref.id = documentPath))
              );
              break;
            case "productBidCGMyrtleBeach":
              resolve(
                (ProductsBidCollection as any).find(
                  (i: any) => (i.ref.id = documentPath)
                )
              );
              break;
            case RestaurantCollectionPath:
              resolve(
                RestaurantCollection.find((i) => (i.ref.id = documentPath))
              );
              break;
            default:
              resolve({});
              break;
          }
        }
      }, this.delay);
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public addDoc(
    collectionPath: string,
    documentData: firebase.firestore.DocumentData
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > {
    if (documentData.ref) {
      delete documentData.ref;
    }
    console.log("addDoc =>", collectionPath, documentData);
    return new Promise<any>((resolve, reject) => {
      setTimeout(() => {
        resolve(documentData);
      }, this.delay);
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public delDoc(documentPath: string, collectionPath?: string): Promise<void> {
    console.log("delDoc =>", documentPath, collectionPath);
    return new Promise<any>((resolve, reject) => {
      setTimeout(() => resolve(documentPath), this.delay);
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public updDoc(
    documentPath: string,
    documentData: firebase.firestore.DocumentData,
    collectionPath?: string
  ): Promise<void> {
    if (documentData.ref) {
      delete documentData.ref;
    }
    console.log("updDoc =>", documentPath, documentData, collectionPath);
    return new Promise<any>((resolve, reject) => {
      setTimeout(() => resolve(documentData), this.delay);
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public setDoc(
    documentPath: string,
    documentData: firebase.firestore.DocumentData,
    collectionPath?: string
  ): Promise<void> {
    if (documentData.ref) {
      delete documentData.ref;
    }
    console.log("setDoc =>", documentPath, documentData, collectionPath);
    return new Promise<any>((resolve, reject) => {
      setTimeout(() => resolve(documentData), this.delay);
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public sendEmail(
    email: IEmail
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > {
    console.log(
      "sendEmail =>",
      email.EmailTo,
      `${email.EmailSubject}`,
      `\n${formatXML(email.EmailBody)}`
    );
    return new Promise<any>((resolve, reject) => {
      setTimeout(() => resolve({}), this.delay);
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public sendTextSMS(
    textSMS: ITextSMS
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > {
    console.log(
      "sendTextSMS =>",
      `+${textSMS.TextSMSTo}`,
      `${textSMS.TextSMSBody}`
    );
    return new Promise<any>((resolve, reject) => {
      setTimeout(() => resolve({}), this.delay);
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  public async merCollect(
    collection: Array<any>,
    collectionPath: string,
    lookUp: string,
    addIf: (doc: any) => boolean,
    currentOp?: (op: string, doc: any) => void
  ): Promise<Array<any>> {
    return [];
  }
  public createUser(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return {} as Promise<firebase.auth.UserCredential>;
  }
  public signIn(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return {} as Promise<firebase.auth.UserCredential>;
  }
  public signOut(): Promise<void> {
    return {} as Promise<void>;
  }
  public resetPassword(email: string): Promise<void> {
    return {} as Promise<void>;
  }
  public onAuthStateChanged(
    nextOrObserver:
      | firebase.Observer<any, Error>
      | ((a: firebase.User | null) => any),
    error?: ((a: firebase.auth.Error) => any) | undefined,
    completed?: firebase.Unsubscribe | undefined
  ): void {}
  public putFile(
    path: string,
    data: Blob | Uint8Array | ArrayBuffer,
    metadata?: firebase.storage.UploadMetadata | undefined
  ): firebase.storage.UploadTask {
    return {} as firebase.storage.UploadTask;
  }
  public getFile(path: string): Promise<any> {
    return {} as Promise<any>;
  }
  public deleteFile(path: string): Promise<any> {
    return {} as Promise<any>;
  }
}
