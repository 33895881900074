import firebase from "firebase/app";

export enum IQueueStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  RETRY = "RETRY",
}

export interface IEmail {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  EmailTo: string;
  EmailSubject: string;
  EmailBody: string;
  EmailStatus?: IQueueStatus;
  EmailSendOn?: Date;
  EmailTriggerDate?: Date;
  EmailDelivery?: Date;
}

export interface ITextSMS {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  TextSMSTo: number;
  TextSMSBody: string;
  TextSMSStatus?: IQueueStatus;
  TextSMSSendOn?: Date;
  TextSMSTriggerDate?: Date;
  TextSMSDelivery?: Date;
}
