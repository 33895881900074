import React, { Component } from "react";
import IApi from "../../api/IApi";
import { RestaurantCollectionPath } from "../../const/CollectionsPaths";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import { IRestaurantCG, RestaurantStaff } from "../../models/IFirestoreModels";
import firebase from "firebase/app";
import CRUD, { CRUDPropertyType } from "../DevelopmentView/CRUD";

interface IModelEditorRestaurantStaffProps {
  currentUser: firebase.User | null;
  Api: IApi;
  Restaurant: IRestaurantCG;
  EmailGroup: string;
}
interface IModelEditorRestaurantStaffState {
  isLoading: boolean;
  RestaurantStaffCollection: Array<RestaurantStaff>;
}
export default class ModelEditorRestaurantStaff extends Component<
  IModelEditorRestaurantStaffProps,
  IModelEditorRestaurantStaffState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      RestaurantStaffCollection: [],
    };
    this.Api = this.props.Api;
  }
  componentDidMount() {
    if (this.props.currentUser && this.props.Restaurant) {
      this.Api.getDoc(
        this.props.Restaurant.ref?.id as string,
        RestaurantCollectionPath,
        (Restaurant: any) => {
          let RestaurantStaffCollection = Restaurant[
            this.props.EmailGroup
          ] as Array<RestaurantStaff>;
          this.setState({
            isLoading: false,
            RestaurantStaffCollection,
          });
          //if NO ref, assing by Index as string
          if (RestaurantStaffCollection.find((i) => i.ref === undefined)) {
            this.Api.updDoc(
              this.props.Restaurant.ref?.id as string,
              {
                [this.props.EmailGroup]: RestaurantStaffCollection.map(
                  (i, index) => {
                    return { ...i, ref: `${index}` };
                  }
                ),
              },
              RestaurantCollectionPath
            );
          }
        }
      );
    }
  }
  componentDidUpdate(
    prevProps: IModelEditorRestaurantStaffProps,
    prevState: IModelEditorRestaurantStaffState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.Restaurant?.RestaurantSubCollectionPaths !==
        prevProps.Restaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  render(): JSX.Element {
    return (
      <div>
        {this.state.isLoading ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : (
          <CRUD
            List={this.state.RestaurantStaffCollection.map((i) => {
              return { ...i, ref: `${i.ref}` };
            })}
            ItemName={"Contact"}
            DisplayField={"ProfileContactName"}
            Structure={[
              {
                Type: CRUDPropertyType.ID,
                Name: "ref",
              },
              {
                Type: CRUDPropertyType.String,
                Name: "ProfileContactName",
                Enforced: true,
                DisplayName: "Contact Name",
                IconName: "People",
                Width: "30%",
                BuiltInFilter: true,
              },
              {
                Type: CRUDPropertyType.Email,
                Name: "ProfileContactEmail",
                Enforced: true,
                DisplayName: "Contact Email",
                IconName: "Mail",
                Width: "30%",
              },
              {
                Type: CRUDPropertyType.Number,
                Name: "ProfileContactPhone",
                Enforced: true,
                DisplayName: "Contact Phone",
                IconName: "Phone",
                Width: "20%",
              },
              {
                Type: CRUDPropertyType.Control,
                Name: "Control",
                Width: "20%",
              },
            ]}
            AddItem={true}
            AddConfirm={true}
            onAdd={async (item) => {
              // console.log("ADD", item);
              try {
                let RestaurantStaffCollection = this.state
                  .RestaurantStaffCollection;
                const lastIndex: number = Number(
                  RestaurantStaffCollection[
                    RestaurantStaffCollection.length - 1
                  ].ref
                );
                RestaurantStaffCollection.push({
                  ...item,
                  ref: `${lastIndex + 1}`,
                });
                await this.Api.updDoc(
                  this.props.Restaurant.ref?.path as string,
                  {
                    [this.props.EmailGroup]: RestaurantStaffCollection,
                  }
                );
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            UpdateConfirm={true}
            onUpdate={async (updates) => {
              // console.log("UPD", updates);
              try {
                let RestaurantStaffCollection = this.state
                  .RestaurantStaffCollection;
                RestaurantStaffCollection = RestaurantStaffCollection.map(
                  (item) => {
                    const update = updates.find((u) => u.id === `${item.ref}`);
                    if (update) {
                      return { ...item, ...update.changes };
                    } else {
                      return item;
                    }
                  }
                );
                await this.Api.updDoc(
                  this.props.Restaurant.ref?.path as string,
                  {
                    [this.props.EmailGroup]: RestaurantStaffCollection,
                  }
                );
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            DeleteConfirm={true}
            onDelete={async (id) => {
              // console.log("DEL", id);
              try {
                let RestaurantStaffCollection = this.state
                  .RestaurantStaffCollection;
                RestaurantStaffCollection = RestaurantStaffCollection.filter(
                  (item) => `${item.ref}` !== id
                );
                await this.Api.updDoc(
                  this.props.Restaurant.ref?.path as string,
                  {
                    [this.props.EmailGroup]: RestaurantStaffCollection,
                  }
                );
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            BuiltInFilter={true}
            SortField={"ProfileContactName@Up"}
          ></CRUD>
        )}
      </div>
    );
  }
}
