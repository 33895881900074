// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const key = {
  apiKey: "AIzaSyASiubc_bkCu142AEg-6m4gxLCwSUALQiU",
  authDomain: "orderingportal-0.firebaseapp.com",
  databaseURL: "https://orderingportal-0.firebaseio.com",
  projectId: "orderingportal-0",
  storageBucket: "orderingportal-0.appspot.com",
  messagingSenderId: "557098375387",
  appId: "1:557098375387:web:bf89391beafcb3252054fe",
  measurementId: "G-59ZL69FF5Z",
};
export default key;
