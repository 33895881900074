import React, { Component } from "react";
import IApi from "../../api/IApi";
import {
  IProfile,
  IRestaurantArea,
  IRestaurantCG,
  IRestaurantItem,
  ProfileRoles,
} from "../../models/IFirestoreModels";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import firebase from "firebase/app";
import CRUD, { CRUDPropertyType } from "../DevelopmentView/CRUD";

interface IModelEditorAreasProps {
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  Restaurant: IRestaurantCG;
}
interface IModelEditorAreasState {
  isLoading: boolean;
  AreaCollection: Array<IRestaurantArea>;
}
export default class ModelEditorAreas extends Component<
  IModelEditorAreasProps,
  IModelEditorAreasState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      AreaCollection: [],
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (this.props.currentUser && this.props.Restaurant) {
      this.Api.getCollect(
        this.props.Restaurant.RestaurantSubCollectionPaths
          .RestaurantAreaCollectionPath,
        undefined,
        (AreaCollection) =>
          this.setState({
            AreaCollection: AreaCollection.map((item, index) => {
              return { id: index, ...item };
            }),
            isLoading: false,
          }),
        "AreaList",
        "asc"
      );
    }
  }
  componentDidUpdate(
    prevProps: IModelEditorAreasProps,
    prevState: IModelEditorAreasState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.Restaurant?.RestaurantSubCollectionPaths !==
        prevProps.Restaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  handleDeleteRelationships = async (ref: string): Promise<Array<void>> => {
    let promiseDeleteRelationships: Array<Promise<void>> = [];
    const Area = this.state.AreaCollection.find(
      (Area: IRestaurantArea) => Area.ref?.path === ref
    );
    const ProductCollection: Array<IRestaurantItem> = await this.Api.getCollect(
      this.props.Restaurant.RestaurantSubCollectionPaths
        .RestaurantItemCollectionPath,
      [{ fieldPath: "AreaGUID", opStr: "==", value: Area?.ref }]
    );
    ProductCollection.forEach((p) => {
      promiseDeleteRelationships.push(this.Api.delDoc(p.ref?.path as string));
    });
    return Promise.all(promiseDeleteRelationships);
  };
  render(): JSX.Element {
    return (
      <div>
        {this.state.isLoading ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : (
          <CRUD
            List={this.state.AreaCollection.map((i) => {
              return { ...i, ref: i.ref?.path };
            })}
            ItemName={"Area"}
            DisplayField={"AreaName"}
            Structure={[
              {
                Type: CRUDPropertyType.ID,
                Name: "ref",
              },
              {
                Type: CRUDPropertyType.Number,
                Name: "AreaList",
                Hidden: true,
                DisplayName: "List",
              },
              {
                Type: CRUDPropertyType.String,
                Name: "AreaName",
                Enforced: true,
                DisplayName: "Area Name",
                IconName: "Move",
                Width: "70%",
              },
              {
                Type: CRUDPropertyType.Control,
                Name: "Control",
                Width: "30%",
              },
            ]}
            onDisable={(item, col) =>
              this.props.currentProfile?.ProfileRole !== ProfileRoles.Dev &&
              col.Name === "@Delete"
            }
            AddItem={true}
            AddConfirm={true}
            onAdd={async (item) => {
              // console.log("ADD", item);
              try {
                const lastIndex: number = Number(
                  this.state.AreaCollection.sort(
                    (A: IRestaurantArea, B: IRestaurantArea) =>
                      Number(A.AreaList) > Number(B.AreaList)
                        ? 1
                        : Number(A.AreaList) < Number(B.AreaList)
                        ? -1
                        : 0
                  )[this.state.AreaCollection.length - 1].AreaList
                );
                await this.Api.addDoc(
                  this.props.Restaurant.RestaurantSubCollectionPaths
                    .RestaurantAreaCollectionPath,
                  { ...item, AreaList: lastIndex + 1 }
                );
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            UpdateConfirm={true}
            onUpdate={async (updates) => {
              // console.log("UPD", updates);
              try {
                let promiseUpdates: Array<Promise<void>> = [];
                updates.forEach((u) =>
                  promiseUpdates.push(this.Api.updDoc(u.id, u.changes))
                );
                await Promise.all(promiseUpdates);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            DeleteConfirm={true}
            onDelete={async (id) => {
              // console.log("DEL", id);
              try {
                await this.handleDeleteRelationships(id);
                await this.Api.delDoc(id);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            SortField={"AreaList@Up"}
            BuiltInReSort={true}
          ></CRUD>
        )}
      </div>
    );
  }
}
