import firebase from "firebase/app";

export interface IProfile {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProfileRole: ProfileRoles;
  ProfileContactName: string;
  ProfileContactPhone: number;
  ProfileContactEmail: string;
  ProviderGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  RestaurantGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  RestaurantConfigurations?: Array<IRestaurantConfigurations>;
}
export interface IRestaurantConfigurations {
  RestaurantGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  SelectedAreasRefs?: Array<string>;
  LastTimeStored: firebase.firestore.Timestamp;
  OrderProducts?: Array<IRestaurantItemOrder>;
}
export enum ProfileRoles {
  Dev = "Dev",
  Admin = "Admin",
  Manager = "Manager",
  Provider = "Vendor",
  AdminMyrtleBeach = "AdminMyrtleBeach",
  ManagerMyrtleBeach = "ManagerMyrtleBeach",
  ProviderMyrtleBeach = "VendorMyrtleBeach",
  AdminVirginiaBeach = "AdminVirginiaBeach",
  ManagerVirginiaBeach = "ManagerVirginiaBeach",
  ProviderVirginiaBeach = "VendorVirginiaBeach",
  New = "New",
}
export interface IRestaurantCG {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  RestaurantName: string;
  RestaurantCity: string;
  RestaurantProductTags: Array<ProductTag>;
  RestaurantRecipeTypes: Array<RecipeType>;
  RestaurantRoles: Array<string>;
  RestaurantStaffNewOrderNotify: Array<RestaurantStaff>;
  RestaurantStaffReturnOrderNotify: Array<RestaurantStaff>;
  RestaurantStaffOrderCheckedNotify: Array<RestaurantStaff>;
  RestaurantStaffUpdateBidsNotify: Array<RestaurantStaff>;
  RestaurantStaffProductPriceChangedNotify: Array<RestaurantStaff>;
  RestaurantStaffProductStockLeftNotify: Array<RestaurantStaff>;
  RestaurantSubCollectionPaths: {
    RestaurantAreaCollectionPath: string;
    RestaurantItemCollectionPath: string;
    RestaurantRecipeCollectionPath: string;
    ProviderCollectionPath: string;
    RestaurantItemBidCollectionPath: string;
    RestaurantItemOrderCollectionPath: string;
    RestaurantItemInventoryCollectionPath: string;
  };
  RestaurantUnitMesures: {
    cases: Array<{
      ifCases: Array<string>;
      replaceWith: ProductUnitMesure;
    }>;
    defaultCase?: ProductUnitMesure;
  };
  RestaurantUnitConversions: IConversionMap;
}
export interface RestaurantStaff {
  ref?: string;
  ProfileContactName: string;
  ProfileContactPhone: number;
  ProfileContactEmail: string;
}
export enum SystemOfMeasurement {
  InternationalSystem = "InternationalSystem",
  UnitedStatesSystem = "UnitedStatesSystem",
}
export enum PhysicalProperty {
  Volumen = "Volumen",
  Mass = "Mass",
  Length = "Length",
  Semantic = "Semantic",
  Price = "Price",
}
export enum ProductUnitMesure {
  //IS - Volumen
  liter = "liter",
  ml = "ml",
  //IS - Mass
  kg = "kg",
  gram = "gram",
  //US - Volumen
  gallon = "gallon",
  quarter = "quarter",
  cup = "cup",
  tablespoon = "tablespoon",
  teaspoon = "teaspoon",
  //US - Mass
  pound = "pound",
  ounce = "ounce",
  drop = "drop",
  //IS - Length
  meter = "meter",
  cm = "cm",
  //US - Length
  feet = "feet",
  inch = "inch",
  //Semantic Only
  roll = "roll",
  bunch = "bunch",
  can = "can",
  each = "each",
  //Price Only
  poundMeat = "pound (Meat)",
}
export interface IConversionMap {
  ConversionSystems: Array<{
    SystemOfMeasurement: SystemOfMeasurement;
    PhysicalProperty: PhysicalProperty;
    SystemValues: Array<{
      UnitMeasure: ProductUnitMesure;
      Factor: number;
    }>;
  }>;
  ConversionSystemsUnions: Array<{
    SystemOfMeasurementFrom: SystemOfMeasurement;
    PhysicalPropertyFrom: PhysicalProperty;
    SystemOfMeasurementTo: SystemOfMeasurement;
    PhysicalPropertyTo: PhysicalProperty;
    Factor: number;
  }>;
}
export interface IRestaurantArea {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  AreaName: string;
  AreaList: number;
}
export interface IRestaurantItem {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  AreaGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProductName: string;
  ProductList: number;
  ProductUnitMesure: ProductUnitMesure;
  ProductTags?: Array<ProductTag>;
  ProviderPreference?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProductStockLeft?: Array<ProviderStock>;
  ProductOffSeason?: boolean;
  ProductPriceChangedNotify?: boolean;
}
export interface ProviderStock {
  ProviderGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  StockLeft: number;
}
export enum ProductTag {
  Eggs = "Eggs",
  Dairy = "Dairy",
  Peanuts = "Peanuts",
  TreeNuts = "TreeNuts",
  Fish = "Fish",
  Shellfish = "Shellfish",
  Wheat = "Wheat",
  Soy = "Soy",
  Gluten = "Gluten",
  Onion = "Onion",
  Sugar = "Sugar",
  Alcohol = "Alcohol",
  Bacon = "Bacon",
  Cinnamon = "Cinnamon",
  Pork = "Pork",
  Beef = "Beef",
  Poultry = "Poultry",
  MSG = "MSG",
  NonFood = "Non Food",
}
export interface IRestaurantRecipe {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  RecipeName: string;
  RecipeShow: boolean;
  RecipeRating: number;
  RecipeRatingIndex?: number;
  RecipeTypes: Array<RecipeType>;
  RecipeDescription: string;
  RecipeWorkInHours: number;
  RecipeCookTimeInHours: number;
  RecipeSteps: Array<string>;
  RecipePortions: number;
  RecipeContent: {
    RecipeProducts: Array<{
      ProductGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
      ProductUnitAmount: number;
      ProductUnitMesure: ProductUnitMesure;
    }>;
    RecipeRecipes: Array<{
      RecipeGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
      RecipeUnitAmount: number;
      RecipeUnitMesure: ProductUnitMesure;
    }>;
  };

  RecipeLastUpdateProfile?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  RecipeLastUpdateDate: firebase.firestore.Timestamp;
}
export enum RecipeType {
  Dessert = "Dessert",
  Dressing = "Dressing",
  Mix = "Mix",
  Salad = "Salad",
  Sauce = "Sauce",
}
export interface IProvider {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProviderName: string;
  ProviderContactName: string;
  ProviderContactPhone: number;
  ProviderContactEmail: string;
  ProviderDiscountPercentage?: number;
}
export interface IProviderBid {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProviderGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProductBidDay: firebase.firestore.Timestamp;
  ProductBidList: Array<IProviderItemBid>;
  isLastProviderBid?: boolean;
}
export interface IProviderItemBid {
  ProductGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProductDescription: string;
  ProductPackPrice: number;
  ProductUnitPerPack: number;
  ProductUnitAmount: number;
  ProductUnitMesure: ProductUnitMesure;
}
export interface IRestaurantOrder {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  OrderProfile?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  OrderDate: firebase.firestore.Timestamp;
  OrderDelivery: firebase.firestore.Timestamp;
  OrderTotal: number;
  OrderTotalChecked?: number;
  OrderPacks: number;
  OrderProducts: Array<IRestaurantItemOrder>;
  ReturnedProducts?: Array<IRestaurantItemOrder>;
  OrderRequireCheck?: boolean;
  OrderRequireInvoiceCheck?: boolean;

  OrderLastCheckProfile?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  OrderLastCheckDate?: firebase.firestore.Timestamp;
}
export interface IRestaurantItemOrder {
  AreaGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProductGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProviderGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  ProductDescription: string;
  ProductPackPrice: number;
  ProductUnitPerPack: number;
  ProductUnitAmount: number;
  ProductUnitMesure: ProductUnitMesure;
  ProductBidDay: firebase.firestore.Timestamp;
  ProductOrderAmount: number;

  ProductOrderRequireCheck?: boolean;
  ProductOrderState?: ProductOrderStates;
  ProductOrderIncomingAmount?: number;
  ProductOrderReturnAmount?: number;
  ProductOrderReturnReason?: string;

  ProductOrderInvoiceRequireCheck?: boolean;
  ProductOrderInvoiceState?: ProductOrderInvoiceStates;
  ProductOrderInvoiceAmount?: number;
  ProductOrderInvoicePrice?: number;
}
export enum ProductOrderStates {
  AllGood = "AllGood",
  WrongIncomingAmount = "WrongIncomingAmount",
  Returned = "Returned",
  OutOfStock = "OutOfStock",
  Meat = "Meat",
  PickUp = "PickUp",
}
export enum ProductOrderInvoiceStates {
  AllGood = "AllGood",
  WrongInvoiceAmount = "WrongInvoiceAmount",
  WrongInvoicePrice = "WrongInvoicePrice",
  WrongInvoiceAmountAndPrice = "WrongInvoiceAmountAndPrice",
}
export interface IRestaurantInventory {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  InventoryProducts: Array<IRestaurantItemInventory>;

  InventoryCreateProfile?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  InventoryCreateDate: firebase.firestore.Timestamp;
}
export interface IRestaurantItemInventory {
  ProductGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  StockLeft: number;
  LastOrders: Array<{
    OrderGUID: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    ProviderGUID: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  }>;
}
