import React, { Component } from "react";
import IApi, { Environment, whereQuery } from "../../api/IApi";
import {
  IRestaurantCG,
  IProfile,
  IRestaurantArea,
  IRestaurantItem,
  IProvider,
  ProfileRoles,
  IRestaurantOrder,
  IRestaurantInventory,
} from "../../models/IFirestoreModels";
import {
  Spinner,
  SpinnerSize,
  Dropdown,
  IDropdownOption,
  DatePicker,
  PrimaryButton,
  Icon,
  ISelectableOption,
  DefaultButton,
} from "office-ui-fabric-react";
import { ProfileCollectionPath } from "../../const/CollectionsPaths";
import OrderSelected from "./InventorySelected";
import {
  PrintDate,
  PrintTime,
  getHash,
  setHash,
  deleteHash,
} from "../../util/functions";
import MultiSelect from "react-multi-select-component";
import firebase from "firebase/app";

interface IInventoryManagerProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
}
interface IInventoryManagerState {
  isLoading: boolean;
  RestaurantOrderCollection: Array<IRestaurantOrder>;
  RestaurantInventoryCollection: Array<IRestaurantInventory>;
  RestaurantInventoryCollectionNotFilter: Array<IRestaurantInventory>;
  ProfileCollection: Array<IProfile>;
  ProfileCollectionOptions: Array<IDropdownOption>;
  AreaCollection: Array<IRestaurantArea>;
  ProductCollection: Array<IRestaurantItem>;
  ProviderCollection: Array<IProvider>;

  SortSelected: string;
  ProductsSearch: Array<{ label: string; value: string; disabled?: boolean }>;
  ProductCollectionOptions: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;
  StartDate?: Date;
  EndDate?: Date;
  Profiles?: Array<string>;

  CurrentInventorySelected: IRestaurantInventory | null;
}
export default class InventoryManager extends Component<
  IInventoryManagerProps,
  IInventoryManagerState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    let StartDate: Date = new Date();
    StartDate.setDate(StartDate.getDate() - 30);
    this.state = {
      isLoading: true,
      RestaurantOrderCollection: [],
      RestaurantInventoryCollection: [],
      RestaurantInventoryCollectionNotFilter: [],
      ProfileCollection: [],
      ProfileCollectionOptions: [],
      AreaCollection: [],
      ProductCollection: [],
      ProviderCollection: [],

      SortSelected: "InventoryCreateDateDescendent",
      ProductsSearch: [],
      ProductCollectionOptions: [],
      StartDate,
      EndDate: new Date(),
      Profiles: [],

      CurrentInventorySelected: null,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (
      this.props.currentUser &&
      this.props.currentProfile &&
      this.props.currentRestaurant
    ) {
      this.setState({ isLoading: true });
      const ProfileCollection: Array<IProfile> = await this.Api.getCollect(
        ProfileCollectionPath
      );
      const ProfileCollectionOptions: Array<IDropdownOption> = ProfileCollection.filter(
        (p) => p.ProfileRole !== ProfileRoles.Dev
      ).map((p) => {
        return { key: p.ref?.id, text: p.ProfileContactName } as any;
      });
      const AreaCollection: Array<IRestaurantArea> = await this.Api.getCollect(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantAreaCollectionPath
      );
      const ProductCollection: Array<IRestaurantItem> = (
        await this.Api.getCollect(
          this.props.currentRestaurant.RestaurantSubCollectionPaths
            .RestaurantItemCollectionPath
        )
      )
        .sort((A, B) => {
          if (Number(A.ProductList) < Number(B.ProductList)) {
            return -1;
          } else if (Number(A.ProductList) > Number(B.ProductList)) {
            return 1;
          }
          return 0;
        })
        .sort((A, B) => {
          const AreaA = AreaCollection.find(
            (a) => a.ref?.id === A.AreaGUID?.id
          );
          const AreaB = AreaCollection.find(
            (a) => a.ref?.id === B.AreaGUID?.id
          );
          if (
            Number(AreaA ? AreaA.AreaList : 0) <
            Number(AreaB ? AreaB.AreaList : 0)
          ) {
            return -1;
          } else if (
            Number(AreaA ? AreaA.AreaList : 0) >
            Number(AreaB ? AreaB.AreaList : 0)
          ) {
            return 1;
          }
          return 0;
        });
      const ProviderCollection: Array<IProvider> = (
        await this.Api.getCollect(
          this.props.currentRestaurant.RestaurantSubCollectionPaths
            .ProviderCollectionPath
        )
      ).sort((A, B) =>
        A.ProviderName > B.ProviderName
          ? 1
          : A.ProviderName < B.ProviderName
          ? -1
          : 0
      );
      const ProductCollectionOptions = ProductCollection.map((p) => {
        return {
          label: p.ProductName,
          value: p.ref?.id as string,
        };
      }).sort((A, B) => (A.label > B.label ? 1 : A.label < B.label ? -1 : 0));
      const Data = await this.getRestaurantOrderCollection(
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantItemInventoryCollectionPath,
        this.props.currentRestaurant.RestaurantSubCollectionPaths
          .RestaurantItemOrderCollectionPath,
        this.state.StartDate,
        this.state.EndDate,
        this.state.Profiles
      );
      const RestaurantInventoryCollection: Array<IRestaurantInventory> =
        Data.Inventories;
      const RestaurantOrderCollection: Array<IRestaurantOrder> = Data.Orders;
      let hash = new URL(window.location.href).hash;
      const CurrentInventorySelected = RestaurantInventoryCollection.find(
        (o) => o.ref?.id === getHash(hash, "Inventory")
      );
      this.setState({
        ProfileCollection,
        ProfileCollectionOptions,
        AreaCollection,
        ProductCollection,
        ProviderCollection,
        RestaurantInventoryCollectionNotFilter: this.sortRestaurantOrderCollection(
          RestaurantInventoryCollection,
          this.state.SortSelected
        ),
        RestaurantInventoryCollection: this.sortRestaurantOrderCollection(
          RestaurantInventoryCollection,
          this.state.SortSelected
        ),
        RestaurantOrderCollection,
        ProductCollectionOptions,

        CurrentInventorySelected: CurrentInventorySelected
          ? CurrentInventorySelected
          : null,
        isLoading: false,
      });
    }
  }
  async getRestaurantOrderCollection(
    RestaurantItemInventoryCollectionPath: string,
    RestaurantItemOrderCollectionPath: string,
    StartDate?: Date,
    EndDate?: Date,
    Profiles?: Array<string>
  ): Promise<{
    Inventories: Array<IRestaurantInventory>;
    Orders: Array<IRestaurantOrder>;
  }> {
    let InventoryQueryArray: Array<whereQuery> = [];
    if (StartDate) {
      InventoryQueryArray.push({
        fieldPath: "InventoryCreateDate",
        opStr: ">=",
        value: StartDate,
      });
    }
    if (EndDate) {
      InventoryQueryArray.push({
        fieldPath: "InventoryCreateDate",
        opStr: "<=",
        value: EndDate,
      });
    }
    let ProfilesRefs: Array<any> = [];
    Profiles?.forEach((profile) => {
      ProfilesRefs.push(
        profile === "-1"
          ? { id: "-1", path: "-1" }
          : this.state.ProfileCollection.find((p) => p.ref?.id === profile)?.ref
      );
    });
    if (Profiles?.length) {
      InventoryQueryArray.push({
        fieldPath: "InventoryCreateProfile",
        opStr: "in",
        value: ProfilesRefs,
      });
    }
    let OrdersQueryArray: Array<whereQuery> = [];
    if (StartDate) {
      let t_minus30Days = new Date(StartDate.getTime());
      t_minus30Days.setDate(t_minus30Days.getDate() - 30);
      OrdersQueryArray.push({
        fieldPath: "OrderDelivery",
        opStr: ">=",
        value: t_minus30Days,
      });
    }
    if (EndDate) {
      OrdersQueryArray.push({
        fieldPath: "OrderDelivery",
        opStr: "<=",
        value: EndDate,
      });
    }
    return {
      Inventories: await this.Api.getCollect(
        RestaurantItemInventoryCollectionPath,
        InventoryQueryArray
      ),
      Orders: (
        await this.Api.getCollect(
          RestaurantItemOrderCollectionPath,
          OrdersQueryArray
        )
      ).sort((A, B) =>
        A.OrderDelivery > B.OrderDelivery
          ? -1
          : A.OrderDelivery < B.OrderDelivery
          ? 1
          : 0
      ),
    };
  }
  sortRestaurantOrderCollection(
    RestaurantInventoryCollection: Array<IRestaurantInventory>,
    SortSelected: string
  ): Array<IRestaurantInventory> {
    let Output: Array<IRestaurantInventory> = [];
    switch (SortSelected) {
      case "InventoryCreateProfileAscendent":
        Output = RestaurantInventoryCollection.sort((A, B) =>
          this.getProfileContactName(A) < this.getProfileContactName(B)
            ? -1
            : this.getProfileContactName(A) > this.getProfileContactName(B)
            ? 1
            : 0
        );
        break;
      case "InventoryCreateProfileDescendent":
        Output = RestaurantInventoryCollection.sort((A, B) =>
          this.getProfileContactName(A) > this.getProfileContactName(B)
            ? -1
            : this.getProfileContactName(A) < this.getProfileContactName(B)
            ? 1
            : 0
        );
        break;
      case "InventoryCreateDateAscendent":
        Output = RestaurantInventoryCollection.sort((A, B) =>
          A.InventoryCreateDate < B.InventoryCreateDate
            ? -1
            : A.InventoryCreateDate > B.InventoryCreateDate
            ? 1
            : 0
        );
        break;
      case "InventoryCreateDateDescendent":
        Output = RestaurantInventoryCollection.sort((A, B) =>
          A.InventoryCreateDate > B.InventoryCreateDate
            ? -1
            : A.InventoryCreateDate < B.InventoryCreateDate
            ? 1
            : 0
        );
        break;
    }
    return Output;
  }
  filterByProduct(
    RestaurantInventoryCollection: Array<IRestaurantInventory>,
    ProductsSearch: Array<{
      label: string;
      value: string;
      disabled?: boolean;
    }>
  ): Array<IRestaurantInventory> {
    let RestaurantOrderCollectionFiltered: Array<IRestaurantInventory> = [];
    if (ProductsSearch.length > 0) {
      const filterIds: Array<string> = ProductsSearch.map((p) => p.value);
      RestaurantOrderCollectionFiltered = RestaurantInventoryCollection.filter(
        (o) =>
          o.InventoryProducts.findIndex((p) =>
            filterIds.includes(p.ProductGUID?.id as string)
          ) !== -1
      );
    } else {
      RestaurantOrderCollectionFiltered = RestaurantInventoryCollection.map(
        (inventory) => {
          return {
            ...inventory,
          };
        }
      );
    }
    return RestaurantOrderCollectionFiltered;
  }
  getProfileContactName(Inventory: any): string {
    return (this.state.ProfileCollection.find(
      (p) => p.ref?.id === Inventory.InventoryCreateProfile.id
    ) as IProfile).ProfileContactName;
  }
  componentDidUpdate(
    prevProps: IInventoryManagerProps,
    prevState: IInventoryManagerState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  onChanged = async (event: any, option?: any) => {
    let index: number = Number(event.target.id.split(" ")[0]);
    let field: string = event.target.id.split(" ")[1];
    if (index === -1) {
      let Data: {
        Inventories: Array<IRestaurantInventory>;
        Orders: Array<IRestaurantOrder>;
      } = { Inventories: [], Orders: [] };
      let RestaurantInventoryCollection: Array<IRestaurantInventory> = [];
      switch (field) {
        case "StartDate":
          this.setState({ isLoading: true });
          Data = await this.getRestaurantOrderCollection(
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemInventoryCollectionPath as string,
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemOrderCollectionPath as string,
            option,
            this.state.EndDate,
            this.state.Profiles
          );
          RestaurantInventoryCollection = this.filterByProduct(
            Data.Inventories,
            this.state.ProductsSearch
          );
          RestaurantInventoryCollection = this.sortRestaurantOrderCollection(
            RestaurantInventoryCollection,
            this.state.SortSelected
          );
          this.setState({
            isLoading: false,
            RestaurantInventoryCollectionNotFilter: RestaurantInventoryCollection,
            RestaurantInventoryCollection: RestaurantInventoryCollection,
            RestaurantOrderCollection: Data.Orders,
            StartDate: option,
          });
          break;
        case "EndDate":
          this.setState({ isLoading: true });
          Data = await this.getRestaurantOrderCollection(
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemInventoryCollectionPath as string,
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemOrderCollectionPath as string,
            this.state.StartDate,
            option,
            this.state.Profiles
          );
          RestaurantInventoryCollection = this.filterByProduct(
            Data.Inventories,
            this.state.ProductsSearch
          );
          RestaurantInventoryCollection = this.sortRestaurantOrderCollection(
            RestaurantInventoryCollection,
            this.state.SortSelected
          );
          this.setState({
            isLoading: false,
            RestaurantInventoryCollectionNotFilter: RestaurantInventoryCollection,
            RestaurantInventoryCollection: RestaurantInventoryCollection,
            RestaurantOrderCollection: Data.Orders,
            EndDate: option,
          });
          break;
        case "InventoryCreateProfile":
          this.setState({ isLoading: true });
          const Profiles =
            option?.selected === true
              ? this.state.Profiles?.concat([option.key as string])
              : this.state.Profiles?.filter((key) => key !== option.key);

          Data = await this.getRestaurantOrderCollection(
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemInventoryCollectionPath as string,
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemOrderCollectionPath as string,
            this.state.StartDate,
            this.state.EndDate,
            Profiles
          );
          RestaurantInventoryCollection = this.filterByProduct(
            Data.Inventories,
            this.state.ProductsSearch
          );
          RestaurantInventoryCollection = this.sortRestaurantOrderCollection(
            RestaurantInventoryCollection,
            this.state.SortSelected
          );
          this.setState({
            isLoading: false,
            RestaurantInventoryCollectionNotFilter: RestaurantInventoryCollection,
            RestaurantInventoryCollection: RestaurantInventoryCollection,
            RestaurantOrderCollection: Data.Orders,
            Profiles,
          });
          break;
        case "Sort":
          this.setState({
            RestaurantInventoryCollection: this.sortRestaurantOrderCollection(
              this.state.RestaurantInventoryCollection,
              option?.key.toString()
            ),
            SortSelected: option?.key.toString(),
          });
          break;
        case "Clear":
          this.setState({ isLoading: true });
          let StartDate: Date = new Date();
          StartDate.setDate(StartDate.getDate() - 30);
          Data = await this.getRestaurantOrderCollection(
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemInventoryCollectionPath as string,
            this.props.currentRestaurant?.RestaurantSubCollectionPaths
              .RestaurantItemOrderCollectionPath as string,
            StartDate,
            new Date()
          );
          RestaurantInventoryCollection = this.sortRestaurantOrderCollection(
            Data.Inventories,
            "InventoryCreateDateDescendent"
          );
          this.setState({
            RestaurantInventoryCollectionNotFilter: RestaurantInventoryCollection,
            RestaurantInventoryCollection: RestaurantInventoryCollection,
            RestaurantOrderCollection: Data.Orders,
            ProductsSearch: [],
            StartDate,
            EndDate: new Date(),
            Profiles: [],
            SortSelected: "InventoryCreateDateDescendent",
            isLoading: false,
          });
          break;
        case "Graphs":
          let hash = new URL(window.location.href).hash;
          hash = deleteHash(hash, "Inventory");
          window.location.hash = hash;
          this.setState({
            CurrentInventorySelected: null,
          });
          break;
      }
    } else {
      const CurrentInventorySelected: IRestaurantInventory = this.state.RestaurantInventoryCollection.find(
        (inventory) => inventory.ref?.id === option
      ) as IRestaurantInventory;
      let hash = new URL(window.location.href).hash;
      hash = setHash(
        hash,
        "Inventory",
        CurrentInventorySelected.ref?.id as string
      );
      window.location.hash = hash;
      this.setState({ CurrentInventorySelected });
    }
  };
  renderOrdersList(): Array<JSX.Element> {
    return this.state.RestaurantInventoryCollection.map(
      (Inventory: IRestaurantInventory, index: number) => {
        const Profile: IProfile = this.state.ProfileCollection.find(
          (profile) => profile.ref?.id === Inventory.InventoryCreateProfile?.id
        ) as IProfile;
        return (
          <tr
            id={`${Inventory.ref?.id}`}
            key={`Inventory${index}${Inventory.ref?.id}`}
            style={{
              backgroundColor:
                Inventory.ref?.id ===
                this.state.CurrentInventorySelected?.ref?.id
                  ? "#0078ff"
                  : index % 2
                  ? "#DCDCDC"
                  : "white",
              color:
                Inventory.ref?.id ===
                this.state.CurrentInventorySelected?.ref?.id
                  ? "white"
                  : "black",
              cursor: "pointer",
            }}
            onClick={(event) => this.onChanged(event, Inventory.ref?.id)}
          >
            <td style={{ width: "15%", textAlign: "center" }}>
              {PrintDate(Inventory.InventoryCreateDate)}
            </td>
            <td style={{ width: "15%", textAlign: "center" }}>
              {PrintTime(Inventory.InventoryCreateDate)}
            </td>
            <td style={{ width: "70%" }}>{`${
              Profile ? Profile.ProfileContactName : "N/A"
            }`}</td>
          </tr>
        );
      }
    );
  }
  setCurrentInventorySelected = async (ref?: string) => {
    let RestaurantInventoryCollectionNotFilter: Array<IRestaurantInventory> = this
      .state.RestaurantInventoryCollectionNotFilter;
    let RestaurantInventoryCollection: Array<IRestaurantInventory> = this.state
      .RestaurantInventoryCollection;
    let Inventory: IRestaurantInventory = await this.Api.getDoc(
      ref ? ref : (this.state.CurrentInventorySelected?.ref?.id as string),
      this.props.currentRestaurant?.RestaurantSubCollectionPaths
        .RestaurantItemInventoryCollectionPath
    );
    if (ref) {
      RestaurantInventoryCollectionNotFilter.push(Inventory);
      RestaurantInventoryCollection = this.filterByProduct(
        RestaurantInventoryCollectionNotFilter,
        this.state.ProductsSearch
      );
    } else {
      const indexNotFilter: number = RestaurantInventoryCollection.findIndex(
        (p) => p.ref?.id === this.state.CurrentInventorySelected?.ref?.id
      );
      const index: number = RestaurantInventoryCollection.findIndex(
        (p) => p.ref?.id === this.state.CurrentInventorySelected?.ref?.id
      );
      if (indexNotFilter !== -1) {
        RestaurantInventoryCollectionNotFilter[indexNotFilter] = Inventory;
      }
      if (index !== -1) {
        RestaurantInventoryCollection[index] = Inventory;
      }
    }
    this.setState({
      RestaurantInventoryCollectionNotFilter,
      RestaurantInventoryCollection,
      CurrentInventorySelected: Inventory,
    });
  };
  render(): JSX.Element {
    const CurrentInventorySelected: IRestaurantInventory | null = this.state
      .CurrentInventorySelected;
    const OverFlowStyle: React.CSSProperties = {
      width: "100%",
      height: `${this.props.innerHeight - 265}px`,
      overflowY: "scroll",
    };
    const OrderTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const OrderTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    const iconStyles = { marginRight: "8px" };
    const onRenderOption = (option: ISelectableOption): JSX.Element => {
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
      const option = options[0];
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    return (
      <div>
        {this.props.innerWidth < 1400 && this.state.CurrentInventorySelected && (
          <span style={{ position: "fixed", bottom: 15, left: 10 }}>
            <DefaultButton
              text="Back"
              iconProps={{ iconName: "Back" }}
              id={`Back`}
              onClick={() => {
                let hash = new URL(window.location.href).hash;
                hash = deleteHash(hash, "Inventory");
                window.location.hash = hash;
                this.setState({
                  CurrentInventorySelected: null,
                });
              }}
            />
          </span>
        )}
        {!this.state.isLoading &&
          ((((this.props.currentProfile &&
            this.props.currentProfile.ProfileRole === ProfileRoles.Dev) ||
            (this.props.currentProfile &&
              this.props.currentProfile.ProfileRole.includes(
                ProfileRoles.Admin
              )) ||
            (this.props.currentProfile &&
              this.props.currentProfile.ProfileRole.includes(
                ProfileRoles.Manager
              ))) &&
            this.props.innerWidth >= 1400) ||
            !this.state.CurrentInventorySelected) && (
            <span
              style={{ position: "fixed", bottom: 15, left: 10, zIndex: 5 }}
            >
              <PrimaryButton
                id={`-1 AddInventory`}
                onClick={() => {
                  let hash = new URL(window.location.href).hash;
                  hash = setHash(hash, "Inventory", "New");
                  window.location.hash = hash;
                  this.setState({
                    CurrentInventorySelected: {
                      InventoryProducts: [],
                      InventoryCreateProfile: (this.props.currentProfile as any)
                        .ref,
                      InventoryCreateDate: this.Api.TimestampNow(),
                    },
                  });
                }}
                iconProps={{ iconName: "Add" }}
              >
                Add Inventory
              </PrimaryButton>
            </span>
          )}
        <table style={OrderTableStyle}>
          <tbody>
            <tr>
              {(this.props.innerWidth >= 1400 ||
                !this.state.CurrentInventorySelected) && (
                <td
                  style={{
                    width: this.props.innerWidth >= 1400 ? "40%" : "98%",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      width: this.props.innerWidth > 600 ? "100%" : "96%",
                    }}
                  >
                    <span>
                      <MultiSelect
                        options={this.state.ProductCollectionOptions}
                        value={this.state.ProductsSearch}
                        onChange={(
                          value: Array<{
                            label: string;
                            value: string;
                            disabled?: boolean;
                          }>
                        ) => {
                          this.setState({
                            RestaurantInventoryCollection: this.filterByProduct(
                              this.state.RestaurantInventoryCollectionNotFilter,
                              value ? value : []
                            ),
                            ProductsSearch: value,
                            CurrentInventorySelected:
                              value.length > 0 && this.props.innerWidth >= 1400
                                ? null
                                : this.state.CurrentInventorySelected,
                          });
                        }}
                        labelledBy={"Search Item Price..."}
                      />
                    </span>
                    <hr></hr>
                    <table style={OrderTableStyle}>
                      <tbody>
                        <tr key={"DateFilter"}>
                          <td style={{ width: "45%" }}>
                            <DatePicker
                              id={`-1 StartDate`}
                              placeholder="From Inventory Date..."
                              value={this.state.StartDate}
                              onSelectDate={(date) =>
                                this.onChanged(
                                  { target: { id: "-1 StartDate" } },
                                  date
                                )
                              }
                            />
                          </td>
                          <td style={{ width: "45" }}>
                            <DatePicker
                              id={`-1 EndDate`}
                              placeholder="To Inventory Date..."
                              value={this.state.EndDate}
                              onSelectDate={(date) =>
                                this.onChanged(
                                  { target: { id: "-1 EndDate" } },
                                  date
                                )
                              }
                            />
                          </td>
                          <td style={{ width: "10%" }}>
                            <DefaultButton
                              id={`-1 Clear`}
                              onClick={() =>
                                this.onChanged({
                                  target: { id: "-1 Clear" },
                                })
                              }
                              text="Clear"
                              iconProps={{ iconName: "Clear" }}
                              style={{ width: "100%" }}
                            />
                          </td>
                        </tr>
                        <tr key={"ProviderAndSort"}>
                          <td>
                            <Dropdown
                              id={`-1 InventoryCreateProfile`}
                              placeholder="Filter By Profile..."
                              selectedKeys={this.state.Profiles}
                              multiSelect
                              onChange={this.onChanged}
                              options={this.state.ProfileCollectionOptions}
                              style={{
                                maxWidth:
                                  this.props.innerWidth >= 1400
                                    ? this.props.innerWidth * 0.18
                                    : this.props.innerWidth * 0.45,
                              }}
                            />
                          </td>
                          <td>
                            <Dropdown
                              id={`-1 Sort`}
                              placeholder="Select Sort..."
                              selectedKey={this.state.SortSelected}
                              onChange={this.onChanged}
                              options={[
                                {
                                  key: "InventoryCreateProfileDescendent",
                                  text: "Profile",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "InventoryCreateProfileAscendent",
                                  text: "Profile",
                                  data: { icon: "Up" },
                                },
                                {
                                  key: "InventoryCreateDateDescendent",
                                  text: "Date",
                                  data: { icon: "Down" },
                                },
                                {
                                  key: "InventoryCreateDateAscendent",
                                  text: "Date",
                                  data: { icon: "Up" },
                                },
                              ]}
                              onRenderTitle={(t) =>
                                onRenderTitle(t as Array<IDropdownOption>)
                              }
                              onRenderOption={(o) =>
                                onRenderOption(o as ISelectableOption)
                              }
                            />
                          </td>
                          <td>
                            <PrimaryButton
                              id={`-1 Graphs`}
                              onClick={() => console.log("Graphs")}
                              style={{ width: "100%" }}
                              disabled={true}
                            >
                              <Icon iconName="Financial" /> Graphs
                            </PrimaryButton>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr></hr>
                    <table style={OrderTableStyle}>
                      <thead style={OrderTableHeadStyle}>
                        <tr>
                          <th style={{ width: "15%", textAlign: "center" }}>
                            Date
                          </th>
                          <th style={{ width: "15%", textAlign: "center" }}>
                            Time
                          </th>
                          <th style={{ width: "70%" }}>Inventory Profile</th>
                        </tr>
                      </thead>
                    </table>
                    <div style={OverFlowStyle}>
                      <table style={OrderTableStyle}>
                        <tbody>
                          {this.state.isLoading ? (
                            <tr>
                              <td>
                                <Spinner
                                  style={{ marginTop: 10 }}
                                  size={SpinnerSize.large}
                                />
                              </td>
                            </tr>
                          ) : this.state.RestaurantInventoryCollection
                              .length ? (
                            this.renderOrdersList()
                          ) : (
                            <tr>
                              <td>No Results</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <div style={{ height: "100px" }} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              )}
              {this.props.currentProfile &&
                this.props.currentRestaurant &&
                CurrentInventorySelected && (
                  <td
                    style={{
                      width: this.props.innerWidth >= 1400 ? "59%" : "100%",
                    }}
                  >
                    <OrderSelected
                      Api={this.Api}
                      Profile={this.props.currentProfile}
                      Restaurant={this.props.currentRestaurant}
                      RestaurantOrderCollection={
                        this.state.RestaurantOrderCollection
                      }
                      ProfileCollection={this.state.ProfileCollection}
                      AreaCollection={this.state.AreaCollection}
                      ProductCollection={this.state.ProductCollection}
                      ProviderCollection={this.state.ProviderCollection}
                      CurrentInventorySelected={CurrentInventorySelected}
                      innerHeight={this.props.innerHeight}
                      innerWidth={this.props.innerWidth}
                      setCurrentInventorySelected={
                        this.setCurrentInventorySelected
                      }
                      AddInventory={CurrentInventorySelected.ref === undefined}
                    />
                  </td>
                )}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
