import React, { Component } from "react";
import IApi, { Environment } from "../../api/IApi";
import {
  IRestaurantItem,
  IRestaurantArea,
  IProviderItemBid,
  IProvider,
  IRestaurantOrder,
  IRestaurantCG,
  IProviderBid,
  IProfile,
  ProfileRoles,
  IRestaurantItemOrder,
  ProductUnitMesure,
  RestaurantStaff,
  IRestaurantConfigurations,
} from "../../models/IFirestoreModels";
import { IOrderItemModel, IProviderOrder } from "../../models/IOrderItemModel";
import OrderItemTableRow from "./OrderItemTableRow";
import {
  DatePicker,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Dialog,
  DialogFooter,
  DefaultButton,
  DialogType,
  SearchBox,
  Dropdown,
  IDropdownOption,
  Label,
} from "office-ui-fabric-react";
import { PrintDate, removeSpaces } from "../../util/functions";
import { ProfileCollectionPath } from "../../const/CollectionsPaths";
import EmailCreationManager from "../../util/EmailCreationManager";
import firebase from "firebase/app";
// import TextSMSCreationManager from "../../util/TextSMSCreationManager";

interface INewOrderTableProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
}
interface INewOrderTableState {
  ProductsOrderDataNotFilter: Array<IOrderItemModel>;
  ProductsOrderData: Array<IOrderItemModel>;
  ProductsOrderToSubmit: Array<IRestaurantItemOrder>;
  ProductsOrderToReturn: Array<IRestaurantItemOrder>;

  isLoading: boolean;
  isSaving: boolean;
  isUpdateDialogHidden: boolean;
  isSubmitDialogHidden: boolean;
  isSubmitting: boolean;
  isOrderAndOrReturnedAmount: boolean;
  ProductOrderDelivery?: Date;

  AreaCollection: Array<IRestaurantArea>;
  ProductCollection: Array<IRestaurantItem>;
  ProviderCollection: Array<IProvider>;
  Restaurant: IRestaurantCG;

  AreaCollectionOptions: Array<IDropdownOption>;
  SelectedAreasRefs?: Array<string>;
  ProductSearch: string;

  currentRestaurantConfiguration?: IRestaurantConfigurations;
  isSavedDialogHidden: boolean;
}
export default class NewOrderTable extends Component<
  INewOrderTableProps,
  INewOrderTableState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      ProductsOrderDataNotFilter: [],
      ProductsOrderData: [],
      ProductsOrderToSubmit: [],
      ProductsOrderToReturn: [],

      isLoading: true,
      isSaving: false,
      isUpdateDialogHidden: true,
      isSubmitDialogHidden: true,
      isSubmitting: false,
      isOrderAndOrReturnedAmount: false,
      ProductOrderDelivery: undefined,

      AreaCollection: [],
      ProductCollection: [],
      ProviderCollection: [],
      Restaurant: {} as any,

      AreaCollectionOptions: [],
      SelectedAreasRefs: [],
      ProductSearch: "",

      currentRestaurantConfiguration: undefined,
      isSavedDialogHidden: true,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    this.getAllData();
    setInterval(() => this.setRestaurantConfigurations(), 30000);
  }
  async getAllData(Action?: "Reset" | "Lowest") {
    if (
      this.props.currentUser &&
      this.props.currentProfile &&
      this.props.currentRestaurant
    ) {
      this.setState({ isLoading: true });
      let ProductsOrderDataNotFilter: Array<IOrderItemModel> = [];
      const Restaurant: IRestaurantCG = this.props
        .currentRestaurant as IRestaurantCG;
      const ProductCollection: Array<IRestaurantItem> = await this.Api.getCollect(
        this.props.currentRestaurant?.RestaurantSubCollectionPaths
          .RestaurantItemCollectionPath
      );
      const AreaCollection: Array<IRestaurantArea> = (
        await this.Api.getCollect(
          this.props.currentRestaurant?.RestaurantSubCollectionPaths
            .RestaurantAreaCollectionPath
        )
      ).sort((A, B) =>
        Number(A.AreaList > B.AreaList)
          ? 1
          : Number(A.AreaList < B.AreaList)
          ? -1
          : 0
      );
      const ProviderCollection: Array<IProvider> = await this.Api.getCollect(
        this.props.currentRestaurant?.RestaurantSubCollectionPaths
          .ProviderCollectionPath
      );
      const ProviderBidCollection: Array<IProviderBid> = await this.Api.getCollect(
        this.props.currentRestaurant?.RestaurantSubCollectionPaths
          .RestaurantItemBidCollectionPath,
        [{ fieldPath: "isLastProviderBid", opStr: "==", value: true }]
      );
      //////////////////////////////////////////////////////////////////////////////////////////////
      //loadRestaurantConfigurations
      const currentProfile: IProfile = await this.Api.getDoc(
        this.props.currentProfile.ref?.id as string,
        ProfileCollectionPath
      );
      const currentRestaurantConfiguration =
        currentProfile.RestaurantConfigurations &&
        currentProfile.RestaurantConfigurations.length > 0
          ? currentProfile.RestaurantConfigurations?.find(
              (config) =>
                config.RestaurantGUID?.id ===
                this.props.currentRestaurant?.ref?.id
            )
          : undefined;
      let SelectedAreasRefs: Array<string> =
        currentRestaurantConfiguration &&
        currentRestaurantConfiguration.SelectedAreasRefs &&
        currentRestaurantConfiguration.SelectedAreasRefs.length
          ? currentRestaurantConfiguration.SelectedAreasRefs
          : [];
      let OrderProducts: Array<IRestaurantItemOrder> =
        currentRestaurantConfiguration &&
        currentRestaurantConfiguration.OrderProducts &&
        currentRestaurantConfiguration.OrderProducts.length
          ? currentRestaurantConfiguration.OrderProducts
          : [];
      this.setState({
        currentRestaurantConfiguration,
      });
      if (OrderProducts.length > 0) {
        this.setState({
          isSavedDialogHidden: false,
        });
      }
      //////////////////////////////////////////////////////////////////////////////////////////////
      ProductCollection.forEach((Product: IRestaurantItem) => {
        const Area: IRestaurantArea = AreaCollection.find(
          (a: IRestaurantArea) => a.ref?.id === Product.AreaGUID?.id
        ) as IRestaurantArea;
        let ProviderOrders: Array<IProviderOrder> = [];
        //////////////////////////////////////////////////////////////////////////////////////////////
        let ProviderCollectionFiltered: Array<IProvider> = [];
        if (Product.ProviderPreference && Product.ProviderPreference.id) {
          ProviderCollectionFiltered = ProviderCollection.filter(
            (p) => p.ref?.id === Product.ProviderPreference?.id
          );
        } else {
          ProviderCollectionFiltered = ProviderCollection;
        }
        //////////////////////////////////////////////////////////////////////////////////////////////
        ProviderCollectionFiltered.forEach((Provider: IProvider) => {
          const ProviderBid: IProviderBid = ProviderBidCollection.find(
            (providerBid: IProviderBid) =>
              (providerBid.ProviderGUID as any).id === Provider.ref?.id
          ) as IProviderBid;
          const ProductBid: IProviderItemBid =
            ProviderBid && ProviderBid.ProductBidList
              ? (ProviderBid.ProductBidList.find(
                  (productBid: IProviderItemBid) =>
                    productBid.ProductGUID?.id === Product.ref?.id
                ) as IProviderItemBid)
              : (undefined as any);
          if (ProductBid) {
            ProviderOrders.push({
              ProviderGUID: Provider.ref,
              ProviderName: Provider.ProviderName,
              Order: {
                AreaGUID: Area.ref,
                ProductGUID: Product.ref,
                ProviderGUID: Provider.ref,
                ProductDescription: ProductBid.ProductDescription,
                ProductPackPrice: ProductBid.ProductPackPrice,
                ProductUnitPerPack: ProductBid.ProductUnitPerPack,
                ProductUnitAmount: ProductBid.ProductUnitAmount,
                ProductUnitMesure: ProductBid.ProductUnitMesure,
                ProductBidDay: ProviderBid.ProductBidDay,
                ProductOrderAmount:
                  Action && (Action === "Reset" || Action === "Lowest")
                    ? 0
                    : this.getStoredAmount(
                        OrderProducts,
                        ProductBid,
                        Provider.ref
                      ),
              },
            });
          }
        });
        //////////////////////////////////////////////////////////////////////////////////////////////
        ProviderOrders = ProviderOrders.sort(
          (providerA: IProviderOrder, providerB: IProviderOrder) => {
            const ProviderDiscountPercentageA:
              | number
              | undefined = ProviderCollection.find(
              (p) => p.ref?.id === providerA.ProviderGUID?.id
            )?.ProviderDiscountPercentage;
            const ProductPackPriceA: number =
              ProviderDiscountPercentageA && ProviderDiscountPercentageA !== 0
                ? providerA.Order.ProductPackPrice *
                  Number(`0.${100 - ProviderDiscountPercentageA}`)
                : providerA.Order.ProductPackPrice;
            const ProductUnitPerPackA: number =
              providerA.Order.ProductUnitPerPack;
            const ProductUnitAmountA: number =
              providerA.Order.ProductUnitAmount;
            const ProductUnitMesureA: string =
              providerA.Order.ProductUnitMesure;

            const ProviderDiscountPercentageB:
              | number
              | undefined = ProviderCollection.find(
              (p) => p.ref?.id === providerB.ProviderGUID?.id
            )?.ProviderDiscountPercentage;
            const ProductPackPriceB: number =
              ProviderDiscountPercentageB && ProviderDiscountPercentageB !== 0
                ? providerB.Order.ProductPackPrice *
                  Number(`0.${100 - ProviderDiscountPercentageB}`)
                : providerB.Order.ProductPackPrice;
            const ProductUnitPerPackB: number =
              providerB.Order.ProductUnitPerPack;
            const ProductUnitAmountB: number =
              providerB.Order.ProductUnitAmount;
            const ProductUnitMesureB: string =
              providerB.Order.ProductUnitMesure;

            const ProductUnitPriceA: number =
              ProductUnitMesureA === ProductUnitMesure.poundMeat
                ? ProductPackPriceA
                : ProductPackPriceA / ProductUnitPerPackA / ProductUnitAmountA;
            const ProductUnitPriceB: number =
              ProductUnitMesureB === ProductUnitMesure.poundMeat
                ? ProductPackPriceB
                : ProductPackPriceB / ProductUnitPerPackB / ProductUnitAmountB;

            if (ProductUnitPriceA < ProductUnitPriceB) {
              return -1;
            } else if (ProductUnitPriceA > ProductUnitPriceB) {
              return 1;
            }
            return 0;
          }
        );
        ProductsOrderDataNotFilter.push({
          Area,
          Product,
          ProviderPreference:
            Product.ProviderPreference &&
            ProviderCollection.find(
              (p) => p.ref?.id === Product.ProviderPreference?.id
            ),
          ProviderOrders,
        });
      });
      ProductsOrderDataNotFilter.sort(
        (orderItemA: IOrderItemModel, orderItemB: IOrderItemModel) => {
          if (
            Number(orderItemA.Product.ProductList) <
            Number(orderItemB.Product.ProductList)
          ) {
            return -1;
          } else if (
            Number(orderItemA.Product.ProductList) >
            Number(orderItemB.Product.ProductList)
          ) {
            return 1;
          }
          return 0;
        }
      );
      ProductsOrderDataNotFilter.sort(
        (orderItemA: IOrderItemModel, orderItemB: IOrderItemModel) => {
          if (
            Number(orderItemA.Area.AreaList) < Number(orderItemB.Area.AreaList)
          ) {
            return -1;
          } else if (
            Number(orderItemA.Area.AreaList) > Number(orderItemB.Area.AreaList)
          ) {
            return 1;
          }
          return 0;
        }
      );
      ProductsOrderDataNotFilter = ProductsOrderDataNotFilter.filter(
        (product) => !product.Product.ProductOffSeason
      );
      //////////////////////////////////////////////////////////////////////////////////////////////
      if (Action === "Lowest") {
        ProductsOrderDataNotFilter = ProductsOrderDataNotFilter.map(
          (Product) => {
            const ProductSaved = currentRestaurantConfiguration?.OrderProducts?.filter(
              (productSaved) =>
                productSaved.ProductGUID?.id === Product.Product.ref?.id
            );
            const ProductSavedOrderedAmount: number =
              ProductSaved && ProductSaved?.length > 0
                ? (ProductSaved?.reduce(
                    (p1, p2) =>
                      (p1.ProductOrderAmount + p2.ProductOrderAmount) as any
                  ) as any)
                : 0;
            return {
              ...Product,
              ProviderOrders: Product.ProviderOrders.map((o, index) => {
                if (index === 0) {
                  let Order = o.Order;
                  Order.ProductOrderAmount = ProductSavedOrderedAmount;
                  return { ...o, Order };
                } else {
                  return { ...o };
                }
              }),
            };
          }
        );
      }
      //////////////////////////////////////////////////////////////////////////////////////////////
      this.setState({
        ProductsOrderDataNotFilter,
        ProductsOrderData: this.filterByProduct(
          ProductsOrderDataNotFilter,
          "",
          SelectedAreasRefs
        ),
        ProductsOrderToSubmit: [],

        isLoading: false,
        isUpdateDialogHidden: true,
        isSubmitDialogHidden: true,
        isSavedDialogHidden:
          Action === "Lowest" ? true : this.state.isSavedDialogHidden,
        isSubmitting: false,
        isOrderAndOrReturnedAmount: false,
        ProductOrderDelivery: undefined,

        AreaCollection,
        ProductCollection,
        ProviderCollection,
        Restaurant,

        ProductSearch: "",
        AreaCollectionOptions: AreaCollection.map((area) => {
          return { key: area.ref?.id, text: area.AreaName } as any;
        }),
        SelectedAreasRefs,
      });
    }
  }
  getStoredAmount(
    OrderProducts: Array<IRestaurantItemOrder>,
    ProductBid: IProviderItemBid,
    ProviderRef?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  ): number {
    const OrderProduct: IRestaurantItemOrder = OrderProducts.find(
      (orderProduct) =>
        orderProduct.ProviderGUID?.id === ProviderRef?.id &&
        orderProduct.ProductGUID?.id === ProductBid.ProductGUID?.id &&
        orderProduct.ProductDescription === ProductBid.ProductDescription &&
        // orderProduct.ProductPackPrice === ProductBid.ProductPackPrice &&
        orderProduct.ProductUnitPerPack === ProductBid.ProductUnitPerPack &&
        orderProduct.ProductUnitAmount === ProductBid.ProductUnitAmount &&
        orderProduct.ProductUnitMesure === ProductBid.ProductUnitMesure
    ) as IRestaurantItemOrder;
    return OrderProduct ? OrderProduct.ProductOrderAmount : 0;
  }
  setRestaurantConfigurations(
    reset?: boolean,
    SelectedAreasRefs?: Array<string>
  ) {
    this.setState({ isSaving: true });
    this.Api.getDoc(
      this.props.currentProfile?.ref?.id as string,
      ProfileCollectionPath
    ).then((Profile: IProfile) => {
      let RestaurantConfigurations: Array<IRestaurantConfigurations> =
        Profile.RestaurantConfigurations &&
        Profile.RestaurantConfigurations.length
          ? Profile.RestaurantConfigurations.filter(
              (config) =>
                config.RestaurantGUID?.id !== this.state.Restaurant.ref?.id
            )
          : [];
      let OrderProducts: Array<IRestaurantItemOrder> = [];
      if (!reset) {
        this.state.ProductsOrderDataNotFilter.forEach(
          (Order: IOrderItemModel) => {
            Order.ProviderOrders.forEach((providerOrder: IProviderOrder) => {
              if (providerOrder.Order.ProductOrderAmount) {
                let OrderToSubmit: IRestaurantItemOrder = {
                  AreaGUID: Order.Area.ref,
                  ProductGUID: Order.Product.ref,
                  ProviderGUID: providerOrder.ProviderGUID,
                  ProductDescription: providerOrder.Order.ProductDescription,
                  ProductPackPrice: providerOrder.Order.ProductPackPrice,
                  ProductUnitPerPack: providerOrder.Order.ProductUnitPerPack,
                  ProductUnitAmount: providerOrder.Order.ProductUnitAmount,
                  ProductUnitMesure: providerOrder.Order.ProductUnitMesure,
                  ProductBidDay: providerOrder.Order.ProductBidDay,
                  ProductOrderAmount: providerOrder.Order.ProductOrderAmount,
                };
                OrderProducts.push(OrderToSubmit);
              }
            });
          }
        );
      }
      const currentRestaurantConfiguration = {
        RestaurantGUID: this.state.Restaurant.ref,
        SelectedAreasRefs: SelectedAreasRefs
          ? SelectedAreasRefs
          : this.state.SelectedAreasRefs,
        LastTimeStored: this.Api.TimestampNow(),
        OrderProducts,
      };
      RestaurantConfigurations.push(currentRestaurantConfiguration);
      this.Api.updDoc(
        Profile.ref?.id as string,
        {
          RestaurantConfigurations,
        },
        ProfileCollectionPath
      ).then(() => this.setState({ isSaving: false }));
    });
  }
  componentDidUpdate(
    prevProps: INewOrderTableProps,
    prevState: INewOrderTableState,
    snapshot: any
  ) {
    if (
      this.props.currentRestaurant &&
      prevProps.currentRestaurant &&
      this.props.currentRestaurant.ref?.id !==
        prevProps.currentRestaurant.ref?.id
    ) {
      this.setRestaurantConfigurations();
    }
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.getAllData();
    }
  }
  amountChanged = (
    ProductGUIDRef: string,
    ProviderIndex: number,
    ProductOrderAmount: number
  ) => {
    let ProductsOrderDataNotFilter: Array<IOrderItemModel> = this.state
      .ProductsOrderDataNotFilter;
    let ProductsOrderData: Array<IOrderItemModel> = this.state
      .ProductsOrderData;
    const ProductIndexNotFilter: number = ProductsOrderDataNotFilter.findIndex(
      (Order: IOrderItemModel) => Order.Product.ref?.id === ProductGUIDRef
    );
    const ProductIndex: number = ProductsOrderData.findIndex(
      (Order: IOrderItemModel) => Order.Product.ref?.id === ProductGUIDRef
    );
    ProductsOrderDataNotFilter[ProductIndexNotFilter].ProviderOrders[
      ProviderIndex
    ].Order.ProductOrderAmount = ProductOrderAmount;
    ProductsOrderData[ProductIndex].ProviderOrders[
      ProviderIndex
    ].Order.ProductOrderAmount = ProductOrderAmount;
    let isOrderAndOrReturnedAmount: boolean = false;
    if (ProductOrderAmount) {
      isOrderAndOrReturnedAmount = true;
    } else {
      this.state.ProductsOrderDataNotFilter.forEach(
        (Order: IOrderItemModel) => {
          Order.ProviderOrders.forEach((providerOrder: IProviderOrder) => {
            if (providerOrder.Order.ProductOrderAmount) {
              isOrderAndOrReturnedAmount = true;
              return;
            }
          });
        }
      );
    }
    this.setState({
      ProductsOrderDataNotFilter,
      ProductsOrderData,
      isOrderAndOrReturnedAmount,
    });
  };
  deliveryChanged = (date: Date | null | undefined) => {
    if (date) {
      this.setState({ ProductOrderDelivery: date });
    } else {
      this.setState({ ProductOrderDelivery: undefined });
    }
  };
  handleShowSubmitDialog = () => {
    let ProductsOrderToSubmit: Array<IRestaurantItemOrder> = [];
    let ProductsOrderToReturn: Array<IRestaurantItemOrder> = [];
    this.state.ProductsOrderDataNotFilter.forEach((Order: IOrderItemModel) => {
      Order.ProviderOrders.forEach((providerOrder: IProviderOrder) => {
        if (providerOrder.Order.ProductOrderAmount > 0) {
          let OrderToSubmit: IRestaurantItemOrder = {
            AreaGUID: Order.Area.ref,
            ProductGUID: Order.Product.ref,
            ProviderGUID: providerOrder.ProviderGUID,
            ProductDescription: providerOrder.Order.ProductDescription,
            ProductPackPrice: providerOrder.Order.ProductPackPrice,
            ProductUnitPerPack: providerOrder.Order.ProductUnitPerPack,
            ProductUnitAmount: providerOrder.Order.ProductUnitAmount,
            ProductUnitMesure: providerOrder.Order.ProductUnitMesure,
            ProductBidDay: providerOrder.Order.ProductBidDay,
            ProductOrderAmount: providerOrder.Order.ProductOrderAmount,
          };
          ProductsOrderToSubmit.push(OrderToSubmit);
        }
        if (providerOrder.Order.ProductOrderAmount < 0) {
          let OrderToReturn: IRestaurantItemOrder = {
            AreaGUID: Order.Area.ref,
            ProductGUID: Order.Product.ref,
            ProviderGUID: providerOrder.ProviderGUID,
            ProductDescription: providerOrder.Order.ProductDescription,
            ProductPackPrice: providerOrder.Order.ProductPackPrice,
            ProductUnitPerPack: providerOrder.Order.ProductUnitPerPack,
            ProductUnitAmount: providerOrder.Order.ProductUnitAmount,
            ProductUnitMesure: providerOrder.Order.ProductUnitMesure,
            ProductBidDay: providerOrder.Order.ProductBidDay,
            ProductOrderAmount: providerOrder.Order.ProductOrderAmount * -1,
          };
          ProductsOrderToReturn.push(OrderToReturn);
        }
      });
    });
    this.setState({
      ProductsOrderToSubmit,
      ProductsOrderToReturn,
      isSubmitDialogHidden: false,
    });
  };
  handleHideSubmitDialog = () => {
    this.setState({ isSubmitDialogHidden: true });
  };
  handleSubmit = async (notify?: boolean) => {
    this.setState({ isSubmitting: true });
    if (this.state.ProductOrderDelivery) {
      let RestaurantOrder: IRestaurantOrder = {
        OrderProfile: this.props.currentProfile?.ref,
        OrderDate: this.Api.TimestampNow() as any,
        OrderDelivery: this.Api.TimestampFromDate(
          this.state.ProductOrderDelivery
        ) as any,
        OrderTotal: Number(
          this.renderRowSubmitTotal(this.state.ProductsOrderToSubmit, true)
        ),
        OrderPacks: Number(
          this.renderRowSubmitTotal(
            this.state.ProductsOrderToSubmit,
            true,
            true
          )
        ),
        OrderProducts: this.state.ProductsOrderToSubmit.map(
          (OrderProduct: IRestaurantItemOrder) => {
            return {
              ...OrderProduct,
              ProductOrderRequireCheck: true,
            };
          }
        ),
        ReturnedProducts: this.state.ProductsOrderToReturn.map(
          (ReturnedProduct: IRestaurantItemOrder) => {
            return {
              ...ReturnedProduct,
              ProductOrderRequireCheck: true,
            };
          }
        ),
        OrderRequireCheck: true,
      };
      const OrderCreatedRef = await this.Api.addDoc(
        this.state.Restaurant.RestaurantSubCollectionPaths
          .RestaurantItemOrderCollectionPath,
        RestaurantOrder
      );
      this.handleCheckStock(OrderCreatedRef.id, notify);
      let ProvidersOrdersEmails: Array<{
        Provider: IProvider;
        ProductsOrderToSubmit: Array<IRestaurantItemOrder>;
        ProductsOrderToReturn: Array<IRestaurantItemOrder>;
      }> = [];
      this.state.ProviderCollection.forEach((Provider: IProvider) => {
        let ProductsOrderToSubmit: Array<IRestaurantItemOrder> = this.state.ProductsOrderToSubmit.filter(
          (OrderProduct: IRestaurantItemOrder) =>
            OrderProduct.ProviderGUID?.id === Provider.ref?.id
        );
        let ProductsOrderToReturn: Array<IRestaurantItemOrder> = this.state.ProductsOrderToReturn.filter(
          (OrderProduct: IRestaurantItemOrder) =>
            OrderProduct.ProviderGUID?.id === Provider.ref?.id
        );
        ProvidersOrdersEmails.push({
          Provider,
          ProductsOrderToSubmit,
          ProductsOrderToReturn,
        });
      });
      if (notify) {
        //[Self] [Email]
        let promiseArraySendEmails: Array<Promise<any>> = [];
        promiseArraySendEmails.push(
          this.Api.sendEmail(
            EmailCreationManager.newOrderNotify(
              this.props.currentProfile as {
                ProfileContactName: string;
                ProfileContactEmail: string;
              },
              this.state.ProductsOrderToSubmit,
              this.state.ProductsOrderToReturn,
              this.state.ProductCollection,
              this.state.ProviderCollection,
              this.state.Restaurant.RestaurantName,
              this.state.ProductOrderDelivery,
              OrderCreatedRef.id
            )
          )
        );
        //[Self] [Email] [Reminder]
        promiseArraySendEmails.push(
          this.Api.sendEmail(
            EmailCreationManager.newOrderNotify(
              this.props.currentProfile as {
                ProfileContactName: string;
                ProfileContactEmail: string;
              },
              this.state.ProductsOrderToSubmit,
              this.state.ProductsOrderToReturn,
              this.state.ProductCollection,
              this.state.ProviderCollection,
              this.state.Restaurant.RestaurantName,
              this.state.ProductOrderDelivery,
              OrderCreatedRef.id,
              false,
              true
            )
          )
        );
        //[Self] [Text]
        // promiseArraySendEmails.push(
        //   this.Api.sendTextSMS(
        //     await TextSMSCreationManager.newOrderNotify(
        //       this.Api,
        //       this.props.currentProfile as {
        //         ProfileContactName: string;
        //         ProfileContactPhone: number;
        //       },
        //       this.state.ProductsOrderToSubmit,
        //       this.state.ProductsOrderToReturn,
        //       this.state.ProductCollection,
        //       this.state.ProviderCollection,
        //       this.state.Restaurant,
        //       this.state.ProductOrderDelivery,
        //       OrderCreatedRef.id,
        //       "Self"
        //     )
        //   )
        // );
        //[Self] [Text] [Reminder]
        // promiseArraySendEmails.push(
        //   this.Api.sendTextSMS(
        //     await TextSMSCreationManager.newOrderNotify(
        //       this.Api,
        //       this.props.currentProfile as {
        //         ProfileContactName: string;
        //         ProfileContactPhone: number;
        //       },
        //       this.state.ProductsOrderToSubmit,
        //       this.state.ProductsOrderToReturn,
        //       this.state.ProductCollection,
        //       this.state.ProviderCollection,
        //       this.state.Restaurant,
        //       this.state.ProductOrderDelivery,
        //       OrderCreatedRef.id,
        //       "Self",
        //       true
        //     )
        //   )
        // );
        //[Staff NewOrder] [Email]
        if (this.state.ProductsOrderToSubmit.length > 0) {
          this.state.Restaurant.RestaurantStaffNewOrderNotify.forEach(
            async (Staff: RestaurantStaff) => {
              promiseArraySendEmails.push(
                this.Api.sendEmail(
                  EmailCreationManager.newOrderNotify(
                    Staff,
                    this.state.ProductsOrderToSubmit,
                    this.state.ProductsOrderToReturn,
                    this.state.ProductCollection,
                    this.state.ProviderCollection,
                    this.state.Restaurant.RestaurantName,
                    this.state.ProductOrderDelivery,
                    OrderCreatedRef.id
                  )
                )
              );
              //[Staff NewOrder] [Email] [Reminder]
              promiseArraySendEmails.push(
                this.Api.sendEmail(
                  EmailCreationManager.newOrderNotify(
                    Staff,
                    this.state.ProductsOrderToSubmit,
                    this.state.ProductsOrderToReturn,
                    this.state.ProductCollection,
                    this.state.ProviderCollection,
                    this.state.Restaurant.RestaurantName,
                    this.state.ProductOrderDelivery,
                    OrderCreatedRef.id,
                    false,
                    true
                  )
                )
              );
              //[Staff NewOrder] [Text]
              // promiseArraySendEmails.push(
              //   this.Api.sendTextSMS(
              //     await TextSMSCreationManager.newOrderNotify(
              //       this.Api,
              //       Staff,
              //       this.state.ProductsOrderToSubmit,
              //       this.state.ProductsOrderToReturn,
              //       this.state.ProductCollection,
              //       this.state.ProviderCollection,
              //       this.state.Restaurant,
              //       this.state.ProductOrderDelivery,
              //       OrderCreatedRef.id,
              //       "NewOrder"
              //     )
              //   )
              // );
              //[Staff NewOrder] [Text] [Reminder]
              // promiseArraySendEmails.push(
              //   this.Api.sendTextSMS(
              //     await TextSMSCreationManager.newOrderNotify(
              //       this.Api,
              //       Staff,
              //       this.state.ProductsOrderToSubmit,
              //       this.state.ProductsOrderToReturn,
              //       this.state.ProductCollection,
              //       this.state.ProviderCollection,
              //       this.state.Restaurant,
              //       this.state.ProductOrderDelivery,
              //       OrderCreatedRef.id,
              //       "NewOrder",
              //       true
              //     )
              //   )
              // );
            }
          );
        }
        //[Staff ReturnOrder] [Email]
        if (this.state.ProductsOrderToReturn.length > 0) {
          this.state.Restaurant.RestaurantStaffReturnOrderNotify.forEach(
            async (Staff: RestaurantStaff) => {
              promiseArraySendEmails.push(
                this.Api.sendEmail(
                  EmailCreationManager.newOrderNotify(
                    Staff,
                    [],
                    this.state.ProductsOrderToReturn,
                    this.state.ProductCollection,
                    this.state.ProviderCollection,
                    this.state.Restaurant.RestaurantName,
                    this.state.ProductOrderDelivery,
                    OrderCreatedRef.id
                  )
                )
              );
              //[Staff ReturnOrder] [Email] [Reminder]
              promiseArraySendEmails.push(
                this.Api.sendEmail(
                  EmailCreationManager.newOrderNotify(
                    Staff,
                    [],
                    this.state.ProductsOrderToReturn,
                    this.state.ProductCollection,
                    this.state.ProviderCollection,
                    this.state.Restaurant.RestaurantName,
                    this.state.ProductOrderDelivery,
                    OrderCreatedRef.id,
                    false,
                    true
                  )
                )
              );
              //[Staff ReturnOrder] [Text]
              // promiseArraySendEmails.push(
              //   this.Api.sendTextSMS(
              //     await TextSMSCreationManager.newOrderNotify(
              //       this.Api,
              //       Staff,
              //       [],
              //       this.state.ProductsOrderToReturn,
              //       this.state.ProductCollection,
              //       this.state.ProviderCollection,
              //       this.state.Restaurant,
              //       this.state.ProductOrderDelivery,
              //       OrderCreatedRef.id,
              //       "ReturnOrder"
              //     )
              //   )
              // );
              //[Staff ReturnOrder] [Text] [Reminder]
              // promiseArraySendEmails.push(
              //   this.Api.sendTextSMS(
              //     await TextSMSCreationManager.newOrderNotify(
              //       this.Api,
              //       Staff,
              //       [],
              //       this.state.ProductsOrderToReturn,
              //       this.state.ProductCollection,
              //       this.state.ProviderCollection,
              //       this.state.Restaurant,
              //       this.state.ProductOrderDelivery,
              //       OrderCreatedRef.id,
              //       "ReturnOrder",
              //       true
              //     )
              //   )
              // );
            }
          );
        }
        //[Vendor] [Email]
        ProvidersOrdersEmails.forEach(
          async (ProvidersOrdersEmail: {
            Provider: IProvider;
            ProductsOrderToSubmit: Array<IRestaurantItemOrder>;
            ProductsOrderToReturn: Array<IRestaurantItemOrder>;
          }) => {
            if (
              ProvidersOrdersEmail.ProductsOrderToSubmit.length > 0 ||
              ProvidersOrdersEmail.ProductsOrderToReturn.length > 0
            ) {
              promiseArraySendEmails.push(
                this.Api.sendEmail(
                  EmailCreationManager.newOrderNotify(
                    {
                      ProfileContactEmail:
                        ProvidersOrdersEmail.Provider.ProviderContactEmail,
                      ProfileContactName:
                        ProvidersOrdersEmail.Provider.ProviderContactName,
                    },
                    ProvidersOrdersEmail.ProductsOrderToSubmit,
                    ProvidersOrdersEmail.ProductsOrderToReturn,
                    this.state.ProductCollection,
                    this.state.ProviderCollection,
                    this.state.Restaurant.RestaurantName,
                    this.state.ProductOrderDelivery,
                    OrderCreatedRef.id,
                    true
                  )
                )
              );
              //[Vendor] [Email] [Reminder]
              promiseArraySendEmails.push(
                this.Api.sendEmail(
                  EmailCreationManager.newOrderNotify(
                    {
                      ProfileContactEmail:
                        ProvidersOrdersEmail.Provider.ProviderContactEmail,
                      ProfileContactName:
                        ProvidersOrdersEmail.Provider.ProviderContactName,
                    },
                    ProvidersOrdersEmail.ProductsOrderToSubmit,
                    ProvidersOrdersEmail.ProductsOrderToReturn,
                    this.state.ProductCollection,
                    this.state.ProviderCollection,
                    this.state.Restaurant.RestaurantName,
                    this.state.ProductOrderDelivery,
                    OrderCreatedRef.id,
                    true,
                    true
                  )
                )
              );
              //[Vendor] [Text]
              // promiseArraySendEmails.push(
              //   this.Api.sendTextSMS(
              //     await TextSMSCreationManager.newOrderNotify(
              //       this.Api,
              //       {
              //         ProfileContactPhone:
              //           ProvidersOrdersEmail.Provider.ProviderContactPhone,
              //         ProfileContactName:
              //           ProvidersOrdersEmail.Provider.ProviderContactName,
              //       },
              //       ProvidersOrdersEmail.ProductsOrderToSubmit,
              //       ProvidersOrdersEmail.ProductsOrderToReturn,
              //       this.state.ProductCollection,
              //       this.state.ProviderCollection,
              //       this.state.Restaurant,
              //       this.state.ProductOrderDelivery,
              //       OrderCreatedRef.id,
              //       ProvidersOrdersEmail.Provider.ref?.id as string
              //     )
              //   )
              // );
              //[Vendor] [Text] [Reminder]
              // promiseArraySendEmails.push(
              //   this.Api.sendTextSMS(
              //     await TextSMSCreationManager.newOrderNotify(
              //       this.Api,
              //       {
              //         ProfileContactPhone:
              //           ProvidersOrdersEmail.Provider.ProviderContactPhone,
              //         ProfileContactName:
              //           ProvidersOrdersEmail.Provider.ProviderContactName,
              //       },
              //       ProvidersOrdersEmail.ProductsOrderToSubmit,
              //       ProvidersOrdersEmail.ProductsOrderToReturn,
              //       this.state.ProductCollection,
              //       this.state.ProviderCollection,
              //       this.state.Restaurant,
              //       this.state.ProductOrderDelivery,
              //       OrderCreatedRef.id,
              //       ProvidersOrdersEmail.Provider.ref?.id as string,
              //       true
              //     )
              //   )
              // );
            }
          }
        );
        Promise.all(promiseArraySendEmails);
      }
      this.setRestaurantConfigurations(true);
      this.getAllData("Reset");
    }
  };
  handleShowUpdateDialog = () => {
    this.setState({ isUpdateDialogHidden: false });
  };
  handleHideUpdateDialog = () => {
    if (!this.state.isSubmitting) {
      this.setState({ isUpdateDialogHidden: true });
    }
  };
  handleUpdate = () => {
    this.setState({ isUpdateDialogHidden: true });
    this.setRestaurantConfigurations(true);
    this.getAllData("Reset");
  };
  handleCheckStock = async (OrderChangedRef: string, notify?: boolean) => {
    let ProductCollectionStockChanged: Array<IRestaurantItem> = [];
    this.state.ProductCollection.forEach((product) => {
      let newDoc = { ...product };
      let newDocStockChanged = false;
      let newDocStockProviderGUIDsChanged: Array<string> = [];
      if (product.ProductStockLeft && product.ProductStockLeft.length > 0) {
        product.ProductStockLeft.forEach((ProviderStockLeft, index) => {
          const orderProduct = this.state.ProductsOrderDataNotFilter.find(
            (p) => p.Product.ref?.id === product.ref?.id
          )?.ProviderOrders.find(
            (p) => p.ProviderGUID?.id === ProviderStockLeft.ProviderGUID?.id
          );
          if (
            newDoc &&
            newDoc.ProductStockLeft &&
            orderProduct &&
            orderProduct.Order.ProductOrderAmount
          ) {
            newDoc.ProductStockLeft[index].StockLeft -=
              orderProduct.Order.ProductOrderAmount;
            newDocStockChanged = true;
            newDocStockProviderGUIDsChanged.push(
              ProviderStockLeft.ProviderGUID?.id as string
            );
          }
        });
        if (newDocStockChanged) {
          this.Api.updDoc(
            product.ref?.id as string,
            { ProductStockLeft: newDoc.ProductStockLeft },
            this.state.Restaurant.RestaurantSubCollectionPaths
              .RestaurantItemCollectionPath
          );
          newDoc.ProductStockLeft = newDoc.ProductStockLeft?.filter((s) =>
            newDocStockProviderGUIDsChanged.includes(
              s.ProviderGUID?.id as string
            )
          );
          ProductCollectionStockChanged.push(newDoc);
        }
      }
    });
    // [Staff ProductStockLeft] [Email]
    if (notify) {
      let promiseArraySendEmails: Array<Promise<any>> = [];
      if (ProductCollectionStockChanged.length > 0) {
        this.state.Restaurant.RestaurantStaffProductStockLeftNotify.forEach(
          async (profile) => {
            promiseArraySendEmails.push(
              this.Api.sendEmail(
                EmailCreationManager.productStockLeftNotify(
                  profile,
                  this.state.ProviderCollection,
                  ProductCollectionStockChanged,
                  this.props.currentRestaurant?.RestaurantName as string
                )
              )
            );
            // [Staff ProductStockLeft] [Text]
            // promiseArraySendEmails.push(
            //   this.Api.sendTextSMS(
            //     await TextSMSCreationManager.productStockLeftNotify(
            //       this.Api,
            //       profile,
            //       this.state.ProviderCollection,
            //       ProductCollectionStockChanged,
            //       this.props.currentRestaurant as IRestaurantCG,
            //       OrderChangedRef
            //     )
            //   )
            // );
          }
        );
      }
      Promise.all(promiseArraySendEmails);
    }
  };
  renderRows(Orders: Array<IOrderItemModel>): Array<JSX.Element> {
    return Orders.map((Order: IOrderItemModel, index: number) => (
      <OrderItemTableRow
        key={`NewOrderTableRow${Order.Product.ref?.id}`}
        index={index}
        OrderItem={Order}
        amountChanged={this.amountChanged}
      />
    ));
  }
  renderRowsSubmit(Orders: Array<IRestaurantItemOrder>): Array<JSX.Element> {
    return Orders.map((OrderProduct: IRestaurantItemOrder, index: number) => {
      const Product: IRestaurantItem = this.state.ProductCollection.find(
        (product) => product.ref?.id === OrderProduct.ProductGUID?.id
      ) as IRestaurantItem;
      const Provider: IProvider = this.state.ProviderCollection.find(
        (provider) => provider.ref?.id === OrderProduct.ProviderGUID?.id
      ) as IProvider;
      return (
        <tr
          style={{
            backgroundColor: index % 2 ? "#DCDCDC" : "white",
            color: "black",
          }}
          key={`NewOrderTableRowSubmit${OrderProduct.ProviderGUID?.id}${OrderProduct.ProductGUID?.id}`}
        >
          <td>{Provider ? Provider.ProviderName : "N/A"}</td>
          <td>{Product ? Product.ProductName : "N/A"}</td>
          <td>{`$${OrderProduct.ProductPackPrice}`}</td>
          <td style={{ fontWeight: "bold" }}>
            {OrderProduct.ProductOrderAmount}
          </td>
          <td style={{ fontWeight: "bold" }}>{`$${(
            OrderProduct.ProductPackPrice * OrderProduct.ProductOrderAmount
          ).toFixed(2)}`}</td>
        </tr>
      );
    });
  }
  renderRowSubmitTotal(
    Orders: Array<IRestaurantItemOrder>,
    numberResponse?: boolean,
    packsResponse?: boolean,
    returnResponse?: boolean
  ): JSX.Element | number {
    let total: number = 0;
    if (packsResponse) {
      Orders.forEach((Order: IRestaurantItemOrder) => {
        total += Order.ProductOrderAmount;
      });
    } else {
      Orders.forEach((Order: IRestaurantItemOrder) => {
        total += Order.ProductPackPrice * Order.ProductOrderAmount;
      });
    }
    return numberResponse ? (
      total
    ) : (
      <tr key={"OrderTotal"}>
        <td style={{ textAlign: "left", fontWeight: "bold" }}>
          {returnResponse ? "Returned Total:" : "Order Total:"}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td
          style={{ textAlign: "right", fontWeight: "bold" }}
        >{`$${total.toFixed(2)}`}</td>
      </tr>
    );
  }
  filterByProduct(
    ProductsOrderDataNotFilter: Array<IOrderItemModel>,
    ProductFilter: string,
    AreasRefs?: Array<string>
  ): Array<IOrderItemModel> {
    let ProductsOrderDataFiltered: Array<IOrderItemModel> = ProductsOrderDataNotFilter;
    if (ProductFilter !== "") {
      const filterIds: Array<string> = this.state.ProductCollection.filter(
        (p) => p.ProductName.includes(removeSpaces(ProductFilter))
      ).map((p) => p.ref?.id) as Array<string>;
      ProductsOrderDataFiltered = ProductsOrderDataFiltered.filter(
        (Order) =>
          filterIds.indexOf(Order.Product.ref?.id as string) !== -1 ||
          Order.ProviderOrders.findIndex((o) =>
            o.Order.ProductDescription.toString()
              .toUpperCase()
              .includes(ProductFilter)
          ) !== -1
      );
    }
    if (AreasRefs?.length) {
      ProductsOrderDataFiltered = ProductsOrderDataFiltered.filter(
        (Order) => AreasRefs.indexOf(Order.Area.ref?.id as string) !== -1
      );
    }
    return ProductsOrderDataFiltered;
  }
  render(): JSX.Element {
    const OrderTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const OrderTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    return (
      <div>
        <span style={{ position: "fixed", bottom: 55, right: 10 }}>
          <PrimaryButton
            onClick={this.handleShowSubmitDialog}
            iconProps={{ iconName: "Subscribe" }}
            disabled={
              !this.state.ProductOrderDelivery ||
              !this.state.isOrderAndOrReturnedAmount ||
              this.state.isSubmitting ||
              this.state.isLoading
            }
            text="Submit Order"
          />
        </span>
        <span style={{ position: "fixed", bottom: 15, right: 10 }}>
          <DatePicker
            placeholder="Select a Delivery Date..."
            ariaLabel="Select a Delivery Date"
            minDate={new Date()}
            style={{ width: "200px" }}
            value={this.state.ProductOrderDelivery}
            onSelectDate={this.deliveryChanged}
          />
        </span>
        <span style={{ position: "fixed", bottom: 55, left: 10 }}>
          <DefaultButton
            onClick={() => this.setRestaurantConfigurations()}
            disabled={this.state.isSaving}
            iconProps={{ iconName: "Save" }}
          >
            {this.state.isSaving ? (
              <span>
                <Spinner size={SpinnerSize.small} />
                Saving...
              </span>
            ) : (
              "Save"
            )}
          </DefaultButton>
        </span>
        <span style={{ position: "fixed", bottom: 55, left: 110 }}>
          <DefaultButton
            onClick={this.handleShowUpdateDialog}
            disabled={this.state.isSaving}
            text="Clear"
            iconProps={{ iconName: "Delete" }}
          />
        </span>
        <span style={{ position: "fixed", bottom: 15, left: 10 }}>
          <Dropdown
            id={`-1 AreaFilter`}
            placeholder="Filter By Area..."
            selectedKeys={this.state.SelectedAreasRefs}
            onChange={(event, option) => {
              if (option) {
                let SelectedAreasRefs: Array<string> | undefined = this.state
                  .SelectedAreasRefs;
                SelectedAreasRefs =
                  option?.selected === true
                    ? SelectedAreasRefs?.concat([option.key as string])
                    : SelectedAreasRefs?.filter((key) => key !== option.key);
                this.setState({
                  ProductsOrderData: this.filterByProduct(
                    this.state.ProductsOrderDataNotFilter,
                    this.state.ProductSearch,
                    SelectedAreasRefs
                  ),
                  SelectedAreasRefs,
                });
                this.setRestaurantConfigurations(false, SelectedAreasRefs);
              }
            }}
            multiSelect
            options={this.state.AreaCollectionOptions}
            style={{ width: "250px" }}
          />
        </span>
        <span style={{ position: "fixed", top: 75, left: 0, width: "100%" }}>
          <table style={OrderTableStyle}>
            <thead style={OrderTableHeadStyle}>
              <tr>
                <th style={{ width: "16%" }}>Area</th>
                <th style={{ width: "20%" }}>
                  <SearchBox
                    id={`-1 ProductSearch`}
                    value={this.state.ProductSearch}
                    placeholder="Search Item..."
                    onChange={(event, value) => {
                      const ProductSearch: string = value
                        ? value.toUpperCase()
                        : "";
                      this.setState({
                        ProductsOrderData: this.filterByProduct(
                          this.state.ProductsOrderDataNotFilter,
                          ProductSearch,
                          this.state.SelectedAreasRefs
                        ),
                        ProductSearch,
                      });
                    }}
                  />
                </th>
                <th style={{ width: "6%" }}>Amount Ordered</th>
                <th style={{ width: "12%" }}>Vendor</th>
                <th style={{ width: "8%" }}>Case Price</th>
                <th style={{ width: "10%" }}>Units per Case</th>
                <th style={{ width: "20%" }}>Description</th>
                <th style={{ width: "8%" }}>Bid Day</th>
              </tr>
            </thead>
          </table>
        </span>
        <div
          style={{ height: this.props.innerWidth > 1000 ? "40px" : "60px" }}
        />
        <table style={OrderTableStyle}>
          <tbody>
            {this.state.isLoading ? (
              <tr>
                <td>
                  <Spinner size={SpinnerSize.large} />
                </td>
              </tr>
            ) : this.state.ProductsOrderData.length ? (
              this.renderRows(this.state.ProductsOrderData)
            ) : (
              <tr>
                <td>
                  <Label>No Results</Label>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <div style={{ height: "100px" }} />
              </td>
            </tr>
          </tbody>
        </table>
        <Dialog
          hidden={this.state.isSubmitDialogHidden}
          onDismiss={this.handleHideSubmitDialog}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: "Submit Order?",
            closeButtonAriaLabel: "Close",
            subText: "Do you want to submit this Order now?",
          }}
          modalProps={{
            containerClassName: "ms-dialogMainOverride textDialog",
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          {this.state.ProductsOrderToSubmit.length > 0 && (
            <span>
              <table style={OrderTableStyle}>
                <thead style={OrderTableHeadStyle}>
                  <tr>
                    <th>Vendor</th>
                    <th>Item</th>
                    <th>Case Price</th>
                    <th>Amount Ordered</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderRowsSubmit(this.state.ProductsOrderToSubmit)}
                </tbody>
              </table>
              <hr />
              <table>
                <tfoot>
                  {this.renderRowSubmitTotal(this.state.ProductsOrderToSubmit)}
                </tfoot>
              </table>
              <hr />
            </span>
          )}
          {this.state.ProductsOrderToSubmit.length > 0 &&
            this.state.ProductsOrderToReturn.length > 0 && (
              <span>
                <br />
                <br />
              </span>
            )}
          {this.state.ProductsOrderToReturn.length > 0 && (
            <span>
              <p>
                <b>* The following products are marked for pick up</b>
              </p>
              <table style={OrderTableStyle}>
                <thead style={OrderTableHeadStyle}>
                  <tr>
                    <th>Vendor</th>
                    <th>Item</th>
                    <th>Case Price</th>
                    <th>Amount Returned</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderRowsSubmit(this.state.ProductsOrderToReturn)}
                </tbody>
              </table>
              <hr />
              <table>
                <tfoot>
                  {this.renderRowSubmitTotal(
                    this.state.ProductsOrderToReturn,
                    false,
                    false,
                    true
                  )}
                </tfoot>
              </table>
              <hr />
            </span>
          )}
          <DialogFooter>
            <PrimaryButton
              onClick={() => this.handleSubmit(true)}
              disabled={this.state.isSubmitting}
            >
              {this.state.isSubmitting ? (
                <Spinner size={SpinnerSize.small} />
              ) : (
                "Submit Now"
              )}
            </PrimaryButton>
            {this.props.currentProfile &&
              this.props.currentProfile.ProfileRole === ProfileRoles.Dev && (
                <DefaultButton
                  onClick={() => this.handleSubmit()}
                  disabled={this.state.isSubmitting}
                  text="Submit Now (Dont Notify)"
                />
              )}
            <DefaultButton
              disabled={this.state.isSubmitting}
              onClick={this.handleHideSubmitDialog}
              text="Don't Submit"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={this.state.isUpdateDialogHidden}
          onDismiss={this.handleHideUpdateDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Clear Order?",
            closeButtonAriaLabel: "Close",
            subText:
              "The Current Order Saved for this Restaurant will be Cleared",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleUpdate} text="Clear Now" />
            <DefaultButton
              onClick={this.handleHideUpdateDialog}
              text="Don't Clear"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={this.state.isSavedDialogHidden}
          onDismiss={() => this.setState({ isSavedDialogHidden: true })}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Stored Order",
            closeButtonAriaLabel: "Close",
            subText: `You have a Stored Order from ${
              this.state.currentRestaurantConfiguration &&
              this.state.currentRestaurantConfiguration.LastTimeStored
                ? PrintDate(
                    this.state.currentRestaurantConfiguration.LastTimeStored
                  )
                : "N/A"
            } with ${
              this.state.currentRestaurantConfiguration?.OrderProducts?.length
            } ${
              this.state.currentRestaurantConfiguration?.OrderProducts
                ?.length === 1
                ? "product"
                : "products"
            }`,
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => this.getAllData("Lowest")}
              text="Update with Lowest Prices"
            />
            <DefaultButton
              onClick={() => this.setState({ isSavedDialogHidden: true })}
              text="Dismiss"
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
