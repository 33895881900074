import React, { Component } from "react";
import IApi from "../../api/IApi";
import { IRestaurantCG } from "../../models/IFirestoreModels";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import firebase from "firebase/app";
import CRUD, { CRUDPropertyType } from "../DevelopmentView/CRUD";
import ReactHtmlParser from "react-html-parser";
import { PrintDate, PrintTime } from "../../util/functions";
import { IEmail } from "../../models/ICommunication";

interface ModelEditorMailProps {
  currentUser: firebase.User | null;
  Api: IApi;
  Restaurant: IRestaurantCG;
}
interface ModelEditorMailState {
  isLoading: boolean;
  MailCollection: Array<IEmail>;
  nowMailCollection: Array<IEmail>;
  pendingMailCollection: Array<IEmail>;
  MailSelectedRef: string;
}
export default class ModelEditorMail extends Component<
  ModelEditorMailProps,
  ModelEditorMailState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      MailCollection: [],
      nowMailCollection: [],
      pendingMailCollection: [],
      MailSelectedRef: "",
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (this.props.currentUser && this.props.Restaurant) {
      this.Api.getCollect(
        "mail",
        undefined,
        (nowMailCollection) => {
          this.setState({
            MailCollection: nowMailCollection.concat(
              this.state.pendingMailCollection
            ),
            nowMailCollection,
            isLoading: false,
          });
        },
        "EmailSendOn",
        "desc",
        20
      );
      this.Api.getCollect(
        "mailPending",
        undefined,
        (pendingMailCollection) => {
          this.setState({
            MailCollection: this.state.nowMailCollection.concat(
              pendingMailCollection
            ),
            pendingMailCollection,
            isLoading: false,
          });
        },
        "EmailSendOn",
        "desc",
        20
      );
    }
  }
  componentDidUpdate(
    prevProps: ModelEditorMailProps,
    prevState: ModelEditorMailState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.Restaurant?.RestaurantSubCollectionPaths !==
        prevProps.Restaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  render(): JSX.Element {
    const MailSelected = this.state.MailCollection.find(
      (m: any) => m.ref?.path === this.state.MailSelectedRef
    );
    return (
      <div>
        {this.state.isLoading ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : (
          <CRUD
            List={this.state.MailCollection.map((m) => {
              return {
                ...m,
                ref: m.ref?.path,
                viewContent: (
                  <span>
                    <Icon iconName={"View"} /> View Content
                  </span>
                ),
                EmailSendOn:
                  m.EmailSendOn &&
                  `${PrintDate(m.EmailSendOn)} ${PrintTime(m.EmailSendOn)}`,
                EmailTriggerDate:
                  m.EmailTriggerDate &&
                  `${PrintDate(m.EmailTriggerDate)} ${PrintTime(
                    m.EmailTriggerDate
                  )}`,
                EmailDelivery:
                  m.EmailDelivery &&
                  `${PrintDate(m.EmailDelivery)} ${PrintTime(m.EmailDelivery)}`,
              };
            })}
            ItemName={"Mail"}
            DisplayField={"EmailSubject"}
            Structure={[
              {
                Type: CRUDPropertyType.ID,
                Name: "ref",
              },
              {
                Type: CRUDPropertyType.Enum,
                Name: "EmailStatus",
                DisplayName: "Status",
                Enforced: true,
                IconName: "Refresh",
                Width: "10%",
                Settings: {
                  Choices: [
                    { key: "PENDING", text: "PENDING", value: "PENDING" },
                    {
                      key: "PROCESSING",
                      text: "PROCESSING",
                      value: "PROCESSING",
                    },
                    { key: "SUCCESS", text: "SUCCESS", value: "SUCCESS" },
                    { key: "ERROR", text: "ERROR", value: "ERROR" },
                    { key: "RETRY", text: "RETRY", value: "RETRY" },
                  ],
                },
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Email,
                Name: "EmailTo",
                Enforced: true,
                DisplayName: "To",
                IconName: "People",
                Width: "20%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "EmailSendOn",
                Enforced: true,
                DisplayName: "Queue On",
                IconName: "Clock",
                Width: "10%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "EmailTriggerDate",
                DisplayName: "Trigger Date",
                IconName: "Clock",
                Width: "10%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "EmailDelivery",
                DisplayName: "Delivery Date",
                IconName: "Clock",
                Width: "10%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.String,
                Name: "EmailSubject",
                DisplayName: "Subject",
                IconName: "Message",
                Width: "10%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "viewContent",
                DisplayName: "Content",
                IconName: "Mail",
                Width: "10%",
                Settings: { Cursor: true },
              },
              {
                Type: CRUDPropertyType.Control,
                Name: "Control",
                Width: "20%",
              },
            ]}
            onClick={(item, col) => {
              if (col.Name === "viewContent") {
                this.setState({
                  MailSelectedRef: item.ref as string,
                });
              }
            }}
            UpdateAll={true}
            UpdateConfirm={true}
            onUpdate={async (updates) => {
              // console.log("UPD", updates);
              try {
                let promiseUpdates: Array<Promise<void>> = [];
                let promiseDelete: Array<Promise<void>> = [];
                updates.forEach(async (u) => {
                  const isNow = this.state.nowMailCollection.find(
                    (m) => m.ref?.path === u.id
                  );
                  const isPending = this.state.pendingMailCollection.find(
                    (m) => m.ref?.path === u.id
                  );
                  let newRef:
                    | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
                    | undefined = undefined;
                  if (u.changes.EmailStatus !== undefined) {
                    if (u.changes.EmailStatus === "PENDING" && isNow) {
                      newRef = await this.Api.addDoc("mailPending", isNow);
                      promiseDelete.push(this.Api.delDoc(u.id));
                    }
                    if (u.changes.EmailStatus !== "PENDING" && isPending) {
                      newRef = await this.Api.addDoc("mail", isPending);
                      promiseDelete.push(this.Api.delDoc(u.id));
                    }
                  }
                  promiseUpdates.push(
                    this.Api.updDoc(newRef?.path || u.id, u.changes)
                  );
                });
                await Promise.all(promiseUpdates);
                await Promise.all(promiseDelete);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            DeleteConfirm={true}
            onDelete={async (id) => {
              // console.log("DEL", id);
              try {
                await this.Api.delDoc(id);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            BuiltInFilter={true}
            SortField={"EmailSendOn@Down"}
            BuiltInSort={true}
          ></CRUD>
        )}
        <Dialog
          hidden={MailSelected === undefined}
          onDismiss={() => {
            this.setState({ MailSelectedRef: "" });
          }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: `${MailSelected?.EmailSubject || ""}`,
            subText: `To : ${MailSelected?.EmailTo || ""}`,
            closeButtonAriaLabel: "Close",
          }}
          modalProps={{
            containerClassName: "ms-dialogMainOverride textDialogHalfTall",
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          {ReactHtmlParser(MailSelected?.EmailBody || "")}
          <DialogFooter>
            <DefaultButton
              onClick={() => {
                this.setState({ MailSelectedRef: "" });
              }}
              text="Close"
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
