import React, { Component } from "react";
import {
  IProfile,
  IRestaurantRecipe,
  IRestaurantItem,
  IRestaurantCG,
  ProfileRoles,
  RecipeType,
  ProductUnitMesure,
} from "../../models/IFirestoreModels";
import {
  Dropdown,
  IDropdownOption,
  Label,
  Rating,
  RatingSize,
  TextField,
  Toggle,
} from "office-ui-fabric-react";
import IApi from "../../api/IApi";
import { PrintDate, PrintTime } from "../../util/functions";
import MultiSelect from "react-multi-select-component";
import {
  convertUnitMeasure,
  getUnitMeasuresPossibles,
} from "../../util/convertUnitMeasure";
import firebase from "firebase/app";

interface IRecipeSelectedProps {
  Api: IApi;
  Profile: IProfile;
  ProfileCollection: Array<IProfile>;
  RestaurantRecipeCollection: Array<IRestaurantRecipe>;
  ProductCollection: Array<IRestaurantItem>;
  CurrentRecipeSelected: IRestaurantRecipe;
  Restaurant: IRestaurantCG;
  innerHeight: number;
  innerWidth: number;

  AddRecipe?: boolean;
  setCurrentRecipeSelected: (ref?: string) => void;
}
interface IRecipeSelectedState {
  UpdateMode: boolean;
  isUpdating: boolean;
  UnitMesureOptions: Array<IDropdownOption>;
  RecipeContentOptions: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;

  RecipeName: string;
  RecipeTypes: Array<RecipeType>;
  RecipeDescription: string;
  RecipeWorkInHours: string;
  RecipeCookTimeInHours: string;
  RecipeSteps: Array<string>;
  RecipePortions: string;
  RecipeProducts: Array<{
    ProductGUID?: firebase.firestore.DocumentReference<
      firebase.firestore.DocumentData
    >;
    ProductUnitAmount: number;
    ProductUnitMesure: ProductUnitMesure;
  }>;
  RecipeRecipes: Array<{
    RecipeGUID?: firebase.firestore.DocumentReference<
      firebase.firestore.DocumentData
    >;
    RecipeUnitAmount: number;
    RecipeUnitMesure: ProductUnitMesure;
  }>;

  RecipeAmount: number;
  RecipePart: number;
}
export default class RecipeSelected extends Component<
  IRecipeSelectedProps,
  IRecipeSelectedState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      UpdateMode: false,
      isUpdating: false,
      UnitMesureOptions: [],
      RecipeContentOptions: [],

      RecipeName: "",
      RecipeTypes: [],
      RecipeDescription: "",
      RecipeWorkInHours: "0",
      RecipeCookTimeInHours: "0",
      RecipeSteps: [],
      RecipePortions: "0",
      RecipeProducts: [],
      RecipeRecipes: [],

      RecipeAmount: 1,
      RecipePart: 0,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    this.setState({
      UpdateMode: this.props.AddRecipe === true,
      isUpdating: false,
      UnitMesureOptions: this.props.Restaurant.RestaurantUnitMesures.cases
        .filter((m) => m.replaceWith !== ProductUnitMesure.poundMeat)
        .map((m) => {
          return { key: m.replaceWith, text: m.replaceWith } as any;
        }),
      RecipeContentOptions: this.props.ProductCollection.sort((A, B) =>
        A.ProductName > B.ProductName
          ? 1
          : A.ProductName < B.ProductName
          ? -1
          : 0
      )
        .map((p) => {
          return { label: p.ProductName, value: (p.ref as any).path };
        })
        .concat(
          this.props.RestaurantRecipeCollection.sort((A, B) =>
            A.RecipeName > B.RecipeName
              ? 1
              : A.RecipeName < B.RecipeName
              ? -1
              : 0
          ).map((r) => {
            return {
              label: `${r.RecipeName} *Recipe*`,
              value: (r.ref as any).path,
            };
          })
        ),

      RecipeName: this.props.CurrentRecipeSelected.RecipeName,
      RecipeTypes: this.props.CurrentRecipeSelected.RecipeTypes,
      RecipeDescription: this.props.CurrentRecipeSelected.RecipeDescription,
      RecipeWorkInHours: this.props.CurrentRecipeSelected.RecipeWorkInHours.toString(),
      RecipeCookTimeInHours: this.props.CurrentRecipeSelected.RecipeCookTimeInHours.toString(),
      RecipeSteps: this.props.CurrentRecipeSelected.RecipeSteps,
      RecipePortions: this.props.CurrentRecipeSelected.RecipePortions.toString(),
      RecipeProducts: this.props.CurrentRecipeSelected.RecipeContent.RecipeProducts.map(
        (p) => {
          return { ...p };
        }
      ),
      RecipeRecipes: this.props.CurrentRecipeSelected.RecipeContent.RecipeRecipes.map(
        (p) => {
          return { ...p };
        }
      ),

      RecipeAmount: 1,
      RecipePart: 0,
    });
  }
  componentDidUpdate(
    prevProps: IRecipeSelectedProps,
    prevState: IRecipeSelectedState,
    snapshot: any
  ) {
    if (
      this.props.CurrentRecipeSelected.ref?.id !==
      prevProps.CurrentRecipeSelected.ref?.id
    ) {
      this.setState({
        UpdateMode: this.props.AddRecipe === true,
        isUpdating: false,

        RecipeName: this.props.CurrentRecipeSelected.RecipeName,
        RecipeTypes: this.props.CurrentRecipeSelected.RecipeTypes,
        RecipeDescription: this.props.CurrentRecipeSelected.RecipeDescription,
        RecipeWorkInHours: this.props.CurrentRecipeSelected.RecipeWorkInHours.toString(),
        RecipeCookTimeInHours: this.props.CurrentRecipeSelected.RecipeCookTimeInHours.toString(),
        RecipeSteps: this.props.CurrentRecipeSelected.RecipeSteps,
        RecipePortions: this.props.CurrentRecipeSelected.RecipePortions.toString(),
        RecipeProducts: this.props.CurrentRecipeSelected.RecipeContent.RecipeProducts.map(
          (p) => {
            return { ...p };
          }
        ),
        RecipeRecipes: this.props.CurrentRecipeSelected.RecipeContent.RecipeRecipes.map(
          (p) => {
            return { ...p };
          }
        ),

        RecipeAmount: 1,
        RecipePart: 0,
      });
    }
  }
  handleShow = async (RecipeShow: boolean) => {
    this.setState({ isUpdating: true });
    await this.Api.updDoc(
      this.props.CurrentRecipeSelected.ref?.id as string,
      { RecipeShow },
      this.props.Restaurant.RestaurantSubCollectionPaths
        .RestaurantRecipeCollectionPath
    );
    this.props.setCurrentRecipeSelected();
    this.setState({ isUpdating: false });
  };
  renderRecipeContent(RecipeContent: {
    RecipeProducts: Array<{
      ProductGUID?: firebase.firestore.DocumentReference<
        firebase.firestore.DocumentData
      >;
      ProductUnitAmount: number;
      ProductUnitMesure: ProductUnitMesure;
    }>;
    RecipeRecipes: Array<{
      RecipeGUID?: firebase.firestore.DocumentReference<
        firebase.firestore.DocumentData
      >;
      RecipeUnitAmount: number;
      RecipeUnitMesure: ProductUnitMesure;
    }>;
  }): Array<JSX.Element> {
    return RecipeContent.RecipeProducts.map((product, index) => {
      let ProductUnitAmount = product.ProductUnitAmount;
      if (this.state.RecipePart) {
        ProductUnitAmount =
          ProductUnitAmount * (this.state.RecipeAmount + this.state.RecipePart);
      } else {
        ProductUnitAmount = ProductUnitAmount * this.state.RecipeAmount;
      }
      return this.state.UpdateMode ? (
        <tr key={product.ProductGUID?.id}>
          <td>
            {
              this.props.ProductCollection.find(
                (r) => r.ref?.id === product.ProductGUID?.id
              )?.ProductName
            }
          </td>
          <td style={{ textAlign: "center" }}>
            <input
              id={`ProductUnitAmount${product.ProductGUID?.id}`}
              onChange={(e) =>
                this.onChanged(
                  "ProductUnitAmount",
                  e.target.value,
                  product.ProductGUID?.id
                )
              }
              disabled={this.state.isUpdating}
              style={{
                width: "90%",
                height: "25px",
              }}
              value={
                product.ProductUnitAmount !== 0 ? product.ProductUnitAmount : ""
              }
              type="text"
              placeholder={"Amount"}
            />
          </td>
          <td style={{ textAlign: "center" }}>
            <Dropdown
              selectedKey={product.ProductUnitMesure}
              options={this.state.UnitMesureOptions}
              disabled={this.state.isUpdating}
              onChange={(event: any, option?: IDropdownOption | undefined) =>
                option &&
                this.onChanged(
                  "ProductUnitMesure",
                  option,
                  product.ProductGUID?.id
                )
              }
              style={{ maxHeight: 35 }}
            />
          </td>
        </tr>
      ) : (
        <tr key={product.ProductGUID?.id}>
          <td>
            {
              this.props.ProductCollection.find(
                (r) => r.ref?.id === product.ProductGUID?.id
              )?.ProductName
            }
          </td>
          <td style={{ textAlign: "center" }}>{ProductUnitAmount}</td>
          <td style={{ textAlign: "center" }}>
            <Dropdown
              selectedKey={product.ProductUnitMesure}
              options={getUnitMeasuresPossibles(
                this.props.CurrentRecipeSelected.RecipeContent.RecipeProducts[
                  index
                ]
                  ? this.props.CurrentRecipeSelected.RecipeContent
                      .RecipeProducts[index].ProductUnitMesure
                  : (this.props.Restaurant.RestaurantUnitMesures
                      .defaultCase as ProductUnitMesure),
                this.props.Restaurant.RestaurantUnitConversions
              ).map((m) => {
                return { key: m, text: m };
              })}
              onChange={(event: any, option?: IDropdownOption | undefined) =>
                option &&
                this.onChanged(
                  "ProductUnitMesureConversion",
                  option,
                  product.ProductGUID?.id
                )
              }
              style={{ maxHeight: 35 }}
            />
          </td>
        </tr>
      );
    }).concat(
      RecipeContent.RecipeRecipes.map((recipe, index) => {
        let RecipeUnitAmount = recipe.RecipeUnitAmount;
        if (this.state.RecipePart) {
          RecipeUnitAmount =
            RecipeUnitAmount *
            (this.state.RecipeAmount + this.state.RecipePart);
        } else {
          RecipeUnitAmount = RecipeUnitAmount * this.state.RecipeAmount;
        }
        return this.state.UpdateMode ? (
          <tr key={recipe.RecipeGUID?.id}>
            <td>
              {`${
                this.props.RestaurantRecipeCollection.find(
                  (r) => r.ref?.id === recipe.RecipeGUID?.id
                )?.RecipeName
              } *Recipe*`}
            </td>
            <td style={{ textAlign: "center" }}>
              <input
                id={`RecipeUnitAmount${recipe.RecipeGUID?.id}`}
                onChange={(e) =>
                  this.onChanged(
                    "RecipeUnitAmount",
                    e.target.value,
                    recipe.RecipeGUID?.id
                  )
                }
                disabled={this.state.isUpdating}
                style={{
                  width: "90%",
                  height: "25px",
                }}
                value={
                  recipe.RecipeUnitAmount !== 0 ? recipe.RecipeUnitAmount : ""
                }
                type="text"
                placeholder={"Amount"}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <Dropdown
                selectedKey={recipe.RecipeUnitMesure}
                options={this.state.UnitMesureOptions}
                disabled={this.state.isUpdating}
                onChange={(event: any, option?: IDropdownOption | undefined) =>
                  option &&
                  this.onChanged(
                    "RecipeUnitMesure",
                    option,
                    recipe.RecipeGUID?.id
                  )
                }
                style={{ maxHeight: 35 }}
              />
            </td>
          </tr>
        ) : (
          <tr key={recipe.RecipeGUID?.id}>
            <td
              style={{ cursor: "pointer" }}
              onClick={(event) =>
                this.props.setCurrentRecipeSelected(recipe.RecipeGUID?.id)
              }
            >
              {`${
                this.props.RestaurantRecipeCollection.find(
                  (r) => r.ref?.id === recipe.RecipeGUID?.id
                )?.RecipeName
              } *Recipe*`}
            </td>
            <td style={{ textAlign: "center" }}>{RecipeUnitAmount}</td>
            <td style={{ textAlign: "center" }}>
              <Dropdown
                selectedKey={recipe.RecipeUnitMesure}
                options={getUnitMeasuresPossibles(
                  this.props.CurrentRecipeSelected.RecipeContent.RecipeRecipes[
                    index
                  ]
                    ? this.props.CurrentRecipeSelected.RecipeContent
                        .RecipeRecipes[index].RecipeUnitMesure
                    : (this.props.Restaurant.RestaurantUnitMesures
                        .defaultCase as ProductUnitMesure),
                  this.props.Restaurant.RestaurantUnitConversions
                ).map((m) => {
                  return { key: m, text: m };
                })}
                onChange={(event: any, option?: IDropdownOption | undefined) =>
                  option &&
                  this.onChanged(
                    "RecipeUnitMesureConversion",
                    option,
                    recipe.RecipeGUID?.id
                  )
                }
                style={{ maxHeight: 35 }}
              />
            </td>
          </tr>
        );
      })
    );
  }
  renderRecipeSteps(RecipeSteps: Array<string>): Array<JSX.Element> {
    return RecipeSteps.map((step: string, index: number) => {
      return (
        <tr key={index}>
          <td>{`${index + 1}. ${step}`}</td>
        </tr>
      );
    });
  }
  onChanged = async (field: string, value: any, id?: string) => {
    let RecipeProducts = this.state.RecipeProducts;
    let RecipeRecipes = this.state.RecipeRecipes;
    const indexProduct = RecipeProducts.findIndex(
      (p) => p.ProductGUID?.id === id
    );
    const indexRecipe = RecipeRecipes.findIndex((r) => r.RecipeGUID?.id === id);
    switch (field) {
      case "RecipeName":
        this.setState({ RecipeName: value.toUpperCase() });
        break;
      case "RecipeTypes":
        this.setState({
          RecipeTypes:
            value?.selected === true
              ? this.state.RecipeTypes.concat([value.key as RecipeType])
              : this.state.RecipeTypes.filter((key) => key !== value.key),
        });
        break;
      case "RecipeDescription":
        this.setState({ RecipeDescription: value });
        break;
      case "RecipeWorkInHours":
        this.setState({ RecipeWorkInHours: Number(value) ? value : "" });
        break;
      case "RecipeCookTimeInHours":
        this.setState({ RecipeCookTimeInHours: Number(value) ? value : "" });
        break;
      case "RecipeSteps":
        this.setState({ RecipeSteps: (value as string).split("\n") });
        break;
      case "RecipePortions":
        this.setState({ RecipePortions: Number(value) ? value : "" });
        break;
      case "RecipeContent":
        RecipeProducts = [];
        RecipeRecipes = [];
        (value as Array<{
          label: string;
          value: string;
          disabled?: boolean;
        }>).forEach((o) => {
          const collection: string = o.value.substr(0, o.value.indexOf("/"));
          const doc: string = o.value.substr(o.value.indexOf("/") + 1);
          switch (collection) {
            case this.props.Restaurant.RestaurantSubCollectionPaths
              .RestaurantItemCollectionPath:
              const indexProduct = this.state.RecipeProducts.findIndex(
                (p) => p.ProductGUID?.id === doc
              );
              if (indexProduct !== -1) {
                RecipeProducts.push(this.state.RecipeProducts[indexProduct]);
              } else {
                RecipeProducts.push({
                  ProductGUID: this.props.ProductCollection.find(
                    (p) => p.ref?.id === doc
                  )?.ref,
                  ProductUnitAmount: 1,
                  ProductUnitMesure: this.props.Restaurant.RestaurantUnitMesures
                    .defaultCase as ProductUnitMesure,
                });
              }
              break;
            case this.props.Restaurant.RestaurantSubCollectionPaths
              .RestaurantRecipeCollectionPath:
              const indexRecipe = this.state.RecipeRecipes.findIndex(
                (r) => r.RecipeGUID?.id === doc
              );
              if (indexRecipe !== -1) {
                RecipeRecipes.push(this.state.RecipeRecipes[indexRecipe]);
              } else {
                RecipeRecipes.push({
                  RecipeGUID: this.props.RestaurantRecipeCollection.find(
                    (r) => r.ref?.id === doc
                  )?.ref,
                  RecipeUnitAmount: 1,
                  RecipeUnitMesure: this.props.Restaurant.RestaurantUnitMesures
                    .defaultCase as ProductUnitMesure,
                });
              }
              break;
          }
        });
        this.setState({ RecipeProducts, RecipeRecipes });
        break;
      case "ProductUnitAmount":
        RecipeProducts[indexProduct].ProductUnitAmount =
          Number(value) || value === "0" || value === "0." ? value : 0;
        this.setState({ RecipeProducts });
        break;
      case "ProductUnitMesure":
        RecipeProducts[indexProduct].ProductUnitMesure = value.key;
        this.setState({ RecipeProducts });
        break;
      case "RecipeUnitAmount":
        RecipeRecipes[indexRecipe].RecipeUnitAmount =
          Number(value) || value === "0" || value === "0." ? value : 0;
        this.setState({ RecipeProducts });
        break;
      case "RecipeUnitMesure":
        RecipeRecipes[indexRecipe].RecipeUnitMesure = value.key;
        this.setState({ RecipeProducts });
        break;
      case "UpdateMode":
        if (this.state.UpdateMode) {
          this.setState({ isUpdating: true });
          if (this.props.AddRecipe) {
            const ref = await this.Api.addDoc(
              this.props.Restaurant.RestaurantSubCollectionPaths
                .RestaurantRecipeCollectionPath,
              {
                RecipeName: this.state.RecipeName,
                RecipeTypes: this.state.RecipeTypes,
                RecipeDescription: this.state.RecipeDescription,
                RecipeWorkInHours: Number(this.state.RecipeWorkInHours),
                RecipeCookTimeInHours: Number(this.state.RecipeCookTimeInHours),
                RecipeSteps: this.state.RecipeSteps,
                RecipePortions: Number(this.state.RecipePortions),
                RecipeContent: {
                  RecipeProducts: this.state.RecipeProducts.map((p) => {
                    return {
                      ...p,
                      ProductUnitAmount: Number(p.ProductUnitAmount)
                        ? Number(p.ProductUnitAmount)
                        : 1,
                    };
                  }),
                  RecipeRecipes: this.state.RecipeRecipes.map((r) => {
                    return {
                      ...r,
                      RecipeUnitAmount: Number(r.RecipeUnitAmount)
                        ? Number(r.RecipeUnitAmount)
                        : 1,
                    };
                  }),
                },
                RecipeLastUpdateProfile: this.props.Profile.ref,
                RecipeLastUpdateDate: this.Api.TimestampNow(),
              }
            );
            this.props.setCurrentRecipeSelected(ref.id);
          } else {
            await this.Api.updDoc(
              this.props.CurrentRecipeSelected.ref?.id as string,
              {
                RecipeName: this.state.RecipeName,
                RecipeTypes: this.state.RecipeTypes,
                RecipeDescription: this.state.RecipeDescription,
                RecipeWorkInHours: Number(this.state.RecipeWorkInHours),
                RecipeCookTimeInHours: Number(this.state.RecipeCookTimeInHours),
                RecipeSteps: this.state.RecipeSteps,
                RecipePortions: Number(this.state.RecipePortions),
                RecipeContent: {
                  RecipeProducts: this.state.RecipeProducts.map((p) => {
                    return {
                      ...p,
                      ProductUnitAmount: Number(p.ProductUnitAmount)
                        ? Number(p.ProductUnitAmount)
                        : 1,
                    };
                  }),
                  RecipeRecipes: this.state.RecipeRecipes.map((r) => {
                    return {
                      ...r,
                      RecipeUnitAmount: Number(r.RecipeUnitAmount)
                        ? Number(r.RecipeUnitAmount)
                        : 1,
                    };
                  }),
                },
                RecipeLastUpdateProfile: this.props.Profile.ref,
                RecipeLastUpdateDate: this.Api.TimestampNow(),
              },
              this.props.Restaurant.RestaurantSubCollectionPaths
                .RestaurantRecipeCollectionPath
            );
          }
          this.props.setCurrentRecipeSelected();
        } else {
          this.setState({
            RecipeProducts: this.props.CurrentRecipeSelected.RecipeContent.RecipeProducts.map(
              (p) => {
                return { ...p };
              }
            ),
            RecipeRecipes: this.props.CurrentRecipeSelected.RecipeContent.RecipeRecipes.map(
              (p) => {
                return { ...p };
              }
            ),
          });
        }
        this.setState({
          UpdateMode: value,
          isUpdating: false,
          RecipeAmount: 1,
          RecipePart: 0,
        });
        break;
      case "ProductUnitMesureConversion":
        RecipeProducts[indexProduct].ProductUnitAmount = Number(
          convertUnitMeasure(
            {
              Amount: this.props.CurrentRecipeSelected.RecipeContent
                .RecipeProducts[indexProduct].ProductUnitAmount,
              UnitMeasure: this.props.CurrentRecipeSelected.RecipeContent
                .RecipeProducts[indexProduct].ProductUnitMesure,
            },
            value.key,
            this.props.Restaurant.RestaurantUnitConversions
          ).Amount.toFixed(2)
        );
        RecipeProducts[indexProduct].ProductUnitMesure = value.key;
        this.setState({ RecipeProducts });
        break;
      case "RecipeUnitMesureConversion":
        RecipeRecipes[indexRecipe].RecipeUnitAmount = Number(
          convertUnitMeasure(
            {
              Amount: this.props.CurrentRecipeSelected.RecipeContent
                .RecipeRecipes[indexRecipe].RecipeUnitAmount,
              UnitMeasure: this.props.CurrentRecipeSelected.RecipeContent
                .RecipeRecipes[indexRecipe].RecipeUnitMesure,
            },
            value.key,
            this.props.Restaurant.RestaurantUnitConversions
          ).Amount.toFixed(2)
        );
        RecipeRecipes[indexRecipe].RecipeUnitMesure = value.key;
        this.setState({ RecipeProducts });
        break;
    }
  };
  render(): JSX.Element {
    const OverFlowStyle: React.CSSProperties = {
      width: "100%",
      height: `${this.props.innerHeight - 175}px`,
      overflowY: "scroll",
    };
    const OrderTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const OrderTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    const RecipeLastUpdateProfile: IProfile = this.props.ProfileCollection.find(
      (profile) =>
        profile.ref?.id ===
        this.props.CurrentRecipeSelected.RecipeLastUpdateProfile?.id
    ) as IProfile;

    const sProfile: string = RecipeLastUpdateProfile
      ? RecipeLastUpdateProfile.ProfileContactName
      : "N/A";

    const RecipeLastUpdateDate: firebase.firestore.Timestamp = this.props
      .CurrentRecipeSelected.RecipeLastUpdateDate;

    const sDate: string = RecipeLastUpdateDate
      ? PrintDate(RecipeLastUpdateDate)
      : "N/A";

    const sTime: string = RecipeLastUpdateDate
      ? PrintTime(RecipeLastUpdateDate)
      : "N/A";

    let sTypes: string =
      this.props.CurrentRecipeSelected.RecipeTypes.length > 0
        ? this.props.CurrentRecipeSelected.RecipeTypes.reduce(
            (previousValue: RecipeType, currentValue: RecipeType) => {
              return `${previousValue}, ${currentValue}` as RecipeType;
            }
          )
        : "";
    let RecipePortions = this.props.CurrentRecipeSelected.RecipePortions;
    if (this.state.RecipePart) {
      RecipePortions =
        RecipePortions * (this.state.RecipeAmount + this.state.RecipePart);
    } else {
      RecipePortions = RecipePortions * this.state.RecipeAmount;
    }
    return (
      <div>
        <span style={{ position: "fixed", bottom: 15, right: 85, zIndex: 5 }}>
          <Dropdown
            selectedKey={this.state.RecipeAmount.toString()}
            options={[
              { key: "0", text: "0" },
              { key: "1", text: "1" },
              { key: "2", text: "2" },
              { key: "3", text: "3" },
              { key: "4", text: "4" },
              { key: "5", text: "5" },
              { key: "6", text: "6" },
              { key: "7", text: "7" },
              { key: "8", text: "8" },
            ]}
            disabled={this.state.isUpdating || this.state.UpdateMode}
            onChange={(event: any, option?: IDropdownOption | undefined) =>
              option && this.setState({ RecipeAmount: Number(option.key) })
            }
            style={{ width: "50px" }}
          />
        </span>
        <span style={{ position: "fixed", bottom: 15, right: 10, zIndex: 5 }}>
          <Dropdown
            selectedKey={this.state.RecipePart.toString()}
            options={[
              { key: "0", text: "0" },
              { key: "0.5", text: "1/2" },
              { key: "0.25", text: "1/4" },
              { key: "0.125", text: "1/8" },
              { key: "0.0625", text: "1/16" },
            ]}
            disabled={this.state.isUpdating || this.state.UpdateMode}
            onChange={(event: any, option?: IDropdownOption | undefined) =>
              option && this.setState({ RecipePart: Number(option.key) })
            }
            style={{ width: "70px" }}
          />
        </span>
        <table style={OrderTableStyle}>
          <thead style={OrderTableHeadStyle}>
            <tr>
              <th style={{ width: "10%", textAlign: "center" }}>Date</th>
              <th style={{ width: "10%", textAlign: "center" }}>Time</th>
              <th
                style={{
                  width:
                    this.props.Profile.ProfileRole === ProfileRoles.Dev ||
                    this.props.Profile.ProfileRole === ProfileRoles.Admin
                      ? "70%"
                      : "80%",
                }}
              >
                Last Update Profile
              </th>
              {(this.props.Profile.ProfileRole === ProfileRoles.Dev ||
                this.props.Profile.ProfileRole === ProfileRoles.Admin) && (
                <th style={{ width: "10%" }}>
                  {this.state.UpdateMode ? "Save" : "Update"}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "center" }}>{sDate}</td>
              <td style={{ textAlign: "center" }}>{sTime}</td>
              <td>{sProfile}</td>
              {(this.props.Profile.ProfileRole === ProfileRoles.Dev ||
                this.props.Profile.ProfileRole === ProfileRoles.Admin) && (
                <td style={{ textAlign: "center" }}>
                  <Toggle
                    checked={this.state.UpdateMode}
                    onChange={(event: any, checked?: boolean | undefined) =>
                      this.onChanged("UpdateMode", checked === true)
                    }
                    disabled={
                      this.state.isUpdating || this.state.RecipeName === ""
                    }
                    role="checkbox"
                  />
                </td>
              )}
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <div style={OverFlowStyle}>
          <table style={OrderTableStyle}>
            <thead style={OrderTableHeadStyle}>
              <tr>
                <th style={{ width: "30%" }}>Name</th>
                <th style={{ width: "50%" }}>Type</th>
                <th style={{ width: "10%" }}>Portions</th>
                <th style={{ width: "10%" }}>Show</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>
                  {this.state.UpdateMode ? (
                    <input
                      id={"RecipeName"}
                      onChange={(e) =>
                        this.onChanged("RecipeName", e.target.value)
                      }
                      disabled={this.state.isUpdating}
                      style={{
                        width: "95%",
                        height: "25px",
                        backgroundColor:
                          this.state.RecipeName === "" ? "tomato" : "white",
                      }}
                      value={this.state.RecipeName}
                      type="text"
                    />
                  ) : (
                    this.props.CurrentRecipeSelected.RecipeName
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  {this.state.UpdateMode ? (
                    <Dropdown
                      selectedKeys={this.state.RecipeTypes}
                      options={this.props.Restaurant.RestaurantRecipeTypes.map(
                        (t) => {
                          return { key: t, text: t };
                        }
                      )}
                      multiSelect
                      disabled={this.state.isUpdating}
                      onChange={(
                        event: any,
                        option?: IDropdownOption | undefined
                      ) => {
                        option && this.onChanged("RecipeTypes", option);
                      }}
                      style={{
                        maxWidth:
                          this.props.innerWidth >= 1400
                            ? this.props.innerWidth / 2.5
                            : this.props.innerWidth / 1.5,
                      }}
                    />
                  ) : (
                    sTypes
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  {this.state.UpdateMode ? (
                    <input
                      id={"RecipePortions"}
                      onChange={(e) =>
                        this.onChanged("RecipePortions", e.target.value)
                      }
                      disabled={this.state.isUpdating}
                      style={{
                        width: "90%",
                        height: "25px",
                      }}
                      value={this.state.RecipePortions}
                      type="text"
                      placeholder={"Pans"}
                    />
                  ) : (
                    `${RecipePortions} ${RecipePortions > 1 ? "Pans" : "Pan"}`
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  <Toggle
                    checked={this.props.CurrentRecipeSelected.RecipeShow}
                    onChange={(event: any, checked?: boolean | undefined) =>
                      this.handleShow(checked === true)
                    }
                    disabled={
                      this.state.isUpdating ||
                      this.state.UpdateMode ||
                      (this.props.Profile.ProfileRole !== ProfileRoles.Dev &&
                        this.props.Profile.ProfileRole !== ProfileRoles.Admin)
                    }
                    role="checkbox"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table style={OrderTableStyle}>
            <thead style={OrderTableHeadStyle}>
              <tr>
                <th style={{ width: "30%", textAlign: "center" }}>Rating</th>
                <th style={{ width: "50%", textAlign: "center" }}>
                  Description
                </th>
                <th style={{ width: "10%", textAlign: "center" }}>Work</th>
                <th style={{ width: "10%", textAlign: "center" }}>Cook</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>
                  {this.props.innerWidth > 500 ? (
                    <Rating
                      size={RatingSize.Large}
                      min={0}
                      max={5}
                      disabled={true}
                      rating={this.props.CurrentRecipeSelected.RecipeRating}
                    />
                  ) : (
                    <span>
                      <Rating
                        size={RatingSize.Large}
                        min={0}
                        max={3}
                        disabled={true}
                        rating={
                          this.props.CurrentRecipeSelected.RecipeRating <= 3
                            ? this.props.CurrentRecipeSelected.RecipeRating
                            : 3
                        }
                      />
                      <Rating
                        size={RatingSize.Large}
                        min={0}
                        max={2}
                        disabled={true}
                        rating={
                          this.props.CurrentRecipeSelected.RecipeRating - 3 > 0
                            ? this.props.CurrentRecipeSelected.RecipeRating - 3
                            : 0
                        }
                      />
                    </span>
                  )}
                  <Label style={{ marginTop: "5px" }}>
                    Votes:{" "}
                    {this.props.CurrentRecipeSelected.RecipeRatingIndex
                      ? this.props.CurrentRecipeSelected.RecipeRatingIndex
                      : 0}
                  </Label>
                </td>
                <td style={{ textAlign: "center" }}>
                  {this.state.UpdateMode ? (
                    <TextField
                      id={"RecipeDescription"}
                      onChange={(e, value) =>
                        this.onChanged("RecipeDescription", value)
                      }
                      disabled={this.state.isUpdating}
                      style={{
                        maxWidth:
                          this.props.innerWidth >= 1400
                            ? this.props.innerWidth / 3
                            : this.props.innerWidth / 2,
                        height: "70px",
                        maxHeight: "70px",
                      }}
                      value={this.state.RecipeDescription}
                      multiline={true}
                      rows={3}
                    />
                  ) : (
                    this.props.CurrentRecipeSelected.RecipeDescription
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  {this.state.UpdateMode ? (
                    <input
                      id={"RecipeWorkInHours"}
                      onChange={(e) =>
                        this.onChanged("RecipeWorkInHours", e.target.value)
                      }
                      disabled={this.state.isUpdating}
                      style={{
                        width: "90%",
                        height: "25px",
                      }}
                      value={this.state.RecipeWorkInHours}
                      type="text"
                      placeholder={"Hours"}
                    />
                  ) : (
                    `${this.props.CurrentRecipeSelected.RecipeWorkInHours} ${
                      this.props.CurrentRecipeSelected.RecipeWorkInHours > 1
                        ? "Hours"
                        : "Hour"
                    }`
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  {this.state.UpdateMode ? (
                    <input
                      id={"RecipeCookTimeInHours"}
                      onChange={(e) =>
                        this.onChanged("RecipeCookTimeInHours", e.target.value)
                      }
                      disabled={this.state.isUpdating}
                      style={{
                        width: "90%",
                        height: "25px",
                      }}
                      value={this.state.RecipeCookTimeInHours}
                      type="text"
                      placeholder={"Hours"}
                    />
                  ) : (
                    `${
                      this.props.CurrentRecipeSelected.RecipeCookTimeInHours
                    } ${
                      this.props.CurrentRecipeSelected.RecipeCookTimeInHours > 1
                        ? "Hours"
                        : "Hour"
                    }`
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <hr></hr>
          <table style={OrderTableStyle}>
            <thead style={OrderTableHeadStyle}>
              <tr>
                <th style={{ width: "80%" }}>Content</th>
                <th style={{ width: "10%" }}>Amount</th>
                <th style={{ width: "10%" }}>Measure</th>
              </tr>
            </thead>
            <tbody>
              {this.renderRecipeContent({
                RecipeProducts: this.state.RecipeProducts,
                RecipeRecipes: this.state.RecipeRecipes,
              })}
              {this.state.UpdateMode && (
                <tr>
                  <td colSpan={3}>
                    <Label>Add/Remove: Items Or Recipes...</Label>
                  </td>
                </tr>
              )}
              {this.state.UpdateMode && (
                <tr>
                  <td
                    colSpan={3}
                    style={{ maxWidth: this.props.innerWidth / 2 }}
                  >
                    <MultiSelect
                      options={this.state.RecipeContentOptions}
                      value={this.state.RecipeProducts.map((p) => {
                        return {
                          label: this.props.ProductCollection.find(
                            (pCollet) => pCollet.ref?.id === p.ProductGUID?.id
                          )?.ProductName as string,
                          value: (p.ProductGUID as any).path,
                        };
                      }).concat(
                        this.state.RecipeRecipes.map((r) => {
                          return {
                            label: this.props.RestaurantRecipeCollection.find(
                              (rCollet) => rCollet.ref?.id === r.RecipeGUID?.id
                            )?.RecipeName as string,
                            value: (r.RecipeGUID as any).path,
                          };
                        })
                      )}
                      onChange={(
                        value: Array<{
                          label: string;
                          value: string;
                          disabled?: boolean;
                        }>
                      ) => this.onChanged("RecipeContent", value)}
                      labelledBy={"Search Item Or Recipe..."}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <hr></hr>
          <table style={OrderTableStyle}>
            <thead style={OrderTableHeadStyle}>
              <tr>
                <th style={{ width: "100%" }}>Steps</th>
              </tr>
            </thead>
            <tbody>
              {this.state.UpdateMode ? (
                <tr>
                  <td>
                    <TextField
                      id={"RecipeSteps"}
                      onChange={(e, value) =>
                        this.onChanged("RecipeSteps", value)
                      }
                      disabled={this.state.isUpdating}
                      style={{
                        maxWidth:
                          this.props.innerWidth >= 1400
                            ? this.props.innerWidth / 1.78
                            : this.props.innerWidth / 1.04,
                        height: "200px",
                      }}
                      value={
                        this.state.RecipeSteps.length > 0
                          ? this.state.RecipeSteps.reduce(
                              (s1, s2) => `${s1}\n${s2}`
                            )
                          : ""
                      }
                      multiline={true}
                      rows={3}
                    />
                  </td>
                </tr>
              ) : (
                this.renderRecipeSteps(
                  this.props.CurrentRecipeSelected.RecipeSteps
                )
              )}
              <tr>
                <td>
                  <div style={{ height: "100px" }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
