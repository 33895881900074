import React, { Component } from "react";
import {
  IRestaurantOrder,
  IProfile,
  IRestaurantItemOrder,
  IRestaurantArea,
  IRestaurantItem,
  IProvider,
  ProductOrderStates,
  IRestaurantCG,
  ProfileRoles,
  ProductUnitMesure,
  ProductOrderInvoiceStates,
  RestaurantStaff,
} from "../../models/IFirestoreModels";
import {
  IDropdownOption,
  Dropdown,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
  Toggle,
  SearchBox,
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
  Icon,
} from "office-ui-fabric-react";
import IApi from "../../api/IApi";
import {
  PrintDate,
  PrintTime,
  deleteField,
  removeSpaces,
  createExcelLink,
} from "../../util/functions";
import EmailCreationManager from "../../util/EmailCreationManager";
import firebase from "firebase/app";
import TextSMSCreationManager from "../../util/TextSMSCreationManager";

interface IOrderSelectedProps {
  Api: IApi;
  Profile: IProfile;
  ProfileCollection: Array<IProfile>;
  AreaCollection: Array<IRestaurantArea>;
  ProductCollection: Array<IRestaurantItem>;
  ProviderCollection: Array<IProvider>;
  CurrentOrderSelected: IRestaurantOrder;
  Restaurant: IRestaurantCG;
  innerHeight: number;
  innerWidth: number;
  setCurrentOrderSelected: () => void;
}
interface IOrderSelectedState {
  ProductSearch: string;
  ProviderCollectionOptions: Array<IDropdownOption>;
  ProviderFilter: string;
  OrderPacks: number;
  OrderTotal: number;
  OrderProducts: Array<IRestaurantItemOrder>;
  OrderProductsChecked: Array<IRestaurantItemOrder>;
  ProductsIncomingAmount: Array<string>;
  ProductsReturnAmount: Array<string>;
  ProductsInvoiceAmount: Array<string>;
  ProductsInvoicePrice: Array<string>;
  isOrderCheckedDialogHidden: boolean;
  isUpdating: boolean;
  isNotifyingStaff: boolean;
  isNotifyingProvider: boolean;
  isExporting: boolean;
}
export default class OrderSelected extends Component<
  IOrderSelectedProps,
  IOrderSelectedState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      ProductSearch: "",
      ProviderCollectionOptions: [{ key: "all", text: "All" }].concat(
        this.props.ProviderCollection.map((p) => {
          return { key: p.ref?.id, text: p.ProviderName } as any;
        })
      ),
      ProviderFilter: "all",
      OrderPacks: this.props.CurrentOrderSelected.OrderPacks,
      OrderTotal: this.props.CurrentOrderSelected.OrderTotal,
      OrderProducts: [],
      OrderProductsChecked: [],
      ProductsIncomingAmount: [],
      ProductsReturnAmount: [],
      ProductsInvoiceAmount: [],
      ProductsInvoicePrice: [],
      isOrderCheckedDialogHidden: true,
      isUpdating: false,
      isNotifyingStaff: false,
      isNotifyingProvider: false,
      isExporting: false,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    this.filterByProvider(this.state.ProviderFilter, this.state.ProductSearch);
  }
  componentDidUpdate(
    prevProps: IOrderSelectedProps,
    prevState: IOrderSelectedState,
    snapshot: any
  ) {
    if (
      this.props.CurrentOrderSelected.ref?.id !==
        prevProps.CurrentOrderSelected.ref?.id ||
      this.props.CurrentOrderSelected.OrderProducts !==
        prevProps.CurrentOrderSelected.OrderProducts
    ) {
      this.filterByProvider(
        this.state.ProviderFilter,
        this.state.ProductSearch
      );
    }
  }
  filterByProvider(ProviderFilter: string, ProductFilter: string) {
    this.setState({
      OrderPacks: this.filterByProviderPacks(ProviderFilter),
      OrderTotal: this.filterByProviderTotal(ProviderFilter),
      OrderProducts: this.filterByProducts(ProviderFilter, ProductFilter).map(
        (OrderProduct) => {
          return {
            ...OrderProduct,
            ProductOrderState: OrderProduct.ProductOrderState
              ? OrderProduct.ProductOrderState
              : OrderProduct.ProductUnitMesure === ProductUnitMesure.poundMeat
              ? ProductOrderStates.Meat
              : ProductOrderStates.AllGood,
            ProductOrderIncomingAmount:
              OrderProduct.ProductOrderIncomingAmount !== undefined
                ? OrderProduct.ProductOrderIncomingAmount
                : OrderProduct.ProductUnitMesure === ProductUnitMesure.poundMeat
                ? 0
                : OrderProduct.ProductOrderAmount,
          };
        }
      ),
      ProductsIncomingAmount: this.filterByProducts(
        ProviderFilter,
        ProductFilter
      ).map((OrderProduct) => {
        return OrderProduct.ProductOrderIncomingAmount !== undefined
          ? OrderProduct.ProductOrderIncomingAmount.toString()
          : OrderProduct.ProductUnitMesure === ProductUnitMesure.poundMeat
          ? ""
          : OrderProduct.ProductOrderAmount.toString();
      }),
      ProductsReturnAmount: this.filterByProducts(
        ProviderFilter,
        ProductFilter
      ).map((OrderProduct) => {
        return OrderProduct.ProductOrderReturnAmount !== undefined
          ? OrderProduct.ProductOrderReturnAmount.toString()
          : "0";
      }),
      ProductsInvoiceAmount: this.filterByProducts(
        ProviderFilter,
        ProductFilter
      ).map((OrderProduct) => {
        return OrderProduct.ProductOrderInvoiceAmount !== undefined
          ? OrderProduct.ProductOrderInvoiceAmount.toString()
          : "";
      }),
      ProductsInvoicePrice: this.filterByProducts(
        ProviderFilter,
        ProductFilter
      ).map((OrderProduct) => {
        return OrderProduct.ProductOrderInvoicePrice !== undefined
          ? OrderProduct.ProductOrderInvoicePrice.toString()
          : "";
      }),
      ProviderFilter,
    });
  }
  filterByProviderPacks(ProviderFilter: string): number {
    if (ProviderFilter === "all") {
      return this.props.CurrentOrderSelected.OrderPacks;
    } else {
      let packs: number = 0;
      this.props.CurrentOrderSelected.OrderProducts.filter(
        (p) => p.ProviderGUID?.id === ProviderFilter
      ).forEach((p) => (packs += p.ProductOrderAmount));
      return packs;
    }
  }
  filterByProviderTotal(ProviderFilter: string): number {
    if (ProviderFilter === "all") {
      return this.props.CurrentOrderSelected.OrderTotal;
    } else {
      let total: number = 0;
      this.props.CurrentOrderSelected.OrderProducts.filter(
        (p) => p.ProviderGUID?.id === ProviderFilter
      ).forEach((p) => (total += p.ProductPackPrice * p.ProductOrderAmount));
      return total;
    }
  }
  filterByProducts(
    ProviderFilter: string,
    ProductFilter: string
  ): Array<IRestaurantItemOrder> {
    let OrderProducts = this.props.CurrentOrderSelected.OrderProducts;
    if (this.props.CurrentOrderSelected.ReturnedProducts) {
      OrderProducts = this.props.CurrentOrderSelected.OrderProducts.concat(
        this.props.CurrentOrderSelected.ReturnedProducts.map((p) => {
          return { ...p, ProductOrderState: ProductOrderStates.PickUp };
        })
      );
    }
    if (ProviderFilter !== "all") {
      OrderProducts = OrderProducts.filter(
        (p) => p.ProviderGUID?.id === ProviderFilter
      );
    }
    if (ProductFilter !== "") {
      const filterIds: Array<string> = this.props.ProductCollection.filter(
        (p) => p.ProductName.includes(removeSpaces(ProductFilter))
      ).map((p) => p.ref?.id) as Array<string>;
      OrderProducts = OrderProducts.filter(
        (Order) =>
          filterIds.indexOf(Order.ProductGUID?.id as string) !== -1 ||
          Order.ProductDescription.toString()
            .toUpperCase()
            .includes(ProductFilter)
      );
    }
    OrderProducts.sort((A, B) =>
      !A.ProductOrderInvoiceRequireCheck &&
      B.ProductOrderInvoiceRequireCheck === true
        ? 1
        : A.ProductOrderInvoiceRequireCheck === true &&
          !B.ProductOrderInvoiceRequireCheck
        ? -1
        : 0
    ).sort((A, B) =>
      !A.ProductOrderRequireCheck && B.ProductOrderRequireCheck === true
        ? 1
        : A.ProductOrderRequireCheck === true && !B.ProductOrderRequireCheck
        ? -1
        : 0
    );
    return OrderProducts;
  }
  onChanged = async (event: any, option?: any) => {
    let index: number = Number(event.target.id.split(" ")[0]);
    let field: string = event.target.id.split(" ")[1];

    let OrderProducts: Array<IRestaurantItemOrder> = this.state.OrderProducts;
    let ProductsIncomingAmount: Array<string> = this.state
      .ProductsIncomingAmount;
    let ProductsReturnAmount: Array<string> = this.state.ProductsReturnAmount;
    let ProductsInvoiceAmount: Array<string> = this.state.ProductsInvoiceAmount;
    let ProductsInvoicePrice: Array<string> = this.state.ProductsInvoicePrice;

    const ProductOrderAmount: number = OrderProducts[index].ProductOrderAmount;
    const value: number = Number(event.target.value);
    const valueString: string = event.target.value;

    let WrongInvoiceAmount = false;
    let WrongInvoicePrice = false;
    switch (field) {
      case "ProductOrderState":
        OrderProducts[index].ProductOrderState = option?.key;
        deleteField(OrderProducts[index], "ProductOrderReturnAmount");
        deleteField(OrderProducts[index], "ProductOrderReturnReason");
        //////////////////////////////////////////////////////////////////
        switch (option?.key) {
          case ProductOrderStates.AllGood:
            OrderProducts[
              index
            ].ProductOrderIncomingAmount = ProductOrderAmount;
            ProductsIncomingAmount[index] = ProductOrderAmount.toString();
            ProductsReturnAmount[index] = "0";
            break;
          case ProductOrderStates.WrongIncomingAmount:
            OrderProducts[index].ProductOrderIncomingAmount = 0;
            ProductsIncomingAmount[index] = "";
            ProductsReturnAmount[index] = "0";
            break;
          case ProductOrderStates.Returned:
            OrderProducts[index].ProductOrderIncomingAmount = 0;
            ProductsIncomingAmount[index] = "0";
            ProductsReturnAmount[index] = "";
            break;
          case ProductOrderStates.OutOfStock:
            OrderProducts[index].ProductOrderIncomingAmount = 0;
            ProductsIncomingAmount[index] = "0";
            ProductsReturnAmount[index] = "0";
            break;
        }
        this.setState({
          OrderProducts,
          ProductsIncomingAmount,
          ProductsReturnAmount,
          ProductsInvoiceAmount,
          ProductsInvoicePrice,
        });
        break;
      case "ProductOrderIncomingAmount":
        ProductsIncomingAmount[index] = value || value === 0 ? valueString : "";
        OrderProducts[index].ProductOrderIncomingAmount =
          value || value === 0 ? value : 0;
        this.setState({ OrderProducts, ProductsIncomingAmount });
        break;
      case "ProductOrderReturnAmount":
        OrderProducts[index].ProductOrderIncomingAmount =
          value && value !== 0 && value <= ProductOrderAmount
            ? ProductOrderAmount - value
            : 0;
        ProductsReturnAmount[index] =
          value && value !== 0 && value <= ProductOrderAmount
            ? valueString
            : "";
        OrderProducts[index].ProductOrderReturnAmount =
          value && value !== 0 && value <= ProductOrderAmount
            ? value
            : ProductOrderAmount;
        this.setState({ OrderProducts, ProductsReturnAmount });
        break;
      case "ProductOrderReturnReason":
        OrderProducts[index].ProductOrderReturnReason = valueString;
        this.setState({ OrderProducts });
        break;
      case "ProductOrderInvoiceAmount":
        ProductsInvoiceAmount[index] = value || value === 0 ? valueString : "";
        OrderProducts[index].ProductOrderInvoiceAmount =
          value || value === 0 ? value : 0;
        if (
          OrderProducts[index].ProductOrderState === ProductOrderStates.PickUp
        ) {
          WrongInvoiceAmount =
            (OrderProducts[index].ProductOrderInvoiceAmount as number) <
            (OrderProducts[index].ProductOrderIncomingAmount as number);
          WrongInvoicePrice =
            (OrderProducts[index].ProductOrderInvoicePrice as number) <
            OrderProducts[index].ProductPackPrice;
        } else {
          WrongInvoiceAmount =
            (OrderProducts[index].ProductOrderInvoiceAmount as number) >
            (OrderProducts[index].ProductOrderIncomingAmount as number);
          WrongInvoicePrice =
            (OrderProducts[index].ProductOrderInvoicePrice as number) >
            OrderProducts[index].ProductPackPrice;
        }
        OrderProducts[index].ProductOrderInvoiceState =
          WrongInvoiceAmount && WrongInvoicePrice
            ? ProductOrderInvoiceStates.WrongInvoiceAmountAndPrice
            : WrongInvoiceAmount
            ? ProductOrderInvoiceStates.WrongInvoiceAmount
            : WrongInvoicePrice
            ? ProductOrderInvoiceStates.WrongInvoicePrice
            : ProductOrderInvoiceStates.AllGood;
        this.setState({ OrderProducts, ProductsInvoiceAmount });
        break;
      case "ProductOrderInvoicePrice":
        ProductsInvoicePrice[index] = value || value === 0 ? valueString : "";
        OrderProducts[index].ProductOrderInvoicePrice =
          value || value === 0 ? value : 0;
        if (
          OrderProducts[index].ProductOrderState === ProductOrderStates.PickUp
        ) {
          WrongInvoiceAmount =
            (OrderProducts[index].ProductOrderInvoiceAmount as number) <
            (OrderProducts[index].ProductOrderIncomingAmount as number);
          WrongInvoicePrice =
            (OrderProducts[index].ProductOrderInvoicePrice as number) <
            OrderProducts[index].ProductPackPrice;
        } else {
          WrongInvoiceAmount =
            (OrderProducts[index].ProductOrderInvoiceAmount as number) >
            (OrderProducts[index].ProductOrderIncomingAmount as number);
          WrongInvoicePrice =
            (OrderProducts[index].ProductOrderInvoicePrice as number) >
            OrderProducts[index].ProductPackPrice;
        }
        OrderProducts[index].ProductOrderInvoiceState =
          WrongInvoiceAmount && WrongInvoicePrice
            ? ProductOrderInvoiceStates.WrongInvoiceAmountAndPrice
            : WrongInvoiceAmount
            ? ProductOrderInvoiceStates.WrongInvoiceAmount
            : WrongInvoicePrice
            ? ProductOrderInvoiceStates.WrongInvoicePrice
            : ProductOrderInvoiceStates.AllGood;
        this.setState({ OrderProducts, ProductsInvoicePrice });
        break;
      case "ProductOrderRequireCheck":
        this.setState({ isUpdating: true });
        if (option === true) {
          OrderProducts[index].ProductOrderRequireCheck = false;
          OrderProducts[index].ProductOrderInvoiceRequireCheck = true;
        } else {
          OrderProducts[index].ProductOrderRequireCheck = true;
          if (
            OrderProducts[index].ProductOrderState !== ProductOrderStates.PickUp
          ) {
            deleteField(OrderProducts[index], "ProductOrderState");
          }
          deleteField(OrderProducts[index], "ProductOrderIncomingAmount");
          deleteField(OrderProducts[index], "ProductOrderReturnAmount");
          deleteField(OrderProducts[index], "ProductOrderReturnReason");
          deleteField(OrderProducts[index], "ProductOrderInvoiceRequireCheck");
          deleteField(OrderProducts[index], "ProductOrderInvoiceState");
          deleteField(OrderProducts[index], "ProductOrderInvoiceAmount");
          deleteField(OrderProducts[index], "ProductOrderInvoicePrice");
        }
        if (
          OrderProducts[index].ProductOrderState === ProductOrderStates.PickUp
        ) {
          await this.addProduct(OrderProducts[index], true);
        } else {
          await this.addProduct(OrderProducts[index]);
        }
        this.props.setCurrentOrderSelected();
        this.setState({ isUpdating: false });
        break;
      case "ProductOrderInvoiceRequireCheck":
        this.setState({ isUpdating: true });
        if (option === true) {
          OrderProducts[index].ProductOrderInvoiceRequireCheck = false;
        } else {
          OrderProducts[index].ProductOrderInvoiceRequireCheck = true;
          deleteField(OrderProducts[index], "ProductOrderInvoiceState");
          deleteField(OrderProducts[index], "ProductOrderInvoiceAmount");
          deleteField(OrderProducts[index], "ProductOrderInvoicePrice");
        }
        if (
          OrderProducts[index].ProductOrderState === ProductOrderStates.PickUp
        ) {
          await this.addProduct(OrderProducts[index], true);
        } else {
          await this.addProduct(OrderProducts[index]);
        }
        this.props.setCurrentOrderSelected();
        this.setState({ isUpdating: false });
        break;
    }
  };
  async addProduct(
    OrderProductInput: IRestaurantItemOrder,
    PickUp?: boolean
  ): Promise<void> {
    let OrderProduct = { ...OrderProductInput };
    const Order: IRestaurantOrder = await this.Api.getDoc(
      this.props.CurrentOrderSelected.ref?.id as string,
      this.props.Restaurant.RestaurantSubCollectionPaths
        .RestaurantItemOrderCollectionPath
    );
    let OrderProducts = Order.OrderProducts;
    let ReturnedProducts = Order.ReturnedProducts;
    if (!PickUp) {
      OrderProducts = OrderProducts.filter(
        (p) =>
          p.AreaGUID?.id !== OrderProduct.AreaGUID?.id ||
          p.ProductGUID?.id !== OrderProduct.ProductGUID?.id ||
          p.ProviderGUID?.id !== OrderProduct.ProviderGUID?.id
      ).concat([OrderProduct]);
    } else {
      ReturnedProducts =
        ReturnedProducts &&
        ReturnedProducts.filter(
          (p) =>
            p.AreaGUID?.id !== OrderProduct.AreaGUID?.id ||
            p.ProductGUID?.id !== OrderProduct.ProductGUID?.id ||
            p.ProviderGUID?.id !== OrderProduct.ProviderGUID?.id
        ).concat([OrderProduct]);
    }
    let OrderTotalChecked: number = 0;
    OrderProducts.forEach(
      (product) =>
        (OrderTotalChecked +=
          product.ProductOrderInvoiceState ===
            ProductOrderInvoiceStates.AllGood &&
          product.ProductOrderInvoiceAmount &&
          product.ProductOrderInvoicePrice
            ? product.ProductOrderInvoiceAmount *
              product.ProductOrderInvoicePrice
            : product.ProductOrderIncomingAmount
            ? product.ProductOrderIncomingAmount * product.ProductPackPrice
            : 0)
    );
    ReturnedProducts &&
      ReturnedProducts.forEach(
        (product) =>
          (OrderTotalChecked -=
            product.ProductOrderInvoiceState ===
              ProductOrderInvoiceStates.AllGood &&
            product.ProductOrderInvoiceAmount &&
            product.ProductOrderInvoicePrice
              ? product.ProductOrderInvoiceAmount *
                product.ProductOrderInvoicePrice
              : product.ProductOrderIncomingAmount
              ? product.ProductOrderIncomingAmount * product.ProductPackPrice
              : 0)
      );
    return this.Api.updDoc(
      this.props.CurrentOrderSelected.ref?.id as string,
      {
        OrderProducts,
        ReturnedProducts,
        OrderLastCheckProfile: this.props.Profile.ref,
        OrderLastCheckDate: this.Api.TimestampNow() as any,
        OrderTotalChecked,
        OrderRequireCheck:
          OrderProducts.findIndex((Order) => Order.ProductOrderRequireCheck) !==
          -1,
        OrderRequireInvoiceCheck:
          OrderProducts.findIndex(
            (Order) => Order.ProductOrderInvoiceRequireCheck
          ) !== -1,
      },
      this.props.Restaurant.RestaurantSubCollectionPaths
        .RestaurantItemOrderCollectionPath
    );
  }
  handleShowOrderCheckedDialogHidden = () => {
    this.setState({
      OrderProductsChecked: this.filterByProducts("all", "").map(
        (OrderProduct) => {
          return {
            ...OrderProduct,
            ProductOrderState: OrderProduct.ProductOrderState
              ? OrderProduct.ProductOrderState
              : OrderProduct.ProductUnitMesure === ProductUnitMesure.poundMeat
              ? ProductOrderStates.Meat
              : ("Pending" as ProductOrderStates),
            ProductOrderIncomingAmount:
              OrderProduct.ProductOrderIncomingAmount !== undefined
                ? OrderProduct.ProductOrderIncomingAmount
                : OrderProduct.ProductUnitMesure === ProductUnitMesure.poundMeat
                ? 0
                : OrderProduct.ProductOrderAmount,
          };
        }
      ),
      isOrderCheckedDialogHidden: false,
    });
  };
  handleHideOrderCheckedDialogHidden = () => {
    this.setState({ isOrderCheckedDialogHidden: true });
  };
  NotifyOrderChecked = (provider?: boolean) => {
    this.setState({
      isUpdating: true,
      isNotifyingStaff: !provider,
      isNotifyingProvider: provider === true,
    });
    setTimeout(async () => {
      //[Self] [Email]
      const OrderProducts = this.state.OrderProductsChecked;
      let promiseArraySendEmails: Array<Promise<any>> = [];
      const StaffOrderProducts = OrderProducts.filter(
        (p) => p.ProductOrderRequireCheck === false
      );
      promiseArraySendEmails.push(
        this.Api.sendEmail(
          EmailCreationManager.orderCheckedNotify(
            this.props.Profile,
            StaffOrderProducts,
            this.props.AreaCollection,
            this.props.ProductCollection,
            this.props.ProviderCollection,
            this.props.Restaurant.RestaurantName,
            this.props.CurrentOrderSelected.ref?.id as string,
            0,
            true
          )
        )
      );
      //[Self] [Text]
      promiseArraySendEmails.push(
        this.Api.sendTextSMS(
          await TextSMSCreationManager.orderCheckedNotify(
            this.Api,
            this.props.Profile,
            StaffOrderProducts,
            this.props.AreaCollection,
            this.props.ProductCollection,
            this.props.ProviderCollection,
            this.props.Restaurant,
            this.props.CurrentOrderSelected.ref?.id as string,
            "Self"
          )
        )
      );
      if (!provider) {
        //[Staff OrderChecked] [Email]
        this.props.Restaurant.RestaurantStaffOrderCheckedNotify.forEach(
          async (Staff: RestaurantStaff) => {
            promiseArraySendEmails.push(
              this.Api.sendEmail(
                EmailCreationManager.orderCheckedNotify(
                  Staff,
                  StaffOrderProducts,
                  this.props.AreaCollection,
                  this.props.ProductCollection,
                  this.props.ProviderCollection,
                  this.props.Restaurant.RestaurantName,
                  this.props.CurrentOrderSelected.ref?.id as string
                )
              )
            );
            //[Staff OrderChecked] [Text]
            promiseArraySendEmails.push(
              this.Api.sendTextSMS(
                await TextSMSCreationManager.orderCheckedNotify(
                  this.Api,
                  Staff,
                  StaffOrderProducts,
                  this.props.AreaCollection,
                  this.props.ProductCollection,
                  this.props.ProviderCollection,
                  this.props.Restaurant,
                  this.props.CurrentOrderSelected.ref?.id as string,
                  "Staff"
                )
              )
            );
            //[Staff OrderChecked] [Email] [Reminder]
            promiseArraySendEmails.push(
              this.Api.sendEmail(
                EmailCreationManager.orderCheckedNotify(
                  Staff,
                  StaffOrderProducts,
                  this.props.AreaCollection,
                  this.props.ProductCollection,
                  this.props.ProviderCollection,
                  this.props.Restaurant.RestaurantName,
                  this.props.CurrentOrderSelected.ref?.id as string,
                  3
                )
              )
            );
            //[Staff OrderChecked] [Text] [Reminder]
            promiseArraySendEmails.push(
              this.Api.sendTextSMS(
                await TextSMSCreationManager.orderCheckedNotify(
                  this.Api,
                  Staff,
                  StaffOrderProducts,
                  this.props.AreaCollection,
                  this.props.ProductCollection,
                  this.props.ProviderCollection,
                  this.props.Restaurant,
                  this.props.CurrentOrderSelected.ref?.id as string,
                  "Staff",
                  3
                )
              )
            );
          }
        );
      } else {
        //[Vendor] [Email]
        let ProvidersOrderProducts = StaffOrderProducts.filter(
          (p) =>
            p.ProductOrderInvoiceRequireCheck === false &&
            p.ProductOrderInvoiceState !== ProductOrderInvoiceStates.AllGood
        );
        if (
          this.props.CurrentOrderSelected.ReturnedProducts &&
          this.props.CurrentOrderSelected.ReturnedProducts?.length > 0
        ) {
          ProvidersOrderProducts = ProvidersOrderProducts.concat(
            this.props.CurrentOrderSelected.ReturnedProducts
          );
        }
        const ProviderCollection: Array<IProvider> = this.props.ProviderCollection.filter(
          (provider) =>
            ProvidersOrderProducts.findIndex(
              (product) => product.ProviderGUID?.id === provider.ref?.id
            ) !== -1
        );
        ProviderCollection.forEach(async (Provider) => {
          promiseArraySendEmails.push(
            this.Api.sendEmail(
              EmailCreationManager.orderCheckedNotify(
                {
                  ProfileContactName: Provider.ProviderContactName,
                  ProfileContactEmail: Provider.ProviderContactEmail,
                },
                ProvidersOrderProducts.filter(
                  (p) => p.ProviderGUID?.id === Provider.ref?.id
                ),
                this.props.AreaCollection,
                this.props.ProductCollection,
                this.props.ProviderCollection,
                this.props.Restaurant.RestaurantName,
                this.props.CurrentOrderSelected.ref?.id as string,
                0,
                false,
                true
              )
            )
          );
          //[Vendor] [Text]
          promiseArraySendEmails.push(
            this.Api.sendTextSMS(
              await TextSMSCreationManager.orderCheckedNotify(
                this.Api,
                {
                  ProfileContactName: Provider.ProviderContactName,
                  ProfileContactPhone: Provider.ProviderContactPhone,
                },
                ProvidersOrderProducts.filter(
                  (p) => p.ProviderGUID?.id === Provider.ref?.id
                ),
                this.props.AreaCollection,
                this.props.ProductCollection,
                this.props.ProviderCollection,
                this.props.Restaurant,
                this.props.CurrentOrderSelected.ref?.id as string,
                Provider.ref?.id as string
              )
            )
          );
        });
      }
      Promise.all(promiseArraySendEmails);
      this.setState({
        isUpdating: false,
        isNotifyingStaff: false,
        isNotifyingProvider: false,
      });
    }, 1000);
  };
  async createExcel() {
    this.setState({ isUpdating: true, isExporting: true });
    window.location = (await createExcelLink(
      this.Api,
      `${
        this.props.Restaurant.RestaurantSubCollectionPaths
          .RestaurantItemOrderCollectionPath
      }/Order On ${PrintDate(
        this.props.CurrentOrderSelected.OrderDate,
        true
      )}, Delivery On ${PrintDate(
        this.props.CurrentOrderSelected.OrderDelivery,
        true
      )}`,
      [
        {
          SheetName: "Ordered Products",
          SheetData: this.createSheet(
            this.props.CurrentOrderSelected.OrderProducts
          ),
        },
        {
          SheetName: "PickUp Products",
          SheetData: this.createSheet(
            this.props.CurrentOrderSelected.ReturnedProducts
          ),
        },
      ]
    )) as any;
    this.setState({ isUpdating: false, isExporting: false });
  }
  createSheet(Products?: Array<IRestaurantItemOrder>): Array<Array<string>> {
    let Array: Array<Array<string>> = [
      [
        "Area",
        "Item",
        "Provider",
        "Truck Status",
        "Amount Ordered",
        "Amount Checked",
        "Return Amount",
        "Return Reason",
        "Invoice Status",
        "Invoice Amount",
        "Invoice Price",
        "Case Price",
        "Units per Case",
        "Description",
        "Bid Day",
      ],
    ];
    Products &&
      Products.forEach((p) => {
        const Area: IRestaurantArea = this.props.AreaCollection.find(
          (area) => area.ref?.id === p.AreaGUID?.id
        ) as IRestaurantArea;
        const Product: IRestaurantItem = this.props.ProductCollection.find(
          (product) => product.ref?.id === p.ProductGUID?.id
        ) as IRestaurantItem;
        const Provider: IProvider = this.props.ProviderCollection.find(
          (provider) => provider.ref?.id === p.ProviderGUID?.id
        ) as IProvider;
        Array.push([
          Area ? Area.AreaName : "N/A",
          Product ? Product.ProductName : "N/A",
          Provider ? Provider.ProviderName : "N/A",
          p.ProductOrderState ? p.ProductOrderState : "Pending",
          p.ProductOrderAmount.toString(),
          p.ProductOrderIncomingAmount !== undefined
            ? p.ProductOrderIncomingAmount.toString()
            : "",
          p.ProductOrderReturnAmount !== undefined
            ? p.ProductOrderReturnAmount.toString()
            : "",
          p.ProductOrderReturnReason !== undefined
            ? p.ProductOrderReturnReason
            : "",
          p.ProductOrderInvoiceState !== undefined
            ? p.ProductOrderInvoiceState
            : "",
          p.ProductOrderInvoiceAmount !== undefined
            ? p.ProductOrderInvoiceAmount.toString()
            : "",
          p.ProductOrderInvoicePrice !== undefined
            ? `$${p.ProductOrderInvoicePrice.toString()}`
            : "",
          `$${p.ProductPackPrice.toString()}`,
          `${p.ProductUnitPerPack}/${p.ProductUnitAmount} ${p.ProductUnitMesure}`,
          p.ProductDescription,
          PrintDate(p.ProductBidDay),
        ]);
      });
    return Array;
  }
  renderOrderProducts(
    OrderProducts: Array<IRestaurantItemOrder>
  ): Array<JSX.Element> {
    return OrderProducts.map(
      (OrderProduct: IRestaurantItemOrder, index: number) => {
        const {
          ProductDescription,
          ProductPackPrice,
          ProductUnitPerPack,
          ProductUnitAmount,
          ProductUnitMesure,
          ProductOrderAmount,

          ProductOrderRequireCheck,
          ProductOrderState,
          ProductOrderIncomingAmount,
          ProductOrderReturnReason,

          ProductOrderInvoiceRequireCheck,
          ProductOrderInvoiceState,
          ProductOrderInvoiceAmount,
          ProductOrderInvoicePrice,
        } = OrderProduct;
        const Area: IRestaurantArea = this.props.AreaCollection.find(
          (area) => area.ref?.id === OrderProduct.AreaGUID?.id
        ) as IRestaurantArea;
        const Product: IRestaurantItem = this.props.ProductCollection.find(
          (product) => product.ref?.id === OrderProduct.ProductGUID?.id
        ) as IRestaurantItem;
        const Provider: IProvider = this.props.ProviderCollection.find(
          (provider) => provider.ref?.id === OrderProduct.ProviderGUID?.id
        ) as IProvider;
        const ProductOrderStateOptions: Array<IDropdownOption> = ProductOrderInvoiceRequireCheck
          ? [
              { key: ProductOrderInvoiceStates.AllGood, text: "All Good" },
              {
                key: ProductOrderInvoiceStates.WrongInvoiceAmount,
                text: "Wrong Amount",
              },
              {
                key: ProductOrderInvoiceStates.WrongInvoicePrice,
                text: "Wrong Price",
              },
              {
                key: ProductOrderInvoiceStates.WrongInvoiceAmountAndPrice,
                text: "Wrong Amount and Price",
              },
            ]
          : ProductOrderState === ProductOrderStates.Meat
          ? [
              {
                key: ProductOrderStates.Meat,
                text: "Meat (Pounds)",
              },
            ]
          : ProductOrderState === ProductOrderStates.PickUp
          ? [{ key: ProductOrderStates.PickUp, text: "PickUp" }]
          : [
              { key: ProductOrderStates.AllGood, text: "All Good" },
              {
                key: ProductOrderStates.WrongIncomingAmount,
                text: "Wrong Amount",
              },
              { key: ProductOrderStates.Returned, text: "Returned" },
              { key: ProductOrderStates.OutOfStock, text: "Out of Stock" },
            ];
        return (
          <tr
            id={OrderProduct.ProductGUID?.id}
            key={`Order${index}${OrderProduct.ProductGUID?.id}`}
            style={{
              backgroundColor: index % 2 ? "#DCDCDC" : "white",
              color: "black",
            }}
          >
            <td style={{ width: "10%", textAlign: "center" }}>
              {Area ? Area.AreaName : "N/A"}
            </td>
            <td
              style={{
                width: this.state.ProviderFilter === "all" ? "10%" : "15%",
              }}
            >
              {Product ? Product.ProductName : "N/A"}
            </td>
            {this.props.Profile.ProfileRole !== ProfileRoles.AdminMyrtleBeach &&
              this.props.Profile.ProfileRole !==
                ProfileRoles.AdminVirginiaBeach && (
                <td
                  style={{
                    width: "5%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {ProductOrderState === ProductOrderStates.PickUp
                    ? `${ProductOrderAmount} ~ ${ProductOrderIncomingAmount} PickUp`
                    : ProductOrderState === ProductOrderStates.Meat
                    ? `${ProductOrderAmount}cs ~ ${ProductOrderIncomingAmount}pounds`
                    : `${ProductOrderAmount} ~ ${
                        ProductOrderIncomingAmount
                          ? ProductOrderIncomingAmount
                          : 0
                      }`}
                </td>
              )}
            {this.state.ProviderFilter === "all" && (
              <td style={{ width: "5%", textAlign: "center" }}>
                {Provider ? Provider.ProviderName : "N/A"}
              </td>
            )}
            <td style={{ width: "10%" }}>
              <Dropdown
                id={`${index} ProductOrderState`}
                onChange={this.onChanged}
                disabled={
                  this.state.isUpdating ||
                  !ProductOrderRequireCheck ||
                  ProductOrderState === ProductOrderStates.Meat ||
                  ProductOrderState === ProductOrderStates.PickUp
                }
                selectedKey={
                  ProductOrderInvoiceRequireCheck
                    ? ProductOrderInvoiceState
                    : ProductOrderState
                }
                options={ProductOrderStateOptions}
                style={{ maxWidth: "150px" }}
              />
            </td>
            {ProductOrderInvoiceRequireCheck ? (
              <td
                style={{
                  width:
                    this.props.Profile.ProfileRole !==
                      ProfileRoles.AdminMyrtleBeach &&
                    this.props.Profile.ProfileRole !==
                      ProfileRoles.AdminVirginiaBeach
                      ? "10%"
                      : "20%",
                }}
              >
                <TextField
                  id={`${index} ProductOrderInvoiceAmount`}
                  onChange={this.onChanged}
                  value={this.state.ProductsInvoiceAmount[index]}
                  style={{
                    backgroundColor:
                      this.state.ProductsInvoiceAmount[index] === ""
                        ? "tomato"
                        : "white",
                  }}
                  placeholder="Invoice Amount"
                  disabled={
                    this.props.Profile.ProfileRole !== ProfileRoles.Dev &&
                    !this.props.Profile.ProfileRole.includes(ProfileRoles.Admin)
                  }
                />
                <TextField
                  id={`${index} ProductOrderInvoicePrice`}
                  onChange={this.onChanged}
                  value={this.state.ProductsInvoicePrice[index]}
                  style={{
                    backgroundColor:
                      this.state.ProductsInvoicePrice[index] === ""
                        ? "tomato"
                        : "white",
                  }}
                  placeholder="Invoice Price"
                  disabled={
                    this.props.Profile.ProfileRole !== ProfileRoles.Dev &&
                    !this.props.Profile.ProfileRole.includes(ProfileRoles.Admin)
                  }
                />
              </td>
            ) : ProductOrderState === ProductOrderStates.Returned ? (
              <td
                style={{
                  width:
                    this.props.Profile.ProfileRole !==
                      ProfileRoles.AdminMyrtleBeach &&
                    this.props.Profile.ProfileRole !==
                      ProfileRoles.AdminVirginiaBeach
                      ? "10%"
                      : "20%",
                }}
              >
                <TextField
                  id={`${index} ProductOrderReturnAmount`}
                  onChange={this.onChanged}
                  disabled={
                    this.state.isUpdating ||
                    !ProductOrderRequireCheck ||
                    ProductOrderState !== ProductOrderStates.Returned
                  }
                  value={this.state.ProductsReturnAmount[index]}
                  style={{
                    backgroundColor:
                      this.state.ProductsReturnAmount[index] === ""
                        ? "tomato"
                        : "white",
                  }}
                  placeholder="Return Amount"
                />
                <TextField
                  id={`${index} ProductOrderReturnReason`}
                  onChange={this.onChanged}
                  disabled={
                    this.state.isUpdating ||
                    !ProductOrderRequireCheck ||
                    ProductOrderState !== ProductOrderStates.Returned
                  }
                  defaultValue={
                    ProductOrderReturnReason ? ProductOrderReturnReason : ""
                  }
                  style={{
                    maxHeight: "100px",
                    maxWidth: "250px",
                  }}
                  multiline={true}
                  rows={2}
                  placeholder="Return Reason"
                />
              </td>
            ) : (
              <td
                style={{
                  width:
                    this.props.Profile.ProfileRole !==
                      ProfileRoles.AdminMyrtleBeach &&
                    this.props.Profile.ProfileRole !==
                      ProfileRoles.AdminVirginiaBeach
                      ? "10%"
                      : "20%",
                }}
              >
                <TextField
                  id={`${index} ProductOrderIncomingAmount`}
                  onChange={this.onChanged}
                  disabled={
                    this.state.isUpdating ||
                    !ProductOrderRequireCheck ||
                    (ProductOrderState !==
                      ProductOrderStates.WrongIncomingAmount &&
                      ProductOrderState !== ProductOrderStates.Meat)
                  }
                  value={this.state.ProductsIncomingAmount[index]}
                  style={{
                    backgroundColor:
                      this.state.ProductsIncomingAmount[index] === ""
                        ? "tomato"
                        : "white",
                  }}
                  placeholder="Incoming Amount"
                />
              </td>
            )}
            {this.props.Profile.ProfileRole !== ProfileRoles.AdminMyrtleBeach &&
              this.props.Profile.ProfileRole !==
                ProfileRoles.AdminVirginiaBeach && (
                <td
                  style={{
                    width: "5%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >{`$${ProductPackPrice}`}</td>
              )}
            <td
              style={{ width: "10%", textAlign: "center" }}
            >{`${ProductUnitPerPack}/${ProductUnitAmount} ${ProductUnitMesure}`}</td>
            <td style={{ width: "10%" }}>{`${ProductDescription}`}</td>
            <td style={{ width: "10%", textAlign: "center" }}>
              {PrintDate(OrderProduct.ProductBidDay)}
            </td>
            <td style={{ width: "5%", textAlign: "center" }}>
              <Toggle
                id={`${index} ProductOrderRequireCheck`}
                checked={!ProductOrderRequireCheck}
                onChange={this.onChanged}
                disabled={
                  this.state.isUpdating ||
                  this.state.ProductsIncomingAmount[index] === "" ||
                  this.state.ProductsReturnAmount[index] === "" ||
                  (!ProductOrderRequireCheck &&
                    this.props.Profile.ProfileRole !== ProfileRoles.Dev &&
                    this.props.Profile.ProfileRole !== ProfileRoles.Admin)
                }
              ></Toggle>
            </td>
            <td style={{ width: "5%", textAlign: "center" }}>
              <Toggle
                id={`${index} ProductOrderInvoiceRequireCheck`}
                checked={ProductOrderInvoiceRequireCheck === false}
                onChange={this.onChanged}
                disabled={
                  this.state.isUpdating ||
                  this.state.ProductsInvoiceAmount[index] === "" ||
                  this.state.ProductsInvoicePrice[index] === "" ||
                  ProductOrderRequireCheck ||
                  (!ProductOrderInvoiceRequireCheck &&
                    this.props.Profile.ProfileRole !== ProfileRoles.Dev &&
                    !this.props.Profile.ProfileRole.includes(
                      ProfileRoles.Admin
                    ))
                }
              ></Toggle>
            </td>
            <td
              style={{ width: "5%", textAlign: "center", fontWeight: "bold" }}
            >
              $
              {(ProductOrderInvoiceState ===
                ProductOrderInvoiceStates.AllGood &&
              ProductOrderInvoiceAmount &&
              ProductOrderInvoicePrice
                ? ProductOrderInvoiceAmount * ProductOrderInvoicePrice
                : ProductOrderRequireCheck === false &&
                  ProductOrderIncomingAmount
                ? ProductOrderIncomingAmount * ProductPackPrice
                : 0
              ).toFixed(2)}
            </td>
          </tr>
        );
      }
    );
  }
  renderRowsOrderChecked(
    Orders: Array<IRestaurantItemOrder>
  ): Array<JSX.Element> {
    return Orders.map((Order: IRestaurantItemOrder, index: number) => {
      const Area: IRestaurantArea = this.props.AreaCollection.find(
        (area) => area.ref?.id === Order.AreaGUID?.id
      ) as IRestaurantArea;
      const Product: IRestaurantItem = this.props.ProductCollection.find(
        (product) => product.ref?.id === Order.ProductGUID?.id
      ) as IRestaurantItem;
      const Provider: IProvider = this.props.ProviderCollection.find(
        (provider) => provider.ref?.id === Order.ProviderGUID?.id
      ) as IProvider;
      return (
        <tr
          style={{
            backgroundColor: index % 2 ? "#DCDCDC" : "white",
            color: "black",
          }}
          key={`OrderChecked${Order.ProviderGUID?.id}${Order.ProductGUID?.id}${Order.ProductOrderState}`}
        >
          <td>{Area ? Area.AreaName : "N/A"}</td>
          <td>{Product ? Product.ProductName : "N/A"}</td>
          <td>{Provider ? Provider.ProviderName : "N/A"}</td>
          <td style={{ fontWeight: "bold" }}>{Order.ProductOrderState}</td>
          <td style={{ fontWeight: "bold" }}>{Order.ProductOrderAmount}</td>
          <td>{Order.ProductOrderIncomingAmount}</td>
          <td>
            {Order.ProductOrderReturnAmount
              ? Order.ProductOrderReturnAmount
              : ""}
          </td>
          <td>
            {Order.ProductOrderReturnReason
              ? Order.ProductOrderReturnReason
              : ""}
          </td>
          <td style={{ fontWeight: "bold" }}>
            {Order.ProductOrderInvoiceState
              ? Order.ProductOrderInvoiceState
              : ""}
          </td>
          <td>
            {Order.ProductOrderInvoiceAmount
              ? Order.ProductOrderInvoiceAmount
              : ""}
          </td>
          <td>
            {Order.ProductOrderInvoicePrice
              ? `$${Order.ProductOrderInvoicePrice}`
              : ""}
          </td>
          <td style={{ fontWeight: "bold" }}>${Order.ProductPackPrice}</td>
          <td>
            {Order.ProductUnitPerPack}/{Order.ProductUnitAmount}{" "}
            {Order.ProductUnitMesure}
          </td>
          <td>{Order.ProductDescription}</td>
          <td>{PrintDate(Order.ProductBidDay)}</td>
        </tr>
      );
    });
  }
  render(): JSX.Element {
    const OverFlowStyle: React.CSSProperties = {
      width: "100%",
      height: `${this.props.innerHeight - 290}px`,
      overflowY: "scroll",
    };
    const OrderTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const OrderTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    const OrderProfile: IProfile = this.props.ProfileCollection.find(
      (profile) =>
        profile.ref?.id === this.props.CurrentOrderSelected.OrderProfile?.id
    ) as IProfile;
    const OrderLastCheckProfile: IProfile = this.props.ProfileCollection.find(
      (profile) =>
        profile.ref?.id ===
        this.props.CurrentOrderSelected.OrderLastCheckProfile?.id
    ) as IProfile;

    const OrderProfileTitle: string =
      (this.props.CurrentOrderSelected.OrderRequireCheck ||
        this.props.CurrentOrderSelected.OrderRequireInvoiceCheck) &&
      !OrderLastCheckProfile
        ? "Order Profile (Cheking Pending)"
        : (this.props.CurrentOrderSelected.OrderRequireCheck ||
            this.props.CurrentOrderSelected.OrderRequireInvoiceCheck) &&
          OrderLastCheckProfile
        ? "Last Check Profile (Cheking Pending)"
        : !this.props.CurrentOrderSelected.OrderRequireCheck &&
          !this.props.CurrentOrderSelected.OrderRequireInvoiceCheck &&
          OrderLastCheckProfile
        ? "Last Check Profile"
        : OrderProfile
        ? "Order Profile"
        : "N/A";

    const sProfile: string =
      (this.props.CurrentOrderSelected.OrderRequireCheck ||
        this.props.CurrentOrderSelected.OrderRequireInvoiceCheck) &&
      !OrderLastCheckProfile
        ? OrderProfile.ProfileContactName
        : (this.props.CurrentOrderSelected.OrderRequireCheck ||
            this.props.CurrentOrderSelected.OrderRequireInvoiceCheck) &&
          OrderLastCheckProfile
        ? OrderLastCheckProfile.ProfileContactName
        : !this.props.CurrentOrderSelected.OrderRequireCheck &&
          !this.props.CurrentOrderSelected.OrderRequireInvoiceCheck &&
          OrderLastCheckProfile
        ? OrderLastCheckProfile.ProfileContactName
        : OrderProfile
        ? OrderProfile.ProfileContactName
        : "N/A";

    const OrderDate: firebase.firestore.Timestamp = this.props
      .CurrentOrderSelected.OrderDate;
    const OrderLastCheckDate: firebase.firestore.Timestamp | undefined = this
      .props.CurrentOrderSelected.OrderLastCheckDate;

    const sDate: string =
      this.props.CurrentOrderSelected.OrderRequireCheck && !OrderLastCheckDate
        ? PrintDate(OrderDate)
        : this.props.CurrentOrderSelected.OrderRequireCheck &&
          OrderLastCheckDate
        ? PrintDate(OrderLastCheckDate)
        : !this.props.CurrentOrderSelected.OrderRequireCheck &&
          OrderLastCheckDate
        ? PrintDate(OrderLastCheckDate)
        : OrderDate
        ? PrintDate(OrderDate)
        : "N/A";

    const sTime: string =
      this.props.CurrentOrderSelected.OrderRequireCheck && !OrderLastCheckDate
        ? PrintTime(OrderDate)
        : this.props.CurrentOrderSelected.OrderRequireCheck &&
          OrderLastCheckDate
        ? PrintTime(OrderLastCheckDate)
        : !this.props.CurrentOrderSelected.OrderRequireCheck &&
          OrderLastCheckDate
        ? PrintTime(OrderLastCheckDate)
        : OrderDate
        ? PrintTime(OrderDate)
        : "N/A";

    const OrderDelivery: string = PrintDate(
      this.props.CurrentOrderSelected.OrderDelivery
    );
    const OrderTotal: string = this.state.OrderTotal.toFixed(2).toString();
    const OrderPacks: string = this.state.OrderPacks.toString();
    return (
      <div>
        <span style={{ position: "fixed", bottom: 15, right: 10, zIndex: 5 }}>
          <PrimaryButton
            id={`-1 ViewOrderAllItems`}
            onClick={this.handleShowOrderCheckedDialogHidden}
            iconProps={{ iconName: "EntryView" }}
            disabled={this.state.isUpdating}
          >
            {this.state.isUpdating ? (
              <Spinner size={SpinnerSize.small} />
            ) : (
              `View Details`
            )}
          </PrimaryButton>
        </span>
        <table style={OrderTableStyle}>
          <thead style={OrderTableHeadStyle}>
            <tr>
              <th style={{ width: "10%", textAlign: "center" }}>Date</th>
              <th style={{ width: "10%", textAlign: "center" }}>Time</th>
              <th style={{ width: "25%" }}>{OrderProfileTitle}</th>
              <th style={{ width: "10%" }}>Delivery</th>
              <th style={{ width: "15%" }}>Vendors</th>
              <th style={{ width: "10%" }}>Cases</th>
              <th style={{ width: "10%" }}>Total Ordered</th>
              {this.props.CurrentOrderSelected.OrderTotalChecked !==
                undefined &&
                (this.props.CurrentOrderSelected.OrderTotalChecked < 0 ? (
                  <th style={{ width: "10%" }}>Total Credit</th>
                ) : (
                  <th style={{ width: "10%" }}>Total Checked</th>
                ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "center" }}>{sDate}</td>
              <td style={{ textAlign: "center" }}>{sTime}</td>
              <td>{sProfile}</td>
              <td style={{ textAlign: "center" }}>{OrderDelivery}</td>
              <td style={{ textAlign: "center" }}>
                <Dropdown
                  id={`-1 Provider`}
                  selectedKey={this.state.ProviderFilter}
                  onChange={(event, option) =>
                    this.filterByProvider(
                      option?.key.toString() as string,
                      this.state.ProductSearch
                    )
                  }
                  options={this.state.ProviderCollectionOptions}
                />
              </td>
              <td style={{ textAlign: "center" }}>{OrderPacks}</td>
              <td style={{ textAlign: "center", fontWeight: "bold" }}>
                ${OrderTotal}
              </td>
              {this.props.CurrentOrderSelected.OrderTotalChecked !==
                undefined && (
                <td style={{ textAlign: "center", fontWeight: "bold" }}>
                  $
                  {this.props.CurrentOrderSelected.OrderTotalChecked < 0
                    ? (
                        this.props.CurrentOrderSelected.OrderTotalChecked * -1
                      ).toFixed(2)
                    : this.props.CurrentOrderSelected.OrderTotalChecked.toFixed(
                        2
                      )}
                </td>
              )}
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <table style={OrderTableStyle}>
          <thead style={OrderTableHeadStyle}>
            <tr>
              <th style={{ width: "10%" }}>Area</th>
              <th
                style={{
                  width: this.state.ProviderFilter === "all" ? "10%" : "15%",
                }}
              >
                <SearchBox
                  id={`-1 ProductSearch`}
                  value={this.state.ProductSearch}
                  placeholder="Search Item..."
                  onChange={(event, value) => {
                    const ProductSearch: string = value
                      ? value.toUpperCase()
                      : "";
                    this.filterByProvider(
                      this.state.ProviderFilter,
                      ProductSearch
                    );
                    this.setState({
                      ProductSearch,
                    });
                  }}
                />
              </th>
              {this.props.Profile.ProfileRole !==
                ProfileRoles.AdminMyrtleBeach &&
                this.props.Profile.ProfileRole !==
                  ProfileRoles.AdminVirginiaBeach && (
                  <th style={{ width: "5%" }}>Amount Ordered ~ Checked</th>
                )}
              {this.state.ProviderFilter === "all" && (
                <th style={{ width: "5%" }}>Vendor</th>
              )}
              <th style={{ width: "10%" }}>Status</th>
              <th
                style={{
                  width:
                    this.props.Profile.ProfileRole !==
                      ProfileRoles.AdminMyrtleBeach &&
                    this.props.Profile.ProfileRole !==
                      ProfileRoles.AdminVirginiaBeach
                      ? "10%"
                      : "20%",
                }}
              >
                Amount / Price
              </th>
              {this.props.Profile.ProfileRole !==
                ProfileRoles.AdminMyrtleBeach &&
                this.props.Profile.ProfileRole !==
                  ProfileRoles.AdminVirginiaBeach && (
                  <th style={{ width: "5%" }}>Case Price</th>
                )}
              <th style={{ width: "10%" }}>Units per Case</th>
              <th style={{ width: "10%" }}>Description</th>
              <th style={{ width: "10%" }}>Bid Day</th>
              <th style={{ width: "5%" }}>
                <Icon iconName="DeliveryTruck" /> Truck Check
              </th>
              <th style={{ width: "10%" }}>
                <Icon iconName="Money" /> Invoice Check
              </th>
            </tr>
          </thead>
        </table>
        <hr></hr>
        <div style={OverFlowStyle}>
          <table style={OrderTableStyle}>
            <tbody>
              {this.renderOrderProducts(this.state.OrderProducts)}
              <tr>
                <td>
                  <div style={{ height: "75px" }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {!this.state.isOrderCheckedDialogHidden && (
          <Dialog
            hidden={this.state.isOrderCheckedDialogHidden}
            onDismiss={this.handleHideOrderCheckedDialogHidden}
            dialogContentProps={{
              type: DialogType.largeHeader,
              title: `Notify Order from ${PrintDate(
                this.props.CurrentOrderSelected.OrderDate
              )}?`,
              closeButtonAriaLabel: "Close",
              subText: "Do you want to notify this Order?",
            }}
            modalProps={{
              containerClassName: "ms-dialogMainOverride textDialog",
              isBlocking: true,
              styles: { main: { maxWidth: 400 } },
            }}
          >
            {this.state.OrderProducts.length > 0 && (
              <span>
                <table style={OrderTableStyle}>
                  <thead style={OrderTableHeadStyle}>
                    <tr>
                      <th>Area</th>
                      <th>Item</th>
                      <th>Provider</th>
                      <th>
                        <Icon iconName="DeliveryTruck" /> Truck Status
                      </th>
                      <th>Amount Ordered</th>
                      <th>Amount Checked</th>
                      <th>Return Amount</th>
                      <th>Return Reason</th>
                      <th>
                        <Icon iconName="Money" /> Invoice Status
                      </th>
                      <th>Invoice Amount</th>
                      <th>Invoice Price</th>
                      <th>Case Price</th>
                      <th>Units per Case</th>
                      <th>Description</th>
                      <th>Bid Day</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderRowsOrderChecked(
                      this.state.OrderProductsChecked
                    )}
                  </tbody>
                </table>
                <hr />
                <table>
                  <tfoot>
                    <tr key={"OrderTotal"}>
                      <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        {this.props.CurrentOrderSelected.OrderTotalChecked &&
                        this.props.CurrentOrderSelected.OrderTotalChecked < 0
                          ? "Total Credit:"
                          : "Total Checked:"}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ textAlign: "right", fontWeight: "bold" }}
                      >{`$${
                        this.props.CurrentOrderSelected.OrderTotalChecked
                          ? this.props.CurrentOrderSelected.OrderTotalChecked <
                            0
                            ? (
                                this.props.CurrentOrderSelected
                                  .OrderTotalChecked * -1
                              ).toFixed(2)
                            : this.props.CurrentOrderSelected.OrderTotalChecked.toFixed(
                                2
                              )
                          : 0
                      }`}</td>
                    </tr>
                  </tfoot>
                </table>
                <hr />
              </span>
            )}
            <DialogFooter>
              <PrimaryButton
                onClick={() => this.NotifyOrderChecked()}
                disabled={
                  this.state.isUpdating ||
                  this.state.OrderProductsChecked.findIndex(
                    (p) => p.ProductOrderRequireCheck === false
                  ) === -1
                }
              >
                {this.state.isNotifyingStaff ? (
                  <Spinner size={SpinnerSize.small} />
                ) : (
                  <span>
                    <Icon iconName="Money" /> Request Invoice Check
                  </span>
                )}
              </PrimaryButton>
              <PrimaryButton
                onClick={() => this.NotifyOrderChecked(true)}
                disabled={
                  this.state.isUpdating ||
                  this.state.OrderProductsChecked.findIndex(
                    (p) =>
                      p.ProductOrderRequireCheck === false &&
                      p.ProductOrderInvoiceRequireCheck === false
                  ) === -1 ||
                  (this.props.Profile.ProfileRole !== ProfileRoles.Dev &&
                    !this.props.Profile.ProfileRole.includes(
                      ProfileRoles.Admin
                    ))
                }
              >
                {this.state.isNotifyingProvider ? (
                  <Spinner size={SpinnerSize.small} />
                ) : (
                  <span>
                    <Icon iconName="DeliveryTruck" /> Request Vendor Credit
                  </span>
                )}
              </PrimaryButton>
              <DefaultButton
                disabled={
                  this.state.isUpdating ||
                  (this.props.Profile.ProfileRole !== ProfileRoles.Dev &&
                    !this.props.Profile.ProfileRole.includes(
                      ProfileRoles.Admin
                    ))
                }
                onClick={() => this.createExcel()}
              >
                {this.state.isExporting ? (
                  <Spinner size={SpinnerSize.small} />
                ) : (
                  <span>
                    <Icon iconName="Export" /> Export to Excel
                  </span>
                )}
              </DefaultButton>
              <DefaultButton
                disabled={this.state.isUpdating}
                onClick={this.handleHideOrderCheckedDialogHidden}
                text="Close"
              />
            </DialogFooter>
          </Dialog>
        )}
      </div>
    );
  }
}
