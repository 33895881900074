import React, { Component } from "react";
import {
  IRestaurantOrder,
  IRestaurantItem,
  IProvider,
  IProviderBid,
  IProviderItemBid,
  ProductOrderInvoiceStates,
  IRestaurantItemOrder,
  IRestaurantArea,
  IRestaurantCG,
  IProfile,
  ProfileRoles,
} from "../../models/IFirestoreModels";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import {
  createExcelLink,
  getDaysBetween,
  PrintDate,
  PrintDateWithMonthName,
  PrintMoney,
  PrintTime,
} from "../../util/functions";
import {
  DefaultButton,
  Dropdown,
  Icon,
  IDropdownOption,
  Spinner,
  SpinnerSize,
  Toggle,
} from "office-ui-fabric-react";
import firebase from "firebase/app";
import IApi from "../../api/IApi";

interface IOrderGraphProps {
  Api: IApi;
  Profile: IProfile;
  Restaurant: IRestaurantCG;
  AreaCollection: Array<IRestaurantArea>;
  ProviderCollection: Array<IProvider>;
  ProductCollection: Array<IRestaurantItem>;
  RestaurantOrderCollection: Array<IRestaurantOrder>;
  ProviderBidCollection: Array<IProviderBid>;
  ProductsSearch: Array<{ label: string; value: string; disabled?: boolean }>;
  StartDate: Date;
  EndDate: Date;
  innerHeight: number;
  innerWidth: number;

  isLoadingGraphs: boolean;
  LoadedGraphs: () => void;
}
interface IOrderGraphState {
  RestaurantOrderCollectionFiltered: Array<IRestaurantOrder>;
  totalExpenses: number;
  labels: Array<string>;
  datasets: Array<{
    label: string;
    borderColor: string;
    data: Array<number>;
  }>;
  labelsProviders: Array<string>;
  datasetsProviders: {
    backgroundColor: Array<string>;
    data: Array<number>;
  };

  ForecastMode: boolean;
  timeframe: number;
  TimeFrameOptions: Array<IDropdownOption>;
  StartDate: Date;
  EndDate: Date;
  isExporting: boolean;
}
export default class OrderGraph extends Component<
  IOrderGraphProps,
  IOrderGraphState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      RestaurantOrderCollectionFiltered: [],
      totalExpenses: 0,
      labels: [],
      datasets: [],
      labelsProviders: [],
      datasetsProviders: { backgroundColor: [], data: [] },

      ForecastMode: false,
      timeframe: 1,
      TimeFrameOptions: [],
      StartDate: this.props.StartDate,
      EndDate: this.props.EndDate,
      isExporting: false,
    };
    this.Api = this.props.Api;
  }
  componentDidMount() {
    const StartDate: Date =
      this.props.RestaurantOrderCollection.length > 0
        ? new Date(
            this.props.RestaurantOrderCollection.reduce(
              (
                previousValue: IRestaurantOrder,
                currentValue: IRestaurantOrder
              ) =>
                previousValue.OrderDate < currentValue.OrderDate
                  ? previousValue
                  : currentValue
            )
              .OrderDate.toDate()
              .getTime()
          )
        : this.props.StartDate;
    const EndDate: Date =
      this.props.RestaurantOrderCollection.length > 0
        ? new Date(
            this.props.RestaurantOrderCollection.reduce(
              (
                previousValue: IRestaurantOrder,
                currentValue: IRestaurantOrder
              ) =>
                previousValue.OrderDate > currentValue.OrderDate
                  ? previousValue
                  : currentValue
            )
              .OrderDate.toDate()
              .getTime()
          )
        : this.props.EndDate;
    let labels: Array<string> = [];
    //Datasets Initialization
    setTimeout(() => {
      let totalExpenses: number = 0;
      let ExpensesData: Array<number> = [];
      let CreditData: Array<number> = [];
      let ExpectedDataSet: Array<{
        label: string;
        borderColor: string;
        data: Array<number>;
      }> = [];
      let labelsProviders: Array<string> = [];
      let datasetsProviders: {
        backgroundColor: Array<string>;
        data: Array<number>;
      } = { backgroundColor: [], data: [] };
      let RestaurantOrderCollectionFiltered: Array<IRestaurantOrder> = [];
      if (this.props.ProductsSearch.length > 0) {
        const filterIds: Array<string> = this.props.ProductsSearch.map(
          (p) => p.value
        );
        RestaurantOrderCollectionFiltered = this.props.RestaurantOrderCollection.map(
          (order) => {
            return {
              ...order,
              OrderProducts: order.OrderProducts.filter((p) =>
                filterIds.includes(p.ProductGUID?.id as string)
              ),
              ReturnedProducts: order.ReturnedProducts
                ? order.ReturnedProducts.filter((p) =>
                    filterIds.includes(p.ProductGUID?.id as string)
                  )
                : [],
            };
          }
        );
      } else {
        RestaurantOrderCollectionFiltered = this.props.RestaurantOrderCollection.map(
          (order) => {
            return {
              ...order,
            };
          }
        );
      }
      this.setState({ RestaurantOrderCollectionFiltered });
      let t1 = new Date(StartDate.getTime());
      while (t1 <= EndDate) {
        labels.push(PrintDateWithMonthName(t1, true));
        let t_plusTimeFrame: Date = new Date(t1.getTime());
        t_plusTimeFrame.setDate(
          t_plusTimeFrame.getDate() + this.state.timeframe
        );
        const currentOrders = RestaurantOrderCollectionFiltered.filter(
          (order) =>
            order.OrderDate.toDate() >= t1 &&
            order.OrderDate.toDate() <= t_plusTimeFrame
        );
        let currentOrdersTotal = 0;
        currentOrders.forEach((order: IRestaurantOrder) => {
          order.OrderProducts.forEach((product) => {
            currentOrdersTotal +=
              (product.ProductOrderInvoiceState ===
                ProductOrderInvoiceStates.AllGood ||
                product.ProductOrderInvoiceState === undefined) &&
              product.ProductOrderInvoiceAmount &&
              product.ProductOrderInvoicePrice
                ? product.ProductOrderInvoiceAmount *
                  product.ProductOrderInvoicePrice
                : product.ProductOrderIncomingAmount
                ? product.ProductOrderIncomingAmount * product.ProductPackPrice
                : product.ProductOrderAmount * product.ProductPackPrice;
          });
          order.ReturnedProducts &&
            order.ReturnedProducts.forEach(
              (product) =>
                (currentOrdersTotal -=
                  (product.ProductOrderInvoiceState ===
                    ProductOrderInvoiceStates.AllGood ||
                    product.ProductOrderInvoiceState === undefined) &&
                  product.ProductOrderInvoiceAmount &&
                  product.ProductOrderInvoicePrice
                    ? product.ProductOrderInvoiceAmount *
                      product.ProductOrderInvoicePrice
                    : product.ProductOrderIncomingAmount
                    ? product.ProductOrderIncomingAmount *
                      product.ProductPackPrice
                    : product.ProductOrderAmount * product.ProductPackPrice)
            );
        });
        totalExpenses += currentOrdersTotal;
        ExpensesData.push(
          currentOrdersTotal > 0 ? Number(currentOrdersTotal.toFixed(2)) : 0
        );
        CreditData.push(
          currentOrdersTotal < 0
            ? Number((currentOrdersTotal * -1).toFixed(2))
            : 0
        );
        t1.setDate(t1.getDate() + this.state.timeframe);
      }
      const BorderColors: Array<string> = [
        "MediumSeaGreen",
        "Tomato",
        "Violet",
        "SlateBlue",
        "DarkGreen",
        "Indigo",
        "FireBrick",
        "MediumBlue",
        "DeepPink",
        "DarkSalmon",
        "LightBlue",
        "Lime",
      ];
      let colorIndex = 0;
      this.props.ProviderCollection.forEach((provider) => {
        labelsProviders.push(provider.ProviderName);
        datasetsProviders.backgroundColor.push(
          colorIndex < BorderColors.length ? BorderColors[colorIndex] : "Gray"
        );
        let providerTotal = 0;
        RestaurantOrderCollectionFiltered.forEach((order) => {
          order.OrderProducts.filter(
            (p) => p.ProviderGUID?.id === provider.ref?.id
          ).forEach((product) => {
            providerTotal +=
              (product.ProductOrderInvoiceState ===
                ProductOrderInvoiceStates.AllGood ||
                product.ProductOrderInvoiceState === undefined) &&
              product.ProductOrderInvoiceAmount &&
              product.ProductOrderInvoicePrice
                ? product.ProductOrderInvoiceAmount *
                  product.ProductOrderInvoicePrice
                : product.ProductOrderIncomingAmount
                ? product.ProductOrderIncomingAmount * product.ProductPackPrice
                : product.ProductOrderAmount * product.ProductPackPrice;
          });
          order.ReturnedProducts &&
            order.ReturnedProducts.filter(
              (p) => p.ProviderGUID?.id === provider.ref?.id
            ).forEach(
              (product) =>
                (providerTotal -=
                  (product.ProductOrderInvoiceState ===
                    ProductOrderInvoiceStates.AllGood ||
                    product.ProductOrderInvoiceState === undefined) &&
                  product.ProductOrderInvoiceAmount &&
                  product.ProductOrderInvoicePrice
                    ? product.ProductOrderInvoiceAmount *
                      product.ProductOrderInvoicePrice
                    : product.ProductOrderIncomingAmount
                    ? product.ProductOrderIncomingAmount *
                      product.ProductPackPrice
                    : product.ProductOrderAmount * product.ProductPackPrice)
            );
        });
        datasetsProviders.data.push(
          providerTotal ? Number(providerTotal.toFixed(2)) : 0
        );
        if (this.state.ForecastMode) {
          let ExpectedData: Array<number> = [];
          let t2 = new Date(StartDate.getTime());
          while (t2 <= EndDate) {
            let t_plusTimeFrame: Date = new Date(t2.getTime());
            t_plusTimeFrame.setDate(
              t_plusTimeFrame.getDate() + this.state.timeframe
            );
            const currentOrders = RestaurantOrderCollectionFiltered.filter(
              (order) =>
                order.OrderDate.toDate() >= t2 &&
                order.OrderDate.toDate() <= t_plusTimeFrame
            );
            let t_minus30Days: Date = new Date(t2.getTime());
            t_minus30Days.setDate(t_minus30Days.getDate() - 30);
            const pastBids_30Days = this.props.ProviderBidCollection.filter(
              (b) =>
                b.ProductBidDay.toDate() >= t_minus30Days &&
                b.ProductBidDay.toDate() <= t2 &&
                b.ProviderGUID?.id === provider.ref?.id
            );
            let currentOrdersProvider = 0;
            currentOrders.forEach((order: IRestaurantOrder) => {
              order.OrderProducts.forEach((product) => {
                const SameProducts = this.getPossibleProductBids(
                  this.props.ProviderCollection,
                  pastBids_30Days,
                  product
                );
                if (SameProducts.length > 0) {
                  currentOrdersProvider +=
                    (product.ProductOrderInvoiceState ===
                      ProductOrderInvoiceStates.AllGood ||
                      product.ProductOrderInvoiceState === undefined) &&
                    product.ProductOrderInvoiceAmount
                      ? product.ProductOrderInvoiceAmount *
                        SameProducts[0].ProductBid.ProductPackPrice
                      : product.ProductOrderIncomingAmount
                      ? product.ProductOrderIncomingAmount *
                        SameProducts[0].ProductBid.ProductPackPrice
                      : product.ProductOrderAmount *
                        SameProducts[0].ProductBid.ProductPackPrice;
                } else {
                  currentOrdersProvider +=
                    (product.ProductOrderInvoiceState ===
                      ProductOrderInvoiceStates.AllGood ||
                      product.ProductOrderInvoiceState === undefined) &&
                    product.ProductOrderInvoiceAmount &&
                    product.ProductOrderInvoicePrice
                      ? product.ProductOrderInvoiceAmount *
                        product.ProductOrderInvoicePrice
                      : product.ProductOrderIncomingAmount
                      ? product.ProductOrderIncomingAmount *
                        product.ProductPackPrice
                      : product.ProductOrderAmount * product.ProductPackPrice;
                }
              });
              order.ReturnedProducts &&
                order.ReturnedProducts.forEach((product) => {
                  const SameProducts = this.getPossibleProductBids(
                    this.props.ProviderCollection,
                    pastBids_30Days,
                    product
                  );
                  if (SameProducts.length > 0) {
                    currentOrdersProvider -=
                      (product.ProductOrderInvoiceState ===
                        ProductOrderInvoiceStates.AllGood ||
                        product.ProductOrderInvoiceState === undefined) &&
                      product.ProductOrderInvoiceAmount &&
                      product.ProductOrderInvoicePrice
                        ? product.ProductOrderInvoiceAmount *
                          SameProducts[0].ProductBid.ProductPackPrice
                        : product.ProductOrderIncomingAmount
                        ? product.ProductOrderIncomingAmount *
                          SameProducts[0].ProductBid.ProductPackPrice
                        : product.ProductOrderAmount *
                          SameProducts[0].ProductBid.ProductPackPrice;
                  } else {
                    currentOrdersProvider -=
                      (product.ProductOrderInvoiceState ===
                        ProductOrderInvoiceStates.AllGood ||
                        product.ProductOrderInvoiceState === undefined) &&
                      product.ProductOrderInvoiceAmount &&
                      product.ProductOrderInvoicePrice
                        ? product.ProductOrderInvoiceAmount *
                          product.ProductOrderInvoicePrice
                        : product.ProductOrderIncomingAmount
                        ? product.ProductOrderIncomingAmount *
                          product.ProductPackPrice
                        : product.ProductOrderAmount * product.ProductPackPrice;
                  }
                });
            });
            ExpectedData.push(
              currentOrdersProvider
                ? Number(currentOrdersProvider.toFixed(2))
                : 0
            );
            t2.setDate(t2.getDate() + this.state.timeframe);
          }
          ExpectedDataSet.push({
            label: provider.ProviderName,
            borderColor:
              colorIndex < BorderColors.length
                ? BorderColors[colorIndex]
                : "Gray",
            data: ExpectedData,
          });
        }
        colorIndex++;
      });
      let TimeFrameOptions = [{ key: "1", text: "Day" }];
      if (getDaysBetween(StartDate, EndDate) > 7) {
        TimeFrameOptions.push({ key: "7", text: "Week" });
      }
      if (getDaysBetween(StartDate, EndDate) > 30) {
        TimeFrameOptions.push({ key: "30", text: "Month" });
      }
      if (getDaysBetween(StartDate, EndDate) > 90) {
        TimeFrameOptions.push({ key: "90", text: "3 Month" });
      }
      if (getDaysBetween(StartDate, EndDate) > 180) {
        TimeFrameOptions.push({ key: "180", text: "6 Month" });
      }
      if (getDaysBetween(StartDate, EndDate) > 365) {
        TimeFrameOptions.push({ key: "365", text: "Year" });
      }
      this.setState({
        totalExpenses,
        labels,
        datasets: [
          {
            label: "Expenses",
            borderColor: "DodgerBlue",
            data: ExpensesData,
          },
          {
            label: "Credit",
            borderColor: "Orange",
            data: CreditData,
          },
        ].concat(ExpectedDataSet),
        labelsProviders,
        datasetsProviders,

        StartDate,
        EndDate,
        timeframe:
          getDaysBetween(StartDate, EndDate) < this.state.timeframe
            ? 1
            : this.state.timeframe,
        TimeFrameOptions,
      });
      this.props.LoadedGraphs();
    }, 50);
  }
  componentDidUpdate(
    prevProps: IOrderGraphProps,
    prevState: IOrderGraphState,
    snapshot: any
  ) {
    if (
      this.props.RestaurantOrderCollection !==
        prevProps.RestaurantOrderCollection ||
      this.props.isLoadingGraphs !== prevProps.isLoadingGraphs ||
      this.state.timeframe !== prevState.timeframe ||
      this.state.ForecastMode !== prevState.ForecastMode
    ) {
      this.componentDidMount();
    }
  }
  renderRows(
    datasets: Array<{
      label: string;
      data: number;
    }>
  ): Array<JSX.Element> {
    return datasets
      .sort((A, B) => (A.data > B.data ? -1 : A.data < B.data ? 1 : 0))
      .map((dataset, index) => {
        return (
          <tr key={index}>
            <td>{dataset.label}</td>
            <td>{`${PrintMoney(dataset.data)}`}</td>
            <td>{`${
              dataset.data
                ? (100 / this.state.totalExpenses) * dataset.data >= 0.01
                  ? ((100 / this.state.totalExpenses) * dataset.data).toFixed(2)
                  : 0.01
                : 0
            }%`}</td>
          </tr>
        );
      });
  }
  render(): JSX.Element {
    const OverFlowStyle: React.CSSProperties = {
      marginRight: "5px",
      width: "99%",
      height: `${this.props.innerHeight - 150}px`,
      overflowY: "scroll",
    };
    const GraphTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const GraphTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    return (
      <div>
        <table style={GraphTableStyle}>
          <thead style={GraphTableHeadStyle}>
            <tr>
              <th style={{ width: "30%", height: "30px" }}>Expenses</th>
              <th style={{ width: "30%", height: "30px" }}>
                {PrintMoney(this.state.totalExpenses)}
              </th>
              <th style={{ width: "20%", height: "30px" }}>
                <DefaultButton
                  style={{ width: "100%" }}
                  onClick={() =>
                    this.getCreditReport(
                      this.props.AreaCollection,
                      this.props.ProductCollection,
                      this.props.ProviderCollection
                    )
                  }
                  disabled={
                    this.state.isExporting ||
                    this.props.Profile.ProfileRole !== ProfileRoles.Dev
                  }
                >
                  {this.state.isExporting ? (
                    <Spinner size={SpinnerSize.small} />
                  ) : (
                    <span>
                      <Icon iconName="Money" /> Credit Report
                    </span>
                  )}
                </DefaultButton>
              </th>
              <th style={{ width: "20%", height: "30px" }}>
                <DefaultButton
                  style={{ width: "100%" }}
                  onClick={() =>
                    this.getSavingReport(
                      this.props.AreaCollection,
                      this.props.ProductCollection,
                      this.props.ProviderCollection
                    )
                  }
                  disabled={
                    this.state.isExporting ||
                    this.props.Profile.ProfileRole !== ProfileRoles.Dev
                  }
                >
                  {this.state.isExporting ? (
                    <Spinner size={SpinnerSize.small} />
                  ) : (
                    <span>
                      <Icon iconName="Money" /> Savings Report
                    </span>
                  )}
                </DefaultButton>
              </th>
            </tr>
          </thead>
        </table>
        <hr></hr>
        <div style={OverFlowStyle}>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "70%" }}>
                  <Pie
                    data={{
                      labels: this.state.labelsProviders,
                      datasets: [this.state.datasetsProviders],
                    }}
                  />
                </td>
                <td style={{ width: "30%" }}>
                  <table style={GraphTableStyle}>
                    <thead style={GraphTableHeadStyle}>
                      <tr>
                        <th style={{ width: "40%", height: "30px" }}>Vendor</th>
                        <th style={{ width: "40%", height: "30px" }}>Amount</th>
                        <th style={{ width: "20%", height: "30px" }}>%</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderRows(
                        this.state.labelsProviders.map((label, index) => {
                          return {
                            label,
                            data: this.state.datasetsProviders.data[index],
                          };
                        })
                      )}
                      <tr>
                        <td>
                          <div
                            style={{
                              height:
                                350 - this.state.labelsProviders.length * 15,
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <hr></hr>
          <table style={GraphTableStyle}>
            <thead style={GraphTableHeadStyle}>
              <tr>
                <th style={{ width: "40%", height: "30px" }}>
                  {`From ${PrintDateWithMonthName(
                    this.state.StartDate
                  )} ==> To ${PrintDateWithMonthName(this.state.EndDate)}`}
                </th>
                <th style={{ width: "20%", height: "30px" }}>
                  <Toggle
                    color={"White"}
                    checked={this.state.ForecastMode}
                    onText="Vendors Forecast"
                    offText="Expenses Only"
                    onChange={(e, checked) =>
                      this.setState({ ForecastMode: checked === true })
                    }
                  />
                </th>
                <th style={{ width: "20%", height: "30px" }}>
                  <Dropdown
                    selectedKey={this.state.timeframe.toString()}
                    options={this.state.TimeFrameOptions}
                    onChange={(
                      event: any,
                      option?: IDropdownOption | undefined
                    ) =>
                      option && this.setState({ timeframe: Number(option.key) })
                    }
                    style={{ width: "100%" }}
                  />
                </th>
              </tr>
            </thead>
          </table>
          <hr></hr>
          <Line
            data={{
              labels: this.state.labels,
              datasets: this.state.datasets,
            }}
          />
          <div
            style={{
              height: "80px",
            }}
          />
        </div>
      </div>
    );
  }
  async getSavingReport(
    AreaCollection: Array<IRestaurantArea>,
    ProductCollection: Array<IRestaurantItem>,
    ProviderCollection: Array<IProvider>
  ) {
    this.setState({ isExporting: true });
    const filterIds: Array<string> = this.props.ProductsSearch.map(
      (p) => p.value
    );
    let ProductCollectionFiltered = [];
    if (filterIds.length > 0) {
      ProductCollectionFiltered = ProductCollection.filter((Product) =>
        filterIds.includes(Product.ref?.id as string)
      );
    } else {
      ProductCollectionFiltered = ProductCollection;
    }
    let SheetData: Array<Array<string | number>> = [
      ["Area", "Item", "Amount Ordered", "Actual Total Expenses"].concat(
        ProviderCollection.map((p) => `Estimate ${p.ProviderName} Only`)
      ),
    ];
    ProductCollectionFiltered.sort((A, B) => {
      if (Number(A.ProductList) < Number(B.ProductList)) {
        return -1;
      } else if (Number(A.ProductList) > Number(B.ProductList)) {
        return 1;
      }
      return 0;
    })
      .sort((A, B) => {
        const AreaA = AreaCollection.find((a) => a.ref?.id === A.AreaGUID?.id);
        const AreaB = AreaCollection.find((a) => a.ref?.id === B.AreaGUID?.id);
        if (
          Number(AreaA ? AreaA.AreaList : 0) <
          Number(AreaB ? AreaB.AreaList : 0)
        ) {
          return -1;
        } else if (
          Number(AreaA ? AreaA.AreaList : 0) >
          Number(AreaB ? AreaB.AreaList : 0)
        ) {
          return 1;
        }
        return 0;
      })
      .forEach((Product) => {
        const Area: IRestaurantArea = AreaCollection.find(
          (area) => area.ref?.id === Product.AreaGUID?.id
        ) as IRestaurantArea;
        let ProductAmountOrdered: number = 0;
        let ProductTotalExpenses: number = 0;
        this.state.RestaurantOrderCollectionFiltered.forEach((o) => {
          const Products = o.OrderProducts.filter(
            (p) => p.ProductGUID?.id === Product.ref?.id
          );
          Products.forEach((product) => {
            ProductAmountOrdered += product.ProductOrderAmount;
            ProductTotalExpenses +=
              (product.ProductOrderInvoiceState ===
                ProductOrderInvoiceStates.AllGood ||
                product.ProductOrderInvoiceState === undefined) &&
              product.ProductOrderInvoiceAmount &&
              product.ProductOrderInvoicePrice
                ? product.ProductOrderInvoiceAmount *
                  product.ProductOrderInvoicePrice
                : product.ProductOrderIncomingAmount
                ? product.ProductOrderIncomingAmount * product.ProductPackPrice
                : product.ProductOrderAmount * product.ProductPackPrice;
          });
        });
        let AllProvidersProductTotalExpenses: Array<number> = [];
        ProviderCollection.forEach((provider) => {
          let ProviderProductTotalExpenses: number = 0;
          this.state.RestaurantOrderCollectionFiltered.forEach((o) => {
            const Products = o.OrderProducts.filter(
              (p) => p.ProductGUID?.id === Product.ref?.id
            );
            Products.forEach((product) => {
              let t_minus30Days: Date = new Date(
                o.OrderDate.toDate().getTime()
              );
              t_minus30Days.setDate(t_minus30Days.getDate() - 30);
              const pastBids_30Days = this.props.ProviderBidCollection.filter(
                (b) =>
                  b.ProductBidDay.toDate() >= t_minus30Days &&
                  b.ProductBidDay.toDate() <= o.OrderDate.toDate() &&
                  b.ProviderGUID?.id === provider.ref?.id
              );
              const SameProducts = this.getPossibleProductBids(
                ProviderCollection,
                pastBids_30Days,
                product
              );
              if (SameProducts.length > 0) {
                ProviderProductTotalExpenses +=
                  (product.ProductOrderInvoiceState ===
                    ProductOrderInvoiceStates.AllGood ||
                    product.ProductOrderInvoiceState === undefined) &&
                  product.ProductOrderInvoiceAmount
                    ? product.ProductOrderInvoiceAmount *
                      SameProducts[0].ProductBid.ProductPackPrice
                    : product.ProductOrderIncomingAmount
                    ? product.ProductOrderIncomingAmount *
                      SameProducts[0].ProductBid.ProductPackPrice
                    : product.ProductOrderAmount *
                      SameProducts[0].ProductBid.ProductPackPrice;
              }
              // else {
              //   ProviderProductTotalExpenses -=
              //     (product.ProductOrderInvoiceState ===
              //       ProductOrderInvoiceStates.AllGood ||
              //       product.ProductOrderInvoiceState === undefined) &&
              //     product.ProductOrderInvoiceAmount &&
              //     product.ProductOrderInvoicePrice
              //       ? product.ProductOrderInvoiceAmount *
              //         product.ProductOrderInvoicePrice
              //       : product.ProductOrderIncomingAmount
              //       ? product.ProductOrderIncomingAmount *
              //         product.ProductPackPrice
              //       : product.ProductOrderAmount * product.ProductPackPrice;
              // }
            });
          });
          AllProvidersProductTotalExpenses.push(
            Number(ProviderProductTotalExpenses.toFixed(2))
          );
        });
        SheetData.push(
          [
            `${Area.AreaName}`,
            `${Product.ProductName}`,
            ProductAmountOrdered,
            Number(ProductTotalExpenses.toFixed(2)),
          ].concat(AllProvidersProductTotalExpenses as any)
        );
      });
    let TotalProductAmountOrdered: number = 0;
    let TotalProductTotalExpenses: number = 0;
    let AllTotalProvidersProductTotalExpenses: Array<number> = [];
    for (let index = 1; index < SheetData.length; index++) {
      const row = SheetData[index] as Array<number>;
      TotalProductAmountOrdered += row[2];
      TotalProductTotalExpenses += row[3];
    }
    ProviderCollection.forEach((Provider, ProviderIndex) => {
      let ProviderTotal: number = 0;
      for (let RowIndex = 1; RowIndex < SheetData.length; RowIndex++) {
        const row = SheetData[RowIndex] as Array<number>;
        ProviderTotal += row[ProviderIndex + 4];
      }
      AllTotalProvidersProductTotalExpenses.push(ProviderTotal);
    });
    SheetData.push(
      [
        "Total",
        "=====>",
        TotalProductAmountOrdered,
        TotalProductTotalExpenses,
      ].concat(AllTotalProvidersProductTotalExpenses)
    );
    const link = await createExcelLink(
      this.Api,
      `${
        this.props.Restaurant.RestaurantSubCollectionPaths
          .RestaurantItemOrderCollectionPath
      }/SavingReport/Savings From ${PrintDate(
        this.state.StartDate,
        true
      )} To ${PrintDate(this.state.EndDate, true)}`,
      [{ SheetName: "Savings", SheetData }]
    );
    window.location = link as any;
    this.setState({ isExporting: false });
  }
  async getCreditReport(
    AreaCollection: Array<IRestaurantArea>,
    ProductCollection: Array<IRestaurantItem>,
    ProviderCollection: Array<IProvider>
  ) {
    this.setState({ isExporting: true });
    let Sheets: Array<{
      SheetName: string;
      SheetData: Array<Array<string>>;
    }> = [];
    this.state.RestaurantOrderCollectionFiltered.filter(
      (o) =>
        o.OrderProducts.find(
          (p) =>
            p.ProductOrderInvoiceRequireCheck === false &&
            p.ProductOrderInvoiceState !== ProductOrderInvoiceStates.AllGood
        ) ||
        (o.ReturnedProducts && o.ReturnedProducts.length > 0)
    ).forEach((o, index) => {
      let SheetData: Array<Array<string>> = [
        [
          "Area",
          "Item",
          "Vendor",
          "Truck Status",
          "Amount Ordered",
          "Amount Checked",
          "Return Amount",
          "Return Reason",
          "Invoice Status",
          "Invoice Amount",
          "Invoice Price",
          "Case Price",
          "Units per Case",
          "Description",
          "Bid Day",
        ],
      ];
      let CreditProducts = o.OrderProducts.filter(
        (p) =>
          p.ProductOrderInvoiceRequireCheck === false &&
          p.ProductOrderInvoiceState !== ProductOrderInvoiceStates.AllGood
      );
      if (o.ReturnedProducts && o.ReturnedProducts?.length > 0) {
        CreditProducts = CreditProducts.concat(o.ReturnedProducts);
      }
      CreditProducts.forEach((OrderProduct: IRestaurantItemOrder) => {
        const Area: IRestaurantArea = AreaCollection.find(
          (area) => area.ref?.id === OrderProduct.AreaGUID?.id
        ) as IRestaurantArea;
        const Product: IRestaurantItem = ProductCollection.find(
          (product) => product.ref?.id === OrderProduct.ProductGUID?.id
        ) as IRestaurantItem;
        const Provider: IProvider = ProviderCollection.find(
          (provider) => provider.ref?.id === OrderProduct.ProviderGUID?.id
        ) as IProvider;
        SheetData.push([
          `${Area ? Area.AreaName : "N/A"}`,
          `${Product ? Product.ProductName : "N/A"}`,
          `${Provider ? Provider.ProviderName : "N/A"}`,
          `${
            OrderProduct.ProductOrderState
              ? OrderProduct.ProductOrderState
              : "PickUp"
          }`,
          `${OrderProduct.ProductOrderAmount}`,
          `${
            OrderProduct.ProductOrderIncomingAmount
              ? OrderProduct.ProductOrderIncomingAmount
              : ""
          }`,
          `${
            OrderProduct.ProductOrderReturnAmount
              ? OrderProduct.ProductOrderReturnAmount
              : ""
          }`,
          `${
            OrderProduct.ProductOrderReturnReason
              ? OrderProduct.ProductOrderReturnReason
              : ""
          }`,
          `${
            OrderProduct.ProductOrderInvoiceState
              ? OrderProduct.ProductOrderInvoiceState
              : ""
          }`,
          `${
            OrderProduct.ProductOrderInvoiceAmount
              ? OrderProduct.ProductOrderInvoiceAmount
              : ""
          }`,
          `${
            OrderProduct.ProductOrderInvoicePrice
              ? `$${OrderProduct.ProductOrderInvoicePrice}`
              : ""
          }`,
          `$${OrderProduct.ProductPackPrice}`,
          `${OrderProduct.ProductUnitPerPack}/${OrderProduct.ProductUnitAmount} ${OrderProduct.ProductUnitMesure}`,
          `${OrderProduct.ProductDescription}`,
          `${PrintDate(OrderProduct.ProductBidDay)}`,
        ]);
      });
      Sheets.push({
        SheetName: `Order On ${PrintDate(o.OrderDate, true)} ${PrintTime(
          o.OrderDate,
          true,
          true
        )}`,
        SheetData,
      });
    });
    const link = await createExcelLink(
      this.Api,
      `${
        this.props.Restaurant.RestaurantSubCollectionPaths
          .RestaurantItemOrderCollectionPath
      }/CreditReport/Credits From ${PrintDate(
        this.state.StartDate,
        true
      )} To ${PrintDate(this.state.EndDate, true)}`,
      Sheets
    );
    window.location = link as any;
    this.setState({ isExporting: false });
  }
  getPossibleProductBids(
    ProviderCollection: Array<IProvider>,
    pastBids: Array<IProviderBid>,
    product: IRestaurantItemOrder,
    useStrictBidDay?: boolean
  ): Array<{
    ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    ProviderGUID?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    ProductBidDay: firebase.firestore.Timestamp;
    ProductBid: IProviderItemBid;
  }> {
    const pastBidsWithSameProduct = pastBids
      //Filter Conditions of the Product Bid
      .filter(
        (b) =>
          (useStrictBidDay
            ? b.ProductBidDay === product.ProductBidDay
            : true) &&
          b.ProductBidList.findIndex(
            (p) =>
              p.ProductGUID?.id === product.ProductGUID?.id &&
              p.ProductUnitPerPack === product.ProductUnitPerPack &&
              p.ProductUnitAmount === product.ProductUnitAmount &&
              p.ProductUnitMesure === product.ProductUnitMesure
          ) !== -1
      )
      //Sort to get more recent Bid Day first
      .sort((A, B) =>
        A.ProductBidDay > B.ProductBidDay
          ? -1
          : A.ProductBidDay < B.ProductBidDay
          ? 1
          : 0
      );
    //Filter to get Only One Bid Per Provider
    let pastBidsWithSameProductOnlyOnePerProvider: Array<IProviderBid> = [];
    ProviderCollection.forEach((provider) => {
      let providerPastBidsWithSameProduct = pastBidsWithSameProduct.find(
        (b) => b.ProviderGUID?.id === provider.ref?.id
      );
      if (providerPastBidsWithSameProduct) {
        pastBidsWithSameProductOnlyOnePerProvider.push(
          providerPastBidsWithSameProduct
        );
      }
    });
    return (
      pastBidsWithSameProductOnlyOnePerProvider
        //Map of the Bids to get only the Product Data and Metadata (Provider & Bid Date)
        .map((b) => {
          return {
            ref: b.ref,
            ProviderGUID: b.ProviderGUID,
            ProductBidDay: b.ProductBidDay,
            ProductBid: b.ProductBidList.find(
              (p) => p.ProductGUID?.id === product.ProductGUID?.id
            ) as IProviderItemBid,
          };
        })
        //Sort to get Higher Pack Price First
        .sort((A, B) =>
          A.ProductBid?.ProductPackPrice > B.ProductBid?.ProductPackPrice
            ? -1
            : A.ProductBid?.ProductPackPrice < B.ProductBid?.ProductPackPrice
            ? 1
            : 0
        )
    );
  }
}
