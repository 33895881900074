import firebase from "firebase/app";
import XLSX from "xlsx";
import IApi from "../api/IApi";

export function formatXML(input: string): string {
  // PART 1: Add \n where necessary
  // A) add \n between sets of angled brackets without content between them
  // B) remove \n between opening and closing tags of the same node if no content is between them
  // C) add \n between a self-closing set of angled brackets and the next set
  // D) split it into an array

  let xmlString = input
    .trim()
    .replace(/>\s*</g, ">\n<")
    .replace(/(<[^/>].*>)\n(<[/])/g, "$1$2")
    .replace(/(<\/[^>]+>|<[^>]+\/>)(<[^>]+>)/g, "$1\n$2");
  let xmlArr = xmlString.split("\n");

  // PART 2: indent each line appropriately

  var tabs = ""; //store the current indentation
  var start = 0; //starting line
  if (/^<[?]xml/.test(xmlArr[0])) start++; //if the first line is a header, ignore it

  for (var i = start; i < xmlArr.length; i++) {
    //for each line
    var line = xmlArr[i].trim(); //trim it just in case
    if (/^<[/]/.test(line)) {
      // if the line is a closing tag
      // remove one tab from the store
      // add the tabs at the beginning of the line
      tabs = tabs.replace(/.$/, "");
      xmlArr[i] = tabs + line;
    } else if (/<.*>.*<\/.*>|<.*[^>]\/>/.test(line)) {
      // if the line contains an entire node
      // leave the store as is
      // add the tabs at the beginning of the line
      xmlArr[i] = tabs + line;
    } else {
      // if the line starts with an opening tag and does not contain an entire node
      // add the tabs at the beginning of the line
      // and add one tab to the store
      xmlArr[i] = tabs + line;
      tabs += "\t";
    }
  }

  //rejoin the array to a string and return it
  return xmlArr.join("\n");
}
export function validateEmail(email: string): boolean {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export function getReplaceWith(
  CaseMap: {
    cases: Array<{
      ifCases: Array<string>;
      replaceWith: string;
    }>;
    defaultCase?: string;
  },
  Case: string
): string {
  let isCaseFound: boolean = false;
  let replaceWith: string = "";
  if (Case) {
    CaseMap.cases.forEach((replaceCase) => {
      replaceCase.ifCases.forEach((stringCase: string) => {
        if (removeSpaces(Case).toUpperCase() === stringCase) {
          isCaseFound = true;
          replaceWith = replaceCase.replaceWith;
        }
      });
    });
    Case = isCaseFound ? replaceWith : "NoFoundCase";
  } else {
    if (CaseMap.defaultCase) {
      Case = CaseMap.defaultCase;
    } else {
      Case = "NoDefaultCase";
    }
  }
  return Case;
}
export function prettyStringify(object: any): string {
  return JSON.stringify(JSON.parse(JSON.stringify(object)), null, 2);
}
export function Greeting(): string {
  return new Date().getHours() < 12
    ? "Good Morning"
    : new Date().getHours() < 20
    ? "Good Afternoon"
    : "Good Evening";
}
export function PrintDate(
  Date: Date | firebase.firestore.Timestamp,
  useDash?: boolean
): string {
  const newDate =
    Date instanceof firebase.firestore.Timestamp ? Date.toDate() : Date;
  return `${newDate.getMonth() + 1}${useDash ? "-" : "/"}${newDate.getDate()}${
    useDash ? "-" : "/"
  }${newDate.getFullYear()}`;
}
export function PrintDateWithMonthName(
  Date: Date | firebase.firestore.Timestamp,
  useShortName?: boolean
): string {
  var CompleteName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var ShortName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const newDate =
    Date instanceof firebase.firestore.Timestamp ? Date.toDate() : Date;
  return `${
    useShortName
      ? ShortName[newDate.getMonth()]
      : CompleteName[newDate.getMonth()]
  } ${newDate.getDate()} ${newDate.getFullYear()}`;
}
export function PrintTime(
  Date: Date | firebase.firestore.Timestamp,
  useDash?: boolean,
  use24h?: boolean
): string {
  const newDate =
    Date instanceof firebase.firestore.Timestamp ? Date.toDate() : Date;
  return `${
    use24h
      ? newDate.getHours()
      : newDate.getHours() > 12
      ? newDate.getHours() - 12
      : newDate.getHours()
  }${useDash ? "-" : ":"}${
    newDate.getMinutes() < 10
      ? "0" + newDate.getMinutes()
      : newDate.getMinutes()
  }${use24h ? "" : newDate.getHours() > 12 ? " pm" : " am"}`;
}
export function PrintMoney(Money: number): string {
  const SMoney = Money.toFixed(2);
  return `$${SMoney.substring(
    SMoney.indexOf(".") - 12,
    SMoney.indexOf(".") - 9
  )} ${SMoney.substring(
    SMoney.indexOf(".") - 9,
    SMoney.indexOf(".") - 6
  )} ${SMoney.substring(
    SMoney.indexOf(".") - 6,
    SMoney.indexOf(".") - 3
  )} ${SMoney.substring(
    SMoney.indexOf(".") - 3,
    SMoney.indexOf(".")
  )}${SMoney.substring(SMoney.indexOf("."))}`;
}
export function removeSpaces(str: string): string {
  const strArray = str.split(" ").filter((s) => s !== "");
  return strArray.length > 0 ? strArray.reduce((s1, s2) => `${s1} ${s2}`) : "";
}
export function getDayByWeekOfYear(
  day: number,
  week: number,
  year: number
): Date {
  let t: Date = new Date(year, 0, 1);
  let currentWeek: number = 0;
  if (day >= 0 && day <= 6 && week >= 0 && week <= 52) {
    while (currentWeek < week) {
      do {
        t.setDate(t.getDate() + 1);
      } while (t.getDay() !== day);
      currentWeek += 1;
    }
  }
  return t;
}
export function getDaysBetween(startDate: Date, endDate: Date): number {
  let t: Date = new Date(startDate.getTime());
  let numberOfDays: number = 0;
  while (t < endDate) {
    t.setDate(t.getDate() + 1);
    numberOfDays++;
  }
  return numberOfDays;
}
export function getHash(hash: string, key: string): string | undefined {
  return hash
    .split("#")
    .find((h) => h.startsWith(key))
    ?.replace(`${key}=`, "");
}
export function setHash(hash: string, key: string, value: string): string {
  let hashArray = hash.split("#").filter((h) => !h.startsWith(key));
  hashArray.push(`${key}=${value}`);
  return hashArray.length > 0
    ? hashArray.reduce((s1, s2) => `${s1}#${s2}`)
    : "";
}
export function deleteHash(hash: string, key: string): string {
  let hashArray = hash.split("#").filter((h) => !h.startsWith(key));
  return hashArray.length > 0
    ? hashArray.reduce((s1, s2) => `${s1}#${s2}`)
    : "";
}
export function deleteField(obj: any, field: string): any {
  if (obj[field] !== undefined) {
    delete obj[field];
  }
  return obj;
}
export function s2ab(s: any) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
export async function createExcelLink(
  Api: IApi,
  Path: string,
  Sheets: Array<{ SheetName: string; SheetData: Array<Array<string | number>> }>
): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    try {
      let Workbook: XLSX.WorkBook = XLSX.utils.book_new();
      Sheets.forEach((Sheet) => {
        Workbook.SheetNames.push(Sheet.SheetName);
        Workbook.Sheets[Sheet.SheetName] = XLSX.utils.aoa_to_sheet(
          Sheet.SheetData
        );
        let colsInfo: Array<{ wpx: number }> = [];
        Sheet.SheetData.length > 0 &&
          Sheet.SheetData[0].forEach((col) => {
            colsInfo.push({ wpx: 100 });
          });
        Workbook.Sheets[Sheet.SheetName]["!cols"] = colsInfo;
      });
      const binaryWorkbook = XLSX.write(Workbook, {
        bookType: "xlsx",
        type: "binary",
      });
      Api.putFile(
        `${Path}.xlsx`,
        new Blob([s2ab(binaryWorkbook)], { type: "xlsx" })
      ).on(
        "state_changed",
        (snapshot) =>
          console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
        console.error,
        async () => {
          resolve(await Api.getFile(`${Path}.xlsx`));
        }
      );
    } catch (error) {
      reject(error);
    }
  });
}
