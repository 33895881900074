import React, { useEffect, useMemo, useState } from "react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Label,
  Spinner,
  SpinnerSize,
  TextField,
} from "office-ui-fabric-react";
import CRUD, { CRUDPropertyType } from "../DevelopmentView/CRUD";
import { prettyStringify, PrintDate, PrintTime } from "../../util/functions";
import IApi from "../../api/IApi";
import { IAudit, IAuditFlags, OperationType } from "../../models/IAudit";

export default function ModelEditorAudit(props: {
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
}) {
  // useState
  const [isLoading, setIsLoading] = useState(true as boolean);
  const [AuditCollectionID, setAuditCollectionID] = useState("" as string);
  const [AuditCollection, setAuditCollection] = useState([] as Array<IAudit>);
  const [AuditSelectedRef, setAuditSelectedRef] = useState("" as string);
  // useEffect
  const fetchData = () => {
    props.Api.getCollect(
      "audit",
      AuditCollectionID
        ? [
            {
              fieldPath: "AuditCollectionID",
              opStr: "==",
              value: AuditCollectionID,
            },
          ]
        : undefined,
      (AuditCollection) => {
        setAuditCollection(AuditCollection);
        setIsLoading(false);
      },
      "AuditOperationDate",
      "desc",
      200
    );
  };
  useEffect(fetchData, [props.Api, AuditCollectionID]);
  // useMemo
  const AuditSelected = useMemo(
    () => AuditCollection.find((a: any) => a.ref?.path === AuditSelectedRef),
    [AuditCollection, AuditSelectedRef]
  );
  const AuditSelectedSnapshotChanges = (
    AuditDocumentSnapshotChanges: Array<{
      Operation: OperationType;
      Key: string;
      Value: any;
    }>
  ): Array<JSX.Element> => {
    return AuditDocumentSnapshotChanges.sort((a, b) =>
      a.Operation < b.Operation ? 1 : a.Operation > b.Operation ? -1 : 0
    ).map((Change, index) => {
      let color = "";
      switch (Change.Operation) {
        case OperationType.Create:
          color = "Green";
          break;
        case OperationType.Update:
          color = "DarkOrange";
          break;
        case OperationType.Delete:
          color = "Brown";
          break;
      }
      return (
        <span key={Change.Key}>
          <span style={{ color: "white" }}>{index > 0 ? ", " : ""}</span>
          <span style={{ color }}>
            {`${Change.Key} : ${prettyStringify(Change.Value)}`}
          </span>
        </span>
      );
    });
  };
  return (
    <div>
      <Dropdown
        selectedKey={AuditCollectionID}
        options={AuditCollectionIDChoices}
        onChange={(e, o) => o && setAuditCollectionID(o.key as string)}
      />
      {isLoading ? (
        <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
      ) : (
        <CRUD
          List={AuditCollection.map((a) => {
            return {
              ref: a.ref?.path,
              AuditRef: `${a.ref?.id}${
                a.AuditRollBackREF ? ` &${a.AuditRollBackREF.id}` : ""
              }`,
              AuditAuthUID: a.AuditAuthUID,
              AuditOperationDate:
                a.AuditOperationDate &&
                `${PrintDate(a.AuditOperationDate)} ${PrintTime(
                  a.AuditOperationDate
                )}`,
              AuditOperationDateSort: a.AuditOperationDate,
              AuditOperationType: a.AuditOperationType,
              AuditCollectionID: a.AuditCollectionID,
              AuditDocumentID: `${a.AuditOlderDateFlag ? "@" : ""}${
                a.AuditDocumentID
              }`,
            };
          })}
          ItemName={"Audit"}
          DisplayField={"AuditDocumentID"}
          Structure={[
            {
              Type: CRUDPropertyType.ID,
              Name: "ref",
            },
            {
              Type: CRUDPropertyType.Static,
              Name: "AuditRef",
              DisplayName: "Audit REF",
              IconName: "Database",
              Width: "15%",
              BuiltInFilter: true,
              BuiltInSort: true,
            },
            {
              Type: CRUDPropertyType.Enum,
              Name: "AuditOperationType",
              DisplayName: "Operation Type",
              IconName: "DocumentManagement",
              Width: "10%",
              Settings: {
                Choices: [
                  { key: "Update", text: "Update", value: "Update" },
                  { key: "Create", text: "Create", value: "Create" },
                  { key: "Delete", text: "Delete", value: "Delete" },
                ],
              },
              Enforced: true,
              BuiltInFilter: true,
              BuiltInSort: true,
            },
            {
              Type: CRUDPropertyType.Static,
              Name: "AuditOperationDate",
              DisplayName: "Operation Date",
              IconName: "Clock",
              Width: "10%",
              BuiltInFilter: true,
            },
            {
              Type: CRUDPropertyType.Date,
              Name: "AuditOperationDateSort",
              DisplayName: "Operation Date",
              Hidden: true,
              BuiltInSort: true,
            },
            {
              Type: CRUDPropertyType.Enum,
              Name: "AuditAuthUID",
              DisplayName: "Auth UID",
              IconName: "People",
              Width: "10%",
              Settings: {
                Choices: [
                  {
                    key: "unauthenticated",
                    text: "unauthenticated",
                    value: "unauthenticated",
                  },
                ],
              },
              Enforced: true,
              BuiltInFilter: true,
              BuiltInSort: true,
            },
            {
              Type: CRUDPropertyType.Enum,
              Name: "AuditCollectionID",
              DisplayName: "Collection ID",
              IconName: "DocumentSet",
              Width: "10%",
              Settings: {
                Choices: AuditCollectionIDChoices,
              },
              Enforced: true,
              BuiltInFilter: true,
              BuiltInSort: true,
            },
            {
              Type: CRUDPropertyType.Static,
              Name: "AuditDocumentID",
              DisplayName: "Document ID",
              IconName: "Document",
              Width: "15%",
              BuiltInFilter: true,
              BuiltInSort: true,
              Settings: { Cursor: true },
            },
            {
              Type: CRUDPropertyType.Control,
              Name: "RollBack",
              DisplayName: "Roll Back",
              IconName: "ReturnToSession",
              Width: "10%",
            },
            {
              Type: CRUDPropertyType.Control,
              Name: "Control",
              Width: "20%",
            },
          ]}
          BuiltInFilter={true}
          SortField={"AuditOperationDateSort@Down"}
          BuiltInSort={true}
          onDisable={(item, col) =>
            col.Name === "AuditOperationType" ||
            col.Name === "AuditAuthUID" ||
            col.Name === "AuditCollectionID" ||
            col.Name === "@Update" ||
            col.Name === "@Delete" ||
            col.Name === "@Revert" ||
            col.Name === "@Add" ||
            col.Name === "@AddExtra"
          }
          onClick={(item, col) => {
            if (col.Name === "AuditDocumentID") {
              setAuditSelectedRef(item.ref as string);
            }
          }}
          ActionConfirm={true}
          onAction={async (id) => {
            // console.log("RollBack", id);
            try {
              const Audit = AuditCollection.find(
                (a: any) => a.ref?.path === id
              );
              if (Audit?.AuditOperationType === OperationType.Delete) {
                props.Api.delDoc(
                  `${Audit?.AuditCollectionID}/${Audit?.AuditDocumentID}`
                );
              } else {
                props.Api.setDoc(
                  Audit?.AuditDocumentID as string,
                  {
                    ...Audit?.AuditDocumentSnapshot,
                    // IAuditFlags **
                    AuditRollBackREF: Audit?.AuditRollBackREF || Audit?.ref,
                    AuditRollBackDate: props.Api.TimestampNow(),
                  } as IAuditFlags,
                  Audit?.AuditCollectionID
                );
              }
              return "true";
              //check for onSnapshot posible issues
            } catch (error) {
              return error;
            }
          }}
        ></CRUD>
      )}
      <Dialog
        hidden={AuditSelected === undefined}
        onDismiss={() => setAuditSelectedRef("")}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: `${AuditSelected?.AuditAuthUID || ""} => ${
            AuditSelected?.AuditOperationType || ""
          }`,
          subText: ` ${AuditSelected?.AuditCollectionID || ""}/${
            AuditSelected?.AuditDocumentID || ""
          }`,
          closeButtonAriaLabel: "Close",
        }}
        modalProps={{
          containerClassName: "ms-dialogMainOverride textDialogHalfTall",
          isBlocking: true,
          styles: { main: { maxWidth: 400 } },
        }}
      >
        <Label style={{ backgroundColor: "black", textAlign: "center" }}>
          <span style={{ color: "white" }}>{" { "}</span>
          {AuditSelectedSnapshotChanges(
            AuditSelected?.AuditDocumentSnapshotChanges || []
          )}
          <span style={{ color: "white" }}>{" } "}</span>
        </Label>
        <hr></hr>
        <TextField
          value={prettyStringify(AuditSelected?.AuditDocumentSnapshot || {})}
          multiline
          style={{
            height: props.innerHeight - 550,
            minHeight: props.innerHeight - 550,
            maxHeight: props.innerHeight - 550,
          }}
        />
        <DialogFooter>
          <DefaultButton onClick={() => setAuditSelectedRef("")} text="Close" />
        </DialogFooter>
      </Dialog>
    </div>
  );
}
const AuditCollectionIDChoices = [
  {
    key: "restaurantCG",
    text: "Restaurant",
    value: "restaurantCG",
  },
  {
    key: "profileCG",
    text: "Profile",
    value: "profileCG",
  },
  {
    key: "recipeCGMyrtleBeach",
    text: "Recipe Myrtle Beach",
    value: "recipeCGMyrtleBeach",
  },
  {
    key: "productInventoryCGMyrtleBeach",
    text: "Inventory Myrtle Beach",
    value: "productInventoryCGMyrtleBeach",
  },
  {
    key: "providerCGMyrtleBeach",
    text: "Vendor Myrtle Beach",
    value: "providerCGMyrtleBeach",
  },
  {
    key: "providerCGVirginia",
    text: "Vendor Virginia",
    value: "providerCGVirginia",
  },
  {
    key: "productAreaCGMyrtleBeach",
    text: "Area Myrtle Beach",
    value: "productAreaCGMyrtleBeachs",
  },
  {
    key: "productAreaCGVirginia",
    text: "Area Virginia",
    value: "productAreaCGVirginia",
  },
  {
    key: "productCGMyrtleBeach",
    text: "Item Myrtle Beach",
    value: "productCGMyrtleBeach",
  },
  {
    key: "productCGVirginia",
    text: "Item Virginia",
    value: "productCGVirginia",
  },
  {
    key: "productOrderCGMyrtleBeach",
    text: "Order Myrtle Beach",
    value: "productOrderCGMyrtleBeach",
  },
  {
    key: "productOrderCGVirginia",
    text: "Order Virginia",
    value: "productOrderCGVirginia",
  },
  {
    key: "productBidCGMyrtleBeach",
    text: "Bid Myrtle Beach",
    value: "productBidCGMyrtleBeach",
  },
  {
    key: "productBidCGVirginia",
    text: "Bid Virginia",
    value: "productBidCGVirginia",
  },
];
