import React, { Component } from "react";
import {
  Pivot,
  PivotItem,
  PivotLinkSize,
} from "office-ui-fabric-react/lib/Pivot";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react";

interface INavbarProps {
  innerWidth: number;

  showEditModels: boolean;
  showOrdersManager: boolean;
  showBidsManager: boolean;
  showUpdateBid: boolean;
  showDevelopment: boolean;
}
interface INavbarState {
  NavBarOptions: Array<IDropdownOption>;
}
export default class Navbar extends Component<INavbarProps, INavbarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      NavBarOptions: [],
    };
  }
  async componentDidMount() {
    let NavBarOptions: Array<IDropdownOption> = [{ key: "/", text: "Profile" }];
    if (this.props.showEditModels) {
      NavBarOptions.push({ key: "/edit_models", text: "Admin Tools" });
    }
    if (this.props.showEditModels) {
      NavBarOptions.push({
        key: "/edit_notify",
        text: "Notifications",
      });
    }
    if (this.props.showOrdersManager) {
      NavBarOptions.push({
        key: "/inventory_manager",
        text: "Inventory",
      });
    }
    if (this.props.showOrdersManager) {
      NavBarOptions.push({
        key: "/recipes_manager",
        text: "Recipes",
      });
    }
    if (this.props.showOrdersManager) {
      NavBarOptions.push({ key: "/orders_manager", text: "Orders Management" });
    }
    if (this.props.showBidsManager) {
      NavBarOptions.push({ key: "/bids_manager", text: "Bids Management" });
    }
    if (this.props.showOrdersManager) {
      NavBarOptions.push({ key: "/new_order", text: "Create Order" });
    }
    if (this.props.showUpdateBid) {
      NavBarOptions.push({ key: "/update_bid", text: "Update Bid" });
    }
    if (this.props.showDevelopment) {
      NavBarOptions.push({
        key: "/multiple_update_bid",
        text: "**Multiple Update Bid",
      });
    }
    if (this.props.showDevelopment) {
      NavBarOptions.push({ key: "/dev", text: "**Development" });
    }
    this.setState({
      NavBarOptions,
    });
  }
  componentDidUpdate(
    prevProps: INavbarProps,
    prevState: INavbarState,
    snapshot: any
  ) {
    if (this.props !== prevProps) {
      this.componentDidMount();
    }
  }
  NavTo = (item?: PivotItem) => {
    if (item && item.props && item.props.itemKey) {
      window.location.pathname = item.props.itemKey;
    }
  };
  render(): JSX.Element {
    return (
      <div>
        {this.props.innerWidth < 1400 ? (
          <Dropdown
            id={`-1 NavDropdown`}
            selectedKey={window.location.pathname}
            onChange={(e, o) =>
              this.NavTo({ props: { itemKey: o?.key } } as PivotItem)
            }
            style={{
              width: this.props.innerWidth > 600 ? "250px" : "100px",
            }}
            options={this.state.NavBarOptions}
          />
        ) : (
          <Pivot
            onLinkClick={this.NavTo}
            selectedKey={window.location.pathname}
            linkSize={PivotLinkSize.large}
          >
            <PivotItem itemKey="/" headerText="Profile"></PivotItem>
            {this.props.showEditModels && (
              <PivotItem
                itemKey="/edit_models"
                headerText="Admin Tools"
              ></PivotItem>
            )}
            {this.props.showEditModels && (
              <PivotItem
                itemKey="/edit_notify"
                headerText="Notifications"
              ></PivotItem>
            )}
            {this.props.showOrdersManager && (
              <PivotItem
                itemKey="/inventory_manager"
                headerText="Inventory"
              ></PivotItem>
            )}
            {this.props.showOrdersManager && (
              <PivotItem
                itemKey="/recipes_manager"
                headerText="Recipes"
              ></PivotItem>
            )}
            {this.props.showOrdersManager && (
              <PivotItem
                itemKey="/orders_manager"
                headerText="Orders Management"
              ></PivotItem>
            )}
            {this.props.showBidsManager && (
              <PivotItem
                itemKey="/bids_manager"
                headerText="Bids Management"
              ></PivotItem>
            )}
            {this.props.showOrdersManager && (
              <PivotItem
                itemKey="/new_order"
                headerText="Create Order"
              ></PivotItem>
            )}
            {this.props.showUpdateBid && (
              <PivotItem
                itemKey="/update_bid"
                headerText="Update Bid"
              ></PivotItem>
            )}
            {this.props.showDevelopment && (
              <PivotItem
                itemKey="/multiple_update_bid"
                headerText="**Multiple Update Bid"
              ></PivotItem>
            )}
            {this.props.showDevelopment && (
              <PivotItem itemKey="/dev" headerText="**Development"></PivotItem>
            )}
          </Pivot>
        )}
      </div>
    );
  }
}
