import React, { Component } from "react";
import {
  DefaultButton,
  Label,
  Icon,
  Spinner,
  SpinnerSize,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
} from "office-ui-fabric-react";
import IApi, { Environment } from "../../api/IApi";
import { IProfile, ProfileRoles } from "../../models/IFirestoreModels";
import firebase from "firebase/app";

interface IProfileProps {
  currentEnviroment: Environment;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
  innerWidth: number;
}
interface IProfileState {
  isLoading: boolean;
  isResetDialogHidden: boolean;
}
export default class Profile extends Component<IProfileProps, IProfileState> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = { isLoading: false, isResetDialogHidden: true };
    this.Api = this.props.Api;
  }
  async componentDidMount() {}
  componentDidUpdate(
    prevProps: IProfileProps,
    prevState: IProfileState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileContactEmail !==
        prevProps.currentProfile?.ProfileContactEmail
    ) {
      this.componentDidMount();
    }
  }
  render(): JSX.Element {
    return (
      <div>
        <span style={{ position: "fixed", bottom: 15, left: 10 }}>
          <DefaultButton
            onClick={() => {
              this.Api.signOut();
            }}
            text="Sign Out"
            style={{ zIndex: 5 }}
            iconProps={{ iconName: "SignOut" }}
          />
          <DefaultButton
            onClick={() => this.setState({ isResetDialogHidden: false })}
            disabled={
              !this.props.currentUser ||
              !this.props.currentUser.email ||
              this.state.isLoading
            }
            style={{ zIndex: 5, marginLeft: 10 }}
            iconProps={{ iconName: "UserRemove" }}
          >
            {this.state.isLoading ? (
              <Spinner size={SpinnerSize.small} />
            ) : (
              "Reset Pasword"
            )}
          </DefaultButton>
        </span>
        <div
          style={{
            marginLeft: "15px",
            width: this.props.innerWidth / 3,
            height: this.props.innerHeight - 300,
            padding: "20px",
            backgroundColor: "RoyalBlue",
          }}
        >
          {this.props.currentProfile?.ProfileRole === ProfileRoles.Dev && (
            <Label style={{ color: "white" }}>
              <Icon iconName="Database" /> UID : {this.props.currentUser?.uid}
            </Label>
          )}
          <Label style={{ color: "white" }}>
            <Icon iconName="Mail" /> Email : {this.props.currentUser?.email}
          </Label>
          <Label style={{ color: "white" }}>
            <Icon iconName="People" /> Name :{" "}
            {this.props.currentProfile?.ProfileContactName}
          </Label>
          <Label style={{ color: "white" }}>
            <Icon iconName="Phone" /> Phone :{" "}
            {this.props.currentProfile?.ProfileContactPhone}
          </Label>
          <Label style={{ color: "white" }}>
            <Icon iconName="Assign" /> Role :{" "}
            {this.props.currentProfile?.ProfileRole}
          </Label>
        </div>
        <div
          style={{
            position: "fixed",
            bottom: 15,
            right: 10,
            width: "269px",
            height: "197px",
            backgroundImage: `url(${require("../../logo.png")})`,
            zIndex: -1,
          }}
        ></div>
        <Dialog
          hidden={this.state.isResetDialogHidden}
          onDismiss={() => this.setState({ isResetDialogHidden: true })}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Reset Pasword?",
            closeButtonAriaLabel: "Close",
            subText: `The Profile's Password '${this.props.currentProfile?.ProfileContactEmail}' will be Reset`,
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                this.setState({ isLoading: true, isResetDialogHidden: true });
                this.Api.resetPassword(
                  this.props.currentUser?.email as string
                ).then(() =>
                  setTimeout(() => this.setState({ isLoading: false }), 5000)
                );
              }}
              text="Reset Now"
            />
            <DefaultButton
              onClick={() => this.setState({ isResetDialogHidden: true })}
              text="Don't Reset"
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
