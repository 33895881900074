import React, { Component } from "react";
import {
  IProviderItemBid,
  IRestaurantItem,
  IProvider,
  IRestaurantCG,
  IProviderBid,
  IProfile,
  ProfileRoles,
} from "../../models/IFirestoreModels";
import { IProductsBidsTableErrors } from "../../models/IProductsBidsTableErrors";
import {
  Pivot,
  PivotLinkFormat,
  PivotLinkSize,
  PivotItem,
  PrimaryButton,
  Dialog,
  DialogFooter,
  DefaultButton,
  DialogType,
  Spinner,
  SpinnerSize,
  Dropdown,
  IDropdownOption,
  Toggle,
} from "office-ui-fabric-react";
import IApi from "../../api/IApi";
import EmailCreationManager from "../../util/EmailCreationManager";
import { removeSpaces } from "../../util/functions";
import firebase from "firebase/app";
import TextSMSCreationManager from "../../util/TextSMSCreationManager";

interface IProductBidTableProps {
  Profile: IProfile;
  Table: Array<IProviderItemBid>;
  TableErrors: Array<IProductsBidsTableErrors>;

  ProductCollection: Array<IRestaurantItem>;
  ProviderCollection: Array<IProvider>;

  SelectedProviderID: string;
  SelectedProvider: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  Api: IApi;
  Restaurant: IRestaurantCG;
  ProductBidDay: Date;

  resetUpload: () => void;
  multipleUpload?: boolean;
}
interface IProductBidTableState {
  ProductsRequired: Array<IProviderItemBid>;
  ProductsToAdd: Array<IProviderItemBid>;
  MesureOptions: Array<IDropdownOption>;
  SelectedProviderName: string;
  isUploadDialogHidden: boolean;
  isUploading: boolean;
}
export default class ProductBidTable extends Component<
  IProductBidTableProps,
  IProductBidTableState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      ProductsRequired: [],
      ProductsToAdd: [],
      MesureOptions: [],
      SelectedProviderName: "",
      isUploadDialogHidden: true,
      isUploading: false,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (this.props.multipleUpload) {
      this.handleUpload();
    } else {
      let MesureOptions: Array<IDropdownOption> = this.props.Restaurant.RestaurantUnitMesures.cases.map(
        (currentCase) => {
          return {
            key: currentCase.replaceWith,
            text: currentCase.replaceWith,
          } as any;
        }
      );
      let ProductsFoundIds: Array<string> = this.props.Table.map(
        (Product: IProviderItemBid) => Product.ProductGUID?.id as string
      );
      let ProductsRequired: Array<IProviderItemBid> = [];
      ProductsRequired = this.props.ProductCollection.filter(
        (Product: IRestaurantItem) =>
          ProductsFoundIds.indexOf(Product.ref?.id as string) === -1
      ).map((Product: IRestaurantItem) => {
        let ProductWithErrorsFound:
          | IProductsBidsTableErrors
          | undefined = this.props.TableErrors.find(
          (productError) =>
            removeSpaces(productError.ProductName.toUpperCase()) ===
            Product.ProductName
        );
        return {
          ref: Product.ref,
          ProductGUID: Product.ref,
          ProviderGUID: this.props.SelectedProvider,
          ProductDescription:
            ProductWithErrorsFound && ProductWithErrorsFound.ProductDescription
              ? ProductWithErrorsFound.ProductDescription
              : "",
          ProductPackPrice:
            ProductWithErrorsFound &&
            ProductWithErrorsFound.ProductPackPrice &&
            Number(ProductWithErrorsFound.ProductPackPrice)
              ? Number(ProductWithErrorsFound.ProductPackPrice)
              : 0,
          ProductUnitPerPack:
            ProductWithErrorsFound &&
            ProductWithErrorsFound.ProductUnitPerPack &&
            Number(ProductWithErrorsFound.ProductUnitPerPack)
              ? Number(ProductWithErrorsFound.ProductUnitPerPack)
              : 1,
          ProductUnitAmount:
            ProductWithErrorsFound &&
            ProductWithErrorsFound.ProductUnitAmount &&
            Number(ProductWithErrorsFound.ProductUnitAmount)
              ? Number(ProductWithErrorsFound.ProductUnitAmount)
              : 1,
          ProductUnitMesure: Product.ProductUnitMesure
            ? Product.ProductUnitMesure
            : (this.props.Restaurant.RestaurantUnitMesures.defaultCase as any),
          ProductBidDay: this.props.ProductBidDay,
        };
      });
      // Base - Found = Required ==========================================================
      const Provider: IProvider = this.props.ProviderCollection.find(
        (Provider: IProvider) =>
          Provider.ref?.id === this.props.SelectedProviderID
      ) as IProvider;
      this.setState({
        SelectedProviderName: Provider.ProviderName,
        ProductsRequired,
        ProductsToAdd: this.props.Table.concat(
          ProductsRequired.filter(
            (Product: IProviderItemBid) => Product.ProductPackPrice !== 0
          )
        ),
        MesureOptions,
      });
    }
  }
  renderRowsWithErrors(): Array<JSX.Element> {
    return this.props.TableErrors.map(
      (Product: IProductsBidsTableErrors, index: number) => {
        const OrderTableRowStyle: React.CSSProperties = {
          backgroundColor: index % 2 ? "#DCDCDC" : "white",
          color: "black",
        };
        return (
          <tr
            key={`ProductBidTableRowErrors${index}`}
            style={OrderTableRowStyle}
          >
            <td>{Product.ErrorsFound + ""}</td>
            <td>{Product.ProductName !== null && Product.ProductName}</td>
            <td>{Product.ProductDescription}</td>
            <td style={{ fontWeight: "bold" }}>{Product.ProductPackPrice}</td>
            <td>{Product.ProductUnitPerPack}</td>
            <td>{Product.ProductUnitAmount}</td>
            <td>{Product.ProductUnitMesure}</td>
          </tr>
        );
      }
    );
  }
  renderRows(dialog?: boolean): Array<JSX.Element> {
    let ProductsToAdd: Array<IProviderItemBid> = dialog
      ? this.state.ProductsToAdd
      : this.props.Table;
    return ProductsToAdd.map((Product: IProviderItemBid, index: number) => {
      const OrderTableRowStyle: React.CSSProperties = {
        backgroundColor: index % 2 ? "#DCDCDC" : "white",
        color: "black",
      };
      return dialog ? (
        <tr
          key={`ProductBidTableRow${Product.ProductGUID?.id}`}
          style={OrderTableRowStyle}
        >
          <td>
            {
              this.props.ProductCollection.filter(
                (ProductDoc: IRestaurantItem) =>
                  ProductDoc.ref?.id === Product.ProductGUID?.id
              )[0].ProductName
            }
          </td>
          <td style={{ textAlign: "center", fontWeight: "bold" }}>
            {Product.ProductPackPrice.toFixed(2)}
          </td>
        </tr>
      ) : (
        <tr
          key={`ProductBidTableRow${Product.ProductGUID?.id}`}
          style={OrderTableRowStyle}
        >
          <td>
            {
              this.props.ProductCollection.filter(
                (ProductDoc: IRestaurantItem) =>
                  ProductDoc.ref?.id === Product.ProductGUID?.id
              )[0].ProductName
            }
          </td>
          <td>{Product.ProductDescription}</td>
          <td
            style={{ textAlign: "center", fontWeight: "bold" }}
          >{`$${Product.ProductPackPrice.toFixed(2)}`}</td>
          <td>{Product.ProductUnitPerPack}</td>
          <td>{Product.ProductUnitAmount}</td>
          <td>{Product.ProductUnitMesure}</td>
        </tr>
      );
    });
  }
  renderRowsNotFound(): Array<JSX.Element> {
    return this.state.ProductsRequired.map(
      (Product: IProviderItemBid, index: number) => {
        const OrderTableRowStyle: React.CSSProperties = {
          backgroundColor: index % 2 ? "#DCDCDC" : "white",
          color: "black",
        };
        const ProductDoc: IRestaurantItem = this.props.ProductCollection.find(
          (ProductDoc: IRestaurantItem) =>
            ProductDoc.ref?.id === Product.ProductGUID?.id
        ) as IRestaurantItem;
        return (
          <tr
            key={`ProductBidTableRowNotFound${Product.ProductGUID?.id}`}
            style={OrderTableRowStyle}
          >
            <td>{ProductDoc && ProductDoc.ProductName}</td>
            <td>
              <input
                id={`${index} ProductDescription`}
                type="text"
                onChange={this.onChanged}
                value={Product.ProductDescription}
                style={{ maxWidth: "95%", width: "95%" }}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <Toggle
                id={`${index} AddProductPackPrice`}
                checked={Product.ProductPackPrice !== 0}
                onChange={this.onChanged}
              />
            </td>
            <td>
              <input
                id={`${index} ProductPackPrice`}
                type="number"
                onChange={this.onChanged}
                value={Product.ProductPackPrice}
                style={{ maxWidth: "95%", width: "95%" }}
              />
            </td>
            <td>
              <input
                id={`${index} ProductUnitPerPack`}
                type="number"
                onChange={this.onChanged}
                value={Product.ProductUnitPerPack}
                style={{ maxWidth: "95%", width: "95%" }}
              />
            </td>
            <td>
              <input
                id={`${index} ProductUnitAmount`}
                type="number"
                onChange={this.onChanged}
                value={Product.ProductUnitAmount}
                style={{ maxWidth: "95%", width: "95%" }}
              />
            </td>
            <td>
              <Dropdown
                id={`${index} ProductUnitMesure`}
                options={this.state.MesureOptions}
                disabled={
                  ProductDoc &&
                  ProductDoc.ProductUnitMesure !==
                    this.props.Restaurant.RestaurantUnitMesures.defaultCase
                }
                onChange={this.onChanged}
                selectedKey={Product.ProductUnitMesure}
              />
            </td>
          </tr>
        );
      }
    );
  }
  onChanged = (event: any, option?: any) => {
    let index: number = Number(event.target.id.split(" ")[0]);
    let field: string = event.target.id.split(" ")[1];
    let ProductsRequired: Array<IProviderItemBid> = this.state.ProductsRequired;
    switch (field) {
      case "ProductDescription":
        ProductsRequired[index].ProductDescription = event.target.value
          ? event.target.value
          : "";
        break;
      case "AddProductPackPrice":
        ProductsRequired[index].ProductPackPrice = option ? 1 : 0;
        let ProductsToAdd: Array<IProviderItemBid> = this.props.Table;
        ProductsToAdd = ProductsToAdd.concat(
          this.state.ProductsRequired.filter(
            (Product: IProviderItemBid) => Product.ProductPackPrice !== 0
          )
        );
        this.setState({ ProductsToAdd });
        break;
      case "ProductPackPrice":
        ProductsRequired[index].ProductPackPrice = Number(event.target.value)
          ? Number(event.target.value)
          : event.target.value === ""
          ? 0
          : ProductsRequired[index].ProductPackPrice;
        break;
      case "ProductUnitPerPack":
        ProductsRequired[index].ProductUnitPerPack = Number(event.target.value)
          ? Number(event.target.value)
          : event.target.value === ""
          ? 1
          : ProductsRequired[index].ProductUnitPerPack;
        break;
      case "ProductUnitAmount":
        ProductsRequired[index].ProductUnitAmount = Number(event.target.value)
          ? Number(event.target.value)
          : event.target.value === ""
          ? 1
          : ProductsRequired[index].ProductUnitAmount;
        break;
      case "ProductUnitMesure":
        if (option) {
          ProductsRequired[index].ProductUnitMesure = option?.key.toString();
        }
        break;
    }
    this.setState({ ProductsRequired });
  };
  handleShowUploadDialog = () => {
    this.setState({ isUploadDialogHidden: false });
  };
  handleHideUploadDialog = () => {
    if (!this.state.isUploading) {
      this.setState({ isUploadDialogHidden: true });
    }
  };
  handleUpload = async (notify?: boolean) => {
    this.setState({ isUploading: true });
    let ProductsPriceChanged: Array<{
      previous: IProviderItemBid;
      current: IProviderItemBid;
    }> = [];
    if (!this.props.multipleUpload) {
      let LastProviderBid: Array<IProviderBid> = await this.Api.getCollect(
        this.props.Restaurant.RestaurantSubCollectionPaths
          .RestaurantItemBidCollectionPath,
        [
          { fieldPath: "isLastProviderBid", opStr: "==", value: true },
          {
            fieldPath: "ProviderGUID",
            opStr: "==",
            value: this.props.SelectedProvider,
          },
        ]
      );
      LastProviderBid.forEach((bid) =>
        this.props.Api.updDoc(
          bid.ref?.id as string,
          { isLastProviderBid: false },
          this.props.Restaurant.RestaurantSubCollectionPaths
            .RestaurantItemBidCollectionPath
        )
      );
      if (
        LastProviderBid &&
        LastProviderBid[0] &&
        LastProviderBid[0].ProductBidList &&
        LastProviderBid[0].ProductBidList.length > 0
      ) {
        this.state.ProductsToAdd.forEach((currentProduct) => {
          let previousProduct = LastProviderBid[0].ProductBidList.find(
            (previousProduct) =>
              previousProduct.ProductGUID?.id === currentProduct.ProductGUID?.id
          );
          if (
            previousProduct &&
            this.props.ProductCollection.find(
              (product) => product.ref?.id === currentProduct.ProductGUID?.id
            )?.ProductPriceChangedNotify &&
            currentProduct.ProductPackPrice !== previousProduct.ProductPackPrice
          ) {
            ProductsPriceChanged.push({
              previous: previousProduct,
              current: currentProduct,
            });
          }
        });
      }
    }
    const BidCreatedRef = await this.props.Api.addDoc(
      this.props.Restaurant.RestaurantSubCollectionPaths
        .RestaurantItemBidCollectionPath,
      {
        ProviderGUID: this.props.SelectedProvider,
        ProductBidDay: this.Api.TimestampFromDate(this.props.ProductBidDay),
        ProductBidList: !this.props.multipleUpload
          ? this.state.ProductsToAdd.map((product) => {
              return {
                ...product,
                ProductPackPrice: Number(product.ProductPackPrice.toFixed(2)),
              };
            })
          : this.props.Table.map((product) => {
              return {
                ...product,
                ProductPackPrice: Number(product.ProductPackPrice.toFixed(2)),
              };
            }),
        isLastProviderBid: !this.props.multipleUpload,
      } as IProviderBid
    );
    if (notify) {
      let promiseArraySendEmails: Array<Promise<any>> = [];
      //[Self] [Email]
      promiseArraySendEmails.push(
        this.Api.sendEmail(
          EmailCreationManager.updateBidsNotify(
            this.props.Profile,
            this.state.ProductsToAdd.map((product) => {
              return {
                ...product,
                ProductPackPrice: Number(product.ProductPackPrice.toFixed(2)),
              };
            }),
            this.props.ProductCollection,
            BidCreatedRef.id,
            this.props.Restaurant.RestaurantName
          )
        )
      );
      //[Self] [Text]
      promiseArraySendEmails.push(
        this.Api.sendTextSMS(
          await TextSMSCreationManager.updateBidsNotify(
            this.Api,
            this.props.Profile,
            this.state.ProductsToAdd.map((product) => {
              return {
                ...product,
                ProductPackPrice: Number(product.ProductPackPrice.toFixed(2)),
              };
            }),
            this.props.ProductCollection,
            this.props.Restaurant,
            BidCreatedRef.id
          )
        )
      );
      //[Staff UpdateBids] [Email]
      const Provider: IProvider = this.props.ProviderCollection.find(
        (provider) => provider.ref?.id === this.props.SelectedProviderID
      ) as IProvider;
      this.props.Restaurant.RestaurantStaffUpdateBidsNotify.forEach(
        async (Staff: {
          ProfileContactName: string;
          ProfileContactEmail: string;
          ProfileContactPhone: number;
        }) => {
          promiseArraySendEmails.push(
            this.Api.sendEmail(
              EmailCreationManager.updateBidsNotify(
                Staff,
                this.state.ProductsToAdd.map((product) => {
                  return {
                    ...product,
                    ProductPackPrice: Number(
                      product.ProductPackPrice.toFixed(2)
                    ),
                  };
                }),
                this.props.ProductCollection,
                BidCreatedRef.id,
                Provider.ProviderName
              )
            )
          );
          //[Staff UpdateBids] [Text]
          promiseArraySendEmails.push(
            this.Api.sendTextSMS(
              await TextSMSCreationManager.updateBidsNotify(
                this.Api,
                Staff,
                this.state.ProductsToAdd.map((product) => {
                  return {
                    ...product,
                    ProductPackPrice: Number(
                      product.ProductPackPrice.toFixed(2)
                    ),
                  };
                }),
                this.props.ProductCollection,
                this.props.Restaurant,
                BidCreatedRef.id,
                Provider.ProviderName
              )
            )
          );
        }
      );
      //[Staff ProductPriceChanged] [Email]
      if (ProductsPriceChanged.length > 0) {
        this.props.Restaurant.RestaurantStaffProductPriceChangedNotify.forEach(
          async (profile) => {
            promiseArraySendEmails.push(
              this.Api.sendEmail(
                EmailCreationManager.productPriceChangedNotify(
                  profile,
                  ProductsPriceChanged,
                  this.props.ProductCollection,
                  this.props.Restaurant.RestaurantName,
                  Provider.ProviderName
                )
              )
            );
            //[Staff ProductPriceChanged] [Text]
            promiseArraySendEmails.push(
              this.Api.sendTextSMS(
                await TextSMSCreationManager.productPriceChangedNotify(
                  this.Api,
                  profile,
                  ProductsPriceChanged,
                  this.props.ProductCollection,
                  this.props.Restaurant,
                  Provider.ProviderName,
                  BidCreatedRef.id,
                  "Staff"
                )
              )
            );
          }
        );
      }
      //[Vendor] [Email]
      const ProductsHigherPrices = ProductsPriceChanged.filter(
        (p) => p.current.ProductPackPrice > p.previous.ProductPackPrice
      );
      if (ProductsHigherPrices.length > 0) {
        promiseArraySendEmails.push(
          this.Api.sendEmail(
            EmailCreationManager.productPriceChangedNotify(
              {
                ProfileContactName: Provider.ProviderContactName,
                ProfileContactEmail: Provider.ProviderContactEmail,
              },
              ProductsHigherPrices,
              this.props.ProductCollection,
              Provider.ProviderName,
              this.props.Restaurant.RestaurantName,
              true
            )
          )
        );
        //[Vendor] [Text]
        promiseArraySendEmails.push(
          this.Api.sendTextSMS(
            await TextSMSCreationManager.productPriceChangedNotify(
              this.Api,
              {
                ProfileContactName: Provider.ProviderContactName,
                ProfileContactPhone: Provider.ProviderContactPhone,
              },
              ProductsHigherPrices,
              this.props.ProductCollection,
              this.props.Restaurant,
              Provider.ProviderName,
              BidCreatedRef.id,
              Provider.ref?.id as string
            )
          )
        );
      }

      Promise.all(promiseArraySendEmails);
    }
    this.props.resetUpload();
  };
  render(): JSX.Element {
    const OrderTableStyle: React.CSSProperties = {
      marginTop: 5,
      fontFamily: "Arial",
      width: "100%",
    };
    const OrderTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    const OrderTableRowStyle: React.CSSProperties = {
      backgroundColor: "white",
      color: "black",
    };
    return (
      <div>
        <Pivot linkFormat={PivotLinkFormat.tabs} linkSize={PivotLinkSize.large}>
          <PivotItem
            headerText={`Items Found (${this.props.Table.length}/${this.props.ProductCollection.length})`}
          >
            <br />
            <table style={OrderTableStyle}>
              <thead style={OrderTableHeadStyle}>
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Case Price</th>
                  <th>Unit Per Pack</th>
                  <th>Unit Amount</th>
                  <th>Unit Measure</th>
                </tr>
              </thead>
              <tbody>
                {this.props.Table.length === 0 ? (
                  <tr
                    key={`ProductBidTableRowErrors`}
                    style={OrderTableRowStyle}
                  >
                    <td>No Items Found</td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                  </tr>
                ) : (
                  this.renderRows()
                )}
              </tbody>
            </table>
          </PivotItem>
          {!this.props.multipleUpload && (
            <PivotItem
              headerText={`Items Required, But Not Found  (${
                this.state.ProductsRequired.filter(
                  (p) => p.ProductPackPrice !== 0
                ).length
              }/${this.state.ProductsRequired.length})`}
            >
              <br />
              <table style={OrderTableStyle}>
                <thead style={OrderTableHeadStyle}>
                  <tr>
                    <th>Item</th>
                    <th>Description</th>
                    <td style={{ textAlign: "center" }}>Add?</td>
                    <th>Case Price</th>
                    <th>Unit Per Pack</th>
                    <th>Unit Amount</th>
                    <th>Unit Measure</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderRowsNotFound().length === 0 ? (
                    <tr
                      key={`ProductBidTableRowErrors`}
                      style={OrderTableRowStyle}
                    >
                      <td>No Items Required</td>
                      <td> - </td>
                      <td> - </td>
                      <td> - </td>
                      <td> - </td>
                      <td> - </td>
                      <td> - </td>
                      <td> - </td>
                    </tr>
                  ) : (
                    this.renderRowsNotFound()
                  )}
                </tbody>
              </table>
            </PivotItem>
          )}
          <PivotItem
            headerText={`Not Match (${this.props.TableErrors.length})`}
          >
            <br />
            <table style={OrderTableStyle}>
              <thead style={OrderTableHeadStyle}>
                <tr>
                  <th>Errors Found</th>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Case Price</th>
                  <th>Unit Per Pack</th>
                  <th>Unit Amount</th>
                  <th>Unit Measure</th>
                </tr>
              </thead>
              <tbody>
                {this.props.TableErrors.length === 0 ? (
                  <tr
                    key={`ProductBidTableRowErrors`}
                    style={OrderTableRowStyle}
                  >
                    <td>No Errors Found</td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                  </tr>
                ) : (
                  this.renderRowsWithErrors()
                )}
              </tbody>
            </table>
          </PivotItem>
        </Pivot>
        <Dialog
          hidden={this.state.isUploadDialogHidden}
          onDismiss={this.handleHideUploadDialog}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: "Upload Bid?",
            closeButtonAriaLabel: "Close",
            subText: `Do you want to update ${this.state.SelectedProviderName} bid now?`,
          }}
          modalProps={{
            containerClassName: "ms-dialogMainOverride textDialog",
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          <table style={OrderTableStyle}>
            <thead style={OrderTableHeadStyle}>
              <tr>
                <th>Item</th>
                <th>Case Price</th>
              </tr>
            </thead>
            <tbody>{this.renderRows(true)}</tbody>
          </table>
          <DialogFooter>
            <PrimaryButton
              onClick={() => this.handleUpload(true)}
              disabled={this.state.isUploading}
            >
              {this.state.isUploading ? (
                <Spinner size={SpinnerSize.small} />
              ) : (
                `Upload Now (${this.state.ProductsToAdd.length})`
              )}
            </PrimaryButton>
            {this.props.Profile.ProfileRole === ProfileRoles.Dev && (
              <DefaultButton
                onClick={() => this.handleUpload()}
                disabled={this.state.isUploading}
                text="Upload Now (Dont Notify)"
              />
            )}
            <DefaultButton
              disabled={this.state.isUploading}
              onClick={this.handleHideUploadDialog}
              text="Don't Upload"
            />
          </DialogFooter>
        </Dialog>
        {!this.props.multipleUpload && (
          <span style={{ position: "fixed", bottom: 15, right: 10 }}>
            <PrimaryButton
              disabled={this.state.ProductsToAdd.length === 0}
              onClick={this.handleShowUploadDialog}
              text="Upload Items Now"
              iconProps={{ iconName: "Upload" }}
            />
          </span>
        )}
      </div>
    );
  }
}
