import IApi from "../api/IApi";
import { ITextSMS } from "../models/ICommunication";
import {
  IProvider,
  IProviderItemBid,
  IRestaurantArea,
  IRestaurantCG,
  IRestaurantItem,
  IRestaurantItemOrder,
} from "../models/IFirestoreModels";
import { createExcelLink, Greeting, PrintDate, setHash } from "./functions";

export default class TextSMSCreationManager {
  static newProductNotify(
    ProfileContact: { ProfileContactName: string; ProfileContactPhone: number },
    newProductName: string,
    RestaurantName: string
  ): ITextSMS {
    let textSMS: ITextSMS = {
      TextSMSTo: ProfileContact.ProfileContactPhone,
      TextSMSBody: `${Greeting()}, ${
        ProfileContact.ProfileContactName
      }\n\n${newProductName} Item's Price need to be added for next order at ${RestaurantName}`,
    };
    return textSMS;
  }
  static async newOrderNotify(
    Api: IApi,
    ProfileContact: { ProfileContactName: string; ProfileContactPhone: number },
    ProductsOrderToSubmit: Array<IRestaurantItemOrder>,
    ProductsOrderToReturn: Array<IRestaurantItemOrder>,
    ProductCollection: Array<IRestaurantItem>,
    ProviderCollection: Array<IProvider>,
    Restaurant: IRestaurantCG,
    ProductOrderDelivery: Date | undefined,
    OrderCreatedRef: string,
    DataType: string,
    notifyDaysLater?: boolean
  ): Promise<ITextSMS> {
    let link: string = "";
    if (
      DataType &&
      DataType !== "Self" &&
      DataType !== "NewOrder" &&
      DataType !== "ReturnOrder"
    ) {
      let Sheets: Array<{
        SheetName: string;
        SheetData: Array<Array<string>>;
      }> = [];
      if (ProductsOrderToSubmit.length > 0) {
        let OrderedProducts: Array<Array<string>> = [
          ["Item", "Case Price", "* Amount Ordered", "Total"],
        ];
        ProductsOrderToSubmit.forEach(
          (OrderProduct: IRestaurantItemOrder, index: number) => {
            const Product: IRestaurantItem = ProductCollection.find(
              (product) => product.ref?.id === OrderProduct.ProductGUID?.id
            ) as IRestaurantItem;
            OrderedProducts.push([
              `${Product ? Product.ProductName : "N/A"}`,
              `$${OrderProduct.ProductPackPrice}`,
              `${OrderProduct.ProductOrderAmount}`,
              `$${(
                OrderProduct.ProductPackPrice * OrderProduct.ProductOrderAmount
              ).toFixed(2)}`,
            ]);
          }
        );
        Sheets.push({
          SheetName: "Ordered Products",
          SheetData: OrderedProducts,
        });
      }
      if (ProductsOrderToReturn.length > 0) {
        let PickUpProducts: Array<Array<string>> = [
          ["Item", "Case Price", "* Amount Returned", "Total"],
        ];
        ProductsOrderToReturn.forEach(
          (OrderProduct: IRestaurantItemOrder, index: number) => {
            const Product: IRestaurantItem = ProductCollection.find(
              (product) => product.ref?.id === OrderProduct.ProductGUID?.id
            ) as IRestaurantItem;
            PickUpProducts.push([
              `${Product ? Product.ProductName : "N/A"}`,
              `$${OrderProduct.ProductPackPrice}`,
              `${OrderProduct.ProductOrderAmount}`,
              `$${(
                OrderProduct.ProductPackPrice * OrderProduct.ProductOrderAmount
              ).toFixed(2)}`,
            ]);
          }
        );
        Sheets.push({
          SheetName: "PickUp Products",
          SheetData: PickUpProducts,
        });
      }
      link = await createExcelLink(
        Api,
        `${Restaurant.RestaurantSubCollectionPaths.RestaurantItemOrderCollectionPath}/OrderCreated/${OrderCreatedRef}/${DataType}.xlsx`,
        Sheets
      );
      ///////////////////////////////////////////////////////////////////////////////////////////
    } else {
      let Sheets: Array<{
        SheetName: string;
        SheetData: Array<Array<string>>;
      }> = [];
      ProviderCollection.forEach((Provider) => {
        const ProductsOrderToSubmitFiltered = ProductsOrderToSubmit.filter(
          (OrderProduct) => OrderProduct.ProviderGUID?.id === Provider.ref?.id
        );
        if (ProductsOrderToSubmitFiltered.length > 0) {
          let OrderedProducts: Array<Array<string>> = [
            ["Item", "Case Price", "* Amount Ordered", "Total"],
          ];
          ProductsOrderToSubmitFiltered.forEach(
            (OrderProduct: IRestaurantItemOrder, index: number) => {
              const Product: IRestaurantItem = ProductCollection.find(
                (product) => product.ref?.id === OrderProduct.ProductGUID?.id
              ) as IRestaurantItem;
              OrderedProducts.push([
                `${Product ? Product.ProductName : "N/A"}`,
                `$${OrderProduct.ProductPackPrice}`,
                `${OrderProduct.ProductOrderAmount}`,
                `$${(
                  OrderProduct.ProductPackPrice *
                  OrderProduct.ProductOrderAmount
                ).toFixed(2)}`,
              ]);
            }
          );
          Sheets.push({
            SheetName: `${Provider.ProviderName} Ordered Products`,
            SheetData: OrderedProducts,
          });
        }
        const ProductsOrderToReturnFiltered = ProductsOrderToReturn.filter(
          (OrderProduct) => OrderProduct.ProviderGUID?.id === Provider.ref?.id
        );
        if (ProductsOrderToReturnFiltered.length > 0) {
          let PickUpProducts: Array<Array<string>> = [
            ["Item", "Case Price", "* Amount Returned", "Total"],
          ];
          ProductsOrderToReturnFiltered.forEach(
            (OrderProduct: IRestaurantItemOrder, index: number) => {
              const Product: IRestaurantItem = ProductCollection.find(
                (product) => product.ref?.id === OrderProduct.ProductGUID?.id
              ) as IRestaurantItem;
              PickUpProducts.push([
                `${Product ? Product.ProductName : "N/A"}`,
                `$${OrderProduct.ProductPackPrice}`,
                `${OrderProduct.ProductOrderAmount}`,
                `$${(
                  OrderProduct.ProductPackPrice *
                  OrderProduct.ProductOrderAmount
                ).toFixed(2)}`,
              ]);
            }
          );
          Sheets.push({
            SheetName: `${Provider.ProviderName} PickUp Products`,
            SheetData: PickUpProducts,
          });
        }
      });
      link = await createExcelLink(
        Api,
        `${Restaurant.RestaurantSubCollectionPaths.RestaurantItemOrderCollectionPath}/OrderCreated/${OrderCreatedRef}/${DataType}`,
        Sheets
      );
    }
    ///////////////////////////////////////////////////////////////////////////////////////////
    let hash = setHash("", "Order", OrderCreatedRef);
    let textSMS: ITextSMS = {
      TextSMSTo: ProfileContact.ProfileContactPhone,
      TextSMSBody: `${Greeting()}, ${ProfileContact.ProfileContactName}\n${
        ProductsOrderToSubmit.length === 0 ? "Pick Up" : "Order Delivery"
      } ${
        notifyDaysLater
          ? "Today"
          : `on ${ProductOrderDelivery && PrintDate(ProductOrderDelivery)}`
      }\n\n${
        DataType &&
        (DataType === "Self" ||
          DataType === "NewOrder" ||
          DataType === "ReturnOrder")
          ? `Link to Check Order => ${window.location.origin}/orders_manager${hash}\n`
          : ""
      }Link to Download Order => ${link}\n\nOrder at ${
        Restaurant.RestaurantName
      }`,
    };
    if (notifyDaysLater) {
      let t = new Date((ProductOrderDelivery as Date).getTime());
      t.setHours(t.getHours() + 8);
      textSMS.TextSMSTriggerDate = t;
    }
    return textSMS;
  }
  static async orderCheckedNotify(
    Api: IApi,
    ProfileContact: { ProfileContactName: string; ProfileContactPhone: number },
    OrderProducts: Array<IRestaurantItemOrder>,
    AreaCollection: Array<IRestaurantArea>,
    ProductCollection: Array<IRestaurantItem>,
    ProviderCollection: Array<IProvider>,
    Restaurant: IRestaurantCG,
    OrderCheckedRef: string,
    DataType: string,
    notifyDaysLater?: number
  ): Promise<ITextSMS> {
    let Header: Array<string> = ["Area", "Item"];
    if (DataType === "Self" || DataType === "Staff") {
      Header.push("Vendor");
    }
    Header = Header.concat([
      "Truck Status",
      "Amount Ordered",
      "Amount Checked",
      "Return Amount",
      "Return Reason",
      "Invoice Status",
      "Invoice Amount",
      "Invoice Price",
      "Case Price",
      "Units per Case",
      "Description",
      "Bid Day",
    ]);
    let OrderedProducts: Array<Array<string>> = [Header];
    OrderProducts.forEach(
      (OrderProduct: IRestaurantItemOrder, index: number) => {
        const Area: IRestaurantArea = AreaCollection.find(
          (area) => area.ref?.id === OrderProduct.AreaGUID?.id
        ) as IRestaurantArea;
        const Product: IRestaurantItem = ProductCollection.find(
          (product) => product.ref?.id === OrderProduct.ProductGUID?.id
        ) as IRestaurantItem;
        const Provider: IProvider = ProviderCollection.find(
          (provider) => provider.ref?.id === OrderProduct.ProviderGUID?.id
        ) as IProvider;

        let Row: Array<string> = [
          `${Area ? Area.AreaName : "N/A"}`,
          `${Product ? Product.ProductName : "N/A"}`,
        ];
        if (DataType === "Self" || DataType === "Staff") {
          Row.push(`${Provider ? Provider.ProviderName : "N/A"}`);
        }
        Row = Row.concat([
          `${OrderProduct.ProductOrderState}`,
          `${OrderProduct.ProductOrderAmount}`,
          `${OrderProduct.ProductOrderIncomingAmount}`,
          `${
            OrderProduct.ProductOrderReturnAmount
              ? OrderProduct.ProductOrderReturnAmount
              : ""
          }`,
          `${
            OrderProduct.ProductOrderReturnReason
              ? OrderProduct.ProductOrderReturnReason
              : ""
          }`,
          `${
            OrderProduct.ProductOrderInvoiceState
              ? OrderProduct.ProductOrderInvoiceState
              : ""
          }`,
          `${
            OrderProduct.ProductOrderInvoiceAmount
              ? OrderProduct.ProductOrderInvoiceAmount
              : ""
          }`,
          `${
            OrderProduct.ProductOrderInvoicePrice
              ? `$${OrderProduct.ProductOrderInvoicePrice}`
              : ""
          }`,
          `$${OrderProduct.ProductPackPrice}`,
          `${OrderProduct.ProductUnitPerPack}/${OrderProduct.ProductUnitAmount} ${OrderProduct.ProductUnitMesure}`,
          `${OrderProduct.ProductDescription}`,
          `${PrintDate(OrderProduct.ProductBidDay)}`,
        ]);
        OrderedProducts.push(Row);
      }
    );
    const link = await createExcelLink(
      Api,
      `${
        Restaurant.RestaurantSubCollectionPaths
          .RestaurantItemOrderCollectionPath
      }/OrderChecked/${OrderCheckedRef}/${
        DataType === "Self" || DataType === "Staff" ? "Staff" : DataType
      }`,
      [
        {
          SheetName: "Ordered Products",
          SheetData: OrderedProducts,
        },
      ]
    );
    let hash = setHash("", "Order", OrderCheckedRef);
    let textSMS: ITextSMS = {
      TextSMSTo: ProfileContact.ProfileContactPhone,
      TextSMSBody: `${Greeting()}, ${ProfileContact.ProfileContactName}\n${
        DataType === "Self"
          ? "Order has been Notify Successfully"
          : DataType !== "Staff"
          ? "Order Invoice has been Notify as Ready for Vendor Check"
          : notifyDaysLater
          ? `Reminder: Order has been Notify as Ready for Invoice Check`
          : `Order has been Notify as Ready for Invoice Check`
      }\n\n${
        notifyDaysLater
          ? `If the invoice of this Order has been checked, please disregard...\n`
          : ""
      }${
        DataType === "Self" || DataType === "Staff"
          ? `Link to Check Order Invoice => ${window.location.origin}/orders_manager${hash}\n`
          : ""
      }Link to Download Order Invoice => ${link}\n\nOrder at ${
        Restaurant.RestaurantName
      }`,
    };
    if (notifyDaysLater) {
      let t = new Date();
      t.setDate(t.getDate() + notifyDaysLater);
      textSMS.TextSMSTriggerDate = t;
    }
    return textSMS;
  }
  static async updateBidsNotify(
    Api: IApi,
    ProfileContact: { ProfileContactName: string; ProfileContactPhone: number },
    ProductsToAdd: Array<IProviderItemBid>,
    ProductCollection: Array<IRestaurantItem>,
    Restaurant: IRestaurantCG,
    BidCreatedRef: string,
    ProviderName?: string
  ): Promise<ITextSMS> {
    let BidProducts: Array<Array<string>> = [
      [
        "Item",
        "Description",
        "Case Price",
        "Unit Per Pack",
        "Unit Amount",
        "Unit Measure",
      ],
    ];
    ProductsToAdd.forEach((BidProduct: IProviderItemBid, index: number) => {
      const Product: IRestaurantItem = ProductCollection.find(
        (product) => product.ref?.id === BidProduct.ProductGUID?.id
      ) as IRestaurantItem;
      BidProducts.push([
        `${Product ? Product.ProductName : "N/A"}`,
        `${BidProduct.ProductDescription}`,
        `$${BidProduct.ProductPackPrice}`,
        `${BidProduct.ProductUnitPerPack}`,
        `${BidProduct.ProductUnitAmount}`,
        `${BidProduct.ProductUnitMesure}`,
      ]);
    });
    const link = await createExcelLink(
      Api,
      `${Restaurant.RestaurantSubCollectionPaths.RestaurantItemBidCollectionPath}/UpdatedBid/${BidCreatedRef}`,
      [
        {
          SheetName: "Bid Products",
          SheetData: BidProducts,
        },
      ]
    );
    let hash = setHash("", "Bid", BidCreatedRef);
    let textSMS: ITextSMS = {
      TextSMSTo: ProfileContact.ProfileContactPhone,
      TextSMSBody: `${Greeting()}, ${ProfileContact.ProfileContactName}\n${
        ProviderName
          ? `${ProviderName} has Updated Price Bids`
          : "Price Bids Updated Successfully"
      }\n\n${
        ProviderName
          ? `Link to View Bid Updated => ${window.location.origin}/bids_manager${hash}\n`
          : ""
      }Link to Download Bid => ${link}\n\nBid at ${Restaurant.RestaurantName}`,
    };
    return textSMS;
  }
  static async productPriceChangedNotify(
    Api: IApi,
    ProfileContact: { ProfileContactName: string; ProfileContactPhone: number },
    ProductsPriceChanged: Array<{
      previous: IProviderItemBid;
      current: IProviderItemBid;
    }>,
    ProductCollection: Array<IRestaurantItem>,
    Restaurant: IRestaurantCG,
    ProviderName: string,
    BidChangedRef: string,
    DataType: string
  ): Promise<ITextSMS> {
    let ProductsChanged: Array<Array<string>> = [
      [
        "Item",
        "Previous Description",
        "Current Description",
        "Previous Case Price",
        "Current Case Price",
        "Previous Units per Case",
        "Current Units per Case",
      ],
    ];
    ProductsPriceChanged.forEach(
      (
        BidProductChanged: {
          previous: IProviderItemBid;
          current: IProviderItemBid;
        },
        index: number
      ) => {
        const Product: IRestaurantItem = ProductCollection.find(
          (product) =>
            product.ref?.id === BidProductChanged.previous.ProductGUID?.id
        ) as IRestaurantItem;
        ProductsChanged.push([
          `${Product.ProductName}`,
          `${BidProductChanged.previous.ProductDescription}`,
          `${BidProductChanged.current.ProductDescription}`,
          `$${BidProductChanged.previous.ProductPackPrice}`,
          `$${BidProductChanged.current.ProductPackPrice}`,
          `${BidProductChanged.previous.ProductUnitPerPack}/${BidProductChanged.previous.ProductUnitAmount} ${BidProductChanged.previous.ProductUnitMesure}`,
          `${BidProductChanged.current.ProductUnitPerPack}/${BidProductChanged.current.ProductUnitAmount} ${BidProductChanged.current.ProductUnitMesure}`,
        ]);
      }
    );
    const link = await createExcelLink(
      Api,
      `${Restaurant.RestaurantSubCollectionPaths.RestaurantItemBidCollectionPath}/PriceChanged/${BidChangedRef}/${DataType}`,
      [
        {
          SheetName: "Products Changed",
          SheetData: ProductsChanged,
        },
      ]
    );
    let textSMS: ITextSMS = {
      TextSMSTo: ProfileContact.ProfileContactPhone,
      TextSMSBody: `${Greeting()}, ${ProfileContact.ProfileContactName}\n${
        DataType !== "Staff"
          ? `Prices Changed Marked as Notifiable`
          : `${ProviderName} has Change Prices Marked as Notifiable`
      }\n\n${
        DataType !== "Staff"
          ? "The following prices are higher than the past bid, please review then and update the bid again\n"
          : ""
      }\nLink to Download Products with Changes => ${link}\n\nBid at ${
        Restaurant.RestaurantName
      }`,
    };
    return textSMS;
  }
  static async productStockLeftNotify(
    Api: IApi,
    ProfileContact: { ProfileContactName: string; ProfileContactPhone: number },
    ProviderCollection: Array<IProvider>,
    ProductCollection: Array<IRestaurantItem>,
    Restaurant: IRestaurantCG,
    OrderChangedRef: string
  ): Promise<ITextSMS> {
    let ProductsChanged: Array<Array<string>> = [
      [
        "Item",
        "Previous Description",
        "Current Description",
        "Previous Case Price",
        "Current Case Price",
        "Previous Units per Case",
        "Current Units per Case",
      ],
    ];
    ProductCollection.forEach((Product, index: number) => {
      Product.ProductStockLeft?.forEach((s) => {
        const Provider: IProvider = ProviderCollection.find(
          (provider) => provider.ref?.id === s.ProviderGUID?.id
        ) as IProvider;
        ProductsChanged.push([
          `${Product.ProductName}`,
          `${Provider ? Provider.ProviderName : "N/A"}`,
          `${s.StockLeft}`,
        ]);
      });
    });
    const link = await createExcelLink(
      Api,
      `${Restaurant.RestaurantSubCollectionPaths.RestaurantItemOrderCollectionPath}/StockChanged/${OrderChangedRef}`,
      [
        {
          SheetName: "Products Changed",
          SheetData: ProductsChanged,
        },
      ]
    );
    let textSMS: ITextSMS = {
      TextSMSTo: ProfileContact.ProfileContactPhone,
      TextSMSBody: `${Greeting()}, ${
        ProfileContact.ProfileContactName
      }\nItem Stock Left has Changed\n\nLink to Download Products with Changes => ${link}\n\nOrder at ${
        Restaurant.RestaurantName
      }`,
    };
    return textSMS;
  }
}
