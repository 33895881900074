import React, { Component } from "react";
import IApi from "../../api/IApi";
import {
  IProfile,
  IProvider,
  IProviderBid,
  IRestaurantCG,
  IRestaurantItem,
  ProfileRoles,
} from "../../models/IFirestoreModels";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import firebase from "firebase/app";
import CRUD, { CRUDPropertyType } from "../DevelopmentView/CRUD";

interface IModelEditorProvidersProps {
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  Restaurant: IRestaurantCG;
}
interface IModelEditorProvidersState {
  isLoading: boolean;
  ProviderCollection: Array<IProvider>;
}
export default class ModelEditorProviders extends Component<
  IModelEditorProvidersProps,
  IModelEditorProvidersState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      ProviderCollection: [],
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (this.props.currentUser && this.props.Restaurant) {
      this.Api.getCollect(
        this.props.Restaurant.RestaurantSubCollectionPaths
          .ProviderCollectionPath,
        undefined,
        (ProviderCollection) =>
          this.setState({ ProviderCollection, isLoading: false }),
        "ProviderName",
        "asc"
      );
    }
  }
  componentDidUpdate(
    prevProps: IModelEditorProvidersProps,
    prevState: IModelEditorProvidersState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.Restaurant?.RestaurantSubCollectionPaths !==
        prevProps.Restaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  handleDeleteRelationships = async (ref: string): Promise<Array<void>> => {
    let promiseDeleteRelationships: Array<Promise<void>> = [];
    const Provider = this.state.ProviderCollection.find(
      (Provider: IProvider) => Provider.ref?.path === ref
    );
    const ProductCollection: Array<IRestaurantItem> = await this.Api.getCollect(
      this.props.Restaurant.RestaurantSubCollectionPaths
        .RestaurantItemCollectionPath,
      [{ fieldPath: "ProviderPreference", opStr: "==", value: Provider?.ref }]
    );
    ProductCollection.forEach((p) => {
      promiseDeleteRelationships.push(
        this.Api.updDoc(p.ref?.path as string, {
          ProviderPreference: firebase.firestore.FieldValue.delete(),
        })
      );
    });
    const ProductBidCollection: Array<IProviderBid> = await this.Api.getCollect(
      this.props.Restaurant.RestaurantSubCollectionPaths
        .RestaurantItemBidCollectionPath,
      [{ fieldPath: "ProviderGUID", opStr: "==", value: Provider?.ref }]
    );
    ProductBidCollection.forEach((Product: IProviderBid) => {
      promiseDeleteRelationships.push(
        this.Api.delDoc(
          Product.ref?.id as string,
          this.props.Restaurant.RestaurantSubCollectionPaths
            .RestaurantItemBidCollectionPath
        )
      );
    });
    return Promise.all(promiseDeleteRelationships);
  };
  render(): JSX.Element {
    return (
      <div>
        {this.state.isLoading ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : (
          <CRUD
            List={this.state.ProviderCollection.map((i) => {
              return { ...i, ref: i.ref?.path };
            })}
            ItemName={"Vendor"}
            DisplayField={"ProviderName"}
            Structure={[
              {
                Type: CRUDPropertyType.ID,
                Name: "ref",
              },
              {
                Type: CRUDPropertyType.String,
                Name: "ProviderName",
                Enforced: true,
                DisplayName: "Vendor Name",
                IconName: "DeliveryTruck",
                Width: "20%",
                BuiltInFilter: true,
              },
              {
                Type: CRUDPropertyType.String,
                Name: "ProviderContactName",
                Enforced: true,
                DisplayName: "Contact Name",
                IconName: "People",
                Width: "20%",
              },
              {
                Type: CRUDPropertyType.Email,
                Name: "ProviderContactEmail",
                Enforced: true,
                DisplayName: "Contact Email",
                IconName: "Mail",
                Width: "20%",
              },
              {
                Type: CRUDPropertyType.Number,
                Name: "ProviderContactPhone",
                Enforced: true,
                DisplayName: "Contact Phone",
                IconName: "Phone",
                Width: "15%",
              },
              {
                Type: CRUDPropertyType.Number,
                Name: "ProviderDiscountPercentage",
                DisplayName: "Discount Percentage",
                IconName: "CalculatorPercentage",
                Width: "5%",
                Settings: { Counter: true },
              },
              {
                Type: CRUDPropertyType.Control,
                Name: "Control",
                Width: "20%",
              },
            ]}
            onDisable={(item, col) =>
              this.props.currentProfile?.ProfileRole !== ProfileRoles.Dev &&
              col.Name === "@Delete"
            }
            AddItem={true}
            AddConfirm={true}
            onAdd={async (item) => {
              // console.log("ADD", item);
              try {
                await this.Api.addDoc(
                  this.props.Restaurant.RestaurantSubCollectionPaths
                    .ProviderCollectionPath,
                  item
                );
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            UpdateConfirm={true}
            onUpdate={async (updates) => {
              // console.log("UPD", updates);
              try {
                let promiseUpdates: Array<Promise<void>> = [];
                updates.forEach((u) => {
                  if (u.changes.ProviderDiscountPercentage === null) {
                    u.changes.ProviderDiscountPercentage = firebase.firestore.FieldValue.delete();
                  }
                  promiseUpdates.push(this.Api.updDoc(u.id, u.changes));
                });
                await Promise.all(promiseUpdates);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            DeleteConfirm={true}
            onDelete={async (id) => {
              // console.log("DEL", id);
              try {
                await this.handleDeleteRelationships(id);
                await this.Api.delDoc(id);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            BuiltInFilter={true}
            SortField={"ProviderName@Up"}
          ></CRUD>
        )}
      </div>
    );
  }
}
