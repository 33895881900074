import React, { Component } from "react";
import Dropzone, { DropEvent } from "react-dropzone";
import {
  CompoundButton,
  Dropdown,
  IDropdownOption,
  Label,
  PrimaryButton,
  SpinnerSize,
  Spinner,
  ProgressIndicator,
} from "office-ui-fabric-react";
import XLSX from "xlsx";
import IApi, { Environment } from "../../api/IApi";
import {
  IProviderItemBid,
  IRestaurantItem,
  IProvider,
  IProfile,
  IRestaurantCG,
} from "../../models/IFirestoreModels";
import { IProductsBidsTableErrors } from "../../models/IProductsBidsTableErrors";
import {
  getDayByWeekOfYear,
  getReplaceWith,
  PrintDate,
  removeSpaces,
} from "../../util/functions";
import ProductBidTable from "../UpdateBidView/ProductBidTable";
import firebase from "firebase/app";

interface IMultipleBidUploadProps {
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  Api: IApi;
  innerHeight: number;
}
interface IMultipleBidUploadState {
  Workbook?: XLSX.WorkBook;

  SelectedYear: number;
  TotalSheets: number;

  ColumnsNamesOptions: Array<IDropdownOption>;
  SelectedProductNameColumn: string;
  SelectedProductDescriptionColumn: string;
  SelectedProductPackPriceColumn: string;
  SelectedProductUnitPerPackColumn: string;
  SelectedProductUnitAmountColumn: string;
  SelectedProductUnitMesureColumn: string;

  ProviderIDsOptions: Array<IDropdownOption>;
  SelectedProviderID: string;
  SelectedProvider: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

  SelectedSheetIndex: number;
  SelectedSheet: Array<Array<string>>;
  ProductBidDay: Date;

  ProductsBidsTable: Array<IProviderItemBid>;
  ProductsBidsTableErrors: Array<IProductsBidsTableErrors>;

  Restaurant: IRestaurantCG;
  ProductCollection: Array<IRestaurantItem>;
  ProviderCollection: Array<IProvider>;

  isUploading: boolean;
}
export default class MultipleBidUpload extends Component<
  IMultipleBidUploadProps,
  IMultipleBidUploadState
> {
  Api: IApi;
  constructor(props: IMultipleBidUploadProps) {
    super(props);
    this.state = {
      Workbook: undefined,

      SelectedYear: 0,
      TotalSheets: 0,

      ColumnsNamesOptions: [
        { key: "0", text: "A" },
        { key: "1", text: "B" },
        { key: "2", text: "C" },
        { key: "3", text: "D" },
        { key: "4", text: "E" },
        { key: "5", text: "F" },
        { key: "6", text: "G" },
        { key: "7", text: "H" },
        { key: "8", text: "I" },
        { key: "9", text: "J" },
        { key: "10", text: "K" },
        { key: "11", text: "L" },
        { key: "12", text: "M" },
        { key: "13", text: "N" },
        { key: "14", text: "O" },
        { key: "15", text: "P" },
        { key: "16", text: "Q" },
        { key: "17", text: "R" },
        { key: "18", text: "S" },
        { key: "19", text: "T" },
        { key: "20", text: "U" },
        { key: "21", text: "V" },
        { key: "22", text: "W" },
        { key: "23", text: "X" },
        { key: "24", text: "Y" },
        { key: "25", text: "Z" },
      ],
      SelectedProductNameColumn: "",
      SelectedProductDescriptionColumn: "",
      SelectedProductPackPriceColumn: "",
      SelectedProductUnitPerPackColumn: "",
      SelectedProductUnitAmountColumn: "",
      SelectedProductUnitMesureColumn: "",

      ProviderIDsOptions: [],
      SelectedProviderID: "",
      SelectedProvider: {} as any,

      SelectedSheetIndex: 0,
      SelectedSheet: [],
      ProductBidDay: new Date(),

      ProductsBidsTable: [],
      ProductsBidsTableErrors: [],

      ProductCollection: [],
      ProviderCollection: [],
      Restaurant: {} as any,
      isUploading: false,
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (
      this.props.currentUser &&
      this.props.currentProfile &&
      this.props.currentRestaurant
    ) {
      Promise.all([
        this.Api.getCollect(
          this.props.currentRestaurant?.RestaurantSubCollectionPaths
            .RestaurantItemCollectionPath
        ),
        this.Api.getCollect(
          this.props.currentRestaurant?.RestaurantSubCollectionPaths
            .ProviderCollectionPath
        ),
      ]).then((data: Array<any>) => {
        this.setState({ ProductCollection: data[0] });
        this.setState({
          ProviderCollection: data[1].sort((A: any, B: any) =>
            A.ProviderName < B.ProviderName
              ? -1
              : A.ProviderName > B.ProviderName
              ? 1
              : 0
          ),
        });
        this.setState({
          Restaurant: this.props.currentRestaurant as IRestaurantCG,
        });
        let ProviderIDsOptions: Array<IDropdownOption> = [];
        data[1].forEach((ProviderDoc: IProvider) =>
          ProviderIDsOptions.push({
            key: ProviderDoc.ref?.id ? ProviderDoc.ref?.id : "",
            text: ProviderDoc.ProviderName,
          })
        );
        let SelectedProviderID = this.props.currentProfile?.ProviderGUID
          ? this.props.currentProfile?.ProviderGUID?.id
          : "";
        this.setState({ ProviderIDsOptions, SelectedProviderID });
      });
    }
  }
  componentDidUpdate(
    prevProps: IMultipleBidUploadProps,
    prevState: IMultipleBidUploadState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.currentProfile?.ProfileRole !==
        prevProps.currentProfile?.ProfileRole ||
      this.props.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevProps.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  handleOnDrop = (
    acceptedFiles: Array<File>,
    rejectedFiles: Array<any>,
    event: DropEvent
  ) => {
    let reader = new FileReader();
    reader.readAsBinaryString(acceptedFiles[0]);
    reader.onload = (ev: ProgressEvent<FileReader>) => {
      if (ev.target && ev.target.result) {
        let file: string | ArrayBuffer = ev.target.result;
        let opts: XLSX.ParsingOptions = { type: "binary" };
        let Workbook: XLSX.WorkBook = XLSX.read(file, opts);
        let SelectedSheet: Array<
          Array<string>
        > = XLSX.utils.sheet_to_json(
          Workbook.Sheets[Workbook.SheetNames[this.state.SelectedSheetIndex]],
          { header: 1 }
        );
        let year: number = Number(
          acceptedFiles[0].name.substr(0, acceptedFiles[0].name.indexOf("."))
        );
        this.setState({
          Workbook,
          SelectedYear: year ? year : new Date().getFullYear(),
          SelectedSheet,
          TotalSheets: Workbook.SheetNames.length,
          ProductBidDay:
            year && Number(Workbook.SheetNames[this.state.SelectedSheetIndex])
              ? getDayByWeekOfYear(
                  0,
                  Number(Workbook.SheetNames[this.state.SelectedSheetIndex]),
                  year
                )
              : new Date(),
        });
        if (SelectedSheet.length) {
          this.setColumnsNamesOptions(SelectedSheet);
        }
      }
    };
  };
  setColumnsNamesOptions(SelectedSheet: Array<Array<string>>) {
    let ColumnsNames: Array<string> = [];
    SelectedSheet.forEach(
      (Row: any) => (ColumnsNames = ColumnsNames.concat(Object.keys(Row)))
    );
    let ColumnsNamesSet: Set<string> = new Set(ColumnsNames);
    ColumnsNames = [];
    ColumnsNamesSet.forEach((ColumnsName: string) =>
      ColumnsNames.push(ColumnsName)
    );
    let ColumnsNamesOptions: Array<IDropdownOption> = this.state.ColumnsNamesOptions.slice(
      0,
      ColumnsNames.length + 1
    );
    this.setState({
      ColumnsNamesOptions,
      SelectedProductNameColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "0"
      ),
      SelectedProductDescriptionColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "6"
      ),
      SelectedProductPackPriceColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "1"
      ),
      SelectedProductUnitPerPackColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "3"
      ),
      SelectedProductUnitAmountColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "4"
      ),
      SelectedProductUnitMesureColumn: this.filterByDefaultColumnName(
        ColumnsNamesOptions,
        "5"
      ),
    });
  }
  filterByDefaultColumnName(
    ColumnsNamesOptions: Array<IDropdownOption>,
    DefaultColumnName: string
  ): string {
    let filtered: Array<IDropdownOption> = ColumnsNamesOptions.filter(
      (ColumnName: IDropdownOption) =>
        removeSpaces(ColumnName.key.toString().toUpperCase()) ===
        DefaultColumnName
    );
    return filtered.length
      ? filtered[0].key.toString()
      : ColumnsNamesOptions[0].key.toString();
  }
  handleColumnNameSelected = (
    event: any,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ) => {
    if (option) {
      switch (event.target.id) {
        case "SelectedProviderID":
          const Provider: IProvider = this.state.ProviderCollection.find(
            (provider: IProvider) => provider.ref?.id === option?.key.toString()
          ) as IProvider;
          this.setState({
            SelectedProviderID: option?.key.toString(),
            SelectedProvider: Provider.ref as any,
          });
          break;
        case "SelectedProductNameColumn":
          this.setState({ SelectedProductNameColumn: option?.key.toString() });
          break;
        case "SelectedProductDescriptionColumn":
          this.setState({
            SelectedProductDescriptionColumn: option?.key.toString(),
          });
          break;
        case "SelectedProductPackPriceColumn":
          this.setState({
            SelectedProductPackPriceColumn: option?.key.toString(),
          });
          break;
        case "SelectedProductUnitPerPackColumn":
          this.setState({
            SelectedProductUnitPerPackColumn: option?.key.toString(),
          });
          break;
        case "SelectedProductUnitAmountColumn":
          this.setState({
            SelectedProductUnitAmountColumn: option?.key.toString(),
          });
          break;
        case "SelectedProductUnitMesureColumn":
          this.setState({
            SelectedProductUnitMesureColumn: option?.key.toString(),
          });
          break;
      }
    }
  };
  handleStartMaping = () => {
    let ProductsBidsTable: Array<IProviderItemBid> = [];
    let ProductsBidsTableErrors: Array<IProductsBidsTableErrors> = [];
    let uniqueRefAdded: boolean = false;
    this.state.ProductCollection.forEach((ProductDoc: IRestaurantItem) => {
      uniqueRefAdded = false;
      this.state.SelectedSheet.forEach((Row: any) => {
        if (
          Row[this.state.SelectedProductNameColumn] &&
          removeSpaces(
            Row[this.state.SelectedProductNameColumn] as string
          ).toUpperCase() === ProductDoc.ProductName
        ) {
          if (!uniqueRefAdded) {
            uniqueRefAdded = true;
            try {
              let ProductGUID: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = ProductDoc.ref as any;
              let ProductPackPrice: number = Row[
                this.state.SelectedProductPackPriceColumn
              ]
                ? Number(Row[this.state.SelectedProductPackPriceColumn])
                : 0;
              let ProductUnitPerPack: number = Row[
                this.state.SelectedProductUnitPerPackColumn
              ]
                ? Number(Row[this.state.SelectedProductUnitPerPackColumn])
                : 1;
              let ProductUnitAmount: number = Row[
                this.state.SelectedProductUnitAmountColumn
              ]
                ? Number(Row[this.state.SelectedProductUnitAmountColumn])
                : 1;
              let ProductUnitMesure: any = getReplaceWith(
                this.state.Restaurant.RestaurantUnitMesures,
                Row[this.state.SelectedProductUnitMesureColumn]
              );

              if (!ProductPackPrice) throw new Error("PackPrice_NaN");
              if (!ProductUnitPerPack) throw new Error("UnitPerPack_NaN");
              if (!ProductUnitAmount) throw new Error("UnitAmount_NaN");
              if (ProductUnitMesure === "NoFoundCase")
                throw new Error("UnitMesure_NotFound");
              // if (
              //   ProductDoc.ProductUnitMesure !==
              //     this.state.Restaurant.RestaurantUnitMesures.defaultCase &&
              //   ProductUnitMesure !== ProductDoc.ProductUnitMesure
              // )
              //   throw new Error("UnitMesure_NotMatch");

              let ProductBidRow: IProviderItemBid = {
                ProductGUID,
                ProductDescription: Row[
                  this.state.SelectedProductDescriptionColumn
                ]
                  ? Row[this.state.SelectedProductDescriptionColumn]
                  : "",
                ProductPackPrice,
                ProductUnitPerPack,
                ProductUnitAmount,
                ProductUnitMesure,
              };
              ProductsBidsTable.push(ProductBidRow);
            } catch (ex) {
              let ProductBidRowError: IProductsBidsTableErrors = {
                ErrorsFound: ex,
                ProductName: Row[this.state.SelectedProductNameColumn],
                ProductDescription:
                  Row[this.state.SelectedProductDescriptionColumn],
                ProductPackPrice:
                  Row[this.state.SelectedProductPackPriceColumn],
                ProductUnitPerPack:
                  Row[this.state.SelectedProductUnitPerPackColumn],
                ProductUnitAmount:
                  Row[this.state.SelectedProductUnitAmountColumn],
                ProductUnitMesure:
                  Row[this.state.SelectedProductUnitMesureColumn],
              };
              ProductsBidsTableErrors.push(ProductBidRowError);
            }
          } else {
            let ProductBidRowError: IProductsBidsTableErrors = {
              ErrorsFound: new Error("ProductGUID_Duplicate"),
              ProductName: Row[this.state.SelectedProductNameColumn],
              ProductDescription:
                Row[this.state.SelectedProductDescriptionColumn],
              ProductPackPrice: Row[this.state.SelectedProductPackPriceColumn],
              ProductUnitPerPack:
                Row[this.state.SelectedProductUnitPerPackColumn],
              ProductUnitAmount:
                Row[this.state.SelectedProductUnitAmountColumn],
              ProductUnitMesure:
                Row[this.state.SelectedProductUnitMesureColumn],
            };
            ProductsBidsTableErrors.push(ProductBidRowError);
          }
        }
      });
    });
    this.state.SelectedSheet.forEach((Row: any) => {
      if (
        Row[this.state.SelectedProductNameColumn] &&
        !this.state.ProductCollection.find(
          (ProductDoc: IRestaurantItem) =>
            ProductDoc.ProductName ===
            (Row[this.state.SelectedProductNameColumn] as string).toUpperCase()
        )
      ) {
        let ProductBidRowError: IProductsBidsTableErrors = {
          ErrorsFound: new Error("ProductGUID_NotFound"),
          ProductName: Row[this.state.SelectedProductNameColumn],
          ProductDescription: Row[this.state.SelectedProductDescriptionColumn],
          ProductPackPrice: Row[this.state.SelectedProductPackPriceColumn],
          ProductUnitPerPack: Row[this.state.SelectedProductUnitPerPackColumn],
          ProductUnitAmount: Row[this.state.SelectedProductUnitAmountColumn],
          ProductUnitMesure: Row[this.state.SelectedProductUnitMesureColumn],
        };
        ProductsBidsTableErrors.push(ProductBidRowError);
      }
    });
    this.setState({ ProductsBidsTable, ProductsBidsTableErrors });
  };
  resetUpload = () => {
    setTimeout(() => {
      if (this.state.Workbook) {
        this.setState({
          SelectedSheetIndex: this.state.SelectedSheetIndex + 1,
          SelectedSheet: XLSX.utils.sheet_to_json(
            this.state.Workbook.Sheets[
              this.state.Workbook.SheetNames[this.state.SelectedSheetIndex + 1]
            ],
            { header: 1 }
          ),
          ProductBidDay: getDayByWeekOfYear(
            0,
            Number(
              this.state.Workbook.SheetNames[this.state.SelectedSheetIndex]
            ),
            this.state.SelectedYear
          ),
          ProductsBidsTable: [],
          ProductsBidsTableErrors: [],
        });
      }
      if (this.state.SelectedSheetIndex < this.state.TotalSheets) {
        setTimeout(() => this.handleStartMaping(), 50);
      } else {
        this.setState({ isUploading: false });
      }
    }, 100);
  };
  render() {
    const OverFlowStyle: React.CSSProperties = {
      width: "100%",
      height: `${this.props.innerHeight - 100}px`,
      overflowY: "scroll",
    };
    return (
      <span>
        <span style={{ position: "fixed", bottom: 15, right: 10 }}>
          <PrimaryButton
            iconProps={{ iconName: "Upload" }}
            onClick={() => {
              this.setState({ isUploading: true });
              this.handleStartMaping();
            }}
            disabled={
              this.state.isUploading ||
              this.state.SelectedProviderID === "" ||
              this.state.SelectedSheet.length === 0
            }
          >
            {this.state.isUploading ? (
              <Spinner size={SpinnerSize.small} />
            ) : (
              "Upload Now"
            )}
          </PrimaryButton>
        </span>
        {this.state.isUploading && (
          <span
            style={{ position: "fixed", bottom: 120, left: 10, width: "20%" }}
          >
            <ProgressIndicator
              label={`Overall Progress ${this.state.SelectedSheetIndex}/${this.state.TotalSheets}`}
              description={`Order from: ${PrintDate(this.state.ProductBidDay)}`}
              percentComplete={
                (1 / this.state.TotalSheets) * this.state.SelectedSheetIndex
              }
            />
          </span>
        )}
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "20%", position: "absolute" }}>
                <Dropdown
                  id="SelectedProviderID"
                  label="Vendor Name"
                  defaultSelectedKey={this.state.SelectedProviderID}
                  options={this.state.ProviderIDsOptions}
                  onChange={this.handleColumnNameSelected}
                  disabled={this.state.isUploading}
                />
                <Label style={{ color: "red" }}>
                  First Row Must Be the Table's Header
                </Label>
                <br />
                <Dropdown
                  id="SelectedProductNameColumn"
                  label="Select Item Name Column"
                  defaultSelectedKey={this.state.SelectedProductNameColumn}
                  options={this.state.ColumnsNamesOptions}
                  onChange={this.handleColumnNameSelected}
                  disabled={this.state.isUploading}
                />
                <Dropdown
                  id="SelectedProductDescriptionColumn"
                  label="Select Item Description Column"
                  defaultSelectedKey={
                    this.state.SelectedProductDescriptionColumn
                  }
                  options={this.state.ColumnsNamesOptions}
                  onChange={this.handleColumnNameSelected}
                  disabled={this.state.isUploading}
                />
                <Dropdown
                  id="SelectedProductPackPriceColumn"
                  label="Select Item Case Price Column"
                  defaultSelectedKey={this.state.SelectedProductPackPriceColumn}
                  options={this.state.ColumnsNamesOptions}
                  onChange={this.handleColumnNameSelected}
                  disabled={this.state.isUploading}
                />
                <Dropdown
                  id="SelectedProductUnitPerPackColumn"
                  label="Select Item Units Per Pack Column"
                  defaultSelectedKey={
                    this.state.SelectedProductUnitPerPackColumn
                  }
                  options={this.state.ColumnsNamesOptions}
                  onChange={this.handleColumnNameSelected}
                  disabled={this.state.isUploading}
                />
                <Dropdown
                  id="SelectedProductUnitAmountColumn"
                  label="Select Item Unit Amount Column"
                  defaultSelectedKey={
                    this.state.SelectedProductUnitAmountColumn
                  }
                  options={this.state.ColumnsNamesOptions}
                  onChange={this.handleColumnNameSelected}
                  disabled={this.state.isUploading}
                />
                <Dropdown
                  id="SelectedProductUnitMesureColumn"
                  label="Select Item Unit Measure Column"
                  defaultSelectedKey={
                    this.state.SelectedProductUnitMesureColumn
                  }
                  options={this.state.ColumnsNamesOptions}
                  onChange={this.handleColumnNameSelected}
                  disabled={this.state.isUploading}
                />
                <span style={{ position: "fixed", bottom: 15, left: 10 }}>
                  <Dropzone onDrop={this.handleOnDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <span {...getRootProps()}>
                        <input {...getInputProps()} />
                        <CompoundButton
                          primary={true}
                          text="Upload Excel File"
                          secondaryText="Drag 'n' drop some files here, or click to select files"
                          iconProps={{ iconName: "Table" }}
                          disabled={this.state.isUploading}
                        />
                      </span>
                    )}
                  </Dropzone>
                </span>
              </td>
              <td style={{ width: "78%" }}>
                <div style={OverFlowStyle}>
                  {this.props.currentRestaurant &&
                    this.props.currentProfile &&
                    this.state.SelectedProvider &&
                    this.state.SelectedProviderID &&
                    this.state.ProductsBidsTable.length !== 0 && (
                      <ProductBidTable
                        Profile={this.props.currentProfile}
                        Table={this.state.ProductsBidsTable}
                        TableErrors={this.state.ProductsBidsTableErrors}
                        ProductCollection={this.state.ProductCollection}
                        ProviderCollection={this.state.ProviderCollection}
                        SelectedProviderID={this.state.SelectedProviderID}
                        SelectedProvider={this.state.SelectedProvider}
                        Api={this.Api}
                        Restaurant={this.props.currentRestaurant}
                        ProductBidDay={this.state.ProductBidDay}
                        resetUpload={this.resetUpload}
                        multipleUpload={true}
                      />
                    )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </span>
    );
  }
}
