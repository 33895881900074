import firebase from "firebase/app";
export interface IAudit extends IAuditFlags {
  ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  AuditAuthUID: string | "unauthenticated";
  AuditAuthToken: any | "unauthenticated";
  AuditAuthType: "ADMIN" | "USER" | "UNAUTHENTICATED" | "NULL";
  AuditOperationDate: firebase.firestore.Timestamp;
  AuditOperationType: OperationType;
  AuditCollectionID: string;
  AuditDocumentID: string;
  AuditDocumentSnapshot: any;
  AuditDocumentSnapshotChanges?: Array<{
    Operation: OperationType;
    Key: string;
    Value: any;
  }>;
}
export enum OperationType {
  // Read = "Read",
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}
export interface IAuditFlags {
  AuditRollBackREF?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  AuditRollBackDate?: firebase.firestore.Timestamp;
  AuditOlderDateFlag?: firebase.firestore.Timestamp;
  AuditSkipBackUpFlag?: boolean;
}
