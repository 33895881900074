import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import IApi, { Environment } from "./api/IApi";
import LocalApi from "./api/LocalApi";
import Api from "./api/Api";
import {
  IProfile,
  IRestaurantCG,
  ProfileRoles,
} from "./models/IFirestoreModels";
import { initializeIcons } from "@uifabric/icons";
import "./components/index.scss";
import { Dropdown, IDropdownOption, Label } from "office-ui-fabric-react";
import {
  RestaurantCollectionPath,
  ProfileCollectionPath,
} from "./const/CollectionsPaths";
import firebase from "firebase/app";
import Auth from "./components/Auth";
import Navbar from "./components/Navbar";
import Profile from "./components/ProfileView/Profile";
import NewOrderTable from "./components/NewOrderView/NewOrderTable";
import BidUpload from "./components/UpdateBidView/BidUpload";
import Development from "./components/DevelopmentView/Development";
import ModelEditor from "./components/ModelEditorView/ModelEditor";
import OrderManager from "./components/OrderManagerView/OrderManager";
import BidManager from "./components/BidManagerView/BidManager";
import MultipleBidUpload from "./components/UpdateMultipleBidView/MultipleBidUpload";
import ModelEditorEmails from "./components/ModelEditorView/ModelEditorEmails";
import RecipeManager from "./components/RecipeManagerView/RecipeManager";
import InventoryManager from "./components/InventoryManagerView/InventoryManager";
import Context from "./const/Context";
initializeIcons();

interface IAppProps {
  // TOP Level Logic Component
}
interface IAppState {
  //Gobal States
  currentEnviroment: Environment;
  currentRestaurant: IRestaurantCG | null;
  currentUser: firebase.User | null;
  currentProfile: IProfile | null;
  RestaurantCollection: Array<IRestaurantCG>;
  restaurantOptions: Array<IDropdownOption>;
  innerHeight: number;
  innerWidth: number;

  //Roles Views
  showEditModels: boolean;
  showOrdersManager: boolean;
  showBidsManager: boolean;
  showUpdateBid: boolean;
  showDevelopment: boolean;
}
export default class App extends Component<IAppProps, IAppState> {
  Api: IApi;
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      // Change currentEnviroment
      currentEnviroment: Environment.Release,
      currentRestaurant: null,
      currentUser: null,
      currentProfile: null,
      RestaurantCollection: [],
      restaurantOptions: [],
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,

      showEditModels: false,
      showOrdersManager: false,
      showBidsManager: false,
      showUpdateBid: false,
      showDevelopment: false,
    };
    this.Api =
      this.state.currentEnviroment === Environment.Local
        ? new LocalApi()
        : new Api(this.state.currentEnviroment);
  }
  async componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      })
    );
    if (
      this.state.currentUser &&
      this.state.currentProfile &&
      this.state.currentRestaurant
    ) {
      let RestaurantCollection: Array<IRestaurantCG> =
        await this.Api.getCollect(RestaurantCollectionPath);
      this.setState({
        RestaurantCollection,
        restaurantOptions: RestaurantCollection.map((rest: IRestaurantCG) => {
          return { key: rest.ref?.id, text: rest.RestaurantName } as any;
        }),

        showEditModels:
          this.state.currentEnviroment === Environment.Local ||
          this.state.currentProfile?.ProfileRole === ProfileRoles.Dev ||
          this.state.currentProfile?.ProfileRole === ProfileRoles.Admin
            ? true
            : false,
        showOrdersManager:
          this.state.currentEnviroment === Environment.Local ||
          this.state.currentProfile?.ProfileRole === ProfileRoles.Dev ||
          this.state.currentProfile?.ProfileRole.includes(ProfileRoles.Admin) ||
          this.state.currentProfile?.ProfileRole.includes(ProfileRoles.Manager)
            ? true
            : false,
        showBidsManager:
          this.state.currentEnviroment === Environment.Local ||
          this.state.currentProfile?.ProfileRole === ProfileRoles.Dev ||
          this.state.currentProfile?.ProfileRole.includes(ProfileRoles.Admin)
            ? true
            : false,
        showUpdateBid:
          this.state.currentEnviroment === Environment.Local ||
          this.state.currentProfile?.ProfileRole === ProfileRoles.Dev ||
          this.state.currentProfile?.ProfileRole.includes(ProfileRoles.Admin) ||
          this.state.currentProfile?.ProfileRole.includes(ProfileRoles.Provider)
            ? true
            : false,
        showDevelopment:
          this.state.currentEnviroment === Environment.Local ||
          this.state.currentProfile?.ProfileRole === ProfileRoles.Dev
            ? true
            : false,
      });
    }
  }
  componentDidUpdate(
    prevProps: IAppProps,
    prevState: IAppState,
    snapshot: any
  ) {
    if (
      this.state.currentUser?.uid !== prevState.currentUser?.uid ||
      this.state.currentProfile?.ProfileRole !==
        prevState.currentProfile?.ProfileRole ||
      this.state.currentRestaurant?.RestaurantSubCollectionPaths !==
        prevState.currentRestaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  render(): JSX.Element {
    const EnvironmentBannerStyle: React.CSSProperties = {
      backgroundColor: `${
        this.state.currentEnviroment === Environment.Development
          ? "MediumSeaGreen"
          : "SlateBlue"
      }`,
      position: "fixed",
      color: "white",
      marginTop: 5,
      paddingLeft: 5,
      fontFamily: "Arial",
      height: 18,
      width: "100%",
      left: 0,
      top: -5,
      zIndex: 5,
    };
    return (
      <Context.Provider value={this.state}>
        <BrowserRouter>
          <div className="App">
            <Auth
              Api={this.Api}
              currentEnviroment={this.state.currentEnviroment}
              setCurrentUser={(currentUser: firebase.User | null) =>
                this.setState({ currentUser })
              }
              setCurrentProfile={(currentProfile: IProfile | null) =>
                this.setState({ currentProfile })
              }
              setCurrentRestaurant={(currentRestaurant: IRestaurantCG | null) =>
                this.setState({ currentRestaurant })
              }
            />
            <span style={{ position: "fixed", top: 15, left: 10, zIndex: 5 }}>
              <Navbar
                innerWidth={this.state.innerWidth}
                showEditModels={this.state.showEditModels}
                showOrdersManager={this.state.showOrdersManager}
                showBidsManager={this.state.showBidsManager}
                showUpdateBid={this.state.showUpdateBid}
                showDevelopment={this.state.showDevelopment}
              />
            </span>
            <span style={{ position: "fixed", top: 15, right: 10, zIndex: 5 }}>
              <Dropdown
                id="Restaurant"
                selectedKey={this.state.currentRestaurant?.ref?.id}
                options={this.state.restaurantOptions}
                disabled={
                  this.state.currentProfile?.ProfileRole !== ProfileRoles.Dev &&
                  this.state.currentProfile?.ProfileRole !== ProfileRoles.Admin
                }
                onChange={async (event, opt) => {
                  const Restaurant: IRestaurantCG =
                    this.state.RestaurantCollection.find(
                      (rest) => rest.ref?.id === (opt?.key.toString() as string)
                    ) as IRestaurantCG;
                  this.setState({ currentRestaurant: Restaurant });
                  this.Api.updDoc(
                    this.state.currentProfile?.ref?.id as string,
                    { RestaurantGUID: Restaurant.ref },
                    ProfileCollectionPath
                  );
                }}
              />
            </span>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <div style={{ height: "60px" }} />
              <hr />
            </div>
            <div style={{ height: "70px" }} />
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Profile
                    {...props}
                    currentEnviroment={this.state.currentEnviroment}
                    currentUser={this.state.currentUser}
                    currentProfile={this.state.currentProfile}
                    Api={this.Api}
                    innerHeight={this.state.innerHeight}
                    innerWidth={this.state.innerWidth}
                  />
                )}
              />
              {this.state.showEditModels &&
                (this.state.innerWidth > 1050 ? (
                  <Route
                    path="/edit_models"
                    render={(props) => (
                      <ModelEditor
                        {...props}
                        currentEnviroment={this.state.currentEnviroment}
                        currentRestaurant={this.state.currentRestaurant}
                        currentUser={this.state.currentUser}
                        currentProfile={this.state.currentProfile}
                        Api={this.Api}
                        innerHeight={this.state.innerHeight}
                        innerWidth={this.state.innerWidth}
                        showDevelopment={this.state.showDevelopment}
                      />
                    )}
                  />
                ) : (
                  <Route
                    path="/edit_models"
                    render={(props) => (
                      <Label>
                        We apologize your screen is too small to display this
                        interface :(
                      </Label>
                    )}
                  />
                ))}
              {this.state.showEditModels &&
                (this.state.innerWidth > 1000 ? (
                  <Route
                    path="/edit_notify"
                    render={(props) => (
                      <ModelEditorEmails
                        {...props}
                        currentEnviroment={this.state.currentEnviroment}
                        currentRestaurant={this.state.currentRestaurant}
                        currentUser={this.state.currentUser}
                        currentProfile={this.state.currentProfile}
                        Api={this.Api}
                        innerHeight={this.state.innerHeight}
                        innerWidth={this.state.innerWidth}
                      />
                    )}
                  />
                ) : (
                  <Route
                    path="/edit_notify"
                    render={(props) => (
                      <Label>
                        We apologize your screen is too small to display this
                        interface :(
                      </Label>
                    )}
                  />
                ))}
              {this.state.showOrdersManager &&
                (this.state.currentProfile?.ProfileRole === ProfileRoles.Dev ? (
                  <Route
                    path="/inventory_manager"
                    render={(props) => (
                      <InventoryManager
                        {...props}
                        currentEnviroment={this.state.currentEnviroment}
                        currentRestaurant={this.state.currentRestaurant}
                        currentUser={this.state.currentUser}
                        currentProfile={this.state.currentProfile}
                        Api={this.Api}
                        innerHeight={this.state.innerHeight}
                        innerWidth={this.state.innerWidth}
                      />
                    )}
                  />
                ) : (
                  <Route
                    path="/inventory_manager"
                    render={(props) => (
                      <Label>
                        We apologize this interface is still under Development
                        :(
                      </Label>
                    )}
                  />
                ))}
              {this.state.showOrdersManager && (
                <Route
                  path="/recipes_manager"
                  render={(props) => (
                    <RecipeManager
                      {...props}
                      currentEnviroment={this.state.currentEnviroment}
                      currentRestaurant={this.state.currentRestaurant}
                      currentUser={this.state.currentUser}
                      currentProfile={this.state.currentProfile}
                      Api={this.Api}
                      innerHeight={this.state.innerHeight}
                      innerWidth={this.state.innerWidth}
                    />
                  )}
                />
              )}
              {this.state.showOrdersManager && (
                <Route
                  path="/orders_manager"
                  render={(props) => (
                    <OrderManager
                      {...props}
                      currentEnviroment={this.state.currentEnviroment}
                      currentRestaurant={this.state.currentRestaurant}
                      currentUser={this.state.currentUser}
                      currentProfile={this.state.currentProfile}
                      Api={this.Api}
                      innerHeight={this.state.innerHeight}
                      innerWidth={this.state.innerWidth}
                    />
                  )}
                />
              )}
              {this.state.showBidsManager && (
                <Route
                  path="/bids_manager"
                  render={(props) => (
                    <BidManager
                      {...props}
                      currentEnviroment={this.state.currentEnviroment}
                      currentRestaurant={this.state.currentRestaurant}
                      currentUser={this.state.currentUser}
                      currentProfile={this.state.currentProfile}
                      Api={this.Api}
                      innerHeight={this.state.innerHeight}
                      innerWidth={this.state.innerWidth}
                    />
                  )}
                />
              )}
              {this.state.showOrdersManager && (
                <Route
                  path="/new_order"
                  render={(props) => (
                    <NewOrderTable
                      {...props}
                      currentEnviroment={this.state.currentEnviroment}
                      currentRestaurant={this.state.currentRestaurant}
                      currentUser={this.state.currentUser}
                      currentProfile={this.state.currentProfile}
                      Api={this.Api}
                      innerHeight={this.state.innerHeight}
                      innerWidth={this.state.innerWidth}
                    />
                  )}
                />
              )}
              {this.state.showUpdateBid && (
                <Route
                  path="/update_bid"
                  render={(props) => (
                    <BidUpload
                      {...props}
                      currentEnviroment={this.state.currentEnviroment}
                      currentRestaurant={this.state.currentRestaurant}
                      currentUser={this.state.currentUser}
                      currentProfile={this.state.currentProfile}
                      Api={this.Api}
                    />
                  )}
                />
              )}
              {this.state.showDevelopment && (
                <Route
                  path="/multiple_update_bid"
                  render={(props) => (
                    <MultipleBidUpload
                      {...props}
                      currentEnviroment={this.state.currentEnviroment}
                      currentRestaurant={this.state.currentRestaurant}
                      currentUser={this.state.currentUser}
                      currentProfile={this.state.currentProfile}
                      Api={this.Api}
                      innerHeight={this.state.innerHeight}
                    />
                  )}
                />
              )}
              {this.state.showDevelopment && (
                <Route
                  path="/dev"
                  render={(props) => (
                    <Development
                      {...props}
                      currentEnviroment={this.state.currentEnviroment}
                      currentRestaurant={this.state.currentRestaurant}
                      currentUser={this.state.currentUser}
                      currentProfile={this.state.currentProfile}
                      Api={this.Api}
                      innerHeight={this.state.innerHeight}
                      innerWidth={this.state.innerWidth}
                    />
                  )}
                />
              )}
            </Switch>
            {this.state.currentEnviroment === Environment.Local ? (
              <span style={EnvironmentBannerStyle}>Local</span>
            ) : (
              this.state.currentEnviroment === Environment.Development && (
                <span style={EnvironmentBannerStyle}>Development</span>
              )
            )}
          </div>
        </BrowserRouter>
      </Context.Provider>
    );
  }
}
