import {
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Icon,
  IDropdownOption,
  ISelectableOption,
  Label,
  PrimaryButton,
  SearchBox,
  Spinner,
  SpinnerSize,
  Toggle,
} from "office-ui-fabric-react";
import React, { Component } from "react";
import { PrintDate, removeSpaces, validateEmail } from "../../util/functions";

//================================================================================================
export enum CRUDPropertyType {
  ID = "ID",
  Control = "Control",

  Static = "Static", //Settings: Cursor
  Boolean = "Boolean",
  Number = "Number", //Settings: Negative, Decimal, Counter
  String = "String", //Settings: Multiple
  Email = "Email",
  Password = "Password",
  Date = "Date",
  Enum = "Enum", //Settings: Choices, Multiple, refAsKey
}
interface CRUDPropertyTypeSettings {
  Cursor?: boolean;

  Negative?: boolean;
  Decimal?: number;
  Counter?: boolean;

  refAsKey?: string;
  Choices?: Array<{
    key: string;
    text: string;
    value: any;
  }>;
  Multiple?: number;
}
interface IStructure {
  Type: CRUDPropertyType;
  Name: string;
  Hidden?: boolean;
  Enforced?: boolean;
  DisplayName?: string;
  IconName?: string;
  Width?: number | string;
  Settings?: CRUDPropertyTypeSettings;
  BuiltInFilter?: boolean;
  BuiltInSort?: boolean;
}
interface ICRUDProps<IItem, IFilter, ISort> {
  //Type = initialSet, [onChangeInput], [ContextOnly], [Synchronous]
  List: Array<IItem>; // [onChangeInput]
  ItemName: string; // initialSet
  DisplayField: string; // initialSet
  Structure: Array<IStructure>; // initialSet
  onDisable?: (item: IItem, col: IStructure) => boolean | undefined; // [ContextOnly]
  onClick?: (item: IItem, col: IStructure) => void; // [ContextOnly]

  ActionConfirm?: boolean; // initialSet
  onAction?: (id: string, item: IItem) => Promise<string>; // [Synchronous]

  AddItem?: boolean; // initialSet
  AddItemExtra?: string; // initialSet
  AddConfirm?: boolean; // initialSet
  onAdd?: (item: IItem, AddItemExtra?: boolean) => Promise<string>; // [Synchronous]

  UpdateAll?: boolean; // initialSet
  UpdateConfirm?: boolean; // initialSet
  onUpdate?: (updates: Array<{ id: string; changes: any }>) => Promise<string>; // [Synchronous]

  DeleteConfirm?: boolean; // initialSet
  onDelete?: (id: string) => Promise<string>; // [Synchronous]

  BuiltInFilter?: boolean; // initialSet
  Filter?: IFilter; // [onChangeInput]
  onFilter?: (List: Array<IItem>, Filter?: IFilter) => Array<IItem>; // [ContextOnly]

  SortField?: string; // initialSet
  BuiltInSort?: boolean; // initialSet
  BuiltInReSort?: boolean; // initialSet
  Sort?: ISort; // [onChangeInput]
  onSort?: (List: Array<IItem>, Sort?: ISort) => Array<IItem>; // [ContextOnly]

  Debug?: boolean; // initialSet
  Log?: boolean; // initialSet
  maxHeight?: number; // initialSet
  maxWidth?: number; // initialSet
  //================================================================================================
}
interface ICRUDFilter {
  Type: CRUDPropertyType;
  Name: string;
  Value: any;
  Settings?: CRUDPropertyTypeSettings;
}
interface ICRUDState {
  IDName: string;

  prevList: Array<any>;
  List: Array<any>;
  DisplayList: Array<any>;
  NewItem: any;

  StructureFilter: Array<ICRUDFilter>;
  StructureSort: Array<IDropdownOption>;
  StructureSortSelected: string;
  SortMode: boolean;

  isLoading: boolean;
  isDialogHidden: boolean;
  Operation?: {
    Type: "onAdd" | "onUpdate" | "onDelete" | "onAction";
    ActionName?: string;
    Name: string;
    ID: string;
    Value: any;
  };
}
export default class CRUD<IItem, IFilter, ISort> extends Component<
  ICRUDProps<IItem, IFilter, ISort>,
  ICRUDState
> {
  constructor(props: ICRUDProps<IItem, IFilter, ISort>) {
    super(props);
    const IDName = this.props.Structure.find(
      (col) => col.Type === CRUDPropertyType.ID
    )?.Name;
    const Structure = this.props.Structure.filter(
      (col) => col.Type !== CRUDPropertyType.Control
    );
    const prevList: Array<any> = this.props.List.map((item: any) => {
      let itemCRUD: any = {};
      Structure.forEach(
        (col) =>
          (itemCRUD[col.Name] = item[col.Name] || this.getInitialValue(col))
      );
      return itemCRUD;
    });
    const List: Array<any> = this.props.List.map((item: any) => {
      let itemCRUD: any = {};
      Structure.forEach(
        (col) =>
          (itemCRUD[col.Name] = item[col.Name] || this.getInitialValue(col))
      );
      return itemCRUD;
    });
    let DisplayList: Array<any> = this.props.List.map((item: any) => {
      let itemCRUD: any = {};
      Structure.forEach(
        (col) =>
          (itemCRUD[col.Name] = item[col.Name] || this.getInitialValue(col))
      );
      return itemCRUD;
    });
    const NewItem: any = {};
    Structure.forEach((col) => (NewItem[col.Name] = this.getInitialValue(col)));
    NewItem[IDName as string] = "NewItem";
    const StructureFilter = this.props.Structure.filter(
      (col) =>
        col.Type !== CRUDPropertyType.ID &&
        col.Type !== CRUDPropertyType.Control
    ).map((s) => {
      return {
        Type: s.Type,
        Name: s.Name,
        Value: "",
        Settings: s.Settings,
      };
    });
    DisplayList = this.onFilter(
      DisplayList,
      this.props.Filter,
      StructureFilter
    );
    let StructureSort: Array<IDropdownOption> = [];
    this.props.Structure.filter(
      (col) =>
        col.Type !== CRUDPropertyType.ID &&
        col.Type !== CRUDPropertyType.Control &&
        (this.props.Debug || col.BuiltInSort)
    ).forEach((col) => {
      StructureSort.push({
        text: col.DisplayName || "",
        key: `${col.Name}@Up`,
        data: { icon: "Up" },
      });
      StructureSort.push({
        text: col.DisplayName || "",
        key: `${col.Name}@Down`,
        data: { icon: "Down" },
      });
    });
    const StructureSortSelected =
      this.props.SortField ?? (StructureSort[0]?.key.toString() || "");
    DisplayList = this.onSort(
      DisplayList,
      this.props.Sort,
      StructureSortSelected
    );
    this.state = {
      IDName: IDName || "",
      prevList,
      List,
      DisplayList,
      NewItem,
      StructureFilter,
      StructureSort,
      StructureSortSelected,
      SortMode: false,
      isLoading: false,
      isDialogHidden: true,
    };
    // SET STATE => [prevList] [List] [DisplayList] [NewItem]
  }
  componentDidUpdate(
    prevProps: ICRUDProps<IItem, IFilter, ISort>,
    prevState: ICRUDState,
    snapshot: any
  ) {
    if (this.props.List !== prevProps.List) {
      const { ItemsAdded, ItemsKeep } = this.getListChanges(
        this.props.List,
        this.state.prevList,
        this.state.IDName
      );
      let ItemsKeepChanges: Array<{
        localChanged: boolean;
        ChangedFields: Array<string>;
      }> = [];
      ItemsKeep.forEach((Item: any) => {
        const { AnyChanges, ChangedFields } = this.getUpdateValidation(Item);
        ItemsKeepChanges.push({
          [this.state.IDName]: Item[this.state.IDName],
          localChanged: AnyChanges,
          ChangedFields,
        });
      });
      const Structure = this.props.Structure.filter(
        (col) => col.Type !== CRUDPropertyType.Control
      );
      const prevList = ItemsKeep.concat(ItemsAdded).map((item: any) => {
        let Item: any = {};
        Structure.forEach(
          (col) =>
            (Item[col.Name] = item[col.Name] || this.getInitialValue(col))
        );
        return Item;
      });
      const List = ItemsKeep.map((itemUpdated: IItem) => {
        let Item: any = {};
        if (this.findByItem(ItemsKeepChanges, itemUpdated).localChanged) {
          Item = this.findByItem(ItemsKeep, itemUpdated);
          const localItem = this.findByItem(this.state.List, itemUpdated);
          this.findByItem(ItemsKeepChanges, itemUpdated).ChangedFields.forEach(
            (f: string) => (Item[f] = localItem[f])
          );
        } else {
          Item = this.findByItem(ItemsKeep, itemUpdated);
        }
        return Item;
      })
        .concat(ItemsAdded)
        .map((item: any) => {
          let Item: any = {};
          Structure.forEach(
            (col) =>
              (Item[col.Name] = item[col.Name] || this.getInitialValue(col))
          );
          return Item;
        });
      let DisplayList: Array<IItem> = List.map((item: any) => {
        let Item: any = {};
        Structure.forEach(
          (col) =>
            (Item[col.Name] = item[col.Name] || this.getInitialValue(col))
        );
        return Item;
      });
      DisplayList = this.onFilter(DisplayList, this.props.Filter);
      DisplayList = this.onSort(DisplayList, this.props.Sort);
      this.setState({ prevList, List, DisplayList });
      // SET STATE => [prevList] [List] [DisplayList]
    }
    if (
      this.props.Filter !== prevProps.Filter ||
      this.props.Sort !== prevProps.Sort
    ) {
      this.setState({
        DisplayList: this.onSort(
          this.onFilter(this.state.List, this.props.Filter),
          this.props.Sort
        ),
      });
      // SET STATE => [DisplayList]
    }
  }
  render(): JSX.Element {
    if (this.props.Log) {
      console.clear();
      console.table(this.props.List);
      console.table(this.state.prevList);
      console.table(this.state.List);
      console.log(
        `%c${this.state.StructureSortSelected}`,
        "font-weight: bold; color: blue"
      );
      console.table(this.state.StructureFilter);
      console.table(this.state.DisplayList);
      console.log(this.state.NewItem);
    }
    const OrderTableStyle: React.CSSProperties = {
      marginTop: 5,
      fontFamily: "Arial",
      width: "100%",
    };
    const OrderTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    let dialog = {
      title: `${this.props.ItemName}?`,
      yes: ` Now`,
      no: `Don't `,
    };
    switch (this.state.Operation?.Type) {
      case "onAdd":
        const add = this.state.Operation.Value.AddItemExtra || "Add";
        dialog.title = `${add} ${dialog.title}`;
        dialog.yes = `Add ${dialog.yes}`;
        dialog.no = `${dialog.no} Add`;
        break;
      case "onUpdate":
        dialog.title = `Update ${dialog.title}`;
        dialog.yes = `Update ${dialog.yes}`;
        dialog.no = `${dialog.no} Update`;
        break;
      case "onDelete":
        dialog.title = `Delete ${dialog.title}`;
        dialog.yes = `Delete ${dialog.yes}`;
        dialog.no = `${dialog.no} Delete`;
        break;
      case "onAction":
        dialog.title = `${this.state.Operation.ActionName}?`;
        dialog.yes = `Action ${dialog.yes}`;
        dialog.no = `${dialog.no} Action`;
        break;
    }
    return (
      <div>
        <div
          style={{
            position: "sticky",
            top: "0px",
            backgroundColor: "white",
            zIndex: 5,
          }}
        >
          <table style={OrderTableStyle}>
            <thead style={OrderTableHeadStyle}>
              <tr>{this.renderTableHead()}</tr>
            </thead>
          </table>
        </div>
        <table style={OrderTableStyle}>
          <tbody>{this.renderTableRows()}</tbody>
        </table>
        {this.props.AddItem && !this.state.SortMode && (
          <div
            style={{
              position: "sticky",
              bottom: "0px",
              backgroundColor: "white",
              zIndex: 5,
            }}
          >
            <hr />
            <table style={OrderTableStyle}>
              <tfoot>
                <tr key={`TableRowNew`}>
                  {this.renderTableData(this.state.NewItem, true)}
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        <Dialog
          hidden={this.state.isDialogHidden}
          onDismiss={() => this.setState({ isDialogHidden: true })}
          dialogContentProps={{
            type: DialogType.normal,
            title: dialog.title,
            closeButtonAriaLabel: "Close",
            subText: `${this.state.Operation?.Name}`,
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton
              disabled={this.state.isLoading}
              onClick={() => {
                switch (this.state.Operation?.Type) {
                  case "onAdd":
                    this.onAdd(
                      this.state.Operation.Value.item,
                      this.state.Operation.Value.AddItemExtra && true
                    );
                    break;
                  case "onUpdate":
                    this.onUpdate(this.state.Operation.Value);
                    break;
                  case "onDelete":
                    this.onDelete(this.state.Operation.ID);
                    break;
                  case "onAction":
                    this.onAction(
                      this.state.Operation.ID,
                      this.state.Operation.Value
                    );
                }
              }}
              text={dialog.yes}
            />
            <DefaultButton
              disabled={this.state.isLoading}
              onClick={() => this.setState({ isDialogHidden: true })}
              text={dialog.no}
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
  private renderTableHead(): Array<JSX.Element> {
    let AnyUpdate: Array<boolean> = [];
    let AnyRevert: Array<boolean> = [];
    let updateList: Array<{ ID: string; Value: any }> = [];
    let revertList: Array<{ ID: string; Value: any }> = [];
    this.state.List.forEach((item) => {
      const { AnyTypeError, AnyChanges, Changes } = this.getUpdateValidation(
        item
      );
      const update = AnyChanges && !AnyTypeError;
      AnyUpdate.push(update);
      AnyRevert.push(AnyChanges);
      if (update) {
        updateList.push({
          ID: item[this.state.IDName],
          Value: Changes,
        });
      }
      if (AnyChanges) {
        revertList.push({
          ID: item[this.state.IDName],
          Value: Changes,
        });
      }
    });
    const disabledUpdate = AnyUpdate.findIndex((c) => c === true) === -1;
    const disabledRevert = AnyRevert.findIndex((c) => c === true) === -1;
    const iconStyles = { marginRight: "8px" };
    const onRenderOption = (option: ISelectableOption): JSX.Element => {
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
      const option = options[0];
      return (
        <div>
          {option.data && option.data.icon && (
            <Icon
              style={iconStyles}
              iconName={option.data.icon}
              aria-hidden="true"
              title={option.data.icon}
            />
          )}
          <span>{option.text}</span>
        </div>
      );
    };
    return this.props.Structure.filter(
      (col) =>
        col.Type !== CRUDPropertyType.ID &&
        !col.Hidden &&
        (!this.state.SortMode ||
          col.Type === CRUDPropertyType.Control ||
          col.Name === this.props.DisplayField)
    ).map((col) => {
      const ControlComponent = (
        <span>
          {!this.state.SortMode &&
            (this.props.Debug || this.props.BuiltInSort) && (
              <Dropdown
                placeholder={"Sort By ..."}
                selectedKey={this.state.StructureSortSelected}
                options={this.state.StructureSort}
                onRenderTitle={(t) =>
                  onRenderTitle(t as Array<IDropdownOption>)
                }
                onChange={(e, o) => {
                  if (o) {
                    this.setState({
                      StructureSortSelected: o?.key.toString(),
                      DisplayList: this.onSort(
                        this.onFilter(this.state.List, this.props.Filter),
                        this.props.Sort,
                        o?.key.toString()
                      ),
                    });
                  }
                }}
                onRenderOption={(o) => onRenderOption(o as ISelectableOption)}
                style={{
                  maxHeight: 35,
                  marginTop: "5px",
                  marginBottom: "5px",
                  width:
                    this.props.maxWidth && Number(col.Width)
                      ? 0.01 * this.props.maxWidth * Number(col.Width)
                      : "98%",
                }}
              />
            )}
          {(this.props.Debug || this.props.BuiltInReSort) &&
            (this.state.SortMode ? (
              <span>
                {disabledRevert ? (
                  <DefaultButton
                    onClick={() => this.setState({ SortMode: false })}
                    text={"Cancel"}
                    iconProps={{ iconName: "Cancel" }}
                    style={{
                      width: "48%",
                      height: "30px",
                      marginBottom: "5px",
                      marginTop: "5px",
                    }}
                  />
                ) : (
                  <PrimaryButton
                    disabled={this.state.isLoading}
                    onClick={() => {
                      if (updateList.length) {
                        if (this.props.UpdateConfirm) {
                          this.setState({
                            isDialogHidden: false,
                            Operation: {
                              Type: "onUpdate",
                              Name: `Resort Items: ${updateList.length}...`,
                              ID: "@Multiple",
                              Value: updateList.map((u) => {
                                return { id: u.ID, changes: u.Value };
                              }),
                            },
                          });
                        } else {
                          this.onUpdate(
                            updateList.map((u) => {
                              return { id: u.ID, changes: u.Value };
                            })
                          );
                        }
                      } else {
                        this.setState({ SortMode: false });
                      }
                    }}
                    text={"Update Sort"}
                    iconProps={{ iconName: "Save" }}
                    style={{
                      width: "48%",
                      height: "30px",
                      marginBottom: "5px",
                      marginTop: "5px",
                    }}
                  />
                )}
                <DefaultButton
                  disabled={disabledRevert}
                  onClick={() => {
                    this.onRevert(revertList.map((i) => i.ID));
                  }}
                  text={"Revert All"}
                  iconProps={{ iconName: "Back" }}
                  style={{
                    width: "48%",
                    height: "30px",
                    marginLeft: "1%",
                    marginBottom: "5px",
                    marginTop: "5px",
                  }}
                />
              </span>
            ) : (
              <DefaultButton
                disabled={this.state.isLoading}
                onClick={() => {
                  this.onRevert(revertList.map((i) => i.ID));
                  this.setState({ SortMode: true });
                }}
                text={"Sort Mode"}
                iconProps={{ iconName: "Sort" }}
                style={{
                  width: "97%",
                  height: "30px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              />
            ))}
          {!this.state.SortMode && (this.props.Debug || this.props.UpdateAll) && (
            <span>
              <PrimaryButton
                disabled={disabledUpdate}
                onClick={() => {
                  if (this.props.UpdateConfirm) {
                    this.setState({
                      isDialogHidden: false,
                      Operation: {
                        Type: "onUpdate",
                        Name: `Multiple Items: ${updateList.length}...`,
                        ID: "@Multiple",
                        Value: updateList.map((u) => {
                          return { id: u.ID, changes: u.Value };
                        }),
                      },
                    });
                  } else {
                    this.onUpdate(
                      updateList.map((u) => {
                        return { id: u.ID, changes: u.Value };
                      })
                    );
                  }
                }}
                text={"Update All"}
                iconProps={{ iconName: "Save" }}
                style={{
                  width: "48%",
                  height: "30px",
                  marginBottom: "5px",
                }}
              />
              <DefaultButton
                disabled={disabledRevert}
                onClick={() => {
                  this.onRevert(revertList.map((i) => i.ID));
                }}
                text={"Revert All"}
                iconProps={{ iconName: "Back" }}
                style={{
                  width: "48%",
                  height: "30px",
                  marginLeft: "1%",
                  marginBottom: "5px",
                }}
              />
            </span>
          )}
        </span>
      );
      let FilterComponent: JSX.Element = (
        <SearchBox
          style={{ width: "100%" }}
          value={
            this.state.StructureFilter.find((f) => f.Name === col.Name)?.Value
          }
          placeholder={`${col.DisplayName}`}
          onChange={(event, value) => this.onFilterChange(col, value)}
        />
      );
      switch (col.Type) {
        case CRUDPropertyType.Boolean:
          FilterComponent = (
            <Toggle
              checked={
                this.state.StructureFilter.find((f) => f.Name === col.Name)
                  ?.Value
              }
              onChange={(event, value) => this.onFilterChange(col, value)}
            />
          );
          break;
        case CRUDPropertyType.Date:
          FilterComponent = (
            <span>
              <DatePicker
                value={
                  this.state.StructureFilter.find((f) => f.Name === col.Name)
                    ?.Value
                }
                placeholder={`${col.DisplayName}`}
                onSelectDate={(date) => this.onFilterChange(col, date)}
              />
              <DefaultButton
                onClick={() => this.onFilterChange(col, "")}
                iconProps={{ iconName: "Clear" }}
                style={{ width: "100%", height: "30px", marginTop: "2px" }}
              />
            </span>
          );
          break;
        case CRUDPropertyType.Enum:
          FilterComponent = (
            <Dropdown
              selectedKeys={
                this.state.StructureFilter.find((f) => f.Name === col.Name)
                  ?.Value
              }
              multiSelect={true}
              options={col.Settings?.Choices ? col.Settings?.Choices : []}
              placeholder={`${col.DisplayName}`}
              onChange={(e, o) => {
                const filter = this.state.StructureFilter.find(
                  (f) => f.Name === col.Name
                )?.Value;
                o &&
                  this.onFilterChange(
                    col,
                    filter.map
                      ? o?.selected === true
                        ? filter.concat((o as any).key)
                        : filter.filter((choice: any) =>
                            col.Settings?.refAsKey
                              ? o.key.toString() !== choice
                              : o.key.toString() !== choice
                          )
                      : [(o as any).key]
                  );
              }}
              style={{
                width:
                  this.props.maxWidth && Number(col.Width)
                    ? 0.01 * this.props.maxWidth * Number(col.Width)
                    : "98%",
              }}
            />
          );
          break;
      }
      return (
        <th
          key={`TableHead/${col.Name}`}
          style={{
            height: "25px",
            width:
              this.state.SortMode && col.Name === this.props.DisplayField
                ? "70%"
                : this.state.SortMode && col.Type === CRUDPropertyType.Control
                ? "30%"
                : col.Width
                ? this.props.maxWidth && Number(col.Width)
                  ? 0.01 * this.props.maxWidth * Number(col.Width)
                  : col.Width
                : "0%",
          }}
        >
          {col.Type === CRUDPropertyType.Control ? (
            <span>
              {col.Name === "Control" || col.Name === "" ? (
                this.state.isLoading ? (
                  <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
                ) : (
                  ControlComponent
                )
              ) : (
                <Icon iconName={"Settings"} />
              )}
            </span>
          ) : (
            <span>
              <Icon iconName={col.IconName} />{" "}
              {col.DisplayName
                ? !this.state.SortMode &&
                  (this.props.Debug ||
                    (this.props.BuiltInFilter &&
                      this.props.Structure.find((f) => f.Name === col.Name)
                        ?.BuiltInFilter === true))
                  ? FilterComponent
                  : col.DisplayName
                : col.Name}
            </span>
          )}
        </th>
      );
    });
  }

  private renderTableRows(): Array<JSX.Element> {
    const sortField = this.props.Structure.find(
      (s) => this.props.SortField && this.props.SortField.includes(s.Name)
    )?.Name;
    return this.state.SortMode
      ? this.onSort(this.state.List, undefined, this.props.SortField).map(
          (item: any) => (
            <tr key={item[this.state.IDName]}>
              <td
                draggable={true}
                onDragStart={(e) =>
                  e.dataTransfer.setData("text", item[this.state.IDName])
                }
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => this.handleDrop(e, item, sortField as string)}
                style={{
                  height: "30px",
                  width: "95%",
                  border: "3px solid #0078d4",
                  textAlign: "center",
                }}
              >
                <Label style={{ cursor: "pointer" }}>
                  {item[this.props.DisplayField]}
                </Label>
              </td>
            </tr>
          )
        )
      : this.state.DisplayList.map((item) => (
          <tr key={`TableRow${item[this.state.IDName]}`}>
            {this.renderTableData(item)}
          </tr>
        ));
  }
  private handleDrop(
    e: React.DragEvent<HTMLTableDataCellElement>,
    item: any,
    sortField: string
  ) {
    const indexDrag = this.findIndexByID(
      this.state.List,
      e.dataTransfer.getData("text")
    );
    const indexDrop = this.findIndexByID(
      this.state.List,
      item[this.state.IDName]
    );
    let List = this.state.List.map((i) => {
      return { ...i };
    });
    const valueDrag: number = List[indexDrag][sortField];
    const valueDrop: number = List[indexDrop][sortField];
    const sortIsDown: boolean = valueDrag < valueDrop;
    List[indexDrag][sortField] = valueDrop;
    this.onSort(
      List.slice(
        sortIsDown ? indexDrag + 1 : indexDrop,
        sortIsDown ? indexDrop + 1 : indexDrag
      ),
      undefined,
      this.props.SortField
    ).forEach((itemResort: any, index: number, all: Array<any>) => {
      const indexCurrent = this.findIndexByID(
        this.state.List,
        itemResort[this.state.IDName]
      );
      const isFirstOrLast: boolean = sortIsDown
        ? index === 0
        : index + 1 === all.length;
      List[indexCurrent][sortField] = isFirstOrLast
        ? valueDrag
        : this.state.List[indexCurrent + (sortIsDown ? -1 : 1)][sortField];
    });
    this.setState({
      List,
      DisplayList: List.map((i) => {
        return { ...i };
      }),
    });
  }

  private renderTableData(item: any, isNew?: boolean): Array<JSX.Element> {
    return this.props.Structure.filter(
      (col) => col.Type !== CRUDPropertyType.ID && !col.Hidden
    ).map((CurrentColumn) => {
      let tag: JSX.Element = (
        <td
          key={`TableData/${item[this.state.IDName]}/${CurrentColumn.Name}`}
          style={{ width: CurrentColumn.Width, textAlign: "center" }}
        >
          CRUDPropertyType Not Implemented
        </td>
      );
      switch (CurrentColumn.Type) {
        case CRUDPropertyType.Control:
          const {
            AnyTypeError,
            AnyChanges,
            Changes,
          } = this.getUpdateValidation(item, isNew);
          tag = (
            <td
              key={`TableData/${item[this.state.IDName]}/${CurrentColumn.Name}`}
              style={{ width: CurrentColumn.Width, textAlign: "center" }}
            >
              {CurrentColumn.Name !== "" && CurrentColumn.Name !== "Control" ? (
                CurrentColumn.Enforced ? (
                  <PrimaryButton
                    disabled={
                      this.state.isLoading ||
                      this.props.onDisable?.(item, {
                        Name: `@${CurrentColumn.Name}`,
                      } as any)
                    }
                    onClick={() => {
                      if (this.props.ActionConfirm) {
                        this.setState({
                          isDialogHidden: false,
                          Operation: {
                            Type: "onAction",
                            ActionName: CurrentColumn.DisplayName,
                            Name: item[this.props.DisplayField],
                            ID: item[this.state.IDName],
                            Value: item,
                          },
                        });
                      } else {
                        this.onAction(item[this.state.IDName], item);
                      }
                    }}
                    text={CurrentColumn.DisplayName}
                    iconProps={{ iconName: CurrentColumn.IconName }}
                    style={{ width: "97%", height: "30px" }}
                  />
                ) : (
                  <DefaultButton
                    disabled={
                      this.state.isLoading ||
                      this.props.onDisable?.(item, {
                        Name: `@${CurrentColumn.Name}`,
                      } as any)
                    }
                    onClick={() => {
                      if (this.props.ActionConfirm) {
                        this.setState({
                          isDialogHidden: false,
                          Operation: {
                            Type: "onAction",
                            ActionName: CurrentColumn.DisplayName,
                            Name: item[this.props.DisplayField],
                            ID: item[this.state.IDName],
                            Value: item,
                          },
                        });
                      } else {
                        this.onAction(item[this.state.IDName], item);
                      }
                    }}
                    text={CurrentColumn.DisplayName}
                    iconProps={{ iconName: CurrentColumn.IconName }}
                    style={{ width: "97%", height: "30px" }}
                  />
                )
              ) : isNew ? (
                <span>
                  <PrimaryButton
                    disabled={
                      this.state.isLoading ||
                      this.props.onDisable?.(item, {
                        Name: "@Add",
                      } as any) ||
                      !AnyChanges ||
                      AnyTypeError
                    }
                    onClick={() => {
                      if (this.props.AddConfirm) {
                        this.setState({
                          isDialogHidden: false,
                          Operation: {
                            Type: "onAdd",
                            Name: item[this.props.DisplayField],
                            ID: item[this.state.IDName],
                            Value: { item: Changes },
                          },
                        });
                      } else {
                        this.onAdd(Changes);
                      }
                    }}
                    text={"Add"}
                    iconProps={{ iconName: "Add" }}
                    style={{
                      width: this.props.AddItemExtra ? "48%" : "97%",
                      height: "30px",
                    }}
                  />
                  {this.props.AddItemExtra && (
                    <PrimaryButton
                      disabled={
                        this.state.isLoading ||
                        this.props.onDisable?.(item, {
                          Name: "@AddExtra",
                        } as any) ||
                        !AnyChanges ||
                        AnyTypeError
                      }
                      onClick={() => {
                        if (this.props.AddConfirm) {
                          this.setState({
                            isDialogHidden: false,
                            Operation: {
                              Type: "onAdd",
                              Name: item[this.props.DisplayField],
                              ID: item[this.state.IDName],
                              Value: {
                                item: Changes,
                                AddItemExtra: this.props.AddItemExtra,
                              },
                            },
                          });
                        } else {
                          this.onAdd(Changes, true);
                        }
                      }}
                      text={this.props.AddItemExtra}
                      iconProps={{ iconName: "Add" }}
                      style={{ width: "48%", height: "30px", marginLeft: "1%" }}
                    />
                  )}
                </span>
              ) : (
                <span>
                  <PrimaryButton
                    disabled={
                      this.state.isLoading ||
                      this.props.onDisable?.(item, {
                        Name: "@Update",
                      } as any) ||
                      !AnyChanges ||
                      AnyTypeError
                    }
                    onClick={() => {
                      if (this.props.UpdateConfirm) {
                        this.setState({
                          isDialogHidden: false,
                          Operation: {
                            Type: "onUpdate",
                            Name: item[this.props.DisplayField],
                            ID: item[this.state.IDName],
                            Value: [
                              {
                                id: item[this.state.IDName],
                                changes: Changes,
                              },
                            ],
                          },
                        });
                      } else {
                        this.onUpdate([
                          {
                            id: item[this.state.IDName],
                            changes: Changes,
                          },
                        ]);
                      }
                    }}
                    text={"Update"}
                    iconProps={{ iconName: "Save" }}
                    style={{ width: "48%", height: "30px" }}
                  />
                  <DefaultButton
                    disabled={
                      this.state.isLoading ||
                      this.props.onDisable?.(item, {
                        Name: `@${AnyChanges ? "Revert" : "Delete"}`,
                      } as any)
                    }
                    onClick={() => {
                      if (AnyChanges) {
                        this.onRevert([item[this.state.IDName]]);
                      } else {
                        if (this.props.DeleteConfirm) {
                          this.setState({
                            isDialogHidden: false,
                            Operation: {
                              Type: "onDelete",
                              Name: item[this.props.DisplayField],
                              ID: item[this.state.IDName],
                              Value: {},
                            },
                          });
                        } else {
                          this.onDelete(item[this.state.IDName]);
                        }
                      }
                    }}
                    text={AnyChanges ? "Revert" : "Delete"}
                    iconProps={{
                      iconName: AnyChanges ? "Back" : "Delete",
                    }}
                    style={{ width: "48%", height: "30px", marginLeft: "1%" }}
                  />
                </span>
              )}
            </td>
          );
          break;
        case CRUDPropertyType.Static:
          tag = (
            <td
              key={`TableData/${item[this.state.IDName]}/${CurrentColumn.Name}`}
              style={{ width: CurrentColumn.Width, textAlign: "center" }}
            >
              <Label
                onClick={() =>
                  this.props.onDisable?.(item, CurrentColumn) ||
                  this.props.onClick?.(item, CurrentColumn)
                }
                style={{
                  cursor:
                    !this.props.onDisable?.(item, CurrentColumn) &&
                    CurrentColumn.Settings?.Cursor
                      ? "pointer"
                      : "",
                  backgroundColor: this.props.onDisable?.(item, CurrentColumn)
                    ? "#F3F2F1"
                    : "",
                  minHeight: "32px",
                  border: "1px solid grey",
                }}
              >
                {item[CurrentColumn.Name] ? item[CurrentColumn.Name] : ""}
              </Label>
            </td>
          );
          break;
        case CRUDPropertyType.Boolean:
          tag = (
            <td
              key={`TableData/${item[this.state.IDName]}/${CurrentColumn.Name}`}
              style={{ width: CurrentColumn.Width, textAlign: "center" }}
            >
              <Toggle
                disabled={this.props.onDisable?.(item, CurrentColumn)}
                onClick={() =>
                  this.props.onDisable?.(item, CurrentColumn) ||
                  this.props.onClick?.(item, CurrentColumn)
                }
                onChange={(e, o) =>
                  this.onChanged(
                    item[this.state.IDName],
                    CurrentColumn,
                    o === true ? true : ""
                  )
                }
                role="checkbox"
                checked={item[CurrentColumn.Name]}
                style={{ marginLeft: 10 }}
              />
            </td>
          );
          break;
        case CRUDPropertyType.Number:
        case CRUDPropertyType.String:
        case CRUDPropertyType.Email:
          tag = (
            <td
              key={`TableData/${item[this.state.IDName]}/${CurrentColumn.Name}`}
              style={{ width: CurrentColumn.Width, textAlign: "center" }}
            >
              <input
                disabled={this.props.onDisable?.(item, CurrentColumn)}
                onClick={() =>
                  this.props.onDisable?.(item, CurrentColumn) ||
                  this.props.onClick?.(item, CurrentColumn)
                }
                onChange={(event) =>
                  this.onChanged(
                    item[this.state.IDName],
                    CurrentColumn,
                    event.target.value
                  )
                }
                style={{ width: "95%", height: "25px" }}
                value={item[CurrentColumn.Name]}
                type="text"
              />
            </td>
          );
          break;
        case CRUDPropertyType.Date:
          tag = (
            <td
              key={`TableData/${item[this.state.IDName]}/${CurrentColumn.Name}`}
              style={{ width: CurrentColumn.Width, textAlign: "center" }}
            >
              <DatePicker
                disabled={this.props.onDisable?.(item, CurrentColumn)}
                onClick={() =>
                  this.props.onDisable?.(item, CurrentColumn) ||
                  this.props.onClick?.(item, CurrentColumn)
                }
                onSelectDate={(date) =>
                  this.onChanged(item[this.state.IDName], CurrentColumn, date)
                }
                value={item[CurrentColumn.Name]}
              />
            </td>
          );
          break;
        case CRUDPropertyType.Enum:
          tag = (
            <td
              key={`TableData/${item[this.state.IDName]}/${CurrentColumn.Name}`}
              style={{ width: CurrentColumn.Width, textAlign: "center" }}
            >
              {!CurrentColumn.Settings?.Multiple ? (
                <Dropdown
                  disabled={this.props.onDisable?.(item, CurrentColumn)}
                  onClick={() =>
                    this.props.onDisable?.(item, CurrentColumn) ||
                    this.props.onClick?.(item, CurrentColumn)
                  }
                  selectedKey={
                    CurrentColumn.Settings?.refAsKey
                      ? item[CurrentColumn.Name][
                          CurrentColumn.Settings?.refAsKey
                        ]
                      : item[CurrentColumn.Name]
                  }
                  options={
                    CurrentColumn.Settings?.Choices
                      ? CurrentColumn.Enforced
                        ? CurrentColumn.Settings?.Choices
                        : CurrentColumn.Settings.refAsKey
                        ? CurrentColumn.Settings?.Choices.concat([
                            {
                              key: "",
                              text: "",
                              value: { [CurrentColumn.Settings.refAsKey]: "" },
                            },
                          ])
                        : CurrentColumn.Settings?.Choices.concat([
                            { key: "", text: "", value: "" },
                          ])
                      : []
                  }
                  onChange={(e, o) =>
                    o &&
                    this.onChanged(
                      item[this.state.IDName],
                      CurrentColumn,
                      (o as any).value
                    )
                  }
                  style={{
                    width:
                      this.props.maxWidth && Number(CurrentColumn.Width)
                        ? 0.01 *
                          this.props.maxWidth *
                          Number(CurrentColumn.Width)
                        : "98%",
                  }}
                />
              ) : (
                <Dropdown
                  disabled={this.props.onDisable?.(item, CurrentColumn)}
                  onClick={() =>
                    this.props.onDisable?.(item, CurrentColumn) ||
                    this.props.onClick?.(item, CurrentColumn)
                  }
                  multiSelect={true}
                  selectedKeys={
                    item[CurrentColumn.Name].map
                      ? CurrentColumn.Settings?.refAsKey
                        ? (item[CurrentColumn.Name] as Array<any>).map(
                            (i) => i[CurrentColumn.Settings?.refAsKey as string]
                          )
                        : item[CurrentColumn.Name]
                      : []
                  }
                  options={
                    CurrentColumn.Settings?.Choices
                      ? CurrentColumn.Settings?.Choices
                      : []
                  }
                  onChange={(e, o) =>
                    o &&
                    this.onChanged(
                      item[this.state.IDName],
                      CurrentColumn,
                      item[CurrentColumn.Name].map
                        ? o?.selected === true
                          ? item[CurrentColumn.Name].concat((o as any).value)
                          : item[CurrentColumn.Name].filter((choice: any) =>
                              CurrentColumn.Settings?.refAsKey
                                ? o.key.toString() !==
                                  choice[CurrentColumn.Settings?.refAsKey]
                                : o.key.toString() !== choice
                            )
                        : [(o as any).value]
                    )
                  }
                  style={{
                    width:
                      this.props.maxWidth && Number(CurrentColumn.Width)
                        ? 0.01 *
                          this.props.maxWidth *
                          Number(CurrentColumn.Width)
                        : "98%",
                  }}
                />
              )}
            </td>
          );
      }
      return tag;
    });
  }
  private findIndexByID(List: Array<any>, ItemID: string): number {
    return List.findIndex((i) => i[this.state.IDName] === ItemID);
  }
  private findByItem(List: Array<any>, Item: any): any {
    return List.find((i) => i[this.state.IDName] === Item[this.state.IDName]);
  }
  private getInitialValue(col: IStructure): any {
    return col.Settings?.Multiple
      ? []
      : col.Settings?.refAsKey
      ? { [col.Settings?.refAsKey]: "" }
      : "";
  }
  private onFilter = (
    list: Array<IItem>,
    filter?: IFilter,
    structureFilter?: Array<ICRUDFilter>
  ): Array<IItem> => {
    let List: Array<IItem> = list;
    let StructureFilter: Array<ICRUDFilter> = structureFilter
      ? structureFilter
      : this.state && this.state.StructureFilter
      ? this.state.StructureFilter
      : [];
    StructureFilter.forEach((col) => {
      if (
        col.Value !== undefined &&
        col.Value !== "" &&
        this.props.Structure.find((s) => s.Name === col.Name)
      ) {
        switch (col.Type) {
          case CRUDPropertyType.Date:
            List = List.filter((i: any) => {
              const solved = col.Settings?.refAsKey
                ? i[col.Name][col.Settings?.refAsKey]
                : i[col.Name];
              return solved
                ? PrintDate(solved) === PrintDate(col.Value)
                : false;
            });
            break;
          case CRUDPropertyType.Enum:
            List = List.filter((i: any) => {
              if ((col.Value as Array<string>).length) {
                if (col.Settings?.Multiple) {
                  return (col.Value as Array<string>).find((c) =>
                    ((col.Settings?.refAsKey
                      ? i[col.Name][col.Settings?.refAsKey]
                      : i[col.Name]) as Array<string>).includes(c)
                  );
                } else {
                  return (col.Value as Array<string>).includes(
                    col.Settings?.refAsKey
                      ? i[col.Name][col.Settings?.refAsKey]
                      : i[col.Name]
                  );
                }
              } else {
                return true;
              }
            });
            break;
          default:
            List = List.filter((i: any) => {
              try {
                return new RegExp(`${col.Value}`, "i").test(
                  `${
                    col.Settings?.refAsKey
                      ? i[col.Name][col.Settings?.refAsKey]
                      : i[col.Name]
                  }`
                );
              } catch (error) {
                return false;
              }
            });
            break;
        }
      }
    });
    return this.props.onFilter ? this.props.onFilter(List, filter) : List;
  };
  private onFilterChange(field: IStructure, value: any) {
    const Value = this.getChangeValidation(field.Type, value, field.Settings);
    let StructureFilter = this.state.StructureFilter;
    const StructureIndex = StructureFilter.findIndex(
      (s) => s.Name === field.Name
    );
    switch (field.Type) {
      case CRUDPropertyType.Boolean:
        StructureFilter[StructureIndex].Value = Value === true ? Value : "";
        break;
      default:
        StructureFilter[StructureIndex].Value =
          Value !== undefined ? Value : StructureFilter[StructureIndex].Value;
        break;
    }
    this.setState({ StructureFilter });
    if (Value !== undefined) {
      this.setState({
        DisplayList: this.onSort(
          this.onFilter(this.state.List, this.props.Filter, StructureFilter),
          this.props.Sort
        ),
      });
    }
  }
  private onSort = (
    list: Array<IItem>,
    sort?: ISort,
    structureSortSelected?: string
  ): Array<IItem> => {
    let List: Array<IItem> = list;
    let StructureSortSelected: string = structureSortSelected
      ? structureSortSelected
      : this.state && this.state.StructureSortSelected
      ? this.state.StructureSortSelected
      : "";
    const isAscendent = StructureSortSelected?.includes("@Up") ? 1 : -1;
    const col = this.props.Structure.find(
      (s) =>
        StructureSortSelected.substring(
          0,
          StructureSortSelected.indexOf("@")
        ) === s.Name
    );
    if (col) {
      switch (col.Type) {
        case CRUDPropertyType.Number:
          List = List.sort((A: any, B: any) => {
            const { ASolve, BSolve } = this.solveFields(col, A, B);
            return Number(`${ASolve}`) > Number(`${BSolve}`) ||
              `${BSolve}` === ""
              ? isAscendent
              : Number(`${BSolve}`) > Number(`${ASolve}`) || `${ASolve}` === ""
              ? -1 * isAscendent
              : 0;
          });
          break;
        case CRUDPropertyType.Date:
          List = List.sort((A: any, B: any) => {
            const { ASolve, BSolve } = this.solveFields(col, A, B);
            return ASolve > BSolve
              ? isAscendent
              : BSolve > ASolve
              ? -1 * isAscendent
              : 0;
          });
          break;
        case CRUDPropertyType.Enum:
          List = List.sort((A: any, B: any) => {
            if (col.Settings?.Multiple) {
              const ASolve = (A[col.Name] as Array<any>).length;
              const BSolve = (B[col.Name] as Array<any>).length;
              return Number(`${ASolve}`) > Number(`${BSolve}`) ||
                `${BSolve}` === ""
                ? isAscendent
                : Number(`${BSolve}`) > Number(`${ASolve}`) ||
                  `${ASolve}` === ""
                ? -1 * isAscendent
                : 0;
            } else if (col.Settings?.Choices) {
              const { ASolve, BSolve } = this.solveFields(col, A, B);
              const Achoice = col.Settings?.Choices.find(
                (c) => c.key === ASolve
              );
              const Bchoice = col.Settings?.Choices.find(
                (c) => c.key === BSolve
              );
              return `${Achoice?.text}`.toUpperCase() >
                `${Bchoice?.text}`.toUpperCase()
                ? isAscendent
                : `${Bchoice?.text}`.toUpperCase() >
                  `${Achoice?.text}`.toUpperCase()
                ? -1 * isAscendent
                : 0;
            } else {
              return 0;
            }
          });
          break;
        default:
          List = List.sort((A: any, B: any) => {
            const { ASolve, BSolve } = this.solveFields(col, A, B);
            return `${ASolve}`.toUpperCase() > `${BSolve}`.toUpperCase()
              ? isAscendent
              : `${BSolve}`.toUpperCase() > `${ASolve}`.toUpperCase()
              ? -1 * isAscendent
              : 0;
          });
          break;
      }
    }
    return this.props.onSort ? this.props.onSort(List, sort) : List;
  };
  private solveFields(col: IStructure, A: any, B: any) {
    const ASolve = col.Settings?.refAsKey
      ? A[col.Name][col.Settings?.refAsKey as string]
      : A[col.Name];
    const BSolve = col.Settings?.refAsKey
      ? B[col.Name][col.Settings?.refAsKey as string]
      : B[col.Name];
    return { ASolve, BSolve };
  }
  private onAction = async (id: string, item: IItem) => {
    if (this.props.onAction) {
      this.setState({ isLoading: true });
      const response = await this.props.onAction(id, item);
      if (response === "true") {
      } else {
        console.error(`⚠️ ${this.props.ItemName} Not Action ⚠️ ${response}`);
        window.alert(`⚠️ ${this.props.ItemName} Not Action ⚠️ ${response}`);
      }
      this.setState({ isDialogHidden: true, isLoading: false });
    }
  };
  private onAdd = async (item: IItem, AddItemExtra?: boolean) => {
    if (this.props.onAdd) {
      this.setState({ isLoading: true });
      const response = await this.props.onAdd(item, AddItemExtra);
      if (response === "true") {
        const NewItem: any = {};
        this.props.Structure.filter(
          (col) => col.Type !== CRUDPropertyType.Control
        ).forEach((col) => (NewItem[col.Name] = this.getInitialValue(col)));
        NewItem[this.state.IDName] = "NewItem";
        this.setState({ NewItem });
        // SET STATE => [NewItem]
      } else {
        console.error(`⚠️ ${this.props.ItemName} Not Added ⚠️ ${response}`);
        window.alert(`⚠️ ${this.props.ItemName} Not Added ⚠️ ${response}`);
      }
      this.setState({ isDialogHidden: true, isLoading: false });
    }
  };
  private onUpdate = async (updates: Array<{ id: string; changes: any }>) => {
    if (this.props.onUpdate) {
      this.setState({ isLoading: true });
      const response = await this.props.onUpdate(updates);
      if (response === "true") {
      } else {
        console.error(`⚠️ ${this.props.ItemName} Not Updated ⚠️ ${response}`);
        window.alert(`⚠️ ${this.props.ItemName} Not Updated ⚠️ ${response}`);
      }
      this.setState({
        isDialogHidden: true,
        SortMode: false,
        isLoading: false,
      });
    }
  };
  private onDelete = async (id: string) => {
    if (this.props.onDelete) {
      this.setState({ isLoading: true });
      const response = await this.props.onDelete(id);
      if (response === "true") {
      } else {
        console.error(`⚠️ ${this.props.ItemName} Not Deleted ⚠️ ${response}`);
        window.alert(`⚠️ ${this.props.ItemName} Not Deleted ⚠️ ${response}`);
      }
      this.setState({ isDialogHidden: true, isLoading: false });
    }
  };
  private onChanged = (id: string, field: IStructure, value: any) => {
    const Value = this.getChangeValidation(field.Type, value, field.Settings);
    if (Value !== undefined) {
      if (id === "NewItem") {
        let NewItem = this.state.NewItem;
        NewItem[field.Name] = Value;
        this.setState({ NewItem });
        // SET STATE => [NewItem]
      } else {
        const index = this.findIndexByID(this.state.List, id);
        const indexDisplay = this.findIndexByID(this.state.DisplayList, id);
        let List = this.state.List;
        let DisplayList = this.state.DisplayList;
        List[index][field.Name] = Value;
        DisplayList[indexDisplay][field.Name] = Value;
        this.setState({ List, DisplayList });
        // SET STATE => [List] [DisplayList]
      }
    }
  };
  private onRevert = (ids: Array<string>) => {
    let List = this.state.List;
    let DisplayList = this.state.DisplayList;
    ids.forEach((id) => {
      const index = this.findIndexByID(this.state.List, id);
      const indexDisplay = this.findIndexByID(this.state.DisplayList, id);
      const indexProps = this.findIndexByID(this.state.prevList, id);
      let Item: any = {};
      this.props.Structure.filter(
        (col) => col.Type !== CRUDPropertyType.Control
      ).forEach(
        (col) =>
          (Item[col.Name] =
            this.state.prevList[indexProps][col.Name] !== undefined
              ? this.state.prevList[indexProps][col.Name]
              : this.getInitialValue(col))
      );
      List[index] = Item;
      DisplayList[indexDisplay] = Item;
    });
    this.setState({ List, DisplayList });
    // SET STATE => [List] [DisplayList]
  };
  private getListChanges(Current: Array<any>, Prev: Array<any>, ID: string) {
    let ItemsAdded: Array<any> = [];
    let ItemsKeep: Array<any> = [];
    Current.forEach((Item: any) => {
      const prevItem: any = Prev.find((prevItem) => prevItem[ID] === Item[ID]);
      if (prevItem) {
        ItemsKeep.push(Item);
      } else {
        ItemsAdded.push(Item);
      }
    });
    const ItemsDeleted: Array<any> = Prev.filter(
      (prevItem) =>
        Current.findIndex(
          (currentItem: any) => prevItem[ID] === currentItem[ID]
        ) === -1
    );
    return { ItemsAdded, ItemsKeep, ItemsDeleted };
  }
  private getUpdateValidation(item: any, isNew?: boolean) {
    let TypeError: Array<boolean> = [];
    let Changed: Array<boolean> = [];
    let ChangedFields: Array<string> = [];
    let Changes: any = {};
    const prevItem = isNew ? {} : this.findByItem(this.state.prevList, item);
    const Item = isNew
      ? this.state.NewItem
      : this.findByItem(this.state.List, item);
    this.props.Structure.filter(
      (col) =>
        col.Type !== CRUDPropertyType.ID &&
        col.Type !== CRUDPropertyType.Control
    ).forEach((col) => {
      const prevItemCol = isNew
        ? this.getInitialValue(col)
        : prevItem[col.Name];
      const ItemCol = Item[col.Name];
      if (col.Settings?.refAsKey) {
        if (col.Settings.Multiple) {
          const { ItemsAdded, ItemsDeleted } = this.getListChanges(
            ItemCol.map ? ItemCol : [],
            prevItemCol.map ? prevItemCol : [],
            col.Settings?.refAsKey
          );
          if (ItemsAdded.length || ItemsDeleted.length) {
            Changed.push(true);
            ChangedFields.push(col.Name);
            Changes = this.setTypeValidation(col, ItemCol, Changes);
          } else {
            Changed.push(false);
          }
        } else {
          const prevItemColKey = prevItemCol[col.Settings?.refAsKey];
          const ItemColKey = ItemCol[col.Settings?.refAsKey];
          if (`${prevItemColKey}` !== `${ItemColKey}`) {
            Changed.push(true);
            ChangedFields.push(col.Name);
            Changes = this.setTypeValidation(col, ItemCol, Changes);
          } else {
            Changed.push(false);
          }
        }
      } else {
        if (`${prevItemCol}` !== `${ItemCol}`) {
          Changed.push(true);
          ChangedFields.push(col.Name);
          Changes = this.setTypeValidation(col, ItemCol, Changes);
        } else {
          Changed.push(false);
        }
      }
      TypeError.push(
        this.getTypeValidation(col.Type, ItemCol, col.Settings, col.Enforced)
      );
    });
    const AnyTypeError = TypeError.findIndex((t) => t === true) !== -1;
    const AnyChanges = Changed.findIndex((c) => c === true) !== -1;
    return { AnyTypeError, AnyChanges, ChangedFields, Changes };
  }
  private setTypeValidation(Field: IStructure, Value: any, Changes: any): any {
    switch (Field.Type) {
      case CRUDPropertyType.Boolean:
        Changes[Field.Name as any] = Value === "" ? false : Value;
        break;
      case CRUDPropertyType.Number:
        Changes[Field.Name as any] = Value === "" ? null : Number(Value);
        break;
      case CRUDPropertyType.Enum:
        if (Field.Settings?.refAsKey) {
          Changes[Field.Name as any] =
            Value[Field.Settings?.refAsKey] === "" ? null : Value;
        } else {
          Changes[Field.Name as any] = Value === "" ? null : Value;
        }
        break;
      default:
        Changes[Field.Name as any] = Value;
        break;
    }
    return Changes;
  }
  private getTypeValidation(
    Type: CRUDPropertyType,
    Value: any,
    Settings?: CRUDPropertyTypeSettings,
    Enforced?: boolean
  ): boolean {
    if (!Enforced) {
      return false;
    } else {
      switch (Type) {
        case CRUDPropertyType.Number:
          if (Number(Value) || `${Value}` === "0") {
            return false;
          } else {
            return true;
          }
        case CRUDPropertyType.Email:
          if (validateEmail(`${Value}`)) {
            return false;
          } else {
            return true;
          }
        case CRUDPropertyType.Enum:
          if (Settings?.Multiple) {
            if (Value.length && Value.length > 0) {
              return false;
            } else {
              return true;
            }
          } else {
            if (Settings?.refAsKey) {
              if (`${Value[Settings?.refAsKey]}` !== "") {
                return false;
              } else {
                return true;
              }
            } else {
              if (`${Value}` !== "") {
                return false;
              } else {
                return true;
              }
            }
          }
        default:
          if (`${Value}` !== "") {
            return false;
          } else {
            return true;
          }
      }
    }
  }
  private getChangeValidation(
    Type: CRUDPropertyType,
    Value: any,
    Settings?: CRUDPropertyTypeSettings
  ): any | undefined {
    switch (Type) {
      case CRUDPropertyType.Number:
        const value: string = removeSpaces(`${Value}`);
        const isNumber =
          Number(value) && !value.includes(".") ? Number(value) : value;
        if (value === "") {
          return value;
        }
        if (Settings?.Negative && !Settings.Decimal) {
          const Negative = /^[-]?[0-9]*$/;
          if (Negative.test(value)) {
            return isNumber;
          }
        } else if (Settings?.Decimal && !Settings?.Negative) {
          const Decimal = new RegExp(
            `^[0-9]*[.]?[0-9]{0,${Settings?.Decimal}}$`
          );
          if (Decimal.test(value)) {
            return isNumber;
          }
        } else if (Settings?.Decimal && Settings?.Negative) {
          const DecimalAndNegative = new RegExp(
            `^[-]?[0-9]*[.]?[0-9]{0,${Settings?.Decimal}}$`
          );
          if (DecimalAndNegative.test(value)) {
            return isNumber;
          }
        } else {
          const Natural = /^[0-9]*$/;
          if (Natural.test(value)) {
            return isNumber;
          }
        }
        return undefined;
      case CRUDPropertyType.Enum:
        if (Settings?.Multiple) {
          return (Value as Array<any>).length <= Settings.Multiple
            ? Value
            : undefined;
        }
        return Value;
      default:
        return Value;
    }
  }
}
