import React, { Component } from "react";
import IApi from "../../api/IApi";
import { IRestaurantCG } from "../../models/IFirestoreModels";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import firebase from "firebase/app";
import CRUD, { CRUDPropertyType } from "../DevelopmentView/CRUD";
import { PrintDate, PrintTime } from "../../util/functions";
import { ITextSMS } from "../../models/ICommunication";

interface ModelEditorTextProps {
  currentUser: firebase.User | null;
  Api: IApi;
  Restaurant: IRestaurantCG;
}
interface ModelEditorTextState {
  isLoading: boolean;
  TextSMSCollection: Array<ITextSMS>;
  nowTextSMSCollection: Array<ITextSMS>;
  pendingTextSMSCollection: Array<ITextSMS>;
  TextSMSSelectedRef: string;
}
export default class ModelEditorText extends Component<
  ModelEditorTextProps,
  ModelEditorTextState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      TextSMSCollection: [],
      nowTextSMSCollection: [],
      pendingTextSMSCollection: [],
      TextSMSSelectedRef: "",
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    if (this.props.currentUser && this.props.Restaurant) {
      this.Api.getCollect(
        "textSMS",
        undefined,
        (nowTextSMSCollection) => {
          this.setState({
            TextSMSCollection: nowTextSMSCollection.concat(
              this.state.pendingTextSMSCollection
            ),
            nowTextSMSCollection,
            isLoading: false,
          });
        },
        "TextSMSSendOn",
        "desc",
        20
      );
      this.Api.getCollect(
        "textSMSPending",
        undefined,
        (pendingTextSMSCollection) => {
          this.setState({
            TextSMSCollection: this.state.nowTextSMSCollection.concat(
              pendingTextSMSCollection
            ),
            pendingTextSMSCollection,
            isLoading: false,
          });
        },
        "TextSMSSendOn",
        "desc",
        20
      );
    }
  }
  componentDidUpdate(
    prevProps: ModelEditorTextProps,
    prevState: ModelEditorTextState,
    snapshot: any
  ) {
    if (
      this.props.currentUser?.uid !== prevProps.currentUser?.uid ||
      this.props.Restaurant?.RestaurantSubCollectionPaths !==
        prevProps.Restaurant?.RestaurantSubCollectionPaths
    ) {
      this.componentDidMount();
    }
  }
  render(): JSX.Element {
    const TextSMSSelected = this.state.TextSMSCollection.find(
      (m: any) => m.ref?.path === this.state.TextSMSSelectedRef
    );
    return (
      <div>
        {this.state.isLoading ? (
          <Spinner style={{ marginTop: 5 }} size={SpinnerSize.large} />
        ) : (
          <CRUD
            List={this.state.TextSMSCollection.map((m) => {
              return {
                ...m,
                ref: m.ref?.path,
                viewContent: (
                  <span>
                    <Icon iconName={"View"} /> View Content
                  </span>
                ),
                TextSMSSendOn:
                  m.TextSMSSendOn &&
                  `${PrintDate(m.TextSMSSendOn)} ${PrintTime(m.TextSMSSendOn)}`,
                TextSMSTriggerDate:
                  m.TextSMSTriggerDate &&
                  `${PrintDate(m.TextSMSTriggerDate)} ${PrintTime(
                    m.TextSMSTriggerDate
                  )}`,
                TextSMSDelivery:
                  m.TextSMSDelivery &&
                  `${PrintDate(m.TextSMSDelivery)} ${PrintTime(
                    m.TextSMSDelivery
                  )}`,
              };
            })}
            ItemName={"TextSMS"}
            DisplayField={"TextSMSBody"}
            Structure={[
              {
                Type: CRUDPropertyType.ID,
                Name: "ref",
              },
              {
                Type: CRUDPropertyType.Enum,
                Name: "TextSMSStatus",
                DisplayName: "Status",
                Enforced: true,
                IconName: "Refresh",
                Width: "10%",
                Settings: {
                  Choices: [
                    { key: "PENDING", text: "PENDING", value: "PENDING" },
                    {
                      key: "PROCESSING",
                      text: "PROCESSING",
                      value: "PROCESSING",
                    },
                    { key: "SUCCESS", text: "SUCCESS", value: "SUCCESS" },
                    { key: "ERROR", text: "ERROR", value: "ERROR" },
                    { key: "RETRY", text: "RETRY", value: "RETRY" },
                  ],
                },
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Number,
                Name: "TextSMSTo",
                Enforced: true,
                DisplayName: "To",
                IconName: "Phone",
                Width: "10%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "TextSMSSendOn",
                Enforced: true,
                DisplayName: "Queue On",
                IconName: "Clock",
                Width: "10%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "TextSMSTriggerDate",
                DisplayName: "Trigger Date",
                IconName: "Clock",
                Width: "10%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "TextSMSDelivery",
                DisplayName: "Delivery Date",
                IconName: "Clock",
                Width: "10%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.String,
                Name: "TextSMSBody",
                DisplayName: "Content",
                IconName: "Message",
                Width: "20%",
                BuiltInFilter: true,
                BuiltInSort: true,
              },
              {
                Type: CRUDPropertyType.Static,
                Name: "viewContent",
                DisplayName: "Content",
                IconName: "Message",
                Width: "10%",
                Settings: { Cursor: true },
              },
              {
                Type: CRUDPropertyType.Control,
                Name: "Control",
                Width: "20%",
              },
            ]}
            onClick={(item, col) => {
              if (col.Name === "viewContent") {
                this.setState({
                  TextSMSSelectedRef: item.ref as string,
                });
              }
            }}
            UpdateAll={true}
            UpdateConfirm={true}
            onUpdate={async (updates) => {
              // console.log("UPD", updates);
              try {
                let promiseUpdates: Array<Promise<void>> = [];
                let promiseDelete: Array<Promise<void>> = [];
                updates.forEach(async (u) => {
                  const isNow = this.state.nowTextSMSCollection.find(
                    (m) => m.ref?.path === u.id
                  );
                  const isPending = this.state.pendingTextSMSCollection.find(
                    (m) => m.ref?.path === u.id
                  );
                  let newRef:
                    | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
                    | undefined = undefined;
                  if (u.changes.TextSMSStatus !== undefined) {
                    if (u.changes.TextSMSStatus === "PENDING" && isNow) {
                      newRef = await this.Api.addDoc("textSMSPending", isNow);
                      promiseDelete.push(this.Api.delDoc(u.id));
                    }
                    if (u.changes.TextSMSStatus !== "PENDING" && isPending) {
                      newRef = await this.Api.addDoc("textSMS", isPending);
                      promiseDelete.push(this.Api.delDoc(u.id));
                    }
                  }
                  promiseUpdates.push(
                    this.Api.updDoc(newRef?.path || u.id, u.changes)
                  );
                });
                await Promise.all(promiseUpdates);
                await Promise.all(promiseDelete);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            DeleteConfirm={true}
            onDelete={async (id) => {
              // console.log("DEL", id);
              try {
                await this.Api.delDoc(id);
                return "true";
                //check for onSnapshot posible issues
              } catch (error) {
                return error;
              }
            }}
            BuiltInFilter={true}
            SortField={"TextSMSSendOn@Down"}
            BuiltInSort={true}
          ></CRUD>
        )}
        <Dialog
          hidden={TextSMSSelected === undefined}
          onDismiss={() => {
            this.setState({ TextSMSSelectedRef: "" });
          }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: `${TextSMSSelected?.TextSMSBody?.substring(0, 60) || ""}...`,
            subText: `To : ${TextSMSSelected?.TextSMSTo || ""}`,
            closeButtonAriaLabel: "Close",
          }}
          modalProps={{
            containerClassName: "ms-dialogMainOverride textDialogHalfTall",
            isBlocking: true,
            styles: { main: { maxWidth: 400 } },
          }}
        >
          {TextSMSSelected?.TextSMSBody || ""}
          <DialogFooter>
            <DefaultButton
              onClick={() => {
                this.setState({ TextSMSSelectedRef: "" });
              }}
              text="Close"
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
