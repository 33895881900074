import React, { Component } from "react";
import {
  IRestaurantOrder,
  IProfile,
  IRestaurantArea,
  IRestaurantItem,
  IProvider,
  IRestaurantCG,
  IRestaurantInventory,
  ProfileRoles,
  IRestaurantItemOrder,
  ProductOrderStates,
  IRestaurantItemInventory,
} from "../../models/IFirestoreModels";
import IApi from "../../api/IApi";
import { PrintDate, PrintTime } from "../../util/functions";
import { Toggle } from "office-ui-fabric-react";
import firebase from "firebase/app";

interface IInventorySelectedProps {
  Api: IApi;
  Profile: IProfile;
  RestaurantOrderCollection: Array<IRestaurantOrder>;
  ProfileCollection: Array<IProfile>;
  AreaCollection: Array<IRestaurantArea>;
  ProductCollection: Array<IRestaurantItem>;
  ProviderCollection: Array<IProvider>;
  CurrentInventorySelected: IRestaurantInventory;
  Restaurant: IRestaurantCG;
  innerHeight: number;
  innerWidth: number;

  AddInventory?: boolean;
  setCurrentInventorySelected: () => void;
}
interface IInventorySelectedState {
  UpdateMode: boolean;
  isUpdating: boolean;
  InventoryProducts: Array<IRestaurantItemInventory>;
}
export default class InventorySelected extends Component<
  IInventorySelectedProps,
  IInventorySelectedState
> {
  Api: IApi;
  constructor(props: any) {
    super(props);
    this.state = {
      UpdateMode: false,
      isUpdating: false,
      InventoryProducts: [],
    };
    this.Api = this.props.Api;
  }
  async componentDidMount() {
    this.setState({
      UpdateMode: this.props.AddInventory === true,
      isUpdating: false,
      InventoryProducts: this.props.AddInventory
        ? this.props.ProductCollection.map((product) => {
            return {
              ProductGUID: product.ref,
              StockLeft: 0,
              LastOrders: this.getLastProductOrders(
                this.props.ProviderCollection,
                this.props.RestaurantOrderCollection,
                product
              ).map((p) => {
                return {
                  OrderGUID: p.ref as firebase.firestore.DocumentReference<
                    firebase.firestore.DocumentData
                  >,
                  ProviderGUID: p.OrderProduct
                    .ProviderGUID as firebase.firestore.DocumentReference<
                    firebase.firestore.DocumentData
                  >,
                };
              }),
            };
          })
        : this.props.CurrentInventorySelected.InventoryProducts.map((p) => {
            return { ...p };
          }),
    });
  }
  componentDidUpdate(
    prevProps: IInventorySelectedProps,
    prevState: IInventorySelectedState,
    snapshot: any
  ) {
    if (
      this.props.CurrentInventorySelected.ref?.id !==
      prevProps.CurrentInventorySelected.ref?.id
    ) {
      this.componentDidMount();
    }
  }
  onChanged = async (field: string, value: any, id?: string) => {
    switch (field) {
      case "StockLeft":
        let InventoryProducts = this.state.InventoryProducts;
        const ProductIndex = InventoryProducts.findIndex(
          (p) => p.ProductGUID?.id === id
        );
        InventoryProducts[ProductIndex].StockLeft =
          Number(value) && Number(value) >= 0 ? Number(value) : 0;
        this.setState({ InventoryProducts });
        break;
      case "UpdateMode":
        if (this.state.UpdateMode) {
          this.setState({ isUpdating: true });
        }
        this.setState({
          UpdateMode: value,
          isUpdating: false,
        });
        break;
    }
  };
  renderProducts(): Array<JSX.Element> {
    return this.state.InventoryProducts.map((Inventory, index) => {
      const Product = this.props.ProductCollection.find(
        (p) => p.ref?.id === Inventory.ProductGUID?.id
      );
      const Area = Product
        ? this.props.AreaCollection.find(
            (a) => a.ref?.id === Product.AreaGUID?.id
          )
        : undefined;
      return (
        <tr
          key={`${index}${Inventory.ProductGUID?.id}`}
          style={{
            backgroundColor: index % 2 ? "#DCDCDC" : "white",
            color: "black",
          }}
        >
          <td style={{ width: "15%", textAlign: "center" }}>
            {Area ? Area.AreaName : "N/A"}
          </td>
          <td style={{ width: "15%" }}>
            {Product ? Product.ProductName : "N/A"}
          </td>
          {this.state.UpdateMode ? (
            <td style={{ width: "10%", textAlign: "center" }}>
              <input
                style={{ width: "75%" }}
                onChange={(e) =>
                  this.onChanged(
                    "StockLeft",
                    e.target.value,
                    Inventory.ProductGUID?.id
                  )
                }
                disabled={this.state.isUpdating}
                value={Inventory.StockLeft !== 0 ? Inventory.StockLeft : ""}
                type="number"
              />
            </td>
          ) : (
            <td style={{ width: "10%", textAlign: "center" }}>
              {Inventory.StockLeft}
            </td>
          )}
          <td style={{ width: "10%" }}>{"$"}</td>
          <td style={{ width: "50%" }}>
            <table>
              <tbody>{this.renderProductsLastOrders(Inventory)}</tbody>
            </table>
          </td>
        </tr>
      );
    });
  }
  renderProductsLastOrders(
    Inventory: IRestaurantItemInventory
  ): Array<JSX.Element> {
    // let StockLeft = Inventory.StockLeft;
    return Inventory.LastOrders.map((orderRef, index) => {
      const Order = this.props.RestaurantOrderCollection.find(
        (o) => o.ref?.id === orderRef.OrderGUID.id
      );
      const Provider = this.props.ProviderCollection.find(
        (p) => p.ref?.id === orderRef.ProviderGUID?.id
      );
      const OrderProduct = Order
        ? Order.OrderProducts.find(
            (p) =>
              p.ProductGUID?.id === Inventory.ProductGUID?.id &&
              p.ProviderGUID?.id === orderRef.ProviderGUID.id
          )
        : undefined;
      if (OrderProduct) {
        const {
          ProductPackPrice,
          ProductUnitPerPack,
          ProductUnitAmount,
          ProductUnitMesure,
          ProductOrderAmount,

          ProductOrderState,
          ProductOrderIncomingAmount,
        } = OrderProduct;
        // StockLeft -= ProductOrderIncomingAmount
        //   ? ProductOrderIncomingAmount
        //   : ProductOrderAmount;
        return (
          <tr
            key={`${index}${Inventory.ProductGUID?.id}${orderRef.OrderGUID.id}${orderRef.ProviderGUID.id}`}
          >
            <td style={{ width: "10%", textAlign: "center" }}>
              {Provider ? Provider?.ProviderName : "N/A"}
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>
              {ProductOrderState === ProductOrderStates.Meat
                ? `${ProductOrderAmount}cs ~ ${ProductOrderIncomingAmount}pounds`
                : `${ProductOrderAmount} ~ ${
                    ProductOrderIncomingAmount ? ProductOrderIncomingAmount : 0
                  } ~ ${Inventory.StockLeft}`}
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>
              {ProductPackPrice}
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>
              {ProductUnitPerPack}/{ProductUnitAmount} {ProductUnitMesure}
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>
              {Order ? PrintDate(Order.OrderDelivery) : ""}
            </td>
          </tr>
        );
      } else {
        return (
          <tr
            key={`${index}${Inventory.ProductGUID?.id}${orderRef.OrderGUID.id}${orderRef.ProviderGUID.id}`}
          >
            N/A
          </tr>
        );
      }
    });
  }
  render(): JSX.Element {
    const OverFlowStyle: React.CSSProperties = {
      width: "100%",
      height: `${this.props.innerHeight - 225}px`,
      overflowY: "scroll",
    };
    const OrderTableStyle: React.CSSProperties = {
      fontFamily: "Arial",
      width: "100%",
    };
    const OrderTableHeadStyle: React.CSSProperties = {
      backgroundColor: "#0078d4",
      color: "white",
    };
    const RecipeLastUpdateProfile: IProfile = this.props.ProfileCollection.find(
      (profile) =>
        profile.ref?.id ===
        this.props.CurrentInventorySelected.InventoryCreateProfile?.id
    ) as IProfile;

    const sProfile: string = RecipeLastUpdateProfile
      ? RecipeLastUpdateProfile.ProfileContactName
      : "N/A";

    const RecipeLastUpdateDate: firebase.firestore.Timestamp = this.props
      .CurrentInventorySelected.InventoryCreateDate;

    const sDate: string = RecipeLastUpdateDate
      ? PrintDate(RecipeLastUpdateDate)
      : "N/A";

    const sTime: string = RecipeLastUpdateDate
      ? PrintTime(RecipeLastUpdateDate)
      : "N/A";
    return (
      <div>
        <table style={OrderTableStyle}>
          <thead style={OrderTableHeadStyle}>
            <tr>
              <th style={{ width: "10%", textAlign: "center" }}>Date</th>
              <th style={{ width: "10%", textAlign: "center" }}>Time</th>
              <th
                style={{
                  width:
                    this.props.Profile.ProfileRole === ProfileRoles.Dev ||
                    this.props.Profile.ProfileRole === ProfileRoles.Admin
                      ? "70%"
                      : "80%",
                }}
              >
                Last Update Profile
              </th>
              {(this.props.Profile.ProfileRole === ProfileRoles.Dev ||
                this.props.Profile.ProfileRole === ProfileRoles.Admin) && (
                <th style={{ width: "10%" }}>
                  {this.state.UpdateMode ? "Save" : "Update"}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "center" }}>{sDate}</td>
              <td style={{ textAlign: "center" }}>{sTime}</td>
              <td>{sProfile}</td>
              {(this.props.Profile.ProfileRole === ProfileRoles.Dev ||
                this.props.Profile.ProfileRole === ProfileRoles.Admin) && (
                <td style={{ textAlign: "center" }}>
                  <Toggle
                    checked={this.state.UpdateMode}
                    onChange={(event: any, checked?: boolean | undefined) =>
                      this.onChanged("UpdateMode", checked === true)
                    }
                    disabled={this.state.isUpdating}
                    role="checkbox"
                  />
                </td>
              )}
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <table style={OrderTableStyle}>
          <thead style={OrderTableHeadStyle}>
            <tr>
              <th style={{ width: "15%", textAlign: "center" }}>Area</th>
              <th style={{ width: "15%", textAlign: "center" }}>Item</th>
              <th style={{ width: "10%", textAlign: "center" }}>Stock Left</th>
              <th style={{ width: "10%", textAlign: "center" }}>Extention</th>

              <th style={{ width: "10%", textAlign: "center" }}>Vendor</th>
              <th style={{ width: "10%", textAlign: "center" }}>
                Amount Ordered ~ Checked ~ Left
              </th>
              <th style={{ width: "10%", textAlign: "center" }}>Case Price</th>
              <th style={{ width: "10%", textAlign: "center" }}>
                Units per Case
              </th>
              <th style={{ width: "10%", textAlign: "center" }}>
                Order Delivery
              </th>
            </tr>
          </thead>
        </table>
        <div style={OverFlowStyle}>
          <table style={OrderTableStyle}>
            <tbody>{this.renderProducts()}</tbody>
          </table>
        </div>
      </div>
    );
  }
  getLastProductOrders(
    ProviderCollection: Array<IProvider>,
    pastOrders: Array<IRestaurantOrder>,
    product: IRestaurantItem
  ): Array<{
    ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    OrderDate: firebase.firestore.Timestamp;
    OrderDelivery: firebase.firestore.Timestamp;
    OrderProduct: IRestaurantItemOrder;
  }> {
    const pastOrdersWithSameProduct = pastOrders
      //Filter Conditions of the Product Order
      .filter(
        (o) =>
          o.OrderProducts.findIndex(
            (p) => p.ProductGUID?.id === product.ref?.id
          ) !== -1
      )
      //Sort to get more recent Order Delivery first
      .sort((A, B) =>
        A.OrderDelivery > B.OrderDelivery
          ? -1
          : A.OrderDelivery < B.OrderDelivery
          ? 1
          : 0
      );
    //Filter to get Only One Order Per Provider
    let pastOrdersWithSameProductOnlyOnePerProvider: Array<IRestaurantOrder> = [];
    ProviderCollection.forEach((provider) => {
      let providerPastOrdersWithSameProduct = pastOrdersWithSameProduct.find(
        (o) =>
          o.OrderProducts.findIndex(
            (p) =>
              p.ProductGUID?.id === product.ref?.id &&
              p.ProviderGUID?.id === provider.ref?.id
          ) !== -1
      );
      if (providerPastOrdersWithSameProduct) {
        pastOrdersWithSameProductOnlyOnePerProvider.push({
          ...providerPastOrdersWithSameProduct,
          OrderProducts: providerPastOrdersWithSameProduct.OrderProducts.filter(
            (p) =>
              p.ProductGUID?.id === product.ref?.id &&
              p.ProviderGUID?.id === provider.ref?.id
          ),
        });
      }
    });
    return (
      pastOrdersWithSameProductOnlyOnePerProvider
        //Map of the Orders to get only the Product Data and Metadata (Order Date & Order Delivery)
        .map((o) => {
          return {
            ref: o.ref,
            OrderDate: o.OrderDate,
            OrderDelivery: o.OrderDelivery,
            OrderProduct: o.OrderProducts.find(
              (p) => p.ProductGUID?.id === product.ref?.id
            ) as IRestaurantItemOrder,
          };
        })
        //Sort to get more recent Order Delivery first
        .sort((A, B) =>
          A.OrderDelivery > B.OrderDelivery
            ? -1
            : A.OrderDelivery < B.OrderDelivery
            ? 1
            : 0
        )
    );
  }
}
